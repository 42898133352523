import {ScoreCardView} from '@platform/score-cards/score-card-view';
import {Concept} from '@platform/models/concept.model';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatSort, Sort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {ExportPngService} from '@platform/services/export-png.service';
import {UserService} from '@platform/services/user.service';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {AttributesDeliverableView} from './models/attributes.model';
import {AttributesFilter} from './models/filter.model';
import {AttributesMetaInfo} from './models/view-meta-info.model';
import {AttributesService} from './services/attributes.service';
import {ChartDataService} from './services/chart-data.service';
import {combineLatest, forkJoin, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {DeliverableInsightService} from '@platform/services/deliverable-insight.service';
import {SpinnerService} from '@platform/services/spinner.service';
import {DeliverableInsight} from '@platform/deliverable-insight/deliverable-insight.model';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelLabel, MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {ReportService} from '@platform/services/report.service';
import {DeliverableInfo} from "@platform/models/deliverable-info.model";
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';
import {Report} from "@platform/models/report.model";
import {UserView} from "@platform/models/user-view.model";
import {WordCloudFilter} from "@app/deliverables/word-cloud/models/filter.model";
import {UserViewService} from '@platform/services/user-view.service';
import {RouterService} from "@platform/services/router.service";
import {InsightService} from "@platform/insights/insights.service";
import {FilterService} from "@platform/services/filter.service";
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';

/**
 * `<ns-attributes>` component builds attributes deliverable for both
 * concepts and subgroups deliverable views.
 *
 * @example
 * <ns-attributes></ns-attributes>
 *
 * @export
 * @class AttributesComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'ns-attributes',
    templateUrl: './attributes.component.html',
    styleUrls: ['./attributes.component.scss', './attributes-common.component.scss']
})
export class AttributesComponent implements OnInit, OnDestroy, ScoreCardView {

    /**
     * Attributes deliverable view data.
     *
     * @type {AttributesDeliverableView}
     * @member AttributesComponent
     */
    public attributes: AttributesDeliverableView;

    /**
     * Attributes deliverable view filter object.
     *
     * @type {AttributesFilter}
     * @member AttributesComponent
     */
    public filter: AttributesFilter;

    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @member AttributesComponent
     */
    public subscriptions: Array<Subscription>;
    /**
     * Datasource object for the angular material table.
     *
     * @member AttributesComponent
     */
    public dataSource: MatTableDataSource<any>;

    /**
     * Array of displayed columns keys.
     *
     * @type {Array<string>}
     * @member AttributesComponent
     */
    public displayedColumns: Array<string>;

    /**
     * Array of static column indexes passed to `<ns-swipe-table>`
     * component.
     *
     * @type {number[]}
     * @member AttributesComponent
     */
    public staticColumns: number[];

    /**
     * Array of all column widths passed to `<ns-swipe-table>`
     * component.
     *
     * @type {number[]}
     * @member AttributesComponent
     */
    public columnWidths: number[];

    /**
     * All column headers for the attributes table.
     *
     * @type {Array<any>}
     * @member AttributesComponent
     */
    public colHeaders: Array<any>;


    /**
     * The deliverable insight data when creating insight.
     * @type {DeliverableInsight} deliverableData
     * @memberOf AttributeComponent
     */
    public deliverableData: DeliverableInsight;

    /**
     * The AttributesDeliverableView.
     * @type {AttributesDeliverableView} attributesDeliverableView
     * @memberOf AttributeComponent
     */
    public attributesDeliverableView: AttributesDeliverableView;

    public deliverableViews: Array<DeliverableView>;

    public userViews: Array<UserView>;

    /**
     * toggle insight btn
     * @type {Boolean} isInsightEnable
     * @memberOf AttributeComponent
     */
    public isInsightEnable = false;

    public isInternalUser: Boolean;

    /**
     * Meta info for Attributes
     *
     * @type {AttributesDeliverableView}
     * @memberOf AttributeComponent
     */
    public viewAttributesMetaInfo: AttributesMetaInfo;

    /**
     * Spinner.
     *
     * @type {Array<any>}
     * @member AttributesComponent
     */
    public displayProgressSpinner = false;

    public disableBtn: boolean;
    /**
     * View child for angular material table sorting.
     *
     * @type {MatSort}
     * @member AttributesComponent
     */
    @ViewChild(MatSort) sort: MatSort;

    /**
     * Sets concept object for activation profile.
     *
     * @type {Concept}
     * @memberof AttributesComponent
     */
    public scoreCardConcept: Concept;
    public selectedView: string;
    public addHTMLToInsight = true;

    /**
     * Feature FLAG for Automatic Headlines.
     *
     * @type {Boolean}
     */
    public isAutomatedHeadlinesEnabled: boolean;

    public deliverableInfos: Array<DeliverableInfo>;

    /**
     * Report document
     */
    public report: Report;

    public deliverableType = DeliverableType.ATTRIBUTES.type;

    public defaultViewFilters: AttributesFilter;

    /**
     * Creates an instance of AttributesComponent and initialize
     * the component data.
     *
     * @constructor
     * @param {AttributesService} attributesService
     * @param {ChartDataService} chartDataService
     * @param exportPNGService
     * @param viewMetaInfoService
     * @param spinnerService
     * @param deliverableInsightService
     * @param userService
     * @member AttributesComponent
     */
    constructor(
        private attributesService: AttributesService,
        private chartDataService: ChartDataService,
        private deliverableInfoService: DeliverableInfoService,
        private deliverableInsightService: DeliverableInsightService,
        private deliverableViewService: DeliverableViewService,
        private exportPNGService: ExportPngService,
        private filterService: FilterService,
        private insightService: InsightService,
        private mixpanelService: MixpanelService,
        private productDeliverableViewService: ProductDeliverableViewService,
        private reportService: ReportService,
        private routerService: RouterService,
        private spinnerService: SpinnerService,
        private translate: TranslateService,
        private userService: UserService,
        private userViewService: UserViewService,
        private viewMetaInfoService: ViewMetaInfoService,
    ) {
        this.displayedColumns = [];
        this.subscriptions = [];
        this.staticColumns = [];
    }

    /**
     * Initialize component
     *
     * @memberof WordCloudComponent
     */
    ngOnInit(): void {
        this.init();
        this.reportService.reloadDeliverable.subscribe(() => {
            this.productDeliverableViewService.clearCache();
            this.deliverableViewService.clearCache();
            this.clearSubscriptions();
            this.init();
        });
    }

    /**
     * Initialize the attributes component view.
     *
     * @member AttributesComponent
     */
    init(): void {
        const insightId = this.routerService.getQueryParam('insightId');
        this.subscriptions.push(combineLatest([
            this.reportService.get(),
            this.userService.getUser(),
            this.deliverableViewService.getDeliverableViews(DeliverableType.ATTRIBUTES.type, true)
        ]).subscribe(([report, user, deliverableViews]) => {
            this.report = report;
            this.deliverableInfos = this.deliverableInfoService.getNonForecastDeliverables(report);
            this.deliverableViews = deliverableViews;
            this.isInternalUser = user.isInternalUser;
            this.isAutomatedHeadlinesEnabled = user.featureFlags.includes('REPORTING_AUTOMATED_HEADLINES');

            this.subscriptions.push(forkJoin([
                this.userViewService.fetchReportUserViewsFromAPI(this.report.id),
                this.insightService.getInsightFilterData<WordCloudFilter>(report.id, insightId),
                this.attributesService.loadDefaultFilter(this.scoreCardConcept)
            ]).pipe(debounceTime(1000)).subscribe(([userViews, insightFilter, defaultViewFilters]) => {
                this.userViews = this.userViewService.setupUserViews(this.report.id, this.deliverableType, userViews, defaultViewFilters, insightFilter);
                this.defaultViewFilters = defaultViewFilters;
                const insightView = this.userViews.find(it => it.id === this.userViewService.insightViewId);
                this.selectUserView(insightView ? insightView : this.userViews.find(it => it.isSelected));

                /**
                 * Add subscription to watch filter changes here so that s&w deliverable data can also be updated as per filter change.
                 * */
                this.subscriptions.push(combineLatest([
                    this.attributesService.getAttributesFilter(),
                    this.attributesService.getAttributes(),
                    this.viewMetaInfoService.get<AttributesMetaInfo>(this.deliverableType)
                ]).subscribe(([filters, attributes, viewMetaInfo]) => {
                    this.filter = filters;
                    this.viewAttributesMetaInfo = viewMetaInfo;
                    this.isAutomatedHeadlinesEnabled = user.featureFlags.includes('REPORTING_AUTOMATED_HEADLINES');
                    const [colHeaders, chartData] = this.chartDataService.getChartData(filters, attributes, viewMetaInfo);
                    this.dataSource = (Object.keys(viewMetaInfo).length > 1) ? this.columnSortingOnSavedView(chartData, viewMetaInfo) : this.columnSorting(chartData, colHeaders);
                    this.colHeaders = colHeaders;
                    this.staticColumns = [0];
                    this.columnWidths = this.getColumnWidths(filters, colHeaders);
                    this.disableBtn = this.isConceptSubgroupExists(filters);
                    this.selectedView = (filters.compare.find(selected => selected.isSelected === true).name === 'Concepts') ? 'Concepts' : 'Subgroups';

                    setTimeout(() => {
                        this.deliverableData = {
                            title: this.isAutomatedHeadlinesEnabled ? this.deliverableInsightService.generateInsightTitle('Attributes') : '',
                            deliverable: {
                                deliverableViewId: this.deliverableViews.length > 0 ? this.deliverableViews[0].id : this.attributesDeliverableView.id,
                                filter: this.filter,
                                metaInfo: this.viewAttributesMetaInfo
                            }
                        };
                    }, 1000);

                }));
            }));
        }));
    }

    /**
     * Default sorting behavior if there is no view meta info
     * Default sorted by  first row in Attributes deliverable
     * @param filter
     * @param colHeaders
     */
    private columnSorting(chartData: any, colHeaders: any) {
        const dataSource = new MatTableDataSource(chartData);
        dataSource.sortingDataAccessor = this.matSortingDataAccessor;
        if (colHeaders[1] !== undefined && this.sort) {
            const sortState: Sort = {active: colHeaders[1].name, direction: ''};
            this.sort.active = sortState.active;
            this.sort.direction = sortState.direction;
            this.sort.sortChange.emit(sortState);
        }
        if (this.sort) {
            dataSource.sort = this.sort;
        }
        return dataSource;
    }

    /**
     * Upon navigation to saved view sort the data based on existing view meta info
     * @param chartData
     * @param viewMetaInfo
     */
    private columnSortingOnSavedView(chartData: any, viewMetaInfo: AttributesMetaInfo) {
        const dataSource = new MatTableDataSource(chartData);
        dataSource.sortingDataAccessor = this.matSortingDataAccessor;
        if (Object.keys(viewMetaInfo).length > 1 && viewMetaInfo.sortInfo && Object.keys(viewMetaInfo.sortInfo).length > 0) {
            const sortState: Sort = {
                active: viewMetaInfo.sortInfo.columnHeaderName,
                direction: viewMetaInfo.sortInfo.sortDirection
            };
            this.sort.active = sortState.active;
            this.sort.direction = sortState.direction;
            this.sort.sortChange.emit(sortState);
            dataSource.sort = this.sort;
        }
        return dataSource;
    }

    /**
     * Angular material sorting data accessor function for attributes.
     *
     * @param {*} data
     * @param {string} sortHeaderId
     * @returns {(string | number)}
     * @member ChartDataService
     */
    public matSortingDataAccessor(data: any, sortHeaderId: string): string | number {
        const cellData = data[sortHeaderId];
        if (!cellData) {
            return 0;
        }
        return sortHeaderId === 'statement' ? cellData : cellData.originalSum;
    }

    /**
     * Return an array of column widths for all columns in the table.
     *
     * @param {AttributesFilter} filter The attributes filter
     * @param {any[]} colHeaders The array of Headers
     */
    private getColumnWidths(filter: AttributesFilter, colHeaders: any[]): number[] {
        const minWidth = filter.show.barCharts ? 345 : 150;
        const columnWidths = Array(colHeaders.length).fill(minWidth);
        columnWidths[0] = 140;
        return columnWidths;
    }

    /**
     * Triggers mixpanel event on clicking 'Next' button in table
     *
     * @param none
     */
    nextPage(): void {
        this.mixpanelService.track(MixpanelLabel.attributes, MixpanelEvent.nextConcept);
    }

    /**
     * Triggers mixpanel event on clicking 'Previous' button in table
     *
     * @param none
     */
    previousPage(): void {
        this.mixpanelService.track(MixpanelLabel.attributes, MixpanelEvent.previousConcept);
    }

    /**
     * Updates displayed columns.
     *
     * @param {number[]} visibleColumns array of visible column indexes.
     */
    public update(visibleColumns: number[]): void {
        const columns = [];
        this.colHeaders.forEach((item, index) => {
            if (visibleColumns.indexOf(index) >= 0) {
                columns.push(item.name);
            }
        });
        this.displayedColumns = columns;
    }

    /**
     * Returns unique id for the loop to be refreshed.
     *
     * @param {number} index the attribute table column for loop index
     * @param {any} item the colHeader object
     */
    public trackItem(index: number, item: any): string {
        return `${index}-${item.id ? item.id : 0}`;
    }

    /**
     * Set Views
     * @param evt { String }
     */
    setViews(evt: any) {
        /**
         * ToDo:
         * Create a data model for views which has all details (sorting, highlights, filters, Insights ... )
         * Store the view in the DB
         * Create a Default View (set to defaults)
         * Set the view data on load
         */
        console.log(evt);
    }

    /**
     * Cleanup hook.
     *
     * @member AttributesComponent
     */
    ngOnDestroy(): void {
        this.clearSubscriptions();
        this.deliverableViewService.clearCache();
    }

    clearSubscriptions(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    /**
     * toggle between headers and insight creation form.
     *
     */
    openInsightCreationForm() {
        this.isInsightEnable = true;
    }

    /*
    * updates insightHTML node by adding <style>
    */
    updateNode(node) {
        // TODO: Need to change this logic.
        // appending style tag as node's firstChildElement - to fix style issues of sorting matrix & attributes DOM - S2-910
        node.insertAdjacentHTML('afterbegin', `<style>.mat-sort-header-container{display:flex;cursor:pointer;align-items:center}.mat-sort-header-disabled .mat-sort-header-container{cursor:default}.mat-sort-header-position-before{flex-direction:row-reverse}.mat-sort-header-button{border:none;background:0 0;display:flex;align-items:center;padding:0;cursor:inherit;outline:0;font:inherit;color:currentColor;position:relative}[mat-sort-header].cdk-keyboard-focused .mat-sort-header-button,[mat-sort-header].cdk-program-focused .mat-sort-header-button{border-bottom:solid 1px currentColor}.mat-sort-header-button::-moz-focus-inner{border:0}.mat-sort-header-arrow{height:12px;width:12px;min-width:12px;position:relative;display:flex;opacity:0}.mat-sort-header-arrow,[dir=rtl] .mat-sort-header-position-before .mat-sort-header-arrow{margin:0 0 0 6px}.mat-sort-header-position-before .mat-sort-header-arrow,[dir=rtl] .mat-sort-header-arrow{margin:0 6px 0 0}.mat-sort-header-stem{background:currentColor;height:10px;width:2px;margin:auto;display:flex;align-items:center}.cdk-high-contrast-active .mat-sort-header-stem{width:0;border-left:solid 2px}.mat-sort-header-indicator{width:100%;height:2px;display:flex;align-items:center;position:absolute;top:0;left:0}.mat-sort-header-pointer-middle{margin:auto;height:2px;width:2px;background:currentColor;transform:rotate(45deg)}.cdk-high-contrast-active .mat-sort-header-pointer-middle{width:0;height:0;border-top:solid 2px;border-left:solid 2px}.mat-sort-header-pointer-left,.mat-sort-header-pointer-right{background:currentColor;width:6px;height:2px;position:absolute;top:0}.cdk-high-contrast-active .mat-sort-header-pointer-left,.cdk-high-contrast-active .mat-sort-header-pointer-right{width:0;height:0;border-left:solid 6px;border-top:solid 2px}.mat-sort-header-pointer-left{transform-origin:right;left:0}.mat-sort-header-pointer-right{transform-origin:left;right:0}
    </style>`);
    }

    /**
     * Close insight form
     */
    closeInsight() {
        this.isInsightEnable = false;
    }

    /**
     * capture screen layout and export as png.
     *
     */
    exportAsPNG() {
        this.displayProgressSpinner = true;
        this.exportPNGService.exportPNG();
        this.spinnerService.getSpinnerObs().subscribe(loading => this.displayProgressSpinner = loading);
        this.mixpanelService.track(MixpanelLabel.attributes, MixpanelEvent.exportAsPNG);
    }

    /**
     * Right click for row highlight.
     *
     * @memberof SurveyQuestionComponent
     */
    onContextMenu(event: MouseEvent, row: any) {
        event.preventDefault();
        this.setUserViewRowHighlights(row, 'negative');
    }

    /**
     * On click for row highlight.
     *
     * @memberof AttributesComponent
     */
    rowHighlight(event: MouseEvent, row: any) {
        event.preventDefault();
        this.setUserViewRowHighlights(row, 'positive');
    }

    /**
     * Update row highlights information in view meta info store.
     *
     * @memberof AttributesComponent
     */
    setUserViewRowHighlights(row: any, color: string) {
        const deliverableType = DeliverableType.ATTRIBUTES.type;
        const viewInfo = {deliverableType, rowHighlights: [], sortInfo: {}};
        viewInfo.rowHighlights = Object.keys(this.viewAttributesMetaInfo).length > 1 ? Object.values(this.viewAttributesMetaInfo.rowHighlights) : [];
        viewInfo.sortInfo['columnHeaderName'] = this.dataSource.sort.active;
        viewInfo.sortInfo['sortDirection'] = this.dataSource.sort.direction;
        viewInfo.sortInfo = Object.keys(this.viewAttributesMetaInfo).length > 1 ? this.viewAttributesMetaInfo.sortInfo : viewInfo.sortInfo;
        let initialLength, updatedLength;
        if (viewInfo.rowHighlights.length) {
            initialLength = viewInfo.rowHighlights.length;
            viewInfo.rowHighlights = viewInfo.rowHighlights.filter(x => x.rowId !== row.statement);
            updatedLength = viewInfo.rowHighlights.length;
        }
        if (initialLength === updatedLength) {
            viewInfo.rowHighlights.push({
                rowId: row.statement,
                color: color
            });
            this.mixpanelService.track(MixpanelLabel.attributes,
                `${color == 'positive' ? MixpanelEvent.tableHighlightPositive : MixpanelEvent.tableHighlightNegative}`);
        }
        this.viewMetaInfoService.update(viewInfo, deliverableType);
    }

    /**
     * on Click of Column headers for sorting
     * @param event
     * @param colHeaderId
     * @param colHeaderName
     */
    onClickHeader(event: MouseEvent, colHeaderId: any, colHeaderName: string) {
        event.preventDefault();
        this.mixpanelService.track(MixpanelLabel.attributes, MixpanelEvent.tableSort);
        this.setUserViewSorting(colHeaderId, colHeaderName);
    }

    /**
     * Updates the sorting info in the ViewMetaInfo store
     * @param colHeaderId
     * @param colHeaderName
     */
    setUserViewSorting(colHeaderId: any, colHeaderName: string) {
        const deliverableType = DeliverableType.ATTRIBUTES.type;
        const viewInfo = {deliverableType, rowHighlights: [], sortInfo: {}};
        viewInfo.sortInfo['columnHeaderId'] = colHeaderId;
        viewInfo.sortInfo['columnHeaderName'] = colHeaderName;
        viewInfo.sortInfo['sortDirection'] = this.sort.direction;
        viewInfo.rowHighlights = Object.keys(this.viewAttributesMetaInfo).length > 1 ? Object.values(this.viewAttributesMetaInfo.rowHighlights) : [];
        this.viewMetaInfoService.update(viewInfo, deliverableType);
    }

    /**
     * check concept count
     * */
    isConceptSubgroupExists(filter: AttributesFilter): boolean {
        const conceptCount = filter.concepts.filter(it => it.isSelected === true).length;
        const subgroupCount = filter.subgroups.filter(it => it.isSelected === true).length;
        return (conceptCount === 0 || subgroupCount === 0);
    }

    getSortOrder(headerName: string) {
        let sortOrder = this.translate.instant('sort.lowest.to.highest');
        if (this.sort && this.sort.active === headerName) {
            if (this.sort.direction === 'asc') {
                sortOrder = this.translate.instant('sort.highest.to.lowest');
            } else if (this.sort.direction === 'desc') {
                sortOrder = this.translate.instant('sort.reset.to.default.order');
            }
        }
        return sortOrder;
    }

    /**
     * Action that is triggered when the deliverable info is changed.
     *
     * @param deliverableInfo
     */
    onDeliverableChange(deliverableInfo: DeliverableInfo): void {
        this.deliverableInfoService.routeToDeliverable(deliverableInfo);
    }

    /**
     * Method that is triggered when user view is changed. This will in turn update the filter model in the store.
     * */
    selectUserView(userView: UserView): void {
        this.filter = userView.filter as AttributesFilter;
        if (userView.id !== 'Default View') {
            const conceptsAdded = this.userViewService.updateUserViewConceptsWithDefaultViewConcepts(this.filter , this.defaultViewFilters);
            this.filterService.update({
                ...this.filter,
                concepts: conceptsAdded
            });
        } else {
            this.filterService.update(userView.filter);
        }
    }
}
