import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output} from '@angular/core';
import {DeliverableConfigurationUtilService} from '@platform/services/deliverable-configuration-util.service';
import {ConfirmationDialogComponent} from '@app/common/confirmation-dialog/confirmation-dialog.component';
import {ConfirmationDialogService} from '@app/common/confirmation-dialog/confirmation-dialog.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {SpinnerService} from '@platform/services/spinner.service';
import {ReportService} from '@platform/services/report.service';
import {Report} from '@platform/models/report.model';
import {Concept} from '@platform/models/concept.model';
import {ConceptService} from '@platform/services/concept.service';
import {deleteConcept} from '@platform/store/actions/concept.actions';

@Component({
    selector: 'ns-items-order',
    templateUrl: './items-order.component.html',
    styleUrls: ['./items-order.component.scss']
})
export class ItemsOrderComponent implements OnInit, OnChanges {

    /**
     * survey questions deliverable configuration
     * */
    @Input() report: Report;

    @Input()    deliverableConfigurationsInReport: any;

    /**
     * Event that is triggered when configuration values changes
     * */
    @Output() itemsOrderChangedEvent = new EventEmitter<Concept[]>();

    /**
     * Event that is triggered to move the configuration menu to previous menu.
     * Signals parent component to move to previous menu screen.
     * */
    @Output() moveToPreviousMenuEvent = new EventEmitter();

    @Output() closeFlyoutMenuEvent = new EventEmitter();

    /**
     * Event that is triggered after configuration is saved.
     * */
    @Output() saveEvent = new EventEmitter<Concept>();

    clonedConcepts: Concept[];

    concepts: Concept[];

    enableReset = false;

    enableSave = false;

    conceptPositions: any;

    constructor(private conceptService: ConceptService,
                private confirmationDialogService: ConfirmationDialogService,
                private dialog: MatDialog,
                private spinnerService: SpinnerService,
                private reportService: ReportService,
                private deliverableConfigurationUtilService: DeliverableConfigurationUtilService) {
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges() {
        this.initialize();
    }

    initialize(): void {
            this.conceptService.fetchFromAPI(this.report.id, null).subscribe(concepts => {
                const competitorConcepts = this.deliverableConfigurationsInReport.find(c => c.name === 'exclude-concepts');
                this.concepts = concepts;
                if (competitorConcepts && competitorConcepts.config && competitorConcepts.config['all']) {
                    this.concepts = concepts.filter(it => !(competitorConcepts.config['all'].includes(it.exerciseConceptId)));
                }
                this.loadData();
            });
    }

    loadData() {
        this.clonedConcepts = this.deliverableConfigurationUtilService.deepClone(this.concepts);
        this.clonedConcepts = this.clonedConcepts.map(u => Object.assign({}, u, {show: u.show || u.show + '' === 'false' ? u.show : true}));
        this.enableReset = !!this.findUpdatedConceptConfiguration();
        this.enableSave = false;
        this.clonedConcepts = [...this.clonedConcepts].sort((a, b) => {
            return a.position - b.position;
        });
        this.conceptPositions = this.clonedConcepts.map(it => it.position);
    }

    /**
     * Toggle visibility of question
     * */
    toggleVisibility(concept: Concept): void {
        concept.show = !concept.show;
        this.toggleResetAndSave();
    }

    /**
     * Event that is triggered when the display name is updated/changed
     * */
    displayNameChange(event, concept: Concept) {
        if (event.target && event.target.value) {
            concept.name = event.target.value.trim();
        } else {
            concept.name = concept.name;
        }
        this.toggleResetAndSave();
    }

    toggleResetAndSave(): void {
        this.enableReset = !!this.findUpdatedConceptConfiguration();
        this.enableSave = this.findUpdatedConceptConfiguration() ? true : false;
        if (this.enableSave) {
            this.itemsOrderChangedEvent.emit(this.clonedConcepts);
        }
    }

    /**
     * Returns first matching config whose original values have differed from assigned values to indicate if
     * "rest to default" should be enabled.
     */
    findUpdatedConceptConfiguration(): Concept {
        const updatedConfiguration = this.clonedConcepts.find(it => {
            return it.position !== this.concepts.find(concept => concept.exerciseConceptId === it.exerciseConceptId).position ||
                it.name !== this.concepts.find(concept => concept.exerciseConceptId === it.exerciseConceptId).name ||
                it.show !== this.concepts.find(concept => concept.exerciseConceptId === it.exerciseConceptId).show;
        });
        return updatedConfiguration;
    }

    onSave() {
        this.spinnerService.showSpinner();
        this.conceptService.updateConceptsConfiguration(this.clonedConcepts).subscribe(concepts => {
            this.onConceptsUpdate(concepts);
            this.spinnerService.hideSpinner();
        });
    }

    onSaveAndClose() {
        this.spinnerService.showSpinner();
        this.conceptService.updateConceptsConfiguration(this.clonedConcepts).subscribe(concepts => {
            this.onConceptsUpdate(concepts);
            this.closeFlyoutMenuEvent.emit();
            this.spinnerService.hideSpinner();
        });
    }

    onConceptsUpdate(concepts: Concept[]) {
        this.concepts = concepts;
        concepts.forEach( c => {
           this.conceptService.updateConceptToStore(c);
        });
        this.clonedConcepts = this.deliverableConfigurationUtilService.deepClone(this.concepts);
        this.reportService.reloadDeliverable.next(null);
        this.enableSave = false;
        this.saveEvent.emit();
    }

    returnBack() {
        if (this.enableSave) {
            const dialogConfig = this.confirmationDialogService.getContinueWithoutSavingConfig();
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(value => {
                if (value === 'CANCEL') {
                    this.dialog.closeAll();
                } else {
                    this.clonedConcepts = this.deliverableConfigurationUtilService.deepClone(this.concepts);
                    this.moveToPreviousMenuEvent.emit();
                }
            });
        } else {
            this.moveToPreviousMenuEvent.emit();
        }
    }

    /**
     * Action that is triggered when question DOM is moved to change position
     */
    dropConcept(event: CdkDragDrop<Concept>) {
        moveItemInArray(this.clonedConcepts, event.previousIndex, event.currentIndex);
        this.clonedConcepts.forEach((it, index) => {
            it.position = this.conceptPositions[index];
        });
        this.toggleResetAndSave();
    }

    onCloseButtonClick(): void {
        if (this.enableSave) {
            const dialogConfig = this.confirmationDialogService.getContinueWithoutSavingConfig();
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(value => {
                if (value === 'CANCEL') {
                    this.dialog.closeAll();
                } else {
                    this.initialize();
                    this.closeFlyoutMenuEvent.emit();
                }
            });
        } else {
            this.closeFlyoutMenuEvent.emit();
        }
    }

    @HostListener('document:click', ['$event']) clickOut(event) {
        if (event.target.className.includes('mat-drawer-backdrop')) {
            event.stopPropagation();
            event.preventDefault();
            this.onCloseButtonClick();
        }
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event) {
        if (event.keyCode === 27) {
            this.onCloseButtonClick();
        }
    }
}
