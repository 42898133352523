<div class="btn-group drop-button margin-top-1-point-5">
    <button *ngIf="showResetToDefault" small class="m-0 mat-button-secondary reset-to-default-button" mat-button color="secondary" [disabled]="!enableReset" (click)="onReset()" mat-dialog-close>
        <span class=" sif sif-reset"></span> Reset To Default
    </button>
    <div class="save-slide-down">
        <button small mat-flat-button class="save-dropdown-btn" [disabled]="!enableSave" (click)="onSave()" [color]="!enableSave ? 'secondary' : 'primary'">
            SAVE
        </button>
        <button small mat-flat-button [matMenuTriggerFor]="menu" class="button-as-trigger" [color]="!enableSave ? 'secondary' : 'primary'" [disabled]="!enableSave">
            <span class="sif sif-chevron-s"></span>
        </button>
        <mat-menu #menu="matMenu" xPosition="before" class="save-close-menu-dropdown" #dropdownList>
            <button small mat-menu-item (click)="onSaveAndClose()"> SAVE AND CLOSE </button>
        </mat-menu>
    </div>
</div>
