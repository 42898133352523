import {State} from '@platform/store/state/app.state';
import {AppConfigService} from '@app/core/config/app-config.service';
import {ReportService} from '@platform/services/report.service';
import {combineLatest, Observable, of} from 'rxjs';
import {map, skipWhile, switchMap} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {select, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {addDeliverableView, updateDeliverableView} from '@platform/store/actions/deliverable-view.actions';
import {FilterService} from '@platform/services/filter.service';
import {Filter} from '@platform/models/filter.model';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {selectDeliverableViewById} from '@platform/store/selectors/deliverable-view.selectors';
import {ForecastVarietySplit} from "@app/deliverables/forecast-variety-split/forecast-variety-split.model";

/**
 * This service provides operations for accessing retrieving, updating,
 * filtering and loading deliverable views for the current report object.
 * Report object will be retrieved from the URL.
 *
 * @example
 * constructor(private deliverableViewService: DeliverableViewService) { }
 *
 * @export
 * @class DeliverableViewService
 */
@Injectable({
    providedIn: 'root'
})
export class DeliverableViewService {

    readonly cache: Record<string, Array<DeliverableView>>;

    private loadingMap: Map<string, boolean> = new Map<string, boolean>();

    /**
     * Creates an instance of DeliverableViewService.
     *
     * @constructor
     * @param {Store<State>} store
     * @param {HttpClient} httpClient
     * @param {FilterService} filterService
     * @param {ReportService} reportService
     * @param cs
     * @memberOf DeliverableViewService
     */
    constructor(
        private store: Store<State>,
        private httpClient: HttpClient,
        private filterService: FilterService,
        private reportService: ReportService,
        private cs: AppConfigService) {
        this.cache = {};
    }

    clearCache(): void {
        Object.keys(this.cache).forEach(it => {
            delete this.cache[it];
        });
    }

    /**
     * Returns an observable of a deliverable views.
     * */
    public getDeliverableViews(deliverableType: string, enableCaching: boolean = false): Observable<Array<DeliverableView>> {
        const report$ = this.reportService.get();
        return report$.pipe(
            switchMap(report => {
                if (this.cache[`${report.id}-${deliverableType}`]) {
                    return of(this.cache[`${report.id}-${deliverableType}`]);
                } else {
                    return this.fetchAll(report.id, deliverableType).pipe(switchMap((views) => {
                        if (enableCaching) {
                            this.cache[`${report.id}-${deliverableType}`] = views;
                        }
                        return of(views);
                    }));
                }
            }),
            skipWhile(view => !view)
        );
    }

    /**
     * Returns an observable of a product deliverable view. A product
     * deliverable view is a subclass of {@link DeliverableView}. If
     * the deliverable view data not available in the store it will be
     * fetched using the API. Returned observable if not filtered data.
     *
     * The returned observable will be stream data until its available.
     *
     * @example
     * const view: Observable<AttributesDeliverableView> = deliverableViewService.get<AttributesDeliverableView>('concept', 'Attributes');
     *
     * @template T extends {@link DeliverableView}
     * @param {string} deliverableViewName The deliverable view type
     * @param {string} deliverableType The deliverable type
     * @param {[key: string]: string} params Optional query
     * params object.
     * @param {boolean} reload Set to true if view data needs to be reloaded through API.
     * @returns {Observable<T>} The observable of T
     * @memberOf DeliverableViewService
     */
    public get<T extends DeliverableView>(
        deliverableViewName: string,
        deliverableType: string,
        params?: { [key: string]: string | number },
        reload?: boolean): Observable<T> {
        const report$ = this.reportService.get();
        return report$.pipe(
            switchMap(report => {
                return this.fetchAll(report.id, deliverableType).pipe(switchMap((views) => {
                    const deliverableView = views.find(v => v.viewName === deliverableViewName);
                    this.load(deliverableView.id, report.id, params, reload);
                    return this.fetchFromStore<T>(deliverableView.id);
                }));
            }),
            skipWhile(view => !view)
        );
    }

    /**
     * Updates a product deliverable view object. A product
     * deliverable view is a subclass of {@link DeliverableView}.
     *
     * @example
     * const attributes: AttributesDeliverableView = {...}
     * deliverableViewService.update<AttributesDeliverableView>(attributes)
     *
     * @template T extends {@link DeliverableView}
     * @param {T} deliverableView The product deliverable view type.
     * @returns {void}
     * @memberOf DeliverableViewService
     */
    public update<T extends DeliverableView>(deliverableView: T): void {
        return this.store.dispatch(updateDeliverableView({deliverableView}));
    }

    /**
     * Filters a product deliverable view object based on the projection
     * function argument. A product deliverable view is a subclass of
     * {@link DeliverableView} and A product filter is a subclass of
     * {@link Filter}.
     *
     * Filter projection will be called every time the either the deliverable
     * view or filter emits new data. So this is a live filtering on the
     * store data.
     *
     * @example
     * const filter: (filter: AttributesFilter, data: AttributesDeliverableView) => AttributesDeliverableView = () => {...}
     * this.deliverableViewService.filter('concept', 'Attributes', filter)
     *
     * @template D extends {@link DeliverableView}
     * @template F extends {@link Filter}
     * @param {string} deliverableViewType The product deliverable view type.
     * @param {string} deliverableType The product deliverable type.
     * @param {(filter: F, deliverableView: D) => D} project The projection function
     * @param {[key: string]: string} params Optional query
     * params object.
     * @param {boolean} reload Set to true if view data needs to be reloaded through API.
     * @returns {Observable<D>} The observable of product deliverable function.
     * @memberOf DeliverableViewService
     */
    public filter<D extends DeliverableView, F extends Filter>(
        deliverableViewType: string,
        deliverableType: string,
        project: (filter: F, deliverableView: D) => D,
        params?: { [key: string]: string | number },
        reload?: boolean): Observable<D> {
        const deliverableView$ = this.get<D>(deliverableViewType, deliverableType, params, reload);
        const filter$: Observable<F> = this.filterService.get<F>(deliverableType);
        return combineLatest([filter$, deliverableView$]).pipe(
            map(([filter, deliverableView]) => project(filter, deliverableView))
        );
    }

    /**
     * Fetches a product deliverable view using the API and loads it into the store.
     * A product deliverable view is a subclass of {@link DeliverableView}.
     *
     * @example
     * this.deliverableViewService.load('1');
     *
     * @template T extends {@link DeliverableView}
     * @param {string} id The deliverable view id.
     * @param {[key: string]: string]} params Optional query
     * params object.
     * @param {boolean} reload Set to true if view data needs to be reloaded through API.
     * @memberOf DeliverableViewService
     */
    public load<T extends DeliverableView>(id: string, reportId: string,
                                           params?: { [key: string]: string | number }, reload?: boolean): void {
        const deliverableView$ = this.fetchFromStore<T>(id);
        const loadingMap = this.loadingMap;
        deliverableView$.subscribe(deliverableView => {
            if ((loadingMap.get(id) !== true && !deliverableView) || reload) {
                loadingMap.set(id, true);
                reload = false;
                this.fetch<T>(id, reportId, params).subscribe(result => {
                    this.loadOnStore(result);
                    loadingMap.set(id, false);

                });
            }
        });
    }

    /**
     * Fetches a product deliverable view using the API. A product deliverable
     * view is a subclass of {@link DeliverableView}. This does not update the store.
     *
     * @private
     * @template T extends {@link DeliverableView}
     * @param {string} id The deliverable view id.
     * @param {[key: string]: string} params Optional query
     * params object.
     * @param reportId
     * @returns {Observable<T>} The observable of T
     * @memberOf DeliverableViewService
     */
    public fetch<T extends DeliverableView>(id: string, reportId: string, params?: { [key: string]: string | number }): Observable<T> {
        const url = `${this.cs.config.reporting.url}/reports/${reportId}/deliverableViews/${id}`;
        let httpParams: HttpParams = new HttpParams();
        if (params && Object.keys(params).length > 0) {
            Object.keys(params).forEach(key =>
                httpParams = httpParams.append(key, params[key])
            );
        }
        return this.httpClient.get<T>(url, {params: httpParams});
    }

    /**
     * Fetch All Deliverable Views with reportId and type
     * @param reportId
     * @param type
     */
    public fetchAll<T extends DeliverableView>(reportId: string, type: string): Observable<Array<T>> {
        const url = `${this.cs.config.reporting.url}/reports/${reportId}/deliverableViews?type=${type}`;
        return this.httpClient.get<Array<T>>(url);
    }

    /**
     * Fetch All Deliverable view with just report ID
     * @param reportId
     */
    public fetchAllWithReportId<T extends DeliverableView>(reportId: string): Observable<Array<T>> {
        const url = `${this.cs.config.reporting.url}/reports/${reportId}/deliverableViews`;
        return this.httpClient.get<Array<T>>(url);
    }

    /**
     * Loads a deliverable view into the store.
     *
     * @private
     * @param {DeliverableView} deliverableView
     * @memberOf DeliverableViewService
     */
    public loadOnStore(deliverableView: DeliverableView): void {
        this.store.dispatch(addDeliverableView({deliverableView}));
    }

    /**
     * Returns a deliverable view observable from the store.
     *
     * @private
     * @template T extends {@link DeliverableView}
     * @param {string} id The deliverable view id.
     * @returns {Observable<T>} The observable of T
     * @memberOf DeliverableViewService
     */
    private fetchFromStore<T extends DeliverableView>(id: string): Observable<T> {
        return this.store.pipe(
            select(selectDeliverableViewById<T>(), {id})
        );
    }

}
