import {DeliverableType} from '@products/quick-screen/deliverable-type.enum';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AppConfigService} from '@app/core/config/app-config.service';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output, SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    AxisData,
    Concept,
    SortingMatrixDeliverable
} from '@app/deliverables/sorting-matrix/models/sorting-matrix.model';
import {MatSort, Sort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {SortingMatrixMetaInfo} from '@app/deliverables/sorting-matrix/models/view-meta-info.model';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelLabel, MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {NgScrollbar} from 'ngx-scrollbar';

@Component({
    selector: 'qs-sm-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges, OnDestroy {

    @Input() public data: SortingMatrixDeliverable;
    @Input() public viewSortingMatrixMetaInfo: SortingMatrixMetaInfo;
    @Input() public axisData: AxisData;
    @Input() public disabledConcepts: Array<number>;

    @Output() hovered: EventEmitter<Concept> = new EventEmitter<Concept>();
    @ViewChild(MatSort) sort: MatSort;
    // Get NgScrollbar reference
    @ViewChild(NgScrollbar, {static: true}) scrollbarRef: NgScrollbar;

    /**
     * selectedConcept is an attribute property of this component which tells
     * which concept is to be highlighted.
     */
    @Input()
    set selectedConcept(concept: Concept) {
        this._selectedConcept = concept;
        if (concept) {
            // scroll on the table to get the selected concept row in view, if it is not already.
            const row = this.elRef.nativeElement.querySelector(`#id_${concept.rank}`);
            const headerRow = this.elRef.nativeElement.querySelector(`thead`);
            if (!this.isRowInView(row)) {
                this.scrollbarRef.scrollTo({top: row.offsetTop - headerRow.clientHeight, duration: 0});
            }
        }
    }

    get selectedConcept() {
        return this._selectedConcept;
    }

    /**
     * Table header column names
     */
    public displayedColumns: string[] = ['position', 'conceptName', 'rank', 'needDesire', 'uniqueness', 'image'];

    public dataSource: MatTableDataSource<Concept>;
    public selectedImagePath: string;
    private _selectedConcept: Concept;
    public concepts: Concept[] = [];

    constructor(
        private elRef: ElementRef,
        private cs: AppConfigService,
        private dialog: MatDialog,
        private viewMetaInfoService: ViewMetaInfoService,
        private translate: TranslateService,
        private mixpanelService: MixpanelService
    ) {
    }

    ngOnInit() {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.init();
    }

    /**
     * Subscribe the component here
     */
    init() {
        const viewMetaInfo = this.viewSortingMatrixMetaInfo;
        const sortingMatrixConcept = this.data.concepts;
        this.concepts = sortingMatrixConcept;
        this.viewSortingMatrixMetaInfo = viewMetaInfo;
        this.dataSource = viewMetaInfo !== null ? this.columnSortingOnSavedView(this.concepts, viewMetaInfo) : this.columnSorting(sortingMatrixConcept);
    }

    /**
     * Cleanup hook.
     *
     * @member TableComponent
     */
    ngOnDestroy() {

    }

    /**
     * Default View for Sorting
     * @param sortingConcepts
     * @returns
     */
    private columnSorting(sortingConcepts: Concept[]) {
        const dataSource = new MatTableDataSource(sortingConcepts);
        if (this.sort !== undefined) {
            const sortState: Sort = {active: 'rank', direction: 'asc'};
            this.sort.active = sortState.active;
            this.sort.direction = sortState.direction;
            this.sort.sortChange.emit(sortState);
        }
        dataSource.sort = this.sort;
        return dataSource;
    }

    /**
     * Upon landing on a saved view check the view meta info in store for any sorting information and perform the expected Sort change
     * @param sortingConceptRows
     * @param viewMetaInfo
     * @returns
     */
    private columnSortingOnSavedView(sortingConceptRows: Concept[], viewMetaInfo: SortingMatrixMetaInfo) {
        const dataSource = new MatTableDataSource(sortingConceptRows);
        if (Object.keys(viewMetaInfo).length > 1 && viewMetaInfo.sortInfo && Object.keys(viewMetaInfo.sortInfo).length > 0) {
            const sortState: Sort = {
                active: viewMetaInfo.sortInfo.columnHeaderName,
                direction: viewMetaInfo.sortInfo.sortDirection
            };
            this.sort.active = sortState.active;
            this.sort.direction = sortState.direction;
            this.sort.sortChange.emit(sortState);
            dataSource.sort = this.sort;
        }
        return dataSource;
    }

    /**
     * checks if the table row is in the view or not.
     */
    isRowInView = (row) => {
        const table = this.elRef.nativeElement;
        const scroll = table.scrollTop;
        const boundsTop = row.getBoundingClientRect().top + scroll;
        const headerRow = this.elRef.nativeElement.querySelector(`thead`);
        const viewport = {
            top: scroll + table.getBoundingClientRect().top + headerRow.clientHeight,
            bottom: scroll + table.getBoundingClientRect().bottom
        };

        const bounds = {
            top: boundsTop,
            bottom: boundsTop + row.clientHeight,
        };

        return (bounds.bottom > viewport.top && bounds.bottom < viewport.bottom)
            || (bounds.top < viewport.bottom && bounds.top > viewport.top);
    }

    onMouseOver = concept => this.hovered.emit(concept);

    onMouseOut = () => this.hovered.emit(null);

    enlargeConceptImage(concept: Concept, conceptEnlargeModal: TemplateRef<any>) {
        this.selectedImagePath = concept.image.imagePath;
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        this.dialog.open(conceptEnlargeModal, dialogConfig);

    }

    /**
     * Update the sorting meta info in the ViewMetaInfo store
     * @param sort
     */
    private setUserViewSorting() {
        const deliverableType = DeliverableType.SORTING_MATRIX.type;
        const viewInfo = {deliverableType, sortInfo: {}, chartInfo: {}};
        viewInfo.sortInfo['columnHeaderName'] = this.sort.active;
        viewInfo.sortInfo['sortDirection'] = this.sort.direction;
        viewInfo.chartInfo = this.viewSortingMatrixMetaInfo !== null ? this.viewSortingMatrixMetaInfo.chartInfo : {};
        this.viewMetaInfoService.update(viewInfo, deliverableType);
        this.mixpanelService.track(MixpanelLabel.sortingMatrix, MixpanelEvent.tableSort);
    }

    getSortOrder(headerName: string) {
        let sortOrder = '';
        let keyA = 'sort.lowest.to.highest';
        let keyB = 'sort.highest.to.lowest';
        if (headerName === 'conceptName') {
            keyA = 'sort.A.to.Z';
            keyB = 'sort.Z.to.A';
        }
        if (this.sort?.active === headerName) {
            if (this.sort.direction === 'asc') {
                sortOrder = this.translate.instant(keyB);
            } else {
                sortOrder = this.translate.instant(keyA);
            }
        } else {
            sortOrder = this.translate.instant(keyA);
        }
        return sortOrder;
    }

}
