import {Concept} from '@platform/models/concept.model';
import {ScoreCardView} from '@platform/score-cards/score-card-view';
import {StrengthWatchoutsService} from '@app/deliverables/strengths-watchouts/strength-watchouts.service';
import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, forkJoin, Subscription} from 'rxjs';
import {StrengthWatchoutsFilter} from '@app/deliverables/strengths-watchouts/models/strength-watchouts-filter.model';
import {ExportPngService} from '@platform/services/export-png.service';
import {UserService} from '@platform/services/user.service';
import {StrengthWatchoutsFileService} from '@app/deliverables/strengths-watchouts/strength-watchouts-file.service';
import {SpinnerService} from '@platform/services/spinner.service';
import {DeliverableInsightService} from '@platform/services/deliverable-insight.service';
import {StrengthWatchoutsDeliverableView} from '@app/deliverables/strengths-watchouts/models/strength-watchouts.model';
import {TranslateService} from '@ngx-translate/core';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelLabel, MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';
import {ReportService} from '@platform/services/report.service';
import {UserView} from '@platform/models/user-view.model';
import {UserViewService} from '@platform/services/user-view.service';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {RouterService} from '@platform/services/router.service';
import {Report} from '@platform/models/report.model';
import {InsightService} from '@platform/insights/insights.service';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {FilterService} from '@platform/services/filter.service';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import { UserInfo } from '@platform/models/user-info.model';
import {ProductDeliverableViewService} from "@platform/services/product-deliverable-view.service";


@Component({
    selector: 'ns-strengths-watchouts',
    templateUrl: './strengths-watchouts.component.html',
    styleUrls: ['./strengths-watchouts.component.scss']
})
export class StrengthsWatchoutsComponent implements OnInit, OnDestroy, ScoreCardView {

    /**
     * Report document
     */
    public report: Report;

    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @memberof AttributesComponent
     */
    public subscriptions: Array<Subscription>;

    public deliverableType = DeliverableType.STRENGTH_WATCHOUTS.type;

    public deliverableData: any;

    public isInsightEnable = false;

    public viewId: string;
    /**
     * Strength and Watch outs filter object.
     *
     * @type {StrengthWatchoutsFilter}
     * @memberof StrengthWatchoutsComponent
     */
    public filter: StrengthWatchoutsFilter;

    /**
     * Strength and Watch outs filter object to save default Values.
     *
     * @type {StrengthWatchoutsFilter}
     * @memberof StrengthWatchoutsComponent
     */
    public defaultViewFilters: StrengthWatchoutsFilter;

    /**
     * Spinner.
     *
     * @type {Boolean}
     * @memberof StrengthWatchoutsComponent
     */
    public displayProgressSpinner = false;

    /**
     * The deliverable view object.
     */
    public deliverableViewType: string;

    public isInternalUser: Boolean;

    public selectors: any;

    public isVerbatimsView = false;

    public strengthWatchoutsDeliverableView: StrengthWatchoutsDeliverableView;

    public disableBtn: boolean;

    /**
     * ScoreCard Concept object for SW.
     *
     * @type {Concept}
     */
    public scoreCardConcept: Concept;

    public deliverableInfos: Array<DeliverableInfo>;

    public userViews: Array<UserView>;

    public deliverableViews: Array<DeliverableView>;

    public isAISummaryFeatureEnabled: boolean = false;

    public hideEE: boolean = false;

    public aiConceptSummaries: boolean = false;

    public aiZoneSummaries: boolean = false;

    public aiSubgroupSummaries: any;

    /**
     * Feature FLAG for Automatic Headlines.
     *
     * @type {Boolean}
     */
    public isAutomatedHeadlinesEnabled: boolean;

    /**
     * Take the insight in the HTML format for this Deliverable
     *
     * @type {Boolean}
     */
    public addHTMLToInsight = false;

    /**
     * Creates an instance of StrengthsWatchoutsComponent and initialize
     * the component data.
     *
     * @constructor
     * @param {StrengthWatchoutsService} strengthWatchoutsService
     * @param elementRef
     * @param exportPNGService
     * @param userService
     * @param strengthWatchoutsFileService
     * @param spinnerService
     * @param deliverableInsightService
     * @param translate
     * @param mixpanelService
     * @param routeService
     * @param userViewService
     * @param filterService
     * @param deliverableInfoService
     * @param reportService
     * @param deliverableViewService
     * @param insightService
     * @param productDeliverableViewService
     * @memberof StrengthWatchoutsComponent
     */
    constructor(
        private strengthWatchoutsService: StrengthWatchoutsService,
        private elementRef: ElementRef,
        private exportPNGService: ExportPngService,
        private userService: UserService,
        private strengthWatchoutsFileService: StrengthWatchoutsFileService,
        private spinnerService: SpinnerService,
        private deliverableInsightService: DeliverableInsightService,
        private translate: TranslateService,
        private mixpanelService: MixpanelService,
        private routeService: RouterService,
        private userViewService: UserViewService,
        private filterService: FilterService,
        private deliverableInfoService: DeliverableInfoService,
        private reportService: ReportService,
        private deliverableViewService: DeliverableViewService,
        private insightService: InsightService,
        private productDeliverableViewService: ProductDeliverableViewService
    ) {
        this.subscriptions = [];
        this.userViews = [];
    }

    /**
     * Initialize component
     *
     StrengthWatchoutsComponent* @memberof
     */
    ngOnInit(): void {
        this.init();
        this.reportService.reloadDeliverable.subscribe(() => {
            this.productDeliverableViewService.clearCache();
            this.deliverableViewService.clearCache();
            this.init();
        });
    }

    init() {
        const insightId = this.routeService.getQueryParam('insightId');
        const subscription = combineLatest([
            this.reportService.get(),
            this.userService.getUser(),
            this.deliverableViewService.getDeliverableViews(DeliverableType.STRENGTH_WATCHOUTS.type)
        ]).subscribe(([report, user, deliverableViews]) => {
            this.report = report;
            this.deliverableInfos = this.deliverableInfoService.getNonForecastDeliverables(report);
            this.isInternalUser = user.isInternalUser;
            this.deliverableViews = deliverableViews;
            this.isAISummaryFeatureEnabled = user.featureFlags.includes('REPORTING_AI_SUMMARIES');
            this.isAutomatedHeadlinesEnabled = user.featureFlags.includes('REPORTING_AUTOMATED_HEADLINES');
            this.assignAISummariesflags(deliverableViews.find(it=>it.viewName=='clickData'));

            // load saved user views(filter) if they exist
            forkJoin([
                this.userViewService.fetchReportUserViewsFromAPI(this.report.id),
                this.insightService.getInsightFilterData<StrengthWatchoutsFilter>(report.id, insightId),
                this.strengthWatchoutsService.getDefaultFilterData(this.scoreCardConcept)
            ]).subscribe(([userViews, insightFilter, defaultViewFilters]) => {
                this.defaultViewFilters = defaultViewFilters;
                this.userViews = this.userViewService.setupUserViews(this.report.id, this.deliverableType, userViews, defaultViewFilters, insightFilter)
                    .filter((it: any)=>{
                        if(!this.isAISummaryFeatureEnabled && it.id!=='newView'){
                            if(it.filter.compare.find(view=> view.id === 'verbatims').isSelected && (it.filter?.show?.dataView?.find(ir => ir.id == 'summary' && ir.isSelected === true))){
                                return null;
                            }else {
                                return it;
                            }
                        }
                        else {
                            let isVebatimSummaryView = it.filter.compare.find(view=> view.id === 'verbatims').isSelected && it.filter?.show?.dataView?.find(ir => ir.id == 'summary').isSelected === true;
                            if(it.id!=='newView' && isVebatimSummaryView && !this.aiConceptSummaries && this.aiSubgroupSummaries && !this.aiSubgroupSummaries[it.filter.subgroups.find((x)=>(x.isSelected)).id])
                                return null;
                            return it;
                        }
                    });
                this.hideEE = !!this.deliverableViews.find(deliverView => deliverView.metaInfo?.hideElementEngagement === true);
                if(this.hideEE)
                    this.userViews = this.userViews.filter(view=> view.filter.deliverableViewType !== 'elementEngagements');
                const insightView = this.userViews.find(it => it.id === this.userViewService.insightViewId);
                this.selectUserView(insightView ? insightView : this.userViews.find(it => it.isSelected));

                /**
                 * Add subscription to watch filter changes here so that s&w deliverable data can also be updated as per filter change.
                 * */
                this.subscriptions.push(combineLatest([
                    this.strengthWatchoutsService.getStrengthWatchoutsFilter(),
                    this.strengthWatchoutsService.getStrengthWatchouts()
                ]).subscribe(([filter, strengthWatchouts]) => {
                    this.filter = filter;
                    this.strengthWatchoutsDeliverableView = strengthWatchouts;

                    if(this.isInsightEnable && this.isAutomatedHeadlinesEnabled){
                        this.openInsightCreationForm();
                    }
                    // Reset concepts if the selected option is elementEngagements so all concepts appear.
                    if (this.filter.compare.find(option => option.isSelected).id === 'elementEngagements' && this.filter.concepts.find((concept) => !concept.isSelected)) {
                        this.filter = this.strengthWatchoutsService.resetFilterConcepts(this.filter);
                        this.filterService.update(this.filter);
                    }
                    this.isVerbatimsView = this.filter.compare.find(it => it.isSelected).name === 'Verbatims';
                }));
            });
        });
        this.subscriptions.push(subscription);
    }
    /**
     * Method that is triggered when user view is changed. This will in turn update the filter model in the store.
     * */
    selectUserView(userView: UserView): void {
        this.filter = userView.filter as StrengthWatchoutsFilter;
        this.updateUserViewConceptsWithDefaultViewConcepts(this.filter , this.defaultViewFilters);
        this.validateFilter();
        this.disableBtn = this.isConceptExists(this.filter);
        this.filter = this.strengthWatchoutsService.changeFilterAsPerConfig(this.filter);
        this.filterService.update(this.filter);
    }

    /**
     * Cleanup hook.
     *
     * @memberof StrengthWatchoutsComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * toggle between headers and insight creation form.
     *
     */
    openInsightCreationForm() {
        this.isInsightEnable = true;
        const addCompare = true;
        this.addHTMLToInsight = true;
        setTimeout(() => {
            this.deliverableData = {
                title: this.isAutomatedHeadlinesEnabled ? this.deliverableInsightService.generateInsightTitle('Strengths & Watchouts',addCompare) : '',
                selectors: this.strengthWatchoutsService.getSelectors(this.filter, this.elementRef.nativeElement),
                deliverable: {
                    deliverableViewId: this.deliverableViews.find(it => it.productViewId === this.strengthWatchoutsDeliverableView.id)?.id,
                    filter: this.filter,
                    metaInfo: this.strengthWatchoutsDeliverableView.metaInfo,
                    insightHTML: ''
                }
            };
        }, 10);
    }

    /**
     * Information for user to select only first 10 concept
     */
    getConceptCountMessage() {
        return this.isAISummaryFeatureEnabled ? '' : this.translate.instant('platform.deliverable.insight.info.text');
    }

    /**
     * capture screen layout and export as png.
     *
     */
    exportAsPNG() {
        this.displayProgressSpinner = true;
        const selectors = this.strengthWatchoutsService.getSelectors(this.filter, this.elementRef.nativeElement);
        this.exportPNGService.exportPNG(selectors);
        this.spinnerService.getSpinnerObs().subscribe(loading => this.displayProgressSpinner = loading);
        this.mixpanelService.track(MixpanelLabel.strengthsWatchouts, MixpanelEvent.exportAsPNG);
    }

    /**
     * download survey questions data.
     *
     */
    download(event): void {
        event.preventDefault();
        this.displayProgressSpinner = true;
        const file$ = this.strengthWatchoutsFileService.download();
        this.spinnerService.getSpinnerObs().subscribe(loading => this.displayProgressSpinner = loading);
        this.mixpanelService.track(MixpanelLabel.strengthsWatchouts, MixpanelEvent.verbatimsExport);
    }

    /**
     * Close insight form
     */
    closeInsight() {
        this.isInsightEnable = false;
    }

    /**
     * check concept count
     * */
    isConceptExists(filter: StrengthWatchoutsFilter): boolean {
        const conceptCount = filter.concepts.filter(it => it.isSelected === true).length;
        const subgroupCount = filter.subgroups.filter(it => it.isSelected === true).length;
        return (conceptCount === 0 || subgroupCount === 0);
    }

    /**
     * Action that is triggered when the deliverable info is changed.
     *
     * @param deliverableInfo
     */
    onDeliverableChange(deliverableInfo: DeliverableInfo): void {
        this.deliverableInfoService.routeToDeliverable(deliverableInfo);
    }

    /**
     * Old savedViews required to validate multiple concept Selection & set deafault values of chartOptions
     *
     */
    validateFilter(){
        if(!this.filter.show.zone){
            this.filter.show.zone = this.defaultViewFilters.show.zone;
            this.filter.show.dataView = this.defaultViewFilters.show.dataView;
        }
        if(!this.filter.show.chartMetric){
            this.filter.show.chartMetric = this.defaultViewFilters.show.chartMetric;
        }

        if(this.filter.show.languages.length>1 && !this.filter.show.languages.some(x=>x.id == "all")){
            this.filter.show.languages.splice(0,0,{
                name: 'All Languages*',
                id: 'all',
                isSelected: false
            });

        }

        if(this.hideEE) {
            let i = this.filter.compare.length;
            while(i--){
                if(this.filter.compare[i].id === 'elementEngagements'){
                    this.filter.compare.splice(i,1);
                }
            }
        }

        const selectedConceptCount = this.filter.concepts.reduce((count,concept)=>
            count + (concept.isSelected ? 1 : 0), 0);

        if( selectedConceptCount > 1){
            let firstConcept = false;
            this.filter.concepts.map(concept=>{
                if(concept.isSelected){
                    if(!firstConcept){
                        firstConcept = true;
                    }else {
                        concept.isSelected = false;
                    }
                }
            });
        }
    }

    public updateUserViewConceptsWithDefaultViewConcepts(filter, defaultViewFilters) {
        if ( filter && filter.concepts.length && defaultViewFilters && defaultViewFilters.concepts && defaultViewFilters.concepts.length) {
            const concepts = [];
            defaultViewFilters.concepts.forEach(concept => {
                const conceptObject = this.userViewService.deepClone(concept);
                const matchedConcept = filter?.concepts.find(it => it.id === concept.id);
                if (!matchedConcept) {
                    conceptObject.isSelected = false;
                    concepts.push(conceptObject);
                }
            });
            if (concepts.length) {
                filter.concepts.push(concepts);
            }
            if (filter.concepts.length && !(filter.concepts?.find(it => it.isSelected))) {
                filter.concepts[0].isSelected = true;
            }
        }
    }

    private assignAISummariesflags(deliverable: DeliverableView) {
        this.aiConceptSummaries = deliverable.metaInfo?.hasOwnProperty("aiConceptSummaries")? deliverable.metaInfo.aiConceptSummaries : false;
        this.aiZoneSummaries = deliverable.metaInfo?.hasOwnProperty("aiZoneSummaries")? deliverable.metaInfo.aiZoneSummaries : false;
        this.aiSubgroupSummaries = deliverable.metaInfo?.hasOwnProperty("aiSubgroupSummaries")? deliverable.metaInfo.aiSubgroupSummaries : null;
    }
}
