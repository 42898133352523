import {Concept} from '@platform/models/concept.model';
import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {CorrelationsFilter, FilterItem} from '../models/filter.model';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {CorrelationsService} from '../services/correlation.service';
import {FilterService} from '@platform/services/filter.service';
import {ReportService} from '@platform/services/report.service';
import {TranslateService} from '@ngx-translate/core';
import {DataType, DropdownData, DropdownItem} from '@products/shared/dropdown/dropdown.data.model';
import {take} from 'rxjs/operators';
import {CompareView} from '@app/deliverables/correlations/models/compare-view-enum';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';

@Component({
    selector: 'ns-correlations-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {
    /**
     * Correlations filter object.
     *
     * @property
     * @type {CorrelationsFilter}
     * @memberof FilterComponent
     */
    public filter: CorrelationsFilter;
    public defaultFilter: CorrelationsFilter;
    public deliverableViews: Array<DeliverableView>;

    /**
     * List of concept dropdown items.
     *
     * @property
     * @type {Array<DropdownData>}
     * @memberof FilterComponent
     */
    public conceptsListData: DropdownData<string>;

    /**
     * List of subgroup dropdown items.
     *
     * @property
     * @type {DropdownData}
     * @memberof FilterComponent
     */
    public subgroupListData: DropdownData<string>;

    /**
     * List of deliverable view dropdown items.
     *
     * @property
     * @type {DropdownData}
     * @memberof FilterComponent
     */
    public compareListData: DropdownData<string>;

    /**
     * List of countries on the report.
     *
     * @property
     * @type {DropdownData}
     * @memberof FilterComponent
     */
    public countryListData: DropdownData<string>;

    /**
     * Array of subscriptions for cleanup.
     *
     * @property
     * @private
     * @type {Array<Subscription>}
     * @memberof FilterComponent
     */
    private subscriptions: Array<Subscription>;

    @Input() public concept: Concept;

    /**
     * Creates an instance of FilterComponent.
     *
     * @constructor
     * @param correlationsService
     * @param deliverableViewService
     * @param {FilterService} filterService
     * @param {ReportService} reportService
     * @param translate
     * @memberof FilterComponent
     */
    constructor(
        private correlationsService: CorrelationsService,
        private deliverableViewService: DeliverableViewService,
        private filterService: FilterService,
        private reportService: ReportService,
        private translate: TranslateService,
        private productDeliverableViewService: ProductDeliverableViewService
    ) {
        this.subscriptions = [];
    }

    /**
     * Initialize the filter component. Sets filter and report object
     * for the template.
     *
     * @memberof FilterComponent
     */
    ngOnInit(): void {
        const filter$ = this.correlationsService.getCorrelationsFilter();
        const deliverableViews$ = this.deliverableViewService.getDeliverableViews(DeliverableType.CORRELATIONS.type);
        const subscription = combineLatest([filter$, deliverableViews$]).subscribe(([filter, deliverableViews]) => {
            this.filter = filter;
            this.setFilters(filter, deliverableViews);
        });
        const filterSubscription = filter$.pipe(take(1))
            .subscribe((filter: CorrelationsFilter) => this.filter = filter);
        deliverableViews$.pipe(take(1))
            .subscribe((deliverableViews: DeliverableView[]) => {
                this.deliverableViews = deliverableViews;
            });
        const configChangesSubscription = this.reportService.correlationConfigChanged$.subscribe(configs => {
            this.productDeliverableViewService.clearCache();
            filter$.pipe(take(1))
                .subscribe((filter: CorrelationsFilter) => this.filter = filter);
            deliverableViews$.pipe(take(1))
                .subscribe((deliverableViews: DeliverableView[]) => {
                    this.deliverableViews = deliverableViews;
                    this.deliverableViews = deliverableViews;
                    const metaInfo = this.deliverableViews[0].metaInfo;
                    const hasDataTableItems = metaInfo.filter(it => !it.isDisabled).dataTable.length > 0;
                    const compareQuadMap = this.filter.compare.find(it => it.id === CompareView.QUAD_MAP);
                    const compareDataTable = this.filter.compare.find(it => it.id === CompareView.DATA_TABLE);
                    /**
                     * NOTE: analysts can un-select dataTable from deliverable configuration. In such case we would auto
                     * select quad map in the filter options in correlations deliverable.
                     * */
                    let isSelectedDescriptor = Object.getOwnPropertyDescriptor(compareDataTable, 'isSelected') || {};
                    if (Boolean(isSelectedDescriptor.writable)) {
                        compareDataTable.isSelected = hasDataTableItems;
                    }
                    isSelectedDescriptor = Object.getOwnPropertyDescriptor(compareQuadMap, 'isSelected') || {};
                    if (Boolean(isSelectedDescriptor.writable)) {
                        compareQuadMap.isSelected = !hasDataTableItems;
                    }
                    const dropdownData = [];
                    if (hasDataTableItems) {
                        dropdownData.push(
                            {
                                id: 'dataTable',
                                name: 'Data Table',
                                position: 1,
                                isSelected: this.defaultFilter.deliverableViewType === 'dataTable'
                            }
                        );
                    }
                    if (this.deliverableViews[0].metaInfo.quadMap.length) {
                        dropdownData.push(
                            {
                                id: 'quadMap',
                                name: 'Quad Map',
                                position: 1,
                                isSelected: this.defaultFilter.deliverableViewType === 'quadMap'
                            }
                        );
                    }
                    this.compareListData = this.getCompareOptions(dropdownData, this.deliverableViews);
                });
        });
        this.subscriptions.push(subscription, filterSubscription, configChangesSubscription);
    }

    /**
     * Event listener for concept selection change event.
     *
     * @listens event:selectionChange
     * @param {Array<DropdownItem>} concepts
     * @memberof FilterComponent
     */
    selectConcept(allConcepts: Array<DropdownItem<string>>) {
        this.filterService.update({
            ...this.filter,
            concepts: this.filter.concepts.map((concept) => {
                return {
                    ...concept,
                    isSelected: allConcepts.find(selectedConcept => selectedConcept.value === concept.name).selected
                };
            })
        });
    }

    /**
     * Event listener for subgroup selection change event.
     *
     * @listens event:selectionChange
     * @param {Array<DropdownItem>} subgroups
     * @memberof FilterComponent
     */
    selectSubgroup(subgroups: Array<DropdownItem<string>>) {
        this.filterService.update({
            ...this.filter,
            subgroups: this.filter.subgroups.map((subgroup) => {
                return {
                    ...subgroup,
                    isSelected: subgroups.find(selectedSubgroup => selectedSubgroup.value === subgroup.name).selected
                };
            })
        });
    }

    /**
     * Cleanup the component on removing from the UI.
     *
     * @memberof FilterComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * Sets the filter options for concepts, subgroups, compareList, countries.
     *
     * @private
     * @param {CorrelationsFilter} filter
     * @param deliverableViews[]
     * @memberof FilterComponent
     */
    private setFilters(filter: CorrelationsFilter, deliverableViews: DeliverableView[]): void {
        this.conceptsListData = this.getConceptFilterOptions(filter);
        this.subgroupListData = this.getSubGroupFilterOptions(filter);
        this.compareListData = this.getCompareOptions(filter.compare, deliverableViews);
        this.countryListData = this.getCountryOptions(filter.countries[0]);
    }

    /**
     * Returns the compare filter options.
     *
     * @private
     * @returns {DropdownData}
     * @memberof FilterComponent
     * @param items
     * @param deliverablesViews
     */
    private getCompareOptions(items: Array<FilterItem>, deliverablesViews: DeliverableView[]): DropdownData<string> {
        const dropdownData: DropdownItem<string>[] = items.map(item => {
            return {
                value: item.id,
                label: item.name,
                selected: item.isSelected
            };
        });
        const dropdownItems: DropdownData<string> = {
            dropdownLabel: this.translate.instant('shared.deliverables.correlations.filter.compare.label'),
            dataType: DataType.RADIO,
            contentTitle: this.translate.instant('shared.deliverables.correlations.filter.compare.itemsTitle'),
            data: dropdownData
        };
        const deliverableType = DeliverableType.CORRELATIONS.type;
        const deliverableView = deliverablesViews.find(d => d.type === deliverableType);
        const dataMeasures = deliverableView.metaInfo['dataTable'];
        const quadMap = deliverableView.metaInfo['quadMap'];
        if (!Object.keys(dataMeasures).length) {
            dropdownItems.data = dropdownItems.data.filter(data => data.value === 'quadMap');
            dropdownItems.data[0].selected = true;
        }
        if (!Object.keys(quadMap).length) {
            dropdownItems.data = dropdownItems.data.filter(data => data.value === 'dataTable');
            dropdownItems.data[0].selected = true;
        }
        return dropdownItems;
    }

    /**
     * Returns the country filter options.
     *
     * @private
     * @param {string} country
     * @returns {DropdownData}
     * @memberof FilterComponent
     */
    private getCountryOptions(country: string): DropdownData<string> {
        return {
            dropdownLabel: this.translate.instant('shared.deliverables.correlations.filter.country.label'),
            dataType: DataType.RADIO,
            contentTitle: this.translate.instant('shared.deliverables.correlations.filter.country.itemsTitle'),
            data: [
                {
                    value: country,
                    label: country,
                    selected: true
                }
            ]
        };
    }

    /**
     * Returns concepts filter options.
     *
     * @private
     * @param {Array<FilterItem>} items
     * @returns {DropdownData}
     * @memberof FilterComponent
     */
    private getConceptFilterOptions(filter: CorrelationsFilter): DropdownData<string> {
        const dataType = DataType.RADIO;
        const dropdownData: DropdownItem<string>[] = filter.concepts.map(item => {
            return {
                value: item.name,
                label: item.name,
                selected: item.isSelected
            };
        });

        const dropdownItems: DropdownData<string> = {
            dropdownLabel: this.translate.instant('shared.deliverables.correlations.filter.concepts.label'),
            dataType: dataType,
            contentTitle: this.translate.instant('shared.deliverables.correlations.filter.concepts.itemsTitle'),
            groupSelect: this.translate.instant('shared.deliverables.correlations.filter.concepts.allSelectedLabel'),
            data: dropdownData
        };

        return dropdownItems;
    }

    /**
     * Returns sub groups filter options.
     *
     * @private
     * @param {Array<FilterItem>} items
     * @returns {DropdownData}
     * @memberof FilterComponent
     */
    private getSubGroupFilterOptions(filter: CorrelationsFilter): DropdownData<string> {
        const dataType = DataType.RADIO;
        const groupSelect = (dataType === DataType.RADIO ? '' : this.translate.instant('shared.deliverables.correlations.filter.subgroups.allSubgroups'));
        const dropdownData: DropdownItem<string>[] = filter.subgroups.map(item => {
            return {
                value: item.name,
                label: item.name,
                selected: item.isSelected
            };
        });

        const dropdownItems: DropdownData<string> = {
            dropdownLabel: this.translate.instant('shared.deliverables.correlations.filter.subgroups.label'),
            dataType: dataType,
            groupSelect: groupSelect,
            contentTitle: this.translate.instant('shared.deliverables.correlations.filter.subgroups.itemsTitle'),
            data: dropdownData
        };

        return dropdownItems;
    }

    /**
     * Emits event of the selected filter menu item.
     */
    selectedEvent(event): void {
        console.log(event);
    }

    /**
     * Sets the new filter entries when changes are made to compare drop down.
     *
     * @listens event:selectionChange
     * @param {Array<DropDownItem>}compare The list of compare options.
     */
    selectCompare(compare: any) {
        const filter$ = this.correlationsService.getCorrelationsFilter();
        const filterSubscription = filter$.pipe(take(1))
            .subscribe((filter: CorrelationsFilter) => {
                this.defaultFilter = filter
                const selectedCompare: DropdownItem<string> = compare.find((option: DropdownItem<string>) => {
                    return option.selected;
                });
                this.filterService.update({
                    ...this.filter,
                    deliverableViewType: selectedCompare.value.toString(),
                    compare: this.filter.compare.map((item: FilterItem) => {
                        return {
                            ...item,
                            isSelected: compare.find(selected => selected.value === item.id).selected
                        };
                    }),
                    concepts: this.filter.concepts.map((concept, index) => {
                        return {
                            ...concept,
                            isSelected: compare.find(selected => selected.selected === true && selected.value === 'subgroup') ? (index === 0 ? true : false) : true
                        };
                    }),
                    subgroups: this.filter.subgroups.map((subgroup, index) => {
                        return {
                            ...subgroup,
                            isSelected: compare.find(selected => selected.selected === true && selected.value === 'concept') ? (index === 0 ? true : false) : true
                        };
                    }),
                    // Need to change for Quad Map view
                    show: {
                        ...this.defaultFilter.show,
                        keyMeasures: this.getKeyMeasuresPerView(this.defaultFilter.show.keyMeasures, compare.find(selected => selected.selected).value)
                    }
                });
            });
        this.subscriptions.push(filterSubscription);
    }

    getKeyMeasuresPerView(keyMeasures, viewName) {
        const keys = ['dataTable', 'quadMap'];
        keys.forEach(key => {
            keyMeasures[key].map((keyMeasure, index) => {
                return {
                    ...keyMeasure,
                    isSelected: viewName === CompareView.QUAD_MAP ? index === 0 : true
                };
            });
        });
        return keyMeasures;
    }
}

