<ng-scrollbar [autoHeightDisabled]="false">
    <div style="max-height: 575px;" class="deliverable-insight-sorting-matrix-table">
        <table *ngIf="concepts.length > 0" matSortDisableClear=true id="sorting-matrix-table" mat-table
               [dataSource]="dataSource" matSort matSortActive="rank" matSortDirection="asc" (click)="setUserViewSorting()"
               class="deliverable-insight-sorting-matrix-table concept-table mat-elevation-z8 table table-striped selectable-rows dotted-border">

            <!-- Position Column -->
            <ng-container matColumnDef="position">
                <th rel="tooltip" matTooltipPosition="above" matTooltip = " {{this.getSortOrder('position') }} " class="deliverable-insight-sm-header" mat-header-cell *matHeaderCellDef mat-sort-header width="50"> ID </th>
                <td mat-cell *matCellDef="let element"> {{element.position}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="conceptName">
                <th rel="tooltip" matTooltipPosition="above" matTooltip = " {{this.getSortOrder('conceptName') }} " class="deliverable-insight-sm-header" mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.conceptName}} </td>
            </ng-container>

            <!-- Rank Column -->
            <ng-container matColumnDef="rank">
                <th rel="tooltip" matTooltipPosition="above" matTooltip = " {{this.getSortOrder('rank') }} " class="deliverable-insight-sm-header" mat-header-cell *matHeaderCellDef mat-sort-header width="100"> Rank
                </th>
                <td mat-cell *matCellDef="let element"> {{element.rank}} </td>
            </ng-container>

            <!-- Need/desire Column -->
            <ng-container matColumnDef="needDesire">
                <th rel="tooltip" matTooltipPosition="above" matTooltip = " {{this.getSortOrder('needDesire') }} " class="deliverable-insight-sm-header" mat-header-cell *matHeaderCellDef mat-sort-header width="100">
                    {{axisData.governingQuestionLabel1}} </th>
                <td mat-cell *matCellDef="let element"> {{element.needDesire | roundOff}} </td>
            </ng-container>

            <!-- Uniqueness Column -->
            <ng-container matColumnDef="uniqueness">
                <th rel="tooltip" matTooltipPosition="above" matTooltip = " {{this.getSortOrder('uniqueness') }} " class="deliverable-insight-sm-header" mat-header-cell *matHeaderCellDef mat-sort-header width="100">
                    {{axisData.governingQuestionLabel2}} </th>
                <td mat-cell *matCellDef="let element"> {{element.uniqueness | roundOff}} </td>
            </ng-container>
            <div class="concept-modal-container deliverable-insight-sorting-matrix-table">
                <img *ngIf="selectedImagePath" class="img-responsive center-block" [src]="selectedImagePath"
                     alt="Concept Image">
            </div>
            <div class="buttons">
                <nd-button dialog-confirm flat secondary>Close</nd-button>
            </div>
            <!-- Image icon Column -->
            <ng-container matColumnDef="image">
                <th mat-header-cell *matHeaderCellDef width="65"> </th>
                <td mat-cell *matCellDef="let element">
          <span [style.visibility]="element === selectedConcept ? 'visible':'hidden'" id="enlarge-image"
                class="sif sif-zoom-in scatter-plot-concept-enlarge"
                (click)="enlargeConceptImage(element, conceptEnlargeModal)" rel="tooltip" matTooltipPosition="above" matTooltip=" {{'view.concept' | translate }}"></span>
                </td>
            </ng-container>

            <th></th>
            <tr mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
            <tr class="table-data-row deliverable-insight-sorting-matrix-table" mat-row
                *matRowDef="let row; columns: displayedColumns;" (mouseover)="onMouseOver(row)" (mouseout)="onMouseOut()"
                [id]="'id_' +row.rank" [class.disabled]="disabledConcepts.indexOf(row.exerciseConceptId)!=-1"
                [class.selected]="selectedConcept && row.exerciseConceptId == selectedConcept.exerciseConceptId">
            </tr>
        </table>
    </div>
</ng-scrollbar>

<ng-template #conceptEnlargeModal>
    <mat-dialog-content class="enlarge-modal sorting-matrix-modal">
        <div class="concept-modal-container">
            <img *ngIf="selectedImagePath" class="img-responsive center-block" [src]="selectedImagePath" alt="Concept Image">
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <div class="buttons">
            <button class="button" mat-button matDialogClose>Close</button>
        </div>
    </mat-dialog-actions>
</ng-template>
