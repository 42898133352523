import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, debounceTime, forkJoin, Subscription} from 'rxjs';
import {DeliverableInsight} from '@platform/deliverable-insight/deliverable-insight.model';
import {SpinnerService} from '@platform/services/spinner.service';
import {ExportPngService} from '@platform/services/export-png.service';
import {DeliverableInsightService} from '@platform/services/deliverable-insight.service';
import {UserService} from '@platform/services/user.service';
import {WordCloudFileService} from './services/word-cloud-file.service';
import {Concept} from '@platform/models/concept.model';
import {TranslateService} from '@ngx-translate/core';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelLabel, MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {WordCloudFilter} from './models/filter.model';
import {WordCloudDeliverableView} from './models/word-cloud.model';
import {WordCloudService} from './services/word-cloud.service';
import {DeliverableType} from "@app/deliverables/deliverable-type.enum";
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {DeliverableInfo} from "@platform/models/deliverable-info.model";
import {DeliverableInfoService} from "@platform/services/deliverable-info.service";
import {ReportService} from "@platform/services/report.service";
import {UserView} from "@platform/models/user-view.model";
import {UserViewService} from "@platform/services/user-view.service";
import {RouterService} from "@platform/services/router.service";
import {Report} from "@platform/models/report.model";
import {InsightService} from "@platform/insights/insights.service";
import {FilterService} from "@platform/services/filter.service";
import {ProductDeliverableViewService} from "@platform/services/product-deliverable-view.service";

@Component({
    selector: 'ns-word-cloud',
    templateUrl: './word-cloud.component.html',
    styleUrls: ['./word-cloud.component.scss']
})
export class WordCloudComponent implements OnInit, OnDestroy {

    /**
     * Report document
     */
    public report: Report;

    /**
     * ScoreCard Concept object for SW.
     *
     * @type {Concept}
     */
    public scoreCardConcept: Concept;

    /**
     * Word cloud deliverable view data.
     *
     * @type {WordCloudDeliverableView}
     * @member WordCloudComponent
     */
    public wordCloudDeliverableView: WordCloudDeliverableView;

    /**
     * Word cloud deliverable view filter object.
     *
     * @type {WordCloudFilter}
     * @member WordCloudComponent
     */
    public filter: WordCloudFilter;

    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @member WordCloudComponent
     */
    public subscriptions: Array<Subscription>;

    public deliverableViews: Array<DeliverableView>;
    /**
     * toggle insight btn
     * @type {Boolean} isInsightEnable
     * @member WordCloudComponent
     */
    public isInsightEnable = false;

    /**
     * The deliverable insight data when creating insight.
     * @type {DeliverableInsight} deliverableData
     * @member WordCloudComponent
     */
    public deliverableData: DeliverableInsight;

    /**
     * Spinner.
     *
     * @type {Boolean}
     * @member WordCloudComponent
     */
    public displayProgressSpinner = false;

    /**
     * Disable Insights Button.
     *
     * @type {Boolean}
     * @member WordCloudComponent
     */
    public disableBtn: boolean;

    /**
     * Internal User
     *
     * @type {Boolean}
     * @member WordCloudComponent
     */
    public isInternalUser: Boolean;

    public deliverableType = DeliverableType.WORD_CLOUD.type;

    public viewId: string;

    /**
     * The deliverable view object.
     */
    public deliverableViewType: string;

    public selectors: any;

    public deliverableInfos: Array<DeliverableInfo>;

    public userViews: Array<UserView>;
    public isVerbatimsView = false;

    /**
     * Feature FLAG for Automatic Headlines.
     *
     * @type {Boolean}
     */
    public isAutomatedHeadlinesEnabled: boolean;

    /**
     * Take the insight in the HTML format for this Deliverable
     *
     * @type {Boolean}
     */
    public addHTMLToInsight = false;

    public defaultViewFilters: WordCloudFilter;

    constructor(
        private wordCloudService: WordCloudService,
        private elementRef: ElementRef,
        private deliverableInsightService: DeliverableInsightService,
        private exportPNGService: ExportPngService,
        private spinnerService: SpinnerService,
        private userService: UserService,
        private wordCloudFileService: WordCloudFileService,
        private translate: TranslateService,
        private mixpanelService: MixpanelService,
        private deliverableViewService: DeliverableViewService,
        private routeService: RouterService,
        private userViewService: UserViewService,
        private filterService: FilterService,
        private deliverableInfoService: DeliverableInfoService,
        private reportService: ReportService,
        private insightService: InsightService,
        private productDeliverableViewService: ProductDeliverableViewService,
    ) {
        this.subscriptions = [];
        this.userViews = [];
    }

    /**
     * Initialize component
     *
     * @memberof WordCloudComponent
     */
    ngOnInit(): void {
        this.init();
        this.reportService.reloadDeliverable.subscribe(() => {
            this.productDeliverableViewService.clearCache();
            this.deliverableViewService.clearCache();
            this.clearSubscriptions();
            this.init();
        });
    }

    init(): void {
        this.spinnerService.showSpinner();
        const insightId = this.routeService.getQueryParam('insightId');
        const subscription = combineLatest([
            this.reportService.get(),
            this.userService.getUser(),
            this.deliverableViewService.getDeliverableViews(DeliverableType.WORD_CLOUD.type, true)
        ]).subscribe(([report, user, deliverableViews]) => {
            this.report = report;
            this.deliverableInfos = this.deliverableInfoService.getNonForecastDeliverables(report);
            this.deliverableViews = deliverableViews;
            this.isInternalUser = user.isInternalUser;
            this.isAutomatedHeadlinesEnabled = user.featureFlags.includes('REPORTING_AUTOMATED_HEADLINES');

            //load saved user views(filter) if they exist
            forkJoin([
                this.userViewService.fetchReportUserViewsFromAPI(this.report.id),
                this.insightService.getInsightFilterData<WordCloudFilter>(report.id, insightId),
                this.wordCloudService.loadDefaultFilter()
            ]).pipe(debounceTime(1000)).subscribe(([userViews, insightFilter, defaultViewFilters]) => {
                this.userViews = this.userViewService.setupUserViews(this.report.id, this.deliverableType, userViews, defaultViewFilters, insightFilter);
                const insightView = this.userViews.find(it => it.id === this.userViewService.insightViewId);
               this.defaultViewFilters = defaultViewFilters;
                this.selectUserView(insightView ? insightView : this.userViews.find(it => it.isSelected));

                /**
                 * Add subscription to watch filter changes here so that s&w deliverable data can also be updated as per filter change.
                 * */
                this.subscriptions.push(combineLatest([
                    this.wordCloudService.getWordCloudFilter(),
                    this.wordCloudService.getWordCloud()
                ]).subscribe(([filter, wordCloud]) => {
                    this.filter = filter;
                    this.wordCloudDeliverableView = wordCloud;
                    this.isVerbatimsView = this.filter.compare.find(it => it.isSelected).name === 'Verbatims';
                    if (this.isInsightEnable && this.isAutomatedHeadlinesEnabled) {
                        this.openInsightCreationForm();
                    }
                }));
                this.spinnerService.hideSpinner();
            });
        });

        this.subscriptions.push(subscription);
    }

    /**
     * Method that is triggered when user view is changed. This will in turn update the filter model in the store.
     * */
    selectUserView(userView: UserView): void {
        this.filter = userView.filter as WordCloudFilter;
        this.isVerbatimsView = this.filter.compare.find(it => it.isSelected === true).name === 'Verbatims';
        if (userView.id !== 'Default View') {
            this.filterService.update({
                ...this.filter,
                concepts:  this.userViewService.updateUserViewConceptsWithDefaultViewConcepts(this.filter , this.defaultViewFilters)
            });
        } else {
            this.filterService.update(userView.filter);
        }
        this.disableBtn = this.isConceptExists(this.filter);
    }

    /**
     * download survey questions data.
     *
     */
    download(event): void {
        this.mixpanelService.track(MixpanelLabel.wordCloud, MixpanelEvent.verbatimsExport);
        event.preventDefault();
        this.displayProgressSpinner = true;
        this.wordCloudFileService.download();
        this.spinnerService.getSpinnerObs().subscribe(loading => this.displayProgressSpinner = loading);
    }

    /**
     * Information for user to select only first 10 concept
     */
    getConceptCountMessage() {
        return this.translate.instant('platform.deliverable.insight.info.text');
    }

    /**
     * capture screen layout and export as png.
     *
     */
    exportAsPNG() {
        this.mixpanelService.track(MixpanelLabel.wordCloud, MixpanelEvent.exportAsPNG);
        this.displayProgressSpinner = true;
        const selectors = [];
        const elements: Array<Element> = this.elementRef.nativeElement.querySelectorAll('.word-cloud-export-png');
        elements.forEach((element: Element) => {
            const selector = `#${element.id}`;
            selectors.push(selector);
        });
        this.exportPNGService.exportPNG(selectors);
        this.spinnerService.getSpinnerObs().subscribe((loading) => this.displayProgressSpinner = loading);
    }

    /**
     * toggle between headers and insight creation form.
     *
     */
    openInsightCreationForm() {
        setTimeout(() => {
            this.isInsightEnable = true;
            const selectedQuestion = this.filter.questions.find(it => it.isSelected).name;
            const selectedCompare = this.filter.compare.find(it => it.isSelected);
            const deliverableView = this.deliverableViews.find(it => it.viewName === selectedCompare.id);
            this.addHTMLToInsight = true;
            this.deliverableData = {
                title: this.isAutomatedHeadlinesEnabled ? this.deliverableInsightService.generateInsightTitle(selectedQuestion) : '',
                selectors: this.wordCloudService.getSelectors(this.filter, this.elementRef.nativeElement),
                deliverable: {
                    deliverableViewId: deliverableView.id,
                    filter: this.filter,
                    insightHTML: '',
                    deliverableType: this.deliverableType
                }
            };
        },100);
    }

    /**
     * Close insight form
     */
    closeInsight() {
        this.isInsightEnable = false;
    }

    /**
     * Cleanup hook.
     *
     * @memberof WordCloudComponent
     */
    ngOnDestroy(): void {
        this.clearSubscriptions();
        this.productDeliverableViewService.clearCache();
        this.deliverableViewService.clearCache();
    }

    clearSubscriptions(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
            this.subscriptions = [];
        }
    }

    /**
     * Action that is triggered when the deliverable info is changed.
     *
     * @param deliverableInfo
     */
    onDeliverableChange(deliverableInfo: DeliverableInfo): void {
        this.deliverableInfoService.routeToDeliverable(deliverableInfo);
    }

    /**
     * check concept count
     * */
    isConceptExists(filter: WordCloudFilter): boolean {
        const conceptCount = filter.concepts.filter(it => it.isSelected === true).length;
        const subgroupCount = filter.subgroups.filter(it => it.isSelected === true).length;
        return (conceptCount == 0 || subgroupCount == 0);
    }

}
