<div class="filter-wrapper">
  <div ngClass="['menu-content']">
    <button class="dropdown-facet-button" mat-button [matMenuTriggerFor]="menu" rel="tooltip" matTooltipPosition="above" matTooltip=" {{'tooltip.filter' | translate: { viewComparison: 'Options selected', viewComparisonName: filterLabel } }}">
      <span>{{filterLabel}}</span>
    </button>
    <mat-menu #menu="matMenu">
      <section class="dropdown-filter-menu">
        <ul class="dropdown-menu-list">
          <div>
            <h3 class="menu-title">Decimals</h3>
            <mat-radio-group [ngModel]="getSelectedDecimalPlace()" (change)="changeDecimalPlace($event)">
                <div class="menu-list-content">
                    <mat-radio-button color="primary" class="list-item" [value]="filterNames.noDecimals">
                      <li class="list-item">{{ 'shared.deliverables.survey-question.filter.label.no.decimals' | translate }}</li>
                    </mat-radio-button>
                    <mat-radio-button color="primary" class="list-item" [value]="filterNames.oneDecimalPlace">
                      <li class="list-item">{{ 'shared.deliverables.survey-question.filter.label.one.decimal' | translate }}</li>
                    </mat-radio-button>
                    <mat-radio-button color="primary" class="list-item" [value]="filterNames.twoDecimalPlace">
                      <li class="list-item">{{ 'shared.deliverables.survey-question.filter.label.two.decimal' | translate }}</li>
                    </mat-radio-button>
                </div>
            </mat-radio-group>
          </div>
        </ul>
      </section>
      <section class="dropdown-filter-menu">
          <div>
            <h3 class="menu-title">{{ 'shared.deliverables.survey-question.filter.label.options' | translate }}</h3>
            <mat-checkbox color="primary" rounded flat class="list-item" [label]="filterNames.statTesting" [value]="filterNames.statTesting" [checked]="filter?.show?.statTesting" (change)="toggleSelection(filterNames.statTesting)" noink>{{ 'shared.deliverables.survey-question.filter.label.stat.testing' | translate }}</mat-checkbox>
            <mat-checkbox color="primary" *ngIf="showAggregateFilter" rounded flat class="list-item" [label]="filterNames.aggregate" [value]="filterNames.aggregate" [checked]="filter?.show?.aggregate" (change)="toggleSelection(filterNames.aggregate)" noink>{{ 'shared.deliverables.survey-question.filter.label.aggregate' | translate }}</mat-checkbox>
            <mat-checkbox color="primary" rounded flat class="list-item" [label]="filterNames.mention" [value]="filterNames.mention" [checked]="filter?.show?.mention" (change)="toggleSelection(filterNames.mention)" noink>{{ 'shared.deliverables.survey-question.filter.label.mention' | translate }}</mat-checkbox>
          </div>
      </section>
      </mat-menu>
    </div>
  </div>
