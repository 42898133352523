import { State } from '@platform/store/state/app.state';
import { AppConfigService } from '@app/core/config/app-config.service';
import { ReportService } from '@platform/services/report.service';
import {Observable, filter, of} from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { FilterService } from '@platform/services/filter.service';
import { DeliverableConfiguration } from '@platform/models/deliverable-configuration.model';
import {
    addDeliverableConfigurations,
    updateDeliverableConfiguration
} from '@platform/store/deliverable-configuration/deliverable-configuration.actions';
import { concatMap, skipWhile, switchMap, take } from 'rxjs/operators';
import {
    selectDeliverableConfigurationById,
    selectDeliverableConfigurationByName
} from '@platform/store/deliverable-configuration/deliverable-configuration.selector';
import { selectRouter } from '@platform/store/selectors/router.selectors';
import { DeliverableType } from '@app/deliverables/deliverable-type.enum';
import { ForecastResults } from '@app/deliverables/forecast-volume-estimate/forecast-volume-estimate.model';
import { DeliverableView } from '@platform/models/deliverable-view.model';
import { DeliverableViewService } from './deliverable-view.service';
import { ProductDeliverableViewService } from './product-deliverable-view.service';
import { ForecastMarketingPlanSummary } from '@app/deliverables/forecast-marketing-plan-summary/forecast-marketing-plan-summary.model';
import { ForecastVarietySplit } from '@app/deliverables/forecast-variety-split/forecast-variety-split.model';
import { SpinnerService } from './spinner.service';
import { DeliverablesListItem } from '@products/shared/deliverables-list/deliverables-list-item.data.model';
import {
    SummaryColumns
} from '@platform/report/report-settings/deliverable-configuration/variety-split-deliverable-configuration/variety-split-deliverable-configuration-model';

/**
 * This service provides operations for accessing retrieving, updating,
 * filtering and loading deliverable configurations for the current report object.
 * Report object will be retrieved from the URL.
 *
 * @example
 * constructor(private deliverableConfigurationService: DeliverableConfigurationService) { }
 *
 * @export
 * @class DeliverableConfigurationService
 */
@Injectable({
    providedIn: 'root'
})
export class DeliverableConfigurationService {
    private loadingMap: Map<string, boolean> = new Map<string, boolean>();
    isDeliverableFromForecasting: boolean;
    isVolumeDeliverableFromForecasting: boolean;
    deliverablesCache: any = {};
    isMarketingPlanDeliverableFromForecasting: boolean;
    isVarietySplitDeliverableFromForecasting: boolean;
    isDeliverablesConfigReGenerated: boolean;
    sharedcurrency: string = '';

    //Deliverable Config types
    FORECAST_ESTIMATE = "forecast.estimate";
    FORECAST_MARKETING_PLAN_SUMMARY = "forecast.marketing.plan.summary";
    FORECAST_VOLUME_ESTIMATE = "forecast.volume.estimate";
    FORECAST_VARIETY_SPLIT = "forecast.variety.split";
    PURCHASE_PREFERENCE_SHARE = 'purchase.preference';
    CORRELATIONS = 'correlations';
    FACTORS = 'factors';
    FINANCIAL_POTENTIAL = 'fp';

    /**
     * Creates an instance of DeliverableConfigurationService.
     *
     * @constructor
     * @param {Store<State>} store
     * @param {HttpClient} httpClient
     * @param {FilterService} filterService
     * @param {ReportService} reportService
     * @param cs
     * @memberOf DeliverableConfigurationService
     */
    constructor(
        private store: Store<State>,
        private httpClient: HttpClient,
        private reportService: ReportService,
        private cs: AppConfigService,
        private productDeliverableViewService: ProductDeliverableViewService,
        private deliverableViewService: DeliverableViewService,
        private spinnerService: SpinnerService,) {
    }

    /**
     * Updates a product deliverable configuration object.
     *
     * @example
     * const attributes: DeliverableConfiguration = {...}
     * deliverableConfigurationService.update<DeliverableConfiguration>(attributes)
     *
     * @param DeliverableConfiguration The product deliverable configuration type.
     * @returns {void}
     * @memberOf DeliverableConfigurationService
     */
    public update(deliverableConfiguration): void {
        return this.store.dispatch(updateDeliverableConfiguration({ deliverableConfiguration }));
    }

    /**
     * Returns an observable with a single deliverableConfiguration for use by individual products.
     * @param deliverableConfigurationName name of deliverableConfiguration to be returned.
     * @param reload
     */
    public getDeliverableConfiguration(deliverableConfigurationName: string, reload?: boolean): Observable<DeliverableConfiguration> {
        const report$ = this.reportService.get();
        return report$.pipe(
            switchMap(report => {
                return this.fetchAll(report.id).pipe(switchMap((configurations) => {
                    const deliverableConfiguration = configurations.find(it => it.name === deliverableConfigurationName);
                    if (deliverableConfiguration) {
                        this.load(deliverableConfiguration.id, report.id, null, reload);
                        return this.fetchDeliverableConfigById(deliverableConfiguration.id);
                    } else {
                        return of(null);
                    }
                }));
            })
        );
    }

    /**
     * Returns an observable with a single deliverableConfiguration for use by individual products.
     * @param deliverableConfigurationName name of deliverableConfiguration to be returned.
     */
    public getDeliverableConfigurationByName(deliverableConfigurationName: string): Observable<DeliverableConfiguration> {
        const report$ = this.reportService.get();
        return report$.pipe(
            switchMap(report => {
                return this.fetch(null, report.id, {name: deliverableConfigurationName}).pipe(switchMap((deliverableConfiguration) => {
                    this.loadOnStore(deliverableConfiguration);
                    return this.fetchDeliverableConfigById(deliverableConfiguration.id);
                }));
            }),
            skipWhile(view => !view)
        );
    }

    /**
     * Fetches all deliverableConfiguration Objects for the given reportId.
     * @param reportId
     */
    public fetchAll<T extends DeliverableConfiguration>(reportId: string): Observable<Array<T>> {
        const url = `${this.cs.config.reporting.url}/reports/${reportId}/deliverableConfiguration`;
        return this.httpClient.get<Array<T>>(url);
    }

    //
    // /**
    //  * Updates the deliverableConfiguration in the database.
    //  * @param deliverableConfiguration object to be updated in the database.
    //  * @param reportId
    //  */
    public updateReportDeliverableConfiguration(deliverableConfiguration, reportId): Observable<any> {
        const url = `${this.cs.config.reporting.url}/reports/${reportId}/deliverableConfiguration`;
        const postBody = {};
        postBody['deliverableConfiguration'] = deliverableConfiguration;
        if (deliverableConfiguration.id) {
            this.loadOnStore(deliverableConfiguration);
        }
        return this.httpClient.post(url, postBody);
    }

    /**
     * Returns an observable of DeliverableConfiguration connected to a specific deliverableConfiguration ID.
     * @param id id if deliverableConfiguration to be returned in Observable.
     * @param reportId
     * @param params
     */
    public fetch<T extends DeliverableConfiguration>(id: string, reportId: string, params?: { [key: string]: string | number }): Observable<T> {
        const url = `${this.cs.config.reporting.url}/reports/${reportId}/deliverableConfiguration`;
        let httpParams: HttpParams = new HttpParams();
        if (params && Object.keys(params).length > 0) {
            Object.keys(params).forEach(key =>
                httpParams = httpParams.append(key, params[key])
            );
        }
        return this.httpClient.get<T>(url, { params: httpParams });
    }

    /**
     * Fetches a product deliverable configuration using the API and loads it into the store.
     *
     * @example
     * this.deliverableConfigurationService.load(deliverableConfiguration.id, report.id, null, reload);
     *
     * @template T extends {@link DeliverableConfiguration}
     * @param {string} id The deliverable view id.
     * @param {[key: string]: string]} params Optional query
     * params object.
     * @param {boolean} reload Set to true if view data needs to be reloaded through API.
     * @memberOf DeliverableConfigurationService
     */
    public load<T extends DeliverableConfiguration>(id: string, reportId: string,
        params?: { [key: string]: string | number }, reload?: boolean): void {
        const deliverableConfiguration$ = this.fetchAll(reportId);
        const loadingMap = this.loadingMap;
        deliverableConfiguration$.subscribe(deliverableConfiguration => {
            if ((loadingMap.get(id) !== true && !deliverableConfiguration) || reload) {
                loadingMap.set(id, true);
                reload = false;
                this.fetch<T>(id, reportId, params).subscribe(result => {
                    this.loadOnStore(result);
                    loadingMap.set(id, false);

                });
            }
        });
    }

    /**
     * Returns a deliverable configuration observable from the store.
     *
     * @private
     * @template T extends {@link DeliverableConfiguration}
     * @param {string} id The deliverable view id.
     * @memberOf DeliverableConfigurationService
     */
    public fetchDeliverableConfigById(id: string) {
        return this.store.pipe(select(selectDeliverableConfigurationById, { id }));
    }

    public SetSharedCurrency(value: string) {
        this.sharedcurrency = value;
    }

    public getSharedCurency() {
        return this.sharedcurrency;
    }
    /**
     * Loads a deliverable configuration into the store.
     *
     * @private
     * @param {DeliverableConfiguration} deliverableConfiguration
     * @memberOf DeliverableConfigurationService
     */
    public loadOnStore(deliverableConfiguration: DeliverableConfiguration): void {
        this.store.dispatch(updateDeliverableConfiguration({ deliverableConfiguration }));
    }

    /**
     * Returns an observable of DeliverableConfiguration[]
     */
    getAllDeliverableConfigurations(): Observable<DeliverableConfiguration[]> {
        const routerState$ = this.store.pipe(select(selectRouter));
        return routerState$.pipe(
            concatMap(result => {
                const reportId = result.params.reportId;
                return this.fetchAllFromAPI(reportId);
            }),
            take(1)
        );
    }

    /**
     * API call to return all deliverable configuration in an observable.
     * @param reportId
     */
    fetchAllFromAPI(reportId: string): Observable<DeliverableConfiguration[]> {
        const url = `${this.cs.config.reporting.url}/reports/${reportId}/deliverableConfiguration`;
        return this.httpClient.get<DeliverableConfiguration[]>(url).pipe(concatMap(deliverableConfigurations => {
            this.store.dispatch(addDeliverableConfigurations({ deliverableConfigurations }));
            return this.store.select(selectDeliverableConfigurationByName(reportId));
        }));
    }

    getAllDeliverableConfigurationsFromStore(reportId: string) {
        return this.store.select(selectDeliverableConfigurationByName(reportId));
    }


    updateConfigIfDeliverablesRegeneratedInForecasting(currentDeliverableConfigurations: DeliverableConfiguration[], newConfig, configName, reportId) {
        this.isDeliverablesConfigReGenerated = true;
        if (!currentDeliverableConfigurations) {
            currentDeliverableConfigurations = [];
        }
        else {
            currentDeliverableConfigurations = JSON.parse(JSON.stringify(currentDeliverableConfigurations))
        }
        let config = currentDeliverableConfigurations.find(c => c.name == configName);
        if (!config) {
            config = {
                id: '',
                reportId: reportId,
                name: configName,
                config: { estimates: [] }
            }
            currentDeliverableConfigurations.push(config);
        }

        //if config is store variable it would be Readonly thus doing deep copy
        let deliverableConfiguration = currentDeliverableConfigurations.find(c => c.name == configName);

        if (deliverableConfiguration?.config) {
            let dataList = deliverableConfiguration?.config?.estimates;
            if (dataList) {
                //delete the estimates removed from forecasting deliverables on regenerate
                dataList = dataList.filter(estimate => { if (newConfig.find(vr => vr.id == estimate.id)) return estimate });
                //add or update estimates
                newConfig.forEach(estimate => {
                    const dataListEstimate = dataList.find(e => e.id == estimate.id);
                    if (!dataListEstimate) {
                        //new estimate add it to the dataList
                        dataList.push(estimate);
                    }
                    else {
                        //update original values for existing estimate
                        if (dataListEstimate.originalName == dataListEstimate.displayName) {
                            dataListEstimate.displayName = estimate.originalName;
                        }
                        dataListEstimate.originalName = estimate.originalName;

                        if (dataListEstimate.originalPosition == dataListEstimate.position) {
                            dataListEstimate.position = estimate.originalPosition;
                        }
                        dataListEstimate.originalPosition = estimate.originalPosition;
                    }
                });
                deliverableConfiguration.config.estimates = dataList.sort((a, b) => a.position - b.position);
                this.updateReportDeliverableConfiguration(deliverableConfiguration, reportId).subscribe(view => {
                    this.update(view);
                    this.spinnerService.spinnerObs$.next(false);
                });

                if (currentDeliverableConfigurations.find(c => c.name == this.FORECAST_MARKETING_PLAN_SUMMARY)) {
                    let mpSummaryConfig = JSON.parse(JSON.stringify(currentDeliverableConfigurations.find(c => c.name == this.FORECAST_MARKETING_PLAN_SUMMARY)));
                    mpSummaryConfig = this.updateConfigForMarketingPlan(currentDeliverableConfigurations, mpSummaryConfig.config.headerTableData, mpSummaryConfig.config.tableData);
                    this.updateReportDeliverableConfiguration(mpSummaryConfig, reportId).subscribe(view => {
                        this.update(view);
                        this.spinnerService.spinnerObs$.next(false);
                    });
                }
                if (currentDeliverableConfigurations.find(c => c.name == this.FORECAST_VOLUME_ESTIMATE)) {
                    let volumeEstimateSummaryConfig = JSON.parse(JSON.stringify(currentDeliverableConfigurations.find(c => c.name == this.FORECAST_VOLUME_ESTIMATE)));
                    volumeEstimateSummaryConfig = this.updateConfigForVolumeEstimate(currentDeliverableConfigurations, volumeEstimateSummaryConfig.config.mboList, volumeEstimateSummaryConfig.config.tableData);
                    this.updateReportDeliverableConfiguration(volumeEstimateSummaryConfig, reportId).subscribe(view => {
                        this.update(view);
                        this.spinnerService.spinnerObs$.next(false);
                    });
                }
                if (currentDeliverableConfigurations.find(c => c.name == this.FORECAST_VARIETY_SPLIT)) {
                    let varietyConfig = this.updateConfigForVarietySplit(currentDeliverableConfigurations);
                    this.updateReportDeliverableConfiguration(varietyConfig, reportId).subscribe(view => {
                        this.update(view);
                        this.spinnerService.spinnerObs$.next(false);
                    });
                }
            }
        }
    }

    updateConfigForForecastEstimate(currentDeliverableConfigurations, reportId) {
        if (!this.isDeliverablesConfigReGenerated) {
            this.spinnerService.setSpinnerObs();
            this.deliverableViewService.fetchAll<DeliverableView>(reportId, DeliverableType.FORECAST_RESULTS.type).subscribe((views: DeliverableView[]) => {
                if (views && views.length > 0 && views[0].productViewId) {
                    this.productDeliverableViewService.get<ForecastResults>(reportId, views[0].productViewId).subscribe((result) => {
                        let newConfig = [];
                        this.isDeliverableFromForecasting = false;
                        if (result.volumeRevenues.length > 0 && !!result.volumeRevenues[0].data?.isDeliverableFromForecasting) {
                            this.isDeliverableFromForecasting = true;
                            this.deliverablesCache[this.FORECAST_VOLUME_ESTIMATE] = JSON.parse(JSON.stringify(result.volumeRevenues));
                            newConfig = result.volumeRevenues.map((volume, index) => {
                                return {
                                    id: volume.data.estimateId,
                                    displayName: volume.data.estimateName.trim(),
                                    originalName: volume.data.estimateName.trim(),
                                    isVisible: true,
                                    position: index,
                                    originalPosition: index,
                                    children: [],
                                    isExpanded: false,
                                }
                            });
                        }
                        this.updateConfigForForecastEstimateFromMarketingPlanDeliverable(newConfig, currentDeliverableConfigurations, reportId);
                    });
                }
                else {
                    this.updateConfigForForecastEstimateFromMarketingPlanDeliverable([], currentDeliverableConfigurations, reportId);
                }
            });
        }

    }

    updateConfigForForecastEstimateFromMarketingPlanDeliverable(newConfig, currentDeliverableConfigurations, reportId) {
        if (!this.isDeliverablesConfigReGenerated) {
            this.deliverableViewService.fetchAll<DeliverableView>(reportId, DeliverableType.FORECAST_MARKETING_SUMMARY.type).subscribe((views: DeliverableView[]) => {
                if (views && views.length > 0 && views[0].productViewId) {
                    this.productDeliverableViewService.get<ForecastMarketingPlanSummary>(reportId, views[0].productViewId).subscribe((mpResult) => {
                        if (mpResult.plans.length > 0 && !!mpResult.plans[0].data?.isDeliverableFromForecasting) {
                            this.isDeliverableFromForecasting = true;
                            this.deliverablesCache[this.FORECAST_MARKETING_PLAN_SUMMARY] = JSON.parse(JSON.stringify(mpResult.plans));
                            mpResult.plans.forEach(plan => {
                                if (!newConfig.find(it => it.id == plan.data.estimateId)) {
                                    newConfig.push({
                                        id: plan.data.estimateId,
                                        displayName: plan.data.estimateName.trim(),
                                        originalName: plan.data.estimateName.trim(),
                                        isVisible: true,
                                        position: newConfig.length,
                                        originalPosition: newConfig.length,
                                        children: [],
                                        isExpanded: false,
                                    })
                                }
                            });
                        }
                        this.updateConfigForForecastEstimateFromVarietySplitDeliverable(newConfig, currentDeliverableConfigurations, reportId);
                    });
                }
                else {
                    this.updateConfigForForecastEstimateFromVarietySplitDeliverable(newConfig, currentDeliverableConfigurations, reportId);
                }
            });
        }
    }

    updateConfigForForecastEstimateFromVarietySplitDeliverable(newConfig, currentDeliverableConfigurations, reportId) {
        if (!this.isDeliverablesConfigReGenerated) {
            this.deliverableViewService.fetchAll<DeliverableView>(reportId, DeliverableType.FORECAST_VARIETY_SPLIT.type).subscribe((views: DeliverableView[]) => {
                if (views && views.length > 0 && views[0].productViewId) {
                    this.productDeliverableViewService.get<ForecastVarietySplit>(reportId, views[0].productViewId).subscribe((varietyResult) => {
                        if (varietyResult.plans.length > 0 && !!varietyResult.plans[0].data?.isDeliverableFromForecasting) {
                            this.isDeliverableFromForecasting = true;
                            this.deliverablesCache[this.FORECAST_VARIETY_SPLIT] = JSON.parse(JSON.stringify(varietyResult.plans));
                            varietyResult.plans.forEach(plan => {
                                if (!newConfig.find(it => it.id == plan.estimateId)) {
                                    newConfig.push({
                                        id: plan.estimateId,
                                        displayName: plan.estimateName.trim(),
                                        originalName: plan.estimateName.trim(),
                                        isVisible: true,
                                        position: newConfig.length,
                                        originalPosition: newConfig.length,
                                        children: [],
                                        isExpanded: false,
                                    })
                                }
                            });
                        }
                        this.updateConfigIfDeliverablesRegeneratedInForecasting(currentDeliverableConfigurations, newConfig, this.FORECAST_ESTIMATE, reportId);
                    });
                }
                else {
                    this.updateConfigIfDeliverablesRegeneratedInForecasting(currentDeliverableConfigurations, newConfig, this.FORECAST_ESTIMATE, reportId);
                }
            });
        }
    }

    updateEstimateConfig(oldEstimateConfig, newEstimateConfig, configName) {
        if (!(oldEstimateConfig && oldEstimateConfig.length > 0)) {
            return newEstimateConfig.sort((a, b) => a.position - b.position);
        }

        let positionUpdated = oldEstimateConfig.some(e => e.position != e.originalPosition);
        let oldEstimateConfigOriginals = oldEstimateConfig.map(estimate => {
            return {
                id: estimate.id,
                originalName: estimate.originalName,
                originalPosition: estimate.originalPosition,
                data: estimate.data
            }
        }).sort((a, b) => a.originalPosition - b.originalPosition);
        let newEstimateConfigOriginals = newEstimateConfig.map(e => {
            return {
                id: e.id,
                originalName: e.originalName,
                originalPosition: e.originalPosition,
                data: e.data
            }
        }).sort((a, b) => a.originalPosition - b.originalPosition);
        const isDeliverableRegenerated = JSON.stringify(oldEstimateConfigOriginals) != JSON.stringify(newEstimateConfigOriginals)
        if (isDeliverableRegenerated) {
            //delete the estimates removed from forecasting deliverables on regenerate
            oldEstimateConfig = oldEstimateConfig.filter(estimate => { if (newEstimateConfig.find(vr => vr.id == estimate.id)) return estimate });
            //add or update estimates
            newEstimateConfig.forEach(estimate => {
                const dataListEstimate = oldEstimateConfig.find(e => e.id == estimate.id);
                if (!dataListEstimate) {
                    //new estimate add it to the dataList
                    oldEstimateConfig.push(estimate);
                }
                else {
                    //update original values for existing estimate
                    if (dataListEstimate.originalName == dataListEstimate.displayName) {
                        dataListEstimate.displayName = estimate.originalName;
                    }
                    dataListEstimate.originalName = estimate.originalName;

                    if (dataListEstimate.originalPosition == dataListEstimate.position && !positionUpdated) {
                        dataListEstimate.position = estimate.originalPosition;
                    }
                    dataListEstimate.originalPosition = estimate.originalPosition;

                    if (!dataListEstimate.data) {
                        dataListEstimate.data = estimate.data
                    } else {
                        if (configName === this.FORECAST_VOLUME_ESTIMATE) {
                            if (dataListEstimate.data.originalProductAssumption === dataListEstimate.data.productAssumption) {
                                dataListEstimate.data.productAssumption = estimate.data.originalProductAssumption;
                            }
                            dataListEstimate.data.originalProductAssumption = estimate.data.originalProductAssumption;
                        }

                        if (dataListEstimate.data.originalConsumerAssumption === dataListEstimate.data.consumerAssumption) {
                            dataListEstimate.data.consumerAssumption = estimate.data.originalConsumerAssumption;
                        }
                        dataListEstimate.data.originalConsumerAssumption = estimate.data.consumerAssumption

                        if (dataListEstimate.data.originalTradeAssumption === dataListEstimate.data.tradeAssumption) {
                            dataListEstimate.data.tradeAssumption = estimate.data.originalTradeAssumption;
                        }
                        dataListEstimate.data.originalTradeAssumption = estimate.data.tradeAssumption;

                    }
                }
            });

            oldEstimateConfig = oldEstimateConfig.sort((a, b) => a.position - b.position);
        }
        return oldEstimateConfig;
    }

    applyL1Config(configL1, newViewConfig) {
        if (configL1) {
            return newViewConfig.filter(data => configL1.find(e => e.id == data.id)?.isVisible).map(data => {
                const forecastEstimate = configL1.find(e => e.id == data.id);
                if (forecastEstimate) {
                    data.displayName = forecastEstimate.displayName;
                    data.originalName = forecastEstimate.displayName;
                    data.originalPosition = forecastEstimate.position;
                    data.position = forecastEstimate.position;
                }
                return data;
            })
        }
        return newViewConfig
    }

    updateConfigForVolumeEstimate(currentDeliverableConfigurations: DeliverableConfiguration[], mboList, tableData) {
        this.isVolumeDeliverableFromForecasting = false;
        if (this.deliverablesCache[this.FORECAST_VOLUME_ESTIMATE]) {
            const result = this.deliverablesCache[this.FORECAST_VOLUME_ESTIMATE];
            let newEstimateConfig = [];
            if (result.length > 0 && !!result[0].data?.isDeliverableFromForecasting) {
                this.isVolumeDeliverableFromForecasting = true;
                newEstimateConfig = result.map((volume, index) => {
                    let productPerformance = volume.data['productPerformance'] ? volume.data['productPerformance'] : volume.data['productAssumption'];
                    let consumerPromotionLevel = volume.data.consumerPromotionLevel;
                    let tradePromotionLevel = volume.data.tradePromotionLevel;
                    return {
                        id: volume.data.estimateId,
                        displayName: volume.data.estimateName.trim(),
                        originalName: volume.data.estimateName.trim(),
                        isVisible: true,
                        position: index,
                        originalPosition: index,
                        children: [],
                        data: {
                            productAssumption: productPerformance,
                            originalProductAssumption: productPerformance,
                            consumerAssumption: consumerPromotionLevel,
                            originalConsumerAssumption: consumerPromotionLevel,
                            tradeAssumption: tradePromotionLevel,
                            originalTradeAssumption: tradePromotionLevel
                        }
                    }
                });
                let forecastEstimateDeliverableConfiguration = currentDeliverableConfigurations?.find(c => c.name == this.FORECAST_ESTIMATE)
                if (forecastEstimateDeliverableConfiguration && forecastEstimateDeliverableConfiguration.config?.estimates) {
                    newEstimateConfig = this.applyL1Config(forecastEstimateDeliverableConfiguration.config.estimates, newEstimateConfig);
                }

                let deliverableConfiguration = JSON.parse(JSON.stringify(currentDeliverableConfigurations.find(c => c.name == this.FORECAST_VOLUME_ESTIMATE)));
                if (deliverableConfiguration?.config) {
                    let dataList = deliverableConfiguration?.config.estimates;
                    deliverableConfiguration.config.estimates = this.updateEstimateConfig(dataList, newEstimateConfig, this.FORECAST_VOLUME_ESTIMATE);
                    deliverableConfiguration.config.mboList = mboList;
                    deliverableConfiguration.config.tableData = tableData;
                    return deliverableConfiguration;
                }
            }
        }
        return currentDeliverableConfigurations?.find(c => c.name == this.FORECAST_VOLUME_ESTIMATE);
    }

    updateConfigForVarietySplit(currentDeliverableConfigurations) {
        this.isVarietySplitDeliverableFromForecasting = false;
        let deliverableConfiguration = JSON.parse(JSON.stringify(currentDeliverableConfigurations?.find(c => c.name == this.FORECAST_VARIETY_SPLIT)));
        let config = deliverableConfiguration.config;
        if (this.deliverablesCache[this.FORECAST_VARIETY_SPLIT]) {
            const result = this.deliverablesCache[this.FORECAST_VARIETY_SPLIT];
            if (result.length > 0 && !!result[0].data?.isDeliverableFromForecasting) {
                this.isVarietySplitDeliverableFromForecasting = true;
                const estimates = config.estimates.filter(item => result.find(e => e.estimateId === item.id));
                result.forEach(plan => {
                    this.setVarietySplitConfig(config.summaryConfig.rowsConfig,
                        config.summaryConfig.columnsConfig,
                        estimates, plan, currentDeliverableConfigurations?.find(c => c.name == this.FORECAST_ESTIMATE)?.config?.estimates);
                });
                deliverableConfiguration.config.estimates = estimates.sort((a, b) => a.position - b.position);
                return deliverableConfiguration;
            }

        }
        return currentDeliverableConfigurations?.find(c => c.name == this.FORECAST_VARIETY_SPLIT);
    }

    setVarietySplitConfig(rowsConfig: any[], columnsConfig: any[], estimateList: any[], plan: any, forecastEstimates?: any[]) {
        plan.data.varieties.forEach(sku => {
            if (!(rowsConfig.find(item => item.originalName === sku.variety))) {
                rowsConfig.push({
                    id: sku.variety,
                    displayName: sku.variety,
                    originalName: sku.variety,
                    isVisible: true,
                    position: rowsConfig.length,
                    originalPosition: rowsConfig.length,
                    children: []
                });
            }
        });
        if (forecastEstimates?.length > 0 && forecastEstimates.find(item => item.id === plan.estimateId)?.isVisible) {
            const forecastEstimate = forecastEstimates.find(item => item.id === plan.estimateId);
            const estimate = estimateList.find(item => item.id === plan.estimateId);
            if (!estimate) {
                estimateList.push({
                    id: plan.estimateId,
                    displayName: plan.estimateName,
                    originalName: plan.estimateName,
                    isVisible: true,
                    position: forecastEstimate.position,
                    originalPosition: forecastEstimate.originalPosition,
                    children: []
                });
            }
            else {
                if (estimate.displayName == estimate.originalName)
                    estimate.displayName = forecastEstimate.displayName;
                estimate.originalName = forecastEstimate.displayName;
                estimate.originalPosition = forecastEstimate.position;
                estimate.position = forecastEstimate.position;
            }
        }
        if (!(estimateList.find(item => item.id === plan.estimateId))) {
            estimateList.push({
                id: plan.estimateId,
                displayName: plan.estimateName,
                originalName: plan.estimateName,
                isVisible: true,
                position: estimateList.length,
                originalPosition: estimateList.length,
                children: []
            });
        }
        estimateList = estimateList.sort((a, b) => a.originalPosition - b.originalPosition);
        SummaryColumns.forEach(column => {
            if (!(columnsConfig.find(item => item.originalName === column))) {
                columnsConfig.push({
                    id: column,
                    displayName: column,
                    originalName: column,
                    isVisible: true,
                    position: columnsConfig.length,
                    originalPosition: columnsConfig.length,
                    children: []
                });
            }
        });
    }
    updateConfigForMarketingPlan(currentDeliverableConfigurations, headerTableData, tableData) {
        this.isMarketingPlanDeliverableFromForecasting = false;
        if (this.deliverablesCache[this.FORECAST_MARKETING_PLAN_SUMMARY]) {
            let newEstimateConfig = [];
            const mpResult = this.deliverablesCache[this.FORECAST_MARKETING_PLAN_SUMMARY];
            if (mpResult.length > 0 && !!mpResult[0].data?.isDeliverableFromForecasting) {
                this.isMarketingPlanDeliverableFromForecasting = true;

                /* Update Table Data */
                const channels = [];
                mpResult.forEach(mp => {
                    const keys = Object.keys(mp.data.distributionEndOfYear).filter(k => !k.includes('labelName') && !k.includes('value'));
                    if (keys && keys.length > 0) {
                        keys.forEach(k => {
                            channels.push(k);
                        })
                    }
                });
                const distinctChannels = [...new Set([...channels, "value"])]
                const distributionTableData = tableData.find(e => e.id == "distributionEndOfYearValue");
                distributionTableData.children = this.updateDistributionChannelOnRegenerate(distinctChannels, distributionTableData);
                /*Update Estimates */
                newEstimateConfig = mpResult.map((plan, index) => {
                    let consumerPromotionLevel = plan.data.totalYearOneSpend.consumerPromotionSpend?.value ? plan.data.totalYearOneSpend.consumerPromotionSpend?.value : plan.data?.totalYearOneSpend?.consumerPromotionSpendLevel?.value;
                    let tradePromotionLevel = plan.data.totalYearOneSpend.tradePromotionSpend?.value ? plan.data.totalYearOneSpend.tradePromotionSpend?.value : plan.data?.totalYearOneSpend?.tradePromotionSpendLevel?.value;
                    return {
                        id: plan.data.estimateId,
                        displayName: plan.data.estimateName.trim(),
                        originalName: plan.data.estimateName.trim(),
                        isVisible: true,
                        position: index,
                        originalPosition: index,
                        children: [],
                        data: {
                            consumerAssumption: consumerPromotionLevel,
                            originalConsumerAssumption: consumerPromotionLevel,
                            tradeAssumption: tradePromotionLevel,
                            originalTradeAssumption: tradePromotionLevel
                        }
                    }
                });
                let forecastEstimateDeliverableConfiguration = currentDeliverableConfigurations?.find(c => c.name == this.FORECAST_ESTIMATE)
                if (forecastEstimateDeliverableConfiguration && forecastEstimateDeliverableConfiguration?.config?.estimates) {
                    newEstimateConfig = this.applyL1Config(forecastEstimateDeliverableConfiguration.config?.estimates, newEstimateConfig);
                }

                let deliverableConfiguration = JSON.parse(JSON.stringify(currentDeliverableConfigurations.find(c => c.name == this.FORECAST_MARKETING_PLAN_SUMMARY)));
                if (deliverableConfiguration?.config) {
                    let dataList = deliverableConfiguration?.config.estimates;
                    deliverableConfiguration.config.tableData = tableData;
                    deliverableConfiguration.config.headerTableData = headerTableData;
                    deliverableConfiguration.config.estimates = this.updateEstimateConfig(dataList, newEstimateConfig, this.FORECAST_MARKETING_PLAN_SUMMARY);
                    return deliverableConfiguration;
                }
            }
        }
        return currentDeliverableConfigurations?.find(c => c.name == this.FORECAST_MARKETING_PLAN_SUMMARY);
    }

    updateDistributionChannelOnRegenerate(distinctChannels: any[], distributionTableData: any): any {
        let distributionValue = distributionTableData.children.find((val) => val.id === 'distributionEndOfYearValue.distributionValue');
        distributionTableData.children = distributionTableData.children.filter((val) => val.id !== 'distributionEndOfYearValue.distributionValue');
        distinctChannels.forEach((ch, index) => {
            let tableElement = distributionTableData.children.find(e => e.id == "distributionEndOfYear." + ch);
            //add the new distribution channel
            if (!tableElement) {
                tableElement = {
                    id: "distributionEndOfYear." + ch,
                    displayName: ch,
                    originalName: ch,
                    isVisible: true,
                    position: index,
                    originalPosition: index,
                    children: [],
                }
                if (tableElement.id == 'distributionEndOfYear.value') {
                    tableElement.displayName = 'All Channels';
                    tableElement.originalName = 'All Channels';
                }
                distributionTableData.children.push(tableElement);
            }
            else {
                //update existing distribution channel
                if (tableElement.originalPosition == tableElement.position) {
                    tableElement.position = index;
                }
                tableElement.originalPosition = index;
            }
        });
        //remove the deleted distribution channel
        distributionTableData.children = distributionTableData.children.filter(e => {
            if (distinctChannels.find(ch => e.id == "distributionEndOfYear." + ch)) {
                return e;
            }
        })
        if (distributionValue != null) {
            distributionTableData.children.unshift(distributionValue);
        }
        return distributionTableData.children.sort((a, b) => a.position - b.position);
    }

    updateLevel2Config(configL1: DeliverablesListItem[], configL2: DeliverablesListItem[], configName: string): DeliverablesListItem[] {
        let positionUpdated = configL2.some(e => e.position != e.originalPosition);
        //delete the estimates hidden in L1
        configL2 = configL2.filter(e2 => configL1.find(e1 => e1.id == e2.id)?.isVisible);
        //add or update estimates
        configL1.forEach(e1 => {
            const e2 = configL2.find(e => e.id == e1.id);
            if (e2) {
                //update original values for existing estimate
                if (e2.originalName == e2.displayName) {
                    e2.displayName = e1.displayName;
                }
                e2.originalName = e1.displayName;

                if (!positionUpdated) {
                    e2.position = e1.position;
                }
                e2.originalPosition = e1.position;
            }
            else if ((this.deliverablesCache[configName]?.find(e => e.data?.estimateId == e1.id) || this.deliverablesCache[configName]?.find(e => e.estimateId == e1.id)) && e1.isVisible) {
                const position = positionUpdated ? configL2.length : e1.position;
                const est = this.deliverablesCache[configName].find(e => e.data.estimateId == e1.id);
                let configL2Element: DeliverablesListItem = {
                    id: e1.id,
                    displayName: e1.displayName,
                    originalName: e1.displayName,
                    isVisible: true,
                    position: position,
                    originalPosition: position,
                    children: []
                }
                if (configName == this.FORECAST_VOLUME_ESTIMATE) {
                    let volumeProductPerformance = est.data['productPerformance'] ? est.data['productPerformance'] : est.data['productAssumption'];
                    let volumeConsumerPromotionLevel = est.data.consumerPromotionLevel;
                    let volumeTradePromotionLevel = est.data.tradePromotionLevel;
                    configL2Element.data = {
                        productAssumption: volumeProductPerformance,
                        originalProductAssumption: volumeProductPerformance,
                        consumerAssumption: volumeConsumerPromotionLevel,
                        originalConsumerAssumption: volumeConsumerPromotionLevel,
                        tradeAssumption: volumeTradePromotionLevel,
                        originalTradeAssumption: volumeTradePromotionLevel
                    }
                } if (configName == this.FORECAST_MARKETING_PLAN_SUMMARY) {
                    let marketingPlanConsumerPromotionLevel = est.data.totalYearOneSpend.consumerPromotionSpend?.value ? est.data.totalYearOneSpend.consumerPromotionSpend?.value : est.data?.totalYearOneSpend?.consumerPromotionSpendLevel?.value;
                    let marketingPlanTradePromotionLevel = est.data.totalYearOneSpend.tradePromotionSpend?.value ? est.data.totalYearOneSpend.tradePromotionSpend?.value : est.data?.totalYearOneSpend?.tradePromotionSpendLevel?.value;
                    configL2Element.data = {
                        consumerAssumption: marketingPlanConsumerPromotionLevel,
                        originalConsumerAssumption: marketingPlanConsumerPromotionLevel,
                        tradeAssumption: marketingPlanTradePromotionLevel,
                        originalTradeAssumption: marketingPlanTradePromotionLevel
                    }
                }
                configL2.push(configL2Element);
            }
        });
        return configL2.sort((a, b) => a.position - b.position);
    }

    updateConfigForPurchasePreference(purchasePreferenceConfiguration: any) {
        this.deliverablesCache[this.PURCHASE_PREFERENCE_SHARE] = JSON.parse(JSON.stringify(purchasePreferenceConfiguration));
        this.updateReportDeliverableConfiguration(purchasePreferenceConfiguration, purchasePreferenceConfiguration.reportId).subscribe(config => {
            this.loadOnStore(config);
        });
    }

    checkForDecimalsInConfig(config, deliverableConfigurationsInReport: any[], deliverable, noOfdecimals) {
        if (config && (!config?.decimals)) {
            config['decimals'] = noOfdecimals;
            deliverableConfigurationsInReport.find(c => c.name === deliverable).config['decimals'] = noOfdecimals;
            this.isDeliverablesConfigReGenerated = false;
        }
    }

}
