<div class="deliverable-container word-cloud-content">
    <ng-container *ngIf="!scoreCardConcept">
        <div class="sticky-header-container header-filter-container" [ngClass]="{ 'hide': isInsightEnable }">
            <ns-deliverable-nav-header [deliverableInfos]="deliverableInfos" (deliverableChanged)="onDeliverableChange($event)">
                <ns-kebab-menu class="kabab-menu">
                    <ul class="kebab-menu-list">
                        <li><span (click)="exportAsPNG()">{{ 'export.as.png' | translate }}</span></li>
                        <li><span (click)="download($event)">{{ 'export.as.csv' | translate }}</span></li>
                    </ul>
                </ns-kebab-menu>
                <span rel="tooltip" matTooltipPosition="above" matTooltip=" {{'create.insights' | translate }}" *ngIf="isInternalUser" [ngClass]="{ 'disable-insight-btn': disableBtn }"
                      class="insight-icon sif sif-insight" (click)="openInsightCreationForm()">
          </span>
                <span saveUserView>
                <ns-save-user-view class="save-views"
                                   [(views)]="userViews"
                                   [filter]="filter"
                                   [reportId]="report?.id"
                                   [deliverableType]="deliverableType"
                                   (viewsChange)="userViews=$event"
                                   (selectUserView)="selectUserView($event)"></ns-save-user-view>
            </span>
            </ns-deliverable-nav-header>
        </div>
        <ns-deliverable-insight [displayMessage]="getConceptCountMessage()" [enabled]="isInsightEnable" [deliverableData]="deliverableData" [isHTMLInsight]="addHTMLToInsight" (close)="closeInsight()"></ns-deliverable-insight>
    </ng-container>
    <div id="deliverable-container">
        <div class="filters-sticky-container" [ngClass]="{ 'hide': isInsightEnable }">
            <!--Word Cloud filter-->
            <ns-word-cloud-filter [concept]="scoreCardConcept"></ns-word-cloud-filter>
        </div>
        <ng-container *ngIf="filter && !disableBtn">
            <ng-container [ngSwitch]="filter.deliverableViewType">
                <!-- World Cloud concepts view-->
                <ns-word-cloud-by-concepts *ngSwitchDefault></ns-word-cloud-by-concepts>
                <!-- World Cloud subgroups view-->
                <ns-word-cloud-by-subgroups *ngSwitchCase="'WordCloudSubgroups'"></ns-word-cloud-by-subgroups>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="disableBtn">
            <ns-deliverables-empty-state  sifIcon="sif-no-results"
                                          message="There are no Results" message2="Try refining the filters to populate data.">
            </ns-deliverables-empty-state>
        </ng-container>
    </div>
</div>
