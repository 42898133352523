import {Injectable} from '@angular/core';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {ReportService} from '@platform/services/report.service';
import {ConceptService} from '@platform/services/concept.service';
import {SubgroupService} from '@platform/services/subgroup.service';
import {FilterService} from '@platform/services/filter.service';
import {Observable, zip} from 'rxjs';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {switchMap, take} from 'rxjs/operators';
import {FilterItem, TGAFilter} from '@app/deliverables/target-group-analysis/models/filter.model';
import {TGADeliverableView} from '@app/deliverables/target-group-analysis/models/tga.model';
import {defaultTGAFilter} from '@app/deliverables/target-group-analysis/models/default-tga-filter';
import {Concept} from '@platform/models/concept.model';
import {Subgroup} from '@platform/models/subgroup.model';
import {Report} from '@platform/models/report.model';
import {DeliverableViewType} from '@app/deliverables/target-group-analysis/models/deliverable-view-type-enum';
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';
import { of } from 'rxjs';
import {FinancialPotentialFilter} from '@app/deliverables/financial-potential/models/filter.model';

@Injectable({
    providedIn: 'root'
})
export class TgaService {

    constructor(
        private deliverableViewService: DeliverableViewService,
        private productDeliverableViewService: ProductDeliverableViewService,
        private reportService: ReportService,
        private conceptService: ConceptService,
        private subgroupService: SubgroupService,
        private filterService: FilterService
    ) {
    }

    /**
     * Returns an observable of `AttributesDeliverableView` data.
     *
     * @returns {Observable<AttributesDeliverableView>}
     * @memberof AttributesService
     */
    public getTGA(): Observable<TGADeliverableView> {
        const deliverableType = DeliverableType.TARGET_GROUP_ANALYSIS.type;
        const report$ = this.reportService.get();
        const filter$ = this.getTGAFilter();
        return zip(filter$ , report$).pipe(
            take(1),
            switchMap(result => {
                const report = result[1];
                const filter = result[0];
                return this.deliverableViewService.getDeliverableViews(deliverableType).pipe(switchMap((deliverableViews) => {
                    const deliverableView = deliverableViews.find(it => it.viewName === DeliverableViewType.TARGETGROUP);
                    return this.productDeliverableViewService.get<TGADeliverableView>(report.id, deliverableView.productViewId).pipe(switchMap((productDeliverableView) => {
                        return of(this.filter(filter, productDeliverableView));
                    }));
                }));
            })
        );
    }

    /**
     * Filter projection function for filtering the attributes data and returns
     * filtered `AttributesDeliverableView` data.
     *
     * @private
     * @param {AttributesFilter} filter
     * @param {AttributesDeliverableView} data
     * @returns {AttributesDeliverableView}
     * @memberof AttributesService
     */
    private filter(filter: TGAFilter, data: TGADeliverableView): TGADeliverableView {
        const selectedConcepts = filter.concepts.filter(c => c.isSelected).map(c => c.id);
        const selectedSubgroups = filter.subgroups.filter(s => s.isSelected).map(s => s.id);
        const filteredStatements = [];
        let filteredStatement;
        let filteredDataItems;
        let filteredView;
        for (const statement of data.concepts) {
            filteredDataItems = statement.conceptData.filter(
                d => selectedConcepts.includes(d.conceptId) && selectedSubgroups.includes(d.segmentId)
            );
                filteredStatement = {
                    conceptId: statement.conceptId,
                    conceptData: filteredDataItems
                };
                filteredStatements.push(filteredStatement);
        }
        filteredView = Object.assign({}, data, {concepts: filteredStatements});
        return filteredView;
    }

    /**
     * Returns observable of attributes filter data.
     *
     * @returns {Observable<AttributesFilter>}
     * @memberof AttributesService
     */
    public getTGAFilter(): Observable<TGAFilter> {
        const deliverableType = DeliverableType.TARGET_GROUP_ANALYSIS.type,
            filter$ = this.filterService.get<TGAFilter>(deliverableType);
        return filter$;
    }

    /**
     * Loads default attributes filter data.
     * @memberof AttributesService
     */
    public loadDefaultFilter(concept?: Concept ): Observable<TGAFilter>  {
        const defaultFilter: TGAFilter = Object.assign({}, defaultTGAFilter);
        const report$ = this.reportService.get();
        const concepts$ = this.conceptService.getReportDeliverableConcepts(DeliverableType.TARGET_GROUP_ANALYSIS.type);
        const subgroups$ = this.subgroupService.getReportSubgroups(DeliverableType.TARGET_GROUP_ANALYSIS.type);
        return zip(report$, concepts$, subgroups$).pipe(switchMap(result => {
            const concepts = concept ? [concept] : result[1];
            defaultFilter.concepts = this.getConceptFilters(concepts);
            defaultFilter.countries = [result[0].country];
            defaultFilter.subgroups = this.getSubgroupFilters(result[2], result[0]);
            this.filterService.update(defaultFilter);
            return of(defaultFilter);
        }));
    }

    /**
     * Returns concept filter items.
     *
     * @private
     * @param {Array<Concept>} concepts
     * @returns {Array<FilterItem>}
     * @memberof AttributesService
     */
    private getConceptFilters(concepts: Array<Concept>): Array<FilterItem> {
        const conceptFilterItems: Array<FilterItem> = [];
        for (const item of concepts) {
            conceptFilterItems.push({
                name: item.name,
                id: item.exerciseConceptId,
                isSelected: true,
                position: item.position
            });
        }
        return conceptFilterItems;
    }

    /**
     * Returns subgroup filter items.
     *
     * @private
     * @param {Array<Subgroup>} subgroups
     * @param report
     * @returns {Array<FilterItem>}
     * @memberof AttributesService
     */
    private getSubgroupFilters(subgroups: Array<Subgroup>, report: Report): Array<FilterItem> {
        const deliverableType = DeliverableType.TARGET_GROUP_ANALYSIS.type;
        const deliverable = report.deliverables.find(d => d.type === deliverableType);
        const selectedView = deliverable.views[0].metaInfo.previousAnalysis;
        const selectedSubgroup = deliverable.views[0].metaInfo.subgroups
        const subgroupFilterItems: Array<FilterItem> = [];
        for (const item of subgroups) {
            const isExists = selectedSubgroup.find(it => it.segmentId == item.segmentId)
            if (isExists) {
                if (!selectedView && !item.isDatabaseable) {
                    subgroupFilterItems.push({
                        name: item.name,
                        id: item.segmentId,
                        isSelected: true,
                        position: item.position
                    });
                } else if (selectedView) {
                    subgroupFilterItems.push({
                        name: item.name,
                        id: item.segmentId,
                        isSelected: true,
                        position: item.position
                    });
                }
            }
        }
        return subgroupFilterItems;
    }

    /**
     * build table data for new view
     *
     * @memberOf TgaService
     * @param filter
     * @param deliverableData
     * @param subgroup
     * @param report
     */
    public getTableData(filter: TGAFilter, deliverableData: TGADeliverableView, subgroup: Array<Subgroup>, report: Report): [Array<any>, Array<any>] {
        const data = [];
        const deliverableType = DeliverableType.TARGET_GROUP_ANALYSIS.type;
        const deliverable = report.deliverables.find(d => d.type === deliverableType);
        const factorCount = deliverable.views.find(it => it.viewName === DeliverableViewType.TARGETGROUP).metaInfo.factorCount;
        const selectedConcepts = this.selectedFilterItems(filter.concepts);
        const sortedSelectedConcepts = selectedConcepts.sort(function (a, b) {
            return a.position - b.position;
        });
        const selectedSubgroups = this.selectedFilterItems(filter.subgroups);
        const subgroupsMap = this.getFilterItemMap(selectedSubgroups);
        let rowData, cellDataKey, cellData;
        const colHeaders: Array<any> = [...selectedSubgroups];
        const selectedView = this.getSelectedView(report);
        const filteredData = this.getFilteredData(deliverableData.concepts, sortedSelectedConcepts, selectedSubgroups);
        filteredData.forEach((concept) => {
            rowData = {};
            rowData.statement = sortedSelectedConcepts.find(it => it.id === concept.conceptId).name;
            concept.conceptData.forEach((dataItem, i) => {
                cellData = {};
                cellDataKey = subgroupsMap[dataItem.segmentId];
                if (dataItem.factorCount === factorCount && cellDataKey) {
                    cellData.isDatabasable = subgroup.find(it => it.segmentId === dataItem.segmentId).isDatabaseable;
                    if (!selectedView) {
                        cellData.pi = dataItem.tgWPItertileOutcome;
                        cellData.nd = dataItem.tgNeedDesireTertileOutcome;
                        cellData.ac = dataItem.tgAcceptableCoststertileOutcome;
                        cellData.qualify = dataItem.qualify;
                    } else {
                        cellData.rankText = dataItem.rankText;
                        cellData.rankValue = dataItem.rankValue;
                    }
                    rowData[cellDataKey.name] = cellData;
                }
            });
            data.push(rowData);
        });
        colHeaders.unshift({name: 'statement'});
        return [colHeaders, data];
    }

    /**
     * Returns selected filter items.
     *
     * @private
     * @param {Array<FilterItem>} items
     * @returns {Array<FilterItem>}
     * @memberOf TgaService
     */
    private selectedFilterItems(items: Array<FilterItem>): Array<FilterItem> {
        return items ? items.filter(item => item.isSelected) : [];
    }

    /**
     * Returns map of filter items.
     *
     * @private
     * @param {Array<FilterItem>} items
     * @returns {{[key: number]: FilterItem}}
     * @memberOf TgaService
     */
    private getFilterItemMap(items: Array<FilterItem>): { [key: number]: FilterItem } {
        const itemsMap: { [key: number]: FilterItem } = {};
        items.forEach(item => {
            itemsMap[item.id] = item;
        });
        return itemsMap;
    }

    /**
     * filtered out data as per concept and subgroup
     *
     * @param {data} data
     * @param selectedConcept
     * @param selectedSubgroup
     * @memberOf TgaService
     */
    getFilteredData(data: any[], selectedConcept: Array<FilterItem>, selectedSubgroup: Array<FilterItem>): any[] {
        const filteredData = [];
        data.filter((conceptData) => {
            if (selectedConcept.find(concept => concept.id === conceptData.conceptId)) {
                const selectedConceptValue = selectedConcept.find(concept => concept.id === conceptData.conceptId);
                const filteredConceptData = Object.assign({}, conceptData, {position: selectedConceptValue.position});
                filteredData.push(filteredConceptData);
            }
        });
        return filteredData.sort(function (a, b) {
            return a.position - b.position;
        });
    }

    /**
     * Get Selected View
     *
     * @memberOf TgaService
     * @param report
     */
    public getSelectedView(report: Report): boolean {
        const deliverableType = DeliverableType.TARGET_GROUP_ANALYSIS.type;
        const deliverable = report.deliverables.find(d => d.type === deliverableType);
        return deliverable.views[0].metaInfo.previousAnalysis;
    }
}
