import {Injectable} from '@angular/core';
import {Observable, of, zip} from 'rxjs';
import {ConceptFilterItem, FilterItem, ReachAnalysisFilter} from '@app/deliverables/reach-analysis/models/filter.model';
import {FilterService} from '@platform/services/filter.service';
import {switchMap, take} from 'rxjs/operators';
import {defaultReachAnalysisFilter} from '@app/deliverables/reach-analysis/models/default-reach-analysis-filter';
import {StringHelper} from '@platform/services/string-helper.service';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {ReportService} from '@platform/services/report.service';
import {ConceptService} from '@platform/services/concept.service';
import {SubgroupService} from '@platform/services/subgroup.service';
import {Concept} from '@platform/models/concept.model';
import {Subgroup} from '@platform/models/subgroup.model';
import {
    CombinationDataItem,
    ReachAnalysisDeliverableView
} from '@app/deliverables/reach-analysis/models/reach-anaylsis.model';
import {Report} from '@platform/models/report.model';
import * as numeral from 'numeral';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {DeliverableType} from "@app/deliverables/deliverable-type.enum";
import {DeliverableView} from '@platform/models/deliverable-view.model';

@Injectable({
    providedIn: 'root'
})
export class ReachAnalysisService {

    constructor(
        private stringHelper: StringHelper,
        private deliverableViewService: DeliverableViewService,
        private reportService: ReportService,
        private conceptService: ConceptService,
        private subgroupService: SubgroupService,
        private viewMetaInfoService: ViewMetaInfoService,
        private filterService: FilterService) {
    }

    private getCombinationSetsFilters(defaultFilter: ReachAnalysisFilter): Array<FilterItem> {
        const sets: Array<FilterItem> = [];
        const selectedConcept: ConceptFilterItem = defaultFilter.concepts.find(it => it.isSelected === true);
        const totalVarieties = selectedConcept?.varieties.length;
        if (totalVarieties) {
            for (let i = 1; i <= totalVarieties; i++) {
                sets.push({
                    name: (i).toString(),
                    id: i,
                    isSelected: false,
                    position: i
                });
            }
            sets[0].isSelected = true;
        }
        return sets;
    }

    /**
     * Returns numeraljs display pattern for the numeric value.
     *
     * @private
     * @param {AttributesFilter} filter
     * @returns {string}
     * @memberof ChartDataService
     */
    private getFormatPattern(filter: ReachAnalysisFilter): string {
        let pattern = '0';
        pattern = filter.show.oneDecimal ? '0.0' : pattern;
        pattern = filter.show.twoDecimal ? '0.00' : pattern;
        return pattern + '%';
    }


    /**
     * Returns an observable of `ActivationProfileDeliverableView` data.
     *
     * @returns {Observable<ActivationProfileDeliverableView>}
     * @memberof ActivationProfileService
     */
    public getDefaultReachAnalysis(deliverableViews: Array<DeliverableView>): Observable<ReachAnalysisDeliverableView> {
        const deliverableType = DeliverableType.REACH_ANALYSIS.type;
        const report$ = this.reportService.get();
        return report$.pipe(
            switchMap(report => {
                const deliverable = report.deliverables.find(d => d.type === deliverableType);
                const viewId = deliverableViews[0].id;
                return this.deliverableViewService.fetch<ReachAnalysisDeliverableView>(
                    viewId,
                    report.id
                );
            })
        );
    }

    /**
     * Returns an observable of `AttributesDeliverableView` data.
     *
     * @returns {Observable<AttributesDeliverableView>}
     * @memberof StrengthWatchoutsService
     */
    public getReachAnalysis(): Observable<ReachAnalysisDeliverableView> {
        const deliverableType = DeliverableType.REACH_ANALYSIS.type;
        const filter$ = this.getReachAnalysisFilter();
        return filter$.pipe(
            switchMap((filter) => {
                const reload = this.isFilterChanged(filter);
                const params = this.getParams(filter);
                return this.deliverableViewService.filter<ReachAnalysisDeliverableView, ReachAnalysisFilter>(
                    filter.questions.find(item => item.isSelected).id.toString(),
                    deliverableType,
                    this.filter.bind(this),
                    params,
                    reload
                );
            })
        );
    }

    public getMetaInfo(report: Report, filters, deliverableViews): any {
        const questionId = filters.questions.find(it => it.isSelected === true).id;
        return deliverableViews.find(it => it.viewName == questionId.toString()).metaInfo;
    }

    /**
     * Returns boolean flag.
     */
    isFilterChanged(filter: ReachAnalysisFilter): boolean {
        return (filter.userView == true) ? true : filter.show.reload;
    }

    /**
     * Returns params for query string.
     */
    getParams(filter) {
        const setNumber = filter.show.combinationSets.find(it => it.isSelected === true)?.id;
        const lockedVarieties = [];
        filter.concepts.find(it => it.isSelected === true).varieties.filter(it => it.isLocked === true).forEach(it => lockedVarieties.push(it.id));
        return {
            'subgroupId': filter.subgroups.find(it => it.isSelected === true).id,
            'questionId': filter.questions.find(it => it.isSelected === true).id,
            'conceptId': filter.concepts.find(it => it.isSelected === true).id,
            'viewType': filter.compare.find(it => it.isSelected === true).id !== 'alternative-combination' ? 'summary' : 'set',
            'setNumber': setNumber,
            'locked': lockedVarieties.join(','),
            'excluded': this.getExcludedVarieties(filter),
            'startPaging': filter.show.paging,
            'limit': 20
        };
    }

    /**
     * return set of combinations.
     */
    productRange(setNumber, selectedVarieties) {
        let range = setNumber, i = setNumber;
        while (i++ < selectedVarieties) {
            range *= i;
        }
        return range;
    }

    /**
     * Combinations Calculator (nCr).
     */
    calculateCombinations(filters, selectedVarieties, setNumber, combinations) {
        const lockedCount = filters.concepts.find(it => it.isSelected).varieties.filter(it => it.isLocked === true).length;
        if (lockedCount > setNumber || (lockedCount && combinations.length < 20)) {
            return combinations.length;
        }
        if (selectedVarieties === setNumber) {
            return 1;
        } else if (selectedVarieties < setNumber) {
            return 0;
        } else {
            setNumber = (setNumber < selectedVarieties - setNumber) ? selectedVarieties - setNumber : setNumber;
            return this.productRange(setNumber + 1, selectedVarieties) / this.productRange(1, selectedVarieties - setNumber);
        }
    }

    getExcludedVarieties(filter: ReachAnalysisFilter) {
        const excludedVarieties = [];
        filter.concepts.find(it => it.isSelected === true).varieties.filter(it => it.isSelected === false).forEach(it => excludedVarieties.push(it.id));
        return excludedVarieties.join(',');
    }

    /**
     * Filter projection function for filtering the reach data and returns
     * filtered `ReachAnalysisDeliverableView` data.
     *
     * @private
     * @param {ReachAnalysisFilter} filter
     * @param {ReachAnalysisDeliverableView} data
     * @return ReachAnalysisDeliverableView
     */
    private filter(filter: ReachAnalysisFilter, data: ReachAnalysisDeliverableView): ReachAnalysisDeliverableView {
        return Object.assign({}, data, {combinations: data.combinations, viewType: data.viewType});
    }

    /**
     * Returns observable of Reach Analysis Filter data.
     *
     * @returns {Observable<ReachAnalysisFilter>}
     * @memberOf ReachAnalysisService
     */
    public getReachAnalysisFilter(): Observable<ReachAnalysisFilter> {
        const deliverableType = DeliverableType.REACH_ANALYSIS.type;
        return this.filterService.get<ReachAnalysisFilter>(deliverableType);
    }

    /**
     * Loads default Survey question filter data.
     *
     * @memberOf SurveyQuestionService
     */
    public loadDefaultFilter(deliverableViews: Array<DeliverableView> , concept?: Concept): Observable<ReachAnalysisFilter> {
        const defaultFilter: ReachAnalysisFilter = Object.assign({}, defaultReachAnalysisFilter);
        const report$ = this.reportService.get();
        const reachAnalysis$ = this.getDefaultReachAnalysis(deliverableViews);
        const concepts$ = this.conceptService.getReportDeliverableConcepts(DeliverableType.REACH_ANALYSIS.type);
        const subgroups$ = this.subgroupService.getReportSubgroups(DeliverableType.REACH_ANALYSIS.type);
        return zip(report$, concepts$, subgroups$, reachAnalysis$).pipe(switchMap(result => {
                defaultFilter.countries = [result[0].country];
                const filteredConcepts = concept ? [concept] : result[1];
                defaultFilter.concepts = this.getConceptFilters(filteredConcepts, result[3]);
                defaultFilter.subgroups = this.getSubgroupFilters(result[2], result[3]);
                defaultFilter.questions = this.getQuestionFilters(deliverableViews, result[3]);
                defaultFilter.basesLabel = result[3].basesLabel;
                defaultFilter.show = this.getShowFilter(defaultFilter);
                this.filterService.update(defaultFilter);
                return of(defaultFilter);
            }));
    }

    getShowFilter(filter) {
        const newShowFilter = {...filter.show};
        newShowFilter.combinationSets = this.getCombinationSetsFilters(filter);
        newShowFilter.pageNumber = 1;
        newShowFilter.setNumber = filter.concepts.find(it => it.isSelected)?.varieties.length;
        return newShowFilter;
    }

    private getQuestionFilters(deliverableViews: Array<DeliverableView>, deliverableView: ReachAnalysisDeliverableView): Array<FilterItem> {
        const deliverableType = DeliverableType.REACH_ANALYSIS.type;
        //const deliverable = report.deliverables.find(d => d.type === deliverableType);
        //const questions = deliverableViews[0].metaInfo.questions;
        const questionFilterItems: Array<FilterItem> = [];
        const self = this;
        deliverableViews.forEach(function (item, i) {
            if (item.metaInfo.isViewEnabled) {
                const questionName = self.getQuestionName(item.metaInfo.basesLabel);
                questionFilterItems.push({
                    name: questionName,
                    id: item.viewName,
                    isSelected: false,
                    position: i
                });
            }
        });
        questionFilterItems[0].isSelected = true;
        return questionFilterItems;
    }

    public getQuestionName(questionBasesLabel: string): string {
        let questionName = '';
        switch (questionBasesLabel) {
            case 'OVC': {
                questionName = 'Variety Reach Analysis';
                break;
            }
            case 'OFVC' : {
                questionName = 'Future Variety Reach Analysis';
                break;
            }
            case 'OBC': {
                questionName = 'Benefit Reach Analysis';
                break;
            }
            case 'CLK': {
                questionName = 'Variety Reach Analysis';
                break;
            }
            default: {
                break;
            }
        }
        return questionName;
    }

    public getVarietiesFilters(varieties: Array<any>): Array<FilterItem> {
        const varietiesFilterItems: Array<FilterItem> = [];
        varieties.sort(function (a, b) {
            return a.position - b.position;
        }).forEach(variety => {
            varietiesFilterItems.push({
                name: variety.label,
                id: variety.position,
                isSelected: true,
                position: variety.position,
                isLocked: false
            });
        });
        return varietiesFilterItems;
    }

    /**
     * Returns concept filter items.
     *
     * @private
     * @param {Array<Concept>} concepts
     * @param reachView
     * @returns {Array<FilterItem>}
     * @memberOf SurveyQuestionService
     */
    private getConceptFilters(concepts: Array<Concept>, reachView: ReachAnalysisDeliverableView): Array<FilterItem> {
        const conceptFilterItems: Array<ConceptFilterItem> = [];
        for (const item of concepts) {
            const concept = reachView.concepts.find(it => it.conceptId === item.exerciseConceptId);
            if (concept) {
                const varieties = item.exerciseConceptId === concept.conceptId ? concept.varieties : [];
                conceptFilterItems.push({
                    name: item.name,
                    id: item.exerciseConceptId,
                    isSelected: false,
                    position: item.position,
                    varieties: this.getVarietiesFilters(varieties)
                });
            } else {
                conceptFilterItems.push({
                    name: item.name,
                    id: item.exerciseConceptId,
                    isSelected: false,
                    position: item.position,
                    varieties: []
                });
            }
        }
        if (conceptFilterItems.length) {
            conceptFilterItems.sort(function (a, b) {
                return a.position - b.position;
            });
            const conceptSelected = conceptFilterItems.find(it => it.varieties.length > 0);
            const selectedConceptIndex = conceptFilterItems.findIndex(it => it.id == conceptSelected.id);
            conceptFilterItems[selectedConceptIndex].isSelected = true;
        }
        return conceptFilterItems;
    }

    /**
     * Returns subgroup filter items.
     *
     * @private
     * @param {Array<Subgroup>} subgroups
     * @param reachAnalysisView
     * @returns {Array<FilterItem>}
     * @memberOf SurveyQuestionService
     */
    private getSubgroupFilters(subgroups: Array<Subgroup>, reachAnalysisView: ReachAnalysisDeliverableView): Array<FilterItem> {
        const subgroupFilterItems: Array<FilterItem> = [];
        const selectedSubgroupId = reachAnalysisView.subgroups.find(it => it.included).subgroupId;
        for (const item of reachAnalysisView.subgroups.filter(it => it.included)) {
            const subgroup = subgroups.find(it => it.segmentId === item['subgroupId']);
            if (subgroup) {
                subgroupFilterItems.push({
                    name: subgroup.name,
                    id: subgroup.segmentId,
                    isSelected: subgroup.segmentId === selectedSubgroupId,
                    position: subgroup.position
                });
            }
        }
        subgroupFilterItems.sort(function (a, b) {
            return a.position - b.position;
        });
        return subgroupFilterItems;
    }

    /**
     * Returns varieties filter items.
     *
     * @private
     * @returns {Array<any>}
     * @param setNumber
     * @param combinations
     * @param filter
     * @param compareView
     */
    public getFilteredVarieties(setNumber: number, combinations: Array<CombinationDataItem>, filter: ReachAnalysisFilter, compareView: string): Array<any> {
        const filteredVarieties = filter.concepts.find(it => it.isSelected).varieties;
        if (compareView === 'alternativeView') {
            return filteredVarieties.filter(it => it.isSelected === true).sort(function (a, b) {
                return a.position - b.position;
            });
        } else {
            return this.getSortedVarietiesWithCombinations(filteredVarieties, combinations, 'desc');
        }
    }

    /**
     * Returns sorted varieties filter items.
     *
     * @private
     * @returns {Array<any>}
     * @param filteredVarieties The varieties to filter.
     * @param combinations The combinations for this view.
     * @param sortOrder The sort order of the varieties.
     */
    getSortedVarietiesWithCombinations(filteredVarieties, combinations: Array<CombinationDataItem>, sortOrder: string = 'desc') {
        const selectedVarieties = filteredVarieties.filter(it => it.isSelected === true);
        const varietiesFound = new Set<number>();
        let varieties: Array<any> = [];
        combinations.forEach((combination) => {
            let varietyCombinations = [...combination.varieties];
            if (varietyCombinations.length > 1) {
                varietyCombinations = varietyCombinations.sort((varietyOne, varietyTwo) => {
                    if (varietyOne.position > varietyTwo.position) {
                        return 1;
                    }
                    if (varietyOne.position < varietyTwo.position) {
                        return -1;
                    }
                    return 0;
                });
            }
            varietyCombinations.forEach((variety) => {
                const position = variety.position;
                if (!varietiesFound.has(position)) {
                    const selected = selectedVarieties.find((selectedVariety) => {
                        return position === selectedVariety.position;
                    });
                    if (selected) {
                        varieties.push(selected);
                        varietiesFound.add(position);
                    }
                }
            });
        });
        selectedVarieties.forEach((variety) => {
            const position = variety.position;
            if (!varietiesFound.has(position)) {
                varieties.push(variety);
            }
        });
        if (sortOrder === 'asc') {
            varieties = varieties.reverse();
        }
        return varieties;
    }

    /**
     * Returns Combination Data Item required for Detailed view and Alternate View
     *
     * @private
     * @returns {Array<CombinationDataItem>}
     * @param combinations
     * @param filters
     */
    public getFilteredCombinations(combinations: Array<CombinationDataItem>, filters: ReachAnalysisFilter): Array<CombinationDataItem> {
        const filteredCombinations: Array<CombinationDataItem> = [];
        combinations.forEach((combination) => {
            const reach = this.getFormattedValue(combination.weight, filters);
            combination = Object.assign({}, combination, {weight: reach}); // weight is directly modified here
            filteredCombinations.push(combination);
        });
        return filteredCombinations;
    }

    /**
     * The only difference in SummeryView Combination layout is it has "weights" and also "weight"
     * @param combinations
     * @param filters
     */
    public getSummeryViewFilteredCombinations(combinations: Array<CombinationDataItem>, filters: ReachAnalysisFilter): Array<CombinationDataItem> {
        const filteredCombinations: Array<CombinationDataItem> = [];
        combinations.forEach((combination) => {
            const reach = this.getFormattedValue(combination.weight, filters);
            combination = Object.assign({}, combination, {weights: reach}); // weight is retained and weights is added here
            filteredCombinations.push(combination);
        });
        return filteredCombinations;
    }

    /**
     * Returns formatted value based on the filter.
     *
     * @private
     * @param sum
     * @param {AttributesFilter} filter
     * @param {boolean} [shouldFormat=true]
     * @returns {(number | string)}
     * @memberof ChartDataService
     */
    public getFormattedValue(sum: number | string, filter: ReachAnalysisFilter, shouldFormat: boolean = true): number | string {
        const pattern = this.getFormatPattern(filter);
        return shouldFormat ? numeral(sum).format(pattern) : sum;
    }

    /**
     * this method will clone filters
     * */
    private cloneFilter(filter): ReachAnalysisFilter {
        const newShowFilter = {...filter.show};
        return {...filter, show: newShowFilter};
    }

    /**
     * this method will set selected set number in show filter
     * */
    public selectSetNumber(filters, filterName: any) {
        const filter = this.cloneFilter(filters);
        const show = filter.show;
        show.combinationSets = JSON.parse(JSON.stringify(show.combinationSets));
        if (filterName) {
            show.combinationSets.forEach(set => {
                set.isSelected = set.id === filterName;
            });
            show.pageNumber = 0
            show.paging = 0
        }
        return filter;
    }

    public getViews(report: Report) {
        const deliverableType = DeliverableType.REACH_ANALYSIS.type;
        const deliverable = report.deliverables.find(d => d.type === deliverableType);
        return deliverable.views;
    }
}
