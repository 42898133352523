import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Concept} from '@platform/models/concept.model';
import {FilterService} from '@platform/services/filter.service';
import {FilterItem} from '@app/deliverables/factors/models/filter.model';
import {DataType, DropdownData, DropdownItem} from '@products/shared/dropdown/dropdown.data.model';
import {combineLatest, Subscription} from 'rxjs';
import {PriceMentionsFilter} from '../models/filter.model';
import {PriceMentionsDeliverableView} from '../models/price-mentions.model';
import {PriceMentionsService} from '../services/price-mentions.service';

@Component({
    selector: 'ns-price-mentions-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

    /**
     * Set the concept to set single concept filter.
     *
     * @memberof FilterComponent
     */
    @Input() concept: Concept;

    /**
     * The PriceMentionsDeliverableView.
     * @type {PriceMentionsDeliverableView} PriceMentionsDeliverableView
     * @member FilterComponent
     */
    public priceMentionsDeliverableView: PriceMentionsDeliverableView;

    /**
     * Price Mentions filter object.
     *
     * @property
     * @type {PriceMentionsFilter}
     * @memberof FilterComponent
     */
    public filter: PriceMentionsFilter;

    /**
     * concepts list dropdown data.
     *
     * @property
     * @type {DropdownData<number>}
     * @memberof FilterComponent
     */
    public conceptsListData: DropdownData<number>;

    /**
     * List of countries on the report.
     *
     * @property
     * @type {DropdownData}
     * @memberof FilterComponent
     */
    public countryListData: DropdownData<string>;

    /**
     * subgroups list dropdown data.
     *
     * @property
     * @type {DropdownData<number>}
     * @memberof FilterComponent
     */
    public subgroupsListData: DropdownData<number>;

    /**
     * List of deliverable view dropdown items.
     *
     * @property
     * @type {DropdownData<string>}
     * @memberof FilterComponent
     */
    public compareListData: DropdownData<string>;

    /**
     * Array of subscriptions for cleanup.
     *
     * @property
     * @private
     * @type {Array<Subscription>}
     * @memberof FilterComponent
     */
    private subscriptions: Array<Subscription>;


    /**
     * Creates an instance of FilterComponent.
     *
     * @constructor
     * @param {FilterService} filterService
     * @param {TranslateService} translate
     * @memberof FilterComponent
     */
    constructor(
        private filterService: FilterService,
        private translate: TranslateService,
        private priceMentionsService: PriceMentionsService
    ) {
        this.subscriptions = [];
    }

    /**
     * Initialize the filter component. Sets filter for the template.
     *
     * @memberof FilterComponent
     */
    ngOnInit(): void {
        const filter$ = this.priceMentionsService.getPriceMentionsFilter();
        const priceMentions$ = this.priceMentionsService.getPriceMentions();
        const subscription = combineLatest([filter$, priceMentions$]).subscribe(([filters, priceMentions]) => {
            this.filter = filters;
            this.priceMentionsDeliverableView = priceMentions;
            this.setFilters(filters);
        });
        this.subscriptions.push(subscription);
    }

    /**
     * Event listener for compareFilter selection change event.
     *
     * @listens {event} selectionChange
     * @param {Array<DropdownData<string>>} compare:
     * @memberof FilterComponent
     */
    selectCompare(compare: any) {
        const selectedCompare: DropdownItem<string> = compare.find((option: DropdownItem<string>) => {
            return option.selected;
        });
        this.filterService.update({
            ...this.filter,
            deliverableViewType: selectedCompare.value.toString(),
            compare: this.filter.compare.map((item: FilterItem) => {
                return {
                    ...item,
                    isSelected: compare.find(selected => selected.value === item.id).selected
                };
            }),
            concepts: this.filter.concepts.map((concept, index) => {
                return {
                    ...concept,
                    isSelected: (selectedCompare.label === 'Concepts') ? true : (index === 0)
                };
            })
        });
    }

    /**
     * Event listener for conceptsFilter selection change event.
     *
     * @listens {event} selectionChange
     * @param {Array<DropdownData<number>>} concepts
     * @memberof FilterComponent
     */
    selectedConcept(concepts: Array<DropdownItem<number>>) {
        const updatedConcept: any = concepts.find(it => it.selected);
        const selectedConcepts: any = this.filter.concepts.find(concept => concept.id === updatedConcept.value);
        const selectedSubgroup = selectedConcepts.subgroups.find(subgroup => subgroup.isSelected);
            this.filterService.update({
                ...this.filter,
                concepts: this.filter.concepts.map((concept) => {
                    return {
                        ...concept,
                        isSelected: concepts.find(it => it.value === concept.id).selected
                    };
                }),
                subgroups: this.filter.subgroups.map((subgroup) => {
                    return {
                        ...subgroup,
                        isSelected: selectedSubgroup?(subgroup.id === selectedSubgroup.id):false
                    };
                })
            });
    }

    /**
     * Event listener for subgroupsFilter selection change event.
     *
     * @listens {event} selectionChange
     * @param {Array<DropdownItem<number>>} subgroups
     * @memberof FilterComponent
     */
    selectedSubgroup(subgroups: Array<DropdownItem<number>>) {
        const selectedConcept: any = this.filter.concepts.find(concept => concept.isSelected);
        const updatedConcepts = this.filter.concepts.map((concept: any) => {
            if (concept.id === selectedConcept.id) {
                return {
                    ...concept,
                    subgroups: concept.subgroups.map((subgroup) => {
                        const matchedSubgroup = subgroups.find((sg) => sg.value === subgroup.id);
                        return {
                            ...subgroup,
                            isSelected: matchedSubgroup ? matchedSubgroup.selected : subgroup.isSelected,
                        };
                    }),
                };
            }
            return concept;
        });
        this.filterService.update({
            ...this.filter,
            concepts: updatedConcepts,
            subgroups: this.filter.subgroups.map((subgroup) => {
                return {
                    ...subgroup,
                    isSelected: subgroups.find(it => it.value === subgroup.id)?.selected
                };
            })
        });
    }

    /**
     * Emits event of the selected filter menu item.
     */
    selectedEvent(event): void {
        console.log(event);
    }


    /**
     * Sets the filter options for concepts, subgroups, compareList and questionsList.
     *
     * @private
     * @param {PriceMentionsFilter} filter
     * @memberof FilterComponent
     */
    private setFilters(filter: PriceMentionsFilter): void {
        this.conceptsListData = this.getConceptFilterOptions(filter);
        this.subgroupsListData = this.getSubGroupFilterOptions(filter);
        this.compareListData = this.getCompareFilterOptions(filter.compare);
        this.countryListData = this.getCountryOptions(filter.countries[0]);
    }

    /**
     * Returns the country filter options.
     *
     * @private
     * @param {string} country
     * @returns {DropdownData}
     * @memberof FilterComponent
     */
    private getCountryOptions(country: string): DropdownData<string> {
        return {
            dropdownLabel: this.translate.instant('price.advisor.deliverables.price.mentions.filter.country.label'),
            dataType: DataType.RADIO,
            contentTitle: this.translate.instant('price.advisor.deliverables.price.mentions.filter.country.itemsTitle'),
            data: [
                {
                    value: country,
                    label: country,
                    selected: true
                }
            ]
        };
    }

    /**
     * Returns Concepts filter options.
     *
     * @private
     * @param {PriceMentionsFilter} filter
     * @returns {DropdownData<number>}
     * @memberof FilterComponent
     */
    private getConceptFilterOptions(filter: PriceMentionsFilter): DropdownData<number> {
        const items = filter.concepts;
        const dropDownData: DropdownItem<number>[] = items.map(item => {
            return {
                value: item.id,
                label: item.name,
                selected: item.isSelected
            };
        });

        return {
            dropdownLabel: this.translate.instant('price.advisor.deliverables.price.mentions.filter.concepts.label'),
            dataType: DataType.RADIO,
            contentTitle: this.translate.instant('price.advisor.deliverables.price.mentions.filter.concepts.itemsTitle'),
            data: dropDownData
        };
    }

    /**
     * Returns subgroups filter options.
     *
     * @private
     * @param {PriceMentionsFilter} filter
     * @returns {DropdownData<number>}
     * @memberof FilterComponent
     */
    private getSubGroupFilterOptions(filter: PriceMentionsFilter): DropdownData<number> {
        const selectedConcept: any = filter.concepts.find(concept => concept.isSelected);
        const items =  selectedConcept?selectedConcept.subgroups:[];
        const dropdownData: DropdownItem<number>[] = items.map(item => {
            return {
                value: item.id,
                label: item.name,
                selected: item.isSelected
            };
        });

        if(dropdownData.length > 0 && dropdownData.every(dataItem => !dataItem.selected)) {
            dropdownData[0].selected = true;
        }
        return {
            dropdownLabel: this.translate.instant('price.advisor.deliverables.price.mentions.filter.subgroups.label'),
            dataType: DataType.RADIO,
            contentTitle: this.translate.instant('price.advisor.deliverables.price.mentions.filter.subgroups.itemsTitle'),
            data: dropdownData
        };
    }

    /**
     * Returns the compare filter options.
     *
     * @private
     * @param {Array<FilterItem>} items
     * @returns {DropdownData<string>}
     * @memberof FilterComponent
     */
    private getCompareFilterOptions(items: Array<FilterItem>): DropdownData<string> {
        const compareListItemData: Array<DropdownItem<string>> = [];

        for (const compareby of items) {
            compareListItemData.push({
                value: compareby.id,
                label: compareby.name,
                selected: compareby.isSelected
            });
        }

        return {
            dropdownLabel: this.translate.instant('price.advisor.deliverables.price.mentions.filter.compare.label'),
            dataType: DataType.RADIO,
            contentTitle: this.translate.instant('price.advisor.deliverables.price.mentions.filter.compare.itemsTitle'),
            data: compareListItemData
        };
    }

    /**
     * Cleanup the component on removing from the UI.
     *
     * @memberof FilterComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }


}
