import {DeliverableType} from '@products/quick-screen/deliverable-type.enum';
import {map, skipWhile, take, takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    AxisData,
    Concept,
    SortingMatrixDeliverable
} from '@app/deliverables/sorting-matrix/models/sorting-matrix.model';
import {SortingMatrixService} from '@app/deliverables/sorting-matrix/sorting-matrix.service';
import {combineLatest, forkJoin, Observable, of, Subject, Subscription} from 'rxjs';
import {ExportPngService} from '@platform/services/export-png.service';
import {ReportService} from '@platform/services/report.service';
import {UserService} from '@platform/services/user.service';
import {SortingMatrixFilter} from '@app/deliverables/sorting-matrix/models/filter.model';
import {DeliverableInsight} from '@platform/deliverable-insight/deliverable-insight.model';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import {UserView} from '@platform/models/user-view.model';
import {Report} from '@platform/models/report.model';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {SortingMatrixMetaInfo} from '@app/deliverables/sorting-matrix/models/view-meta-info.model';
import {DeliverableInsightService} from '@platform/services/deliverable-insight.service';
import {SpinnerService} from '@platform/services/spinner.service';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelLabel, MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {RouterService} from '@platform/services/router.service';
import {UserViewService} from '@platform/services/user-view.service';
import {FilterService} from '@platform/services/filter.service';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';
import {InsightService} from '@platform/insights/insights.service';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';

@Component({
    selector: 'qs-sorting-matrix',
    templateUrl: './sorting-matrix.component.html',
    styleUrls: ['./sorting-matrix.component.scss'],
    providers: [SortingMatrixService]
})
export class SortingMatrixComponent implements OnInit, OnDestroy {

    public concepts$: Observable<Concept[]>;
    public selectedConcept: Concept;
    public axisData: AxisData;

    /**
     * Sorting matrix deliverable data
     * @type {SortingMatrixDeliverable} data
     * @memberOf SortingMatrixComponent
     */
    public data: SortingMatrixDeliverable;

    /**
     * Sorting matrix filter data
     * @type {SortingMatrixFilter} sortingMatrixFilter
     * @memberOf SortingMatrixComponent
     */
    public filter: SortingMatrixFilter;

    public viewMetaInfo: SortingMatrixMetaInfo;

    public filterStatus: string;

    /**
     * The deliverable insight data when creating insight.
     * @type {DeliverableInsight} deliverableData
     * @memberOf SortingMatrixComponent
     */
    public deliverableInsight: DeliverableInsight;

    public isInsightEnable = false;
    public displayProgressSpinner = false;

    /**
     * If the current user is internal
     */
    public isInternalUser: Boolean;

    private subscriptions: Array<Subscription>;

    public disableBtn: boolean;

    /**
     * Report document
     */
    public report: Report;

    /**
     * The deliverable view object.
     */
    public deliverableViewType: string;

    public selectors: any;

    public deliverableInfos: Array<DeliverableInfo>;

    public deliverableViews: Array<DeliverableView>;

    public deliverableType = DeliverableType.SORTING_MATRIX.type;

    public userViews: Array<UserView>;

    private destroy$ = new Subject<void>();

    /**
     * Feature FLAG for Automatic Headlines.
     *
     * @type {Boolean}
     */
    public isAutomatedHeadlinesEnabled: boolean;

    /**
     * Take the insight in the HTML format for this Deliverable
     *
     * @type {Boolean}
     */
    public addHTMLToInsight = false;

    disabledConcepts: Array<number> = [];

    public defaultViewFilters: SortingMatrixFilter;

    /**
     * Sorting Matrix constructor
     * @param service
     * @param reportService
     * @param userService
     * @param exportPNGService
     * @param viewMetaInfoService
     * @param deliverableInsightService
     */
    constructor(public service: SortingMatrixService,
                public reportService: ReportService,
                private userService: UserService,
                private spinnerService: SpinnerService,
                private exportPNGService: ExportPngService,
                private viewMetaInfoService: ViewMetaInfoService,
                private deliverableInsightService: DeliverableInsightService,
                private mixpanelService: MixpanelService,
                private routeService: RouterService,
                private userViewService: UserViewService,
                private filterService: FilterService,
                private deliverableInfoService: DeliverableInfoService,
                private productDeliverableViewService: ProductDeliverableViewService,
                private deliverableViewService: DeliverableViewService,
                private insightService: InsightService) {
        this.subscriptions = [];
        this.userViews = [];
    }

    /**
     * Initialize component
     */
    ngOnInit(): void {
        this.init();
        this.reportService.reloadDeliverable.subscribe(() => {
            this.productDeliverableViewService.clearCache();
            this.deliverableViewService.clearCache();
            this.clearSubscriptions();
            this.init();
        });
    }

    init() {
        const insightId = this.routeService.getQueryParam('insightId');
        const sortingMatrix$ = this.service.getFilteredDataWithConceptImage();
        const filter$ = this.service.getSortingMatrixFilter();
        const deliverableType = DeliverableType.SORTING_MATRIX.type;
        const viewMetaInfo$ = this.viewMetaInfoService.get<SortingMatrixMetaInfo>(deliverableType);

        const subscription = combineLatest([
            this.reportService.get(),
            this.userService.getUser(),
            this.deliverableViewService.getDeliverableViews(DeliverableType.SORTING_MATRIX.type, true)
        ]).subscribe(([report, user, deliverableViews]) => {
            this.report = report;
            this.deliverableViews = deliverableViews;
            this.deliverableInfos = this.deliverableInfoService.getNonForecastDeliverables(report);
            this.isInternalUser = user.isInternalUser;
            this.isAutomatedHeadlinesEnabled = user.featureFlags.includes('REPORTING_AUTOMATED_HEADLINES');

            const userViews$ = this.userViewService.fetchReportUserViewsFromAPI(this.report.id);
            const insightFilter$ = this.insightService.getInsightFilterData<SortingMatrixFilter>(report.id, insightId);
            const defaultFilter$ = this.service.loadDefaultFilter().pipe(
                take(1), takeUntil(this.destroy$)
            );

            forkJoin([userViews$, insightFilter$, defaultFilter$])
                .subscribe(([userViews, insightFilter, defaultFilter]) => {
                    this.userViews = this.userViewService.setupUserViews(this.report.id, this.deliverableType, userViews, defaultFilter, insightFilter);
                    const insightView = this.userViews.find(it => it.id === this.userViewService.insightViewId);
                    this.defaultViewFilters = defaultFilter;
                    this.selectUserView(insightView ? insightView : this.userViews.find(it => it.isSelected));

                    /**
                     * Add subscription to watch filter changes here so that deliverable data can also be updated as per filter change.
                     * */
                    this.subscriptions.push(combineLatest([sortingMatrix$, filter$, viewMetaInfo$])
                        .pipe(skipWhile(([sortingMatrix, filter, viewMetaInfo]) => viewMetaInfo == null))
                        .subscribe(([sortingMatrix, filter, viewMetaInfo]) => {

                            this.data = sortingMatrix;
                            this.axisData = sortingMatrix.axisData;
                            this.filter = filter;
                            this.viewMetaInfo = viewMetaInfo;
                            if (this.isInsightEnable && this.isAutomatedHeadlinesEnabled) {
                                this.openInsightCreationForm();
                            }
                        }));
                });
        });
        this.subscriptions.push(subscription);
    }


    /**
     * event emitted by the child components to set highlighted concept.
     */
    highlightConcept(concept: Concept) {
        this.selectedConcept = concept;
    }

    /**
     * reset form
     */
    getFilterStatus(status: string) {
        this.filterStatus = status;
    }

    /**
     * toggle between headers and insight creation form.
     */
    openInsightCreationForm() {
        setTimeout(() => {
            this.isInsightEnable = true;
            this.addHTMLToInsight = true;
            this.deliverableInsight = {
                title: this.isAutomatedHeadlinesEnabled ? this.deliverableInsightService.generateInsightTitle('Sorting Matrix') : '',
                selectors: ['#sorting-matrix-chart-1'],
                deliverable: {
                    deliverableViewId: this.data.id,
                    filter: this.filter,
                    metaInfo: this.viewMetaInfo
                }
            };
        }, 500);
    }

    /**
     * Close insight form
     */
    closeInsight() {
        this.isInsightEnable = false;
    }

    /**
     * capture screen layout and export as png.
     */
    exportAsPNG() {
        this.displayProgressSpinner = true;
        this.exportPNGService.exportPNG();
        const subscription = this.spinnerService.getSpinnerObs().subscribe(loading => this.displayProgressSpinner = loading);
        this.subscriptions.push(subscription);
        this.mixpanelService.track(MixpanelLabel.sortingMatrix, MixpanelEvent.exportAsPNG);
    }

    ngOnDestroy(): void {
        this.clearSubscriptions();
        this.destroy$.next();
        this.destroy$.complete();
    }

    clearSubscriptions(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
            this.subscriptions = [];
        }
    }

    /**
     * check concept count
     */
    isConceptExists(filter: SortingMatrixFilter): boolean {
        const conceptCount = filter.concepts.filter(it => it.selected === true).length;
        return (conceptCount === 0);
    }

    /**
     * Method that is triggered when user view is changed. This will in turn update the filter model in the store.
     * */
    selectUserView(userView: UserView): void {
        this.filter = userView.filter as SortingMatrixFilter;
        if (userView.id !== 'Default View') {
            this.filterService.update({
                ...this.filter,
                concepts:  this.updateUserViewConceptsWithDefaultViewConcepts(this.filter , this.defaultViewFilters)
            });
        } else {
            this.filterService.update(userView.filter);
        }
        this.disableBtn = this.isConceptExists(this.filter);
    }


    /**
     * Action that is triggered when the deliverable info is changed.
     *
     * @param deliverableInfo
     */
    onDeliverableChange(deliverableInfo: DeliverableInfo): void {
        this.deliverableInfoService.routeToDeliverable(deliverableInfo);
    }

    setDisabledConcepts(disabledConcepts: Array<number>) {
        this.disabledConcepts = disabledConcepts;
    }

    public updateUserViewConceptsWithDefaultViewConcepts(filter, defaultViewFilters) {
        if (defaultViewFilters && defaultViewFilters.concepts && defaultViewFilters.concepts.length) {
            let concepts = [];
            defaultViewFilters.concepts.forEach(concept => {
                const conceptObject = this.userViewService.deepClone(concept);
                const matchedConcept = filter?.concepts.find(it => it.id === concept.id);
                conceptObject.selected = matchedConcept ? matchedConcept.selected : false;
                concepts.push(conceptObject);
            });
            if (concepts.length && !concepts?.find(it => it.selected)) {
                if (defaultViewFilters.concepts.every( it => it.selected)) {
                    concepts = concepts.map(it => {
                        it.selected = true;
                        return it;
                    });
                } else {
                   concepts[0].selected = true;
                }
            }
          return concepts;
        }
    }
}
