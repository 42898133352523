import {State} from '@platform/store/state/app.state';
import {Filter} from '@platform/models/filter.model';
import {Observable} from 'rxjs';
import {Store, select} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {updateFilter} from '@platform/store/actions/filter.actions';
import {skipWhile} from 'rxjs/operators';
import {selectFilterByType} from '@platform/store/selectors/filter.selectors';
import {ViewMetaInfoService} from './view-meta-info.service';

/**
 * This service provides operations for retrieving, updating, and
 * adding a product filter. A product filter is a subclass of
 * {@link Filter}.
 *
 * @example
 * constructor(private filterService: FilterService) { }
 *
 * @export
 * @class FilterService
 */
@Injectable({
    providedIn: 'root'
})
export class FilterService {

    /**
     * Creates an instance of FilterService.
     *
     * @constructor
     * @param {Store<State>} store The application state.
     * @memberof FilterService
     */
    constructor(
        private store: Store<State>,
        private viewMetaInfoService: ViewMetaInfoService) {
    }

    /**
     * Adds a product filter object into the store. A product filter is a subclass
     * of {@link Filter}.
     *
     * @example
     * filterService.add<AttributesFilter>(attributesFilterObject);
     *
     * @template T extends {@link Filter}
     * @param {T} filter The product filter object.
     * @memberof FilterService
     */
    public add<T extends Filter>(filter: T): void {
        this.store.dispatch(updateFilter({filter}));
    }

    /**
     * Updates a product filter object in the store. A product filter is a subclass
     * of {@link Filter}.
     *
     * @example
     * filterService.update<AttributesFilter>(attributesFilterObject);
     *
     * @template T extends {@link Filter}
     * @param {T} filter The product filter object.
     * @memberof FilterService
     */
    public update<T extends Filter>(filter: T): void {
        this.viewMetaInfoService.update({deliverableType: filter.deliverableType}, filter.deliverableType);
        this.store.dispatch(updateFilter({filter}));
    }

    /**
     * Retrieves a product filter object from the store. A product filter is a
     * subclass of {@link Filter}. Each filter object is specific to a deliverable.
     *
     * @example
     * const attributesFilter: AttributesFilter = filterService.get<AttributesFilter>('Attributes');
     *
     * @template T extends {@link Filter}
     * @param {string} deliverableType The deliverable type.
     * @returns {Observable<T>} The observable of product filter.
     * @memberof FilterService
     */
    public get<T extends Filter>(deliverableType: string): Observable<T> {
        return this.store.pipe(
            select(selectFilterByType<T>(), {type: deliverableType}),
            skipWhile(f => !f)
        );
    }
}
