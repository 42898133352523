import {DeliverableType} from '@products/quick-screen/deliverable-type.enum';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import * as Highcharts from 'highcharts';
import {
    AxisData,
    Concept,
    SortingMatrixDeliverable
} from '@app/deliverables/sorting-matrix/models/sorting-matrix.model';
import {SortingMatrixService} from '@app/deliverables/sorting-matrix/sorting-matrix.service';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {createOptions} from './chart.options';
import {SortingMatrixFilter} from '../models/filter.model';
import {SortingMatrixMetaInfo} from '@app/deliverables/sorting-matrix/models/view-meta-info.model';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelLabel, MixpanelSortingMatrix} from '@src/assets/utils/mixpanel-enum';

@Component({
    selector: 'qs-sm-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnDestroy, OnChanges {

    @ViewChild('container', {static: true}) container;
    @Input() public data: SortingMatrixDeliverable;
    @Input() public filter: SortingMatrixFilter;
    @Input() public viewSortingMatrixMetaInfo: SortingMatrixMetaInfo;
    @Input() public axisData: AxisData;
    @Input() public filterUpdates: any;
    @Output() public conceptSelected: EventEmitter<Concept> = new EventEmitter();
    @Output() public disableConcepts: EventEmitter<Array<number>> = new EventEmitter();

    @Input() set selectedConcept(value: Concept) {
        // tooltip should be shown on the concept point when the corresponding concept
        // is hovered on from the table component.
        this.showToolTip(value);
    }

    public concepts: Concept[] = [];
    private maxXValue = 0;
    private maxYValue = 0;
    private chart: any;
    private timeoutId: any;
    private showDataLabels = false;

    public Highcharts = Highcharts;
    public chartOptions: any;
    public disableZoom = true;
    public showChart = false;

    constructor(private service: SortingMatrixService,
                private viewMetaInfoService: ViewMetaInfoService,
                private mixpanelService: MixpanelService) {
    }

    ngOnInit() {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.init();
        if (changes.filterUpdates) {
            this.resetZoom();
        }
    }

    init() {
        const viewMetaInfo = this.viewSortingMatrixMetaInfo;
        // all concepts should be set to enabled by default.

        this.concepts = this.filteredConcepts(this.data.concepts, this.filter);
        this.showChart = this.concepts.length > 0;
        const includeMetaInfo = Object.keys(viewMetaInfo).length > 1 && viewMetaInfo.chartInfo && Object.keys(viewMetaInfo.chartInfo).length !== 0;

        if (includeMetaInfo) {
            this.updateChartDataWithMetaInfo(this.concepts);
        } else {
            this.updateChartData(this.concepts);
        }
        // chart options are imported from another file `chart.options.ts`
        this.chartOptions = createOptions(this);
    }

    /**
     * Add the parameter `showId` extracted from the filter to each concept.
     */
    filteredConcepts(concepts: Concept[], filter: SortingMatrixFilter) {
        return concepts.map(concept => {
            return {
                ...concept,
                showId: filter.concepts.find(filterConcept => filterConcept.id === concept.exerciseConceptId)?.showId
            };
        });
    }

    /**
     * IMPORTANT: This is the Highchart's callback function.
     * This function is called when the chart is created.
     * This is the function that set's the this.chart which is used by multiple functions within this component.
     * Saving the chart context locally in the component
     */
    chartCallback = chart => this.chart = chart;

    /** >>>>>> Following Functions are executed based on expectation that Chart already exists or loaded <<<<< */

    /**
     * If MetaInfo exists then Zoom in wrt to viewMetaInfo
     * @param concepts
     */
    updateChartDataWithMetaInfo(concepts) {
        if (this.disableZoom && this.chart) {
            // zoom In
            const disabledConcepts = this.viewSortingMatrixMetaInfo.chartInfo.disabledConceptsInChart;
            const xAxisMin = this.viewSortingMatrixMetaInfo.chartInfo.minXAxis;
            const yAxisMin = this.viewSortingMatrixMetaInfo.chartInfo.minYAxis;
            const xAxisMax = this.viewSortingMatrixMetaInfo.chartInfo.maxXAxis;
            const yAxisMax = this.viewSortingMatrixMetaInfo.chartInfo.maxYAxis;
            this.zoomInToValues(xAxisMin, xAxisMax, yAxisMin, yAxisMax);
            // disable table rows
            this.disableConcepts.emit(disabledConcepts.map(it => parseInt(it)));
            // set data labels to true
            this.showDataLabels = true;
        }
        if (this.chart && this.chart.series) {
            this.chart.series[0].setData(this.service.getChartData(concepts));
        }
        this.disableZoom = false;
    }

    /**
     * Default Chart View If the MetaInfo does not exist
     * @param concepts
     */
    updateChartData(concepts) {
        this.setMaxAxisValues();
        if (this.chart && this.chart.series) {
            this.chart.series[0].setData(this.service.getChartData(concepts));
        }
        if (!this.disableZoom && this.chart) {
            // Zoom out + enable all rows + set Show Data Labels as false
            this.chart.zoomOut();
            this.disableConcepts.emit([]);
            this.showDataLabels = false;
        }
        this.disableZoom = true;
    }

    /**
     * Sets the maxXValue and maxYValue of the chart.
     */
    setMaxAxisValues() {
        this.maxXValue = -Infinity;
        this.maxYValue = -Infinity;
        this.concepts.forEach((concept: Concept) => {
            if (this.maxXValue < concept.needDesire) {
                this.maxXValue = concept.needDesire;
            }
            if (this.maxYValue < concept.uniqueness) {
                this.maxYValue = concept.uniqueness;
            }
        });
    }

    /**
     * Zoom into a specific x and y coordinates
     * @param minXAxis
     * @param maxXValue
     * @param minYAxis
     * @param maxYValue
     */
    zoomInToValues(minXAxis, maxXValue, minYAxis, maxYValue) {
        if (this.chart) {
            this.chart.xAxis[0].setExtremes(minXAxis, maxXValue, true);
            this.chart.yAxis[0].setExtremes(minYAxis, maxYValue, true);
        }
        this.mixpanelService.track(MixpanelLabel.sortingMatrix, MixpanelSortingMatrix.chartZoom);
    }

    /**
     * SHow toolTip trigger
     * @param concept
     */
    showToolTip(concept: Concept) {
        const tooltip = () => this.chart.tooltip.refresh(this.chart.series[0].data.find(x => x.exerciseConceptId === concept.exerciseConceptId));
        clearTimeout(this.timeoutId);
        if (this.chart) {
            this.timeoutId = concept ? setTimeout(tooltip, 150) : this.chart.tooltip.hide();
        }
    }

    /**
     * Emits `conceptSelected` with the clickedconcept or null when hovered out of concept.
     */
    highlightConcept(concept) {
        concept ? this.conceptSelected.emit(concept) : this.conceptSelected.emit(null);
    }

    /**
     * Triggers from a button click - from chart html
     */
    resetZoom() {
        if (this.chart) {
            this.chart.zoomOut();
            this.disableZoom = true;
            this.setAndZoomToAxisValues(this.chart);
        }
    }

    /**
     * Tracks Mixpanel event on Reset button click - from chart html
     */
    trackReset() {
        this.mixpanelService.track(MixpanelLabel.sortingMatrix, MixpanelSortingMatrix.chartReset);
    }

    /**
     * Sets the axis extremes everytime the chart is loaded/reloaded.
     * Called from the onChartLoad event.
     */
    setAndZoomToAxisValues(chart) {
        if (this.container) {
            const maxAxisValue = Math.round(Math.max(this.maxXValue, this.maxYValue));
            const containerWidth = (this.container.el.nativeElement as HTMLElement).offsetWidth;
            const maxXVal = maxAxisValue + ((this.maxXValue / containerWidth) * 30);
            const maxYVal = maxAxisValue + ((this.maxYValue / containerWidth) * 30);
            const maxValue = Math.max(maxXVal, maxYVal);

            chart.xAxis[0].setExtremes(0, maxValue, true);
            chart.yAxis[0].setExtremes(0, maxValue, true);
        }
    }

    /** >>>>>>> All Actions below are triggered with an event done on the chart <<<<<<< */

    /**
     * Draws Chart Line
     * @param chart
     * @param x1
     * @param y1
     * @param x2
     * @param y2
     * @param className
     * @param extraX
     * @param extraY
     */
    drawLine(chart, x1: number, y1: number, x2: number, y2: number, className = '', extraX = 0, extraY = 0) {
        const xAxis = chart.xAxis[0];
        x1 = Math.round(xAxis.toPixels(x1) - extraX);
        x2 = Math.round(xAxis.toPixels(x2) + extraX);
        const yAxis = chart.yAxis[0];
        y1 = Math.round(yAxis.toPixels(y1) + extraY);
        y2 = Math.round(yAxis.toPixels(y2) - extraY);
        const path = chart.renderer.path(['M', x1, y1, 'L', x2, y2]).attr({
            class: 'nd-axis-line ' + className,
            zIndex: 2
        }).add();
        return path;
    }

    /**
     * Clears the existing bands and lines on the chart.
     */
    destroyChartBands(chart) {
        if (chart.xBand) {
            chart.xBand.destroy();
        }
        if (chart.yBand) {
            chart.yBand.destroy();
        }
        if (chart.xBandMinLine) {
            chart.xBandMinLine.destroy();
        }
        if (chart.xBandMaxLine) {
            chart.xBandMaxLine.destroy();
        }
        if (chart.yBandMinLine) {
            chart.yBandMinLine.destroy();
        }
        if (chart.yBandMaxLine) {
            chart.yBandMaxLine.destroy();
        }
        chart.xBand = null;
        chart.yBand = null;
        chart.xBandMinLine = null;
        chart.xBandMaxLine = null;
        chart.yBandMinLine = null;
        chart.yBandMaxLine = null;
    }

    /**
     * Draws the green axis bands on the chart
     */
    drawAxisBands(chart) {
        const xAxis = chart.xAxis[0],
            yAxis = chart.yAxis[0],
            minXAxis = xAxis.min,
            maxXAxis = xAxis.max,
            minYAxis = yAxis.min,
            maxYAxis = yAxis.max;
        const xAxisExtremes = xAxis.getExtremes(),
            yAxisExtremes = yAxis.getExtremes();
        const series = chart.series[0];
        let xMin = {x: Infinity, y: Infinity},
            xMax = {x: -Infinity, y: -Infinity},
            yMin = {x: Infinity, y: Infinity},
            yMax = {x: -Infinity, y: -Infinity};
        this.destroyChartBands(chart);
        series.points.forEach(point => {
            if (point.x >= minXAxis && point.x <= maxXAxis && (point.y >= minYAxis && point.y <= maxYAxis)) {
                if (point.x < xMin.x) {
                    xMin = point;
                }
                if (point.x > xMax.x) {
                    xMax = point;
                }
                if (point.y < yMin.y) {
                    yMin = point;
                }
                if (point.y > yMax.y) {
                    yMax = point;
                }
            }
        });
        if (xMin.x !== Infinity && xMax.x !== -Infinity && xMin !== xMax) {
            chart.xBand = this.drawLine(chart, xMin.x, yAxisExtremes.min, xMax.x, yAxisExtremes.min, 'nd-xaxis-band', 2 / 2, 0);
            chart.xBandMinLine = this.drawLine(chart, xMin.x, xMin.y, xMin.x, yAxisExtremes.min, 'nd-xaxis-band-line');
            chart.xBandMaxLine = this.drawLine(chart, xMax.x, xMax.y, xMax.x, yAxisExtremes.min, 'nd-xaxis-band-line');
        }
        if (yMin.y !== Infinity && yMax.y !== -Infinity && yMin !== yMax) {
            chart.yBand = this.drawLine(chart, xAxisExtremes.min, yMin.y, xAxisExtremes.min, yMax.y, 'nd-yaxis-band', 0, 2 / 2);
            chart.yBandMinLine = this.drawLine(chart, yMin.x, yMin.y, xAxisExtremes.min, yMin.y, 'nd-yaxis-band-line');
            chart.yBandMaxLine = this.drawLine(chart, yMax.x, yMax.y, xAxisExtremes.min, yMax.y, 'nd-yaxis-band-line');
        }
    }

    /**
     * Creates dataLabel for the concept point.
     * This function is called from the `formatter` callback of dataLabels declared in the chartOptions.
     */
    getDataLabelsFormatter(pointContext) {
        return `<div class="nlsn-data-labels"> ${this.getConceptLabel(pointContext.point)} </div>`;
    }

    /**
     * Returns the label on the concept.
     * Concept position for all those concepts whose showId is set from the filter,
     * concept names for the others if the chart is zoomed in
     */
    getConceptLabel(concept) {
        if (concept.showId) {
            return this.showDataLabels ? concept.conceptName : concept.conceptNumber;
        } else {
            return this.showDataLabels ? concept.conceptName : '';
        }
    }

    /**
     * Returns chart's series data.
     * This function is called from the chartOptions.
     */
    getSeriesData() {
        return [{
            name: 'Sorting Matrix Data',
            color: '#009DD9',
            data: this.service.getChartData(this.concepts)
        }];
    }

    /**
     * Creates tooltip for the concept point
     * This function is called from the chartOptions.
     */
    getAxisLabel(axis) {
        return axis === 'x' ? this.axisData.governingQuestionLabel1 : this.axisData.governingQuestionLabel2;
    }

    /**
     * Creates tooltip for the concept point.
     * This function is called from the `pointFormatter` callback declared in the chartOptions.
     */
    getTooltipFormatter(point) {
        if (this.container) {
            const xVal = Math.round(point.x),
                yVal = Math.round(point.y);
            // different style has to be applied to the tooltip for a concept with a long name.
            // this is a domain specific hack to fix the dynamic width problem with the tooltip.
            const style = {width: '', display: '', title: {}};
            const containerWidth: number = (this.container.el.nativeElement as HTMLElement).offsetWidth;
            if (point.conceptName.length > 60) {
                style.width = `${containerWidth - 60}px`;
                style.display = 'block';
                style.title = 'white-space: normal';
            }
            return `<div id="tooltip" class="popover-content remove-from-insights" style="width:${style.width}">
              <img class="concept-thumb" src="${point.imagePath}">
              <div class="data-content" style="display: ${style.display}">
              <h6 class="title" style="${style.title}">${point.conceptName}</h6>
              <h6 class="tooltip-label">${this.axisData.governingQuestionLabel1}:${xVal}</h6>
              <h6 class="tooltip-label"> ${this.axisData.governingQuestionLabel2}:${yVal}</h6>
            </div>`;
        }
    }

    /**
     * Trigger from Chart: Chart's callback event for chart load.
     * This function is run in the chart's context.
     */
    onChartLoad = event => {
        const chart = event.target;
        this.drawAxisBands(chart);
        this.setAndZoomToAxisValues(chart);
        this.disableZoom = true;
        this.chart = chart;
    }

    /**
     * Trigger from Chart: Chart's callback event for when chart is redrawn.
     * This function is run in chart's context.
     */
    onChartRedraw = event => this.drawAxisBands(event.target);

    /**
     * Trigger from Chart: Chart's callback event for when the chart is zoomed in/zoomed out.
     * This function is run in chart's context.
     */
    onChartSelection = (event) => {
        const chart = event.target;
        const xAxisMax = Math.round(chart.xAxis[0].dataMax),
            yAxisMax = Math.round(chart.yAxis[0].dataMax),
            maxAxis = Math.max(xAxisMax, yAxisMax);
        if (!event.resetSelection) {
            const excludedExerciseConceptIds = this.chartSelectionOnResetFalse(chart, event.xAxis[0].min, event.yAxis[0].min, event.xAxis[0].max, event.yAxis[0].max, event.xAxis[0].axis.dataMax, event.yAxis[0].axis.dataMax);
            this.setUserViewOnCharInfoUponZoom(excludedExerciseConceptIds, event.xAxis[0].min, event.yAxis[0].min, event.xAxis[0].max, event.yAxis[0].max);
            this.disableZoom = false;
        } else {
            this.chartSelectionOnResetTrue(chart, maxAxis);
            this.setUserViewOnCharInfoUponReset();
        }
    }

    /**
     * Triggered as part of onChartSelection, only if resetSelection is true
     * @param chart
     * @param maxAxis
     * @private
     */
    private chartSelectionOnResetTrue(chart, maxAxis) {
        this.showDataLabels = false;
        this.disableConcepts.emit([]);
        chart.xAxis[0].update({
            max: maxAxis
        });
        chart.yAxis[0].update({
            max: maxAxis
        });
    }

    /**
     * Triggered as part of onChartSelection, only if resetSelection is false
     * @param chart
     * @param xAxisMin
     * @param yAxisMin
     * @param xAxisMax
     * @param yAxisMax
     * @param xAxisDataMax
     * @param yAxisDataMax
     * @private
     */
    private chartSelectionOnResetFalse(chart, xAxisMin, yAxisMin, xAxisMax, yAxisMax, xAxisDataMax, yAxisDataMax) {
        const xVals = [],
            yVals = [],
            excludedPoints = {};
        chart.series[0].points.forEach((point) => {
            if (point.x < xAxisMin || point.x > xAxisMax) {
                xVals.push(point);
            }
            if (point.y < yAxisMin || point.y > yAxisMax) {
                yVals.push(point);
            }
        });
        Array.from(new Set([...xVals, ...yVals])).map(function (point) {
            excludedPoints[point.exerciseConceptId] = point;
        });
        const excludedExerciseConceptIds = Object.keys(excludedPoints);
        this.disableConcepts.emit(excludedExerciseConceptIds.map(it => parseInt(it)));
        if (((xAxisMax - xAxisMin) <= xAxisDataMax / 2) ||
            ((yAxisMax - yAxisMin) <= yAxisDataMax / 2)) {
            this.showDataLabels = true;
        }
        return excludedExerciseConceptIds;
    }

    /**
     * Chart's callback event for when point is clicked.
     * This function is run in chart's context.
     */
    onPointClick = event => this.highlightConcept(event.point.options);

    /**
     * Chart's callback event for when pointer is moved out of concept plot.
     * This function is run in chart's context.
     */
    onMouseOutPoint = () => this.highlightConcept(null);

    /**
     * Set the ViewMeta Info of Chart
     * */
    private setUserViewOnCharInfoUponZoom(disabledConceptIds: string[], xMin, yMin, xMax, yMax) {
        const deliverableType = DeliverableType.SORTING_MATRIX.type;
        const viewInfo = {deliverableType, sortInfo: {}, chartInfo: {}};
        viewInfo.chartInfo['disabledConceptsInChart'] = disabledConceptIds;
        viewInfo.chartInfo['minXAxis'] = xMin;
        viewInfo.chartInfo['minYAxis'] = yMin;
        viewInfo.chartInfo['maxXAxis'] = xMax;
        viewInfo.chartInfo['maxYAxis'] = yMax;
        viewInfo.sortInfo = this.viewSortingMatrixMetaInfo !== null ? this.viewSortingMatrixMetaInfo.sortInfo : {};
        this.viewMetaInfoService.update(viewInfo, deliverableType);
    }

    /**
     * Reset the MetaInfo of Chart
     * @private
     */
    private setUserViewOnCharInfoUponReset() {
        const deliverableType = DeliverableType.SORTING_MATRIX.type;
        const viewInfo = {deliverableType, sortInfo: {}, chartInfo: {}};
        viewInfo.sortInfo = this.viewSortingMatrixMetaInfo !== null ? this.viewSortingMatrixMetaInfo.sortInfo : {};
        this.viewMetaInfoService.update(viewInfo, deliverableType);
    }

    /**
     * Destroy the subscription
     */
    ngOnDestroy() {

    }

}
