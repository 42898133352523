import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HeaderService} from '@platform/services/header.service';
import {ReportService} from '@platform/services/report.service';
import {UserService} from '@platform/services/user.service';
import {combineLatest, concatMap, Subscription} from 'rxjs';
import {Report} from '@platform/models/report.model';
import {UserInfo} from '@platform/models/user-info.model';
import {Location} from '@angular/common';
import {ConceptService} from '@platform/services/concept.service';
import {Concept} from '@platform/models/concept.model';
import {NavigationService} from '@platform/services/navigation.service';
import {Organization} from '@platform/models/organization.model';
import {OrganizationService} from '@platform/services/organization.service';
import {BenchmarkService} from '@platform/import-benchmark-results/services/benchmark.service';
import {Benchmark} from '@platform/models/benchmark.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ToasterService} from '@platform/services/toaster.service';
import { BenchmarkFiltersSectionComponent } from './benchmark-filters-section/benchmark-filters-section.component';
import { AppConfigService } from '@app/core/config/app-config.service';

@Component({
    selector: 'ns-import-benchmark-results',
    templateUrl: './import-benchmark-results.component.html',
    styleUrls: ['./import-benchmark-results.component.scss']
})
export class ImportBenchmarkResultsComponent implements OnInit, OnDestroy {

    /**
     * The report object for the component.
     *
     * @type {Report}
     * @memberof ReportComponent
     */
    public report: Report;

    /**
     * Currently logged in user.
     *
     * @type {UserInfo}
     * @memberof ReportComponent
     */
    public userInfo: UserInfo;

    public concepts: Concept[];

    public organizations: Organization[];

    /**
     * Maintains map of organizations with keys as org id for faster org lookup
     */
    organizationsMap: Record<string, Organization>;

    reportOrg: Organization;

    benchmarks: Benchmark[];

    defaultBackUrl: string;

    openFilter: boolean = false;

    isEmptyVisible: boolean = true;

    isResultsVisible: boolean = false;

    isImportedVisible: boolean = false;

    benchmarkList: Benchmark[];

    selectedConceptList = [];

    benchmarksListBybenchmarkReportId: any[];

    importedConceptsId: any[];

    /**
     * RxJS subscriptions created by the component instance.
     *
     * @private
     * @type {Array<Subscription>}
     * @memberof ReportComponent
     */
    private subscriptions: Subscription;

    emptyResultMessages = {
        leadText: 'Broaden your search criteria',
        supportText : "No projects matching the criteria you have requested can be found. Please refine your search and filter criteria."
    };

    isImportClicked = false;

     /**
     * Maintains map of Non_visibleReports to user with keys as BenchmarkEReport id
     */
     nonVisibleBenchmarks: Map<string, string> = new Map();
     isNonAccessibleReportWarning: boolean = false;
     nonAccessibleReportWarningList: any;
     nonAccessibleReportWarningHeader: string = `You must be a collaborator on the following projects to manage them:`;

    @ViewChild(BenchmarkFiltersSectionComponent) benchmarkFiltersSectionComponent:BenchmarkFiltersSectionComponent;

    constructor(private headerService: HeaderService,
                private reportService: ReportService,
                private userService: UserService,
                private conceptService: ConceptService,
                private location: Location,
                private router: Router,
                private route: ActivatedRoute,
                private navigationService: NavigationService,
                private organizationService: OrganizationService,
                private benchmarkService: BenchmarkService,
                private toasterService: ToasterService,
                private appConfigService: AppConfigService) {}

    ngOnInit(): void {
        this.subscriptions = new Subscription();
        this.headerService.showBrandBar = true;
        this.headerService.showApplicationBar = false;
        const subscription = combineLatest([
            this.reportService.get(),
            this.userService.getUser(),
            this.conceptService.getReportConcepts(),
            this.organizationService.fetchAll(),
            this.benchmarkService.get()]
        ).pipe(concatMap(result => {
            this.report = result[0];
            this.userInfo = result[1];
            this.concepts = result[2].filter(it => it.show == null || it.show);
            this.organizations = result[3];
            this.benchmarks = result[4];
            const allBenchmarkReportId = result[4].map((it) => it.benchmarkReportId);
            const allBenchmarkExerciseConceptId = result[4].map((it) => it.benchmarkExerciseConceptId);
            this.benchmarksListBybenchmarkReportId = [...new Set(allBenchmarkReportId)];
            this.importedConceptsId = [...new Set(allBenchmarkExerciseConceptId)];
            this.checkActiveContainer();
            this.organizationsMap = this.organizations.reduce((acc: Record<string, Organization>, org: Organization) => {
                acc[org.id] = org;
                return acc;
            }, {});
            return this.organizationService.getById(this.report.orgId);
        })).subscribe(org => {
            this.reportOrg = org;
            this.defaultBackUrl = this.navigationService.currentUrl.substr(0, this.navigationService.currentUrl.lastIndexOf('/')) + '/deliverables';
            this.createNonVisibleBenchmarkMap();
        });
        this.subscriptions.add(subscription);

        const subEvents = this.benchmarkService.benchmarkEvents.subscribe((eve)=>{
            switch (eve) {
                case 'SAVED':
                    if(this.isImportClicked == true){
                        this.toasterService.openSnackBar('Benchmark(s) imported successfully', 'success');
                        this.isImportClicked = false;
                    }
                    this.reloadCurrentRoute();
                    break;
                case 'DELETED':
                    this.subscriptions.unsubscribe();
                    this.ngOnInit();
                    this.checkActiveContainer();
                    break;
            }
        });
        this.subscriptions.add(subEvents);
    }

    openFilters(): boolean {
        return this.openFilter = true;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    returnBack(): void {
        this.navigationService.back(this.defaultBackUrl);
        this.backNavigation();
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event: any) {
        this.backNavigation();
    }
    /**
     * navigate to back url
     */
    backNavigation() {
        this.router.navigateByUrl('/').then(() => {
            this.router.navigate([this.router.url]);
        });
    }

    /**
     * Import the Benchmarks
     */
    importBenchMark() {
        this.isImportClicked = true;
        const benchmarkList: Benchmark[] = [];
        this.selectedConceptList.forEach(selectedConcept => {
            const conceptAddedToBenchmarks = benchmarkList.find(it => it.benchmarkExerciseConceptId === selectedConcept.exerciseConceptId);
            if (!conceptAddedToBenchmarks || benchmarkList.length === 0) {
                benchmarkList.push({
                    id: null,
                    reportId: this.report.id,
                    benchmarkReportId: selectedConcept.report.id,
                    benchmarkReportName: selectedConcept.report.name,
                    benchmarkConceptId: selectedConcept.concept.id,
                    displayName: selectedConcept.concept.name,
                    benchmarkDbSubgroupId: selectedConcept.subgroup.segmentId,
                    benchmarkExerciseConceptId: selectedConcept.concept.exerciseConceptId,
                    });
            }
        });
        if (benchmarkList.length) {
            this.benchmarkService.save(benchmarkList, this.report.id).subscribe((data: Benchmark[]) => {
                this.benchmarkService.benchmarkEvents.emit('SAVED');
            });
        }
    }

    reloadCurrentRoute() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.route });
    }

    /**
     * toggles between concepts
     * @param concepts
     */
    conceptsToggled(concepts): void {
        concepts.selected.forEach(it => {
            if (!this.selectedConceptList.find(element => it.concept.id === element.concept.id)) {
                this.selectedConceptList.push(it);
            }
        });
        this.selectedConceptList = this.selectedConceptList.filter(function (array_el) {
            return concepts.unselected.filter(function (anotherOne_el) {
                return anotherOne_el.concept.id === array_el.concept.id;
            }).length === 0;
        });

    }

    checkActiveContainer(){
        this.setVisibleContainer('emptyPageContainer');
        if( this.benchmarksListBybenchmarkReportId !== undefined && this.benchmarksListBybenchmarkReportId?.length > 0){
            this.setVisibleContainer('importedBenchmarksContainer');
        }
    }

    setVisibleContainer(containerName):void{
        switch (containerName) {
            case 'emptyPageContainer':
                this.isEmptyVisible = true;
                this.isResultsVisible = false;
                this.isImportedVisible = false;
                this.benchmarkService.importBenchmarkActiveView = 'emptyPageContainer';
                break;
            case 'importedBenchmarksContainer':
                this.isEmptyVisible = false;
                this.isResultsVisible = false;
                this.isImportedVisible = true;
                this.benchmarkService.importBenchmarkActiveView = 'importedBenchmarksContainer';
                break;
            case 'searchResultContainer':
                this.isEmptyVisible = false;
                this.isResultsVisible = true;
                this.isImportedVisible = false;
                this.benchmarkService.importBenchmarkActiveView = 'searchResultContainer';
                break;
        }
    }

    searchTriggerd():void{
        if (this.benchmarkList && this.benchmarkList.length == 0){
            this.setVisibleContainer('emptyPageContainer');
        } else {
            this.setVisibleContainer('searchResultContainer');
            this.selectedConceptList = [];
        }
    }

    reportToggled(report): void {
        this.benchmarkList.forEach((bmk) => {
            bmk.isExpanded = report.dataNode.report.id === bmk.id;
        });
    }

    /**
     * @method  onScroll()
     *
     * Method gets invoked when user reaches towards the end of the page to pull more data.
     */
    public onScroll(): void {
        this.benchmarkFiltersSectionComponent.applyFilters(true);
    }

    appendResultAfterPagination(eventData){
        if(eventData.length > 0 ){
            this.benchmarkList = [...this.benchmarkList, ...eventData];
        }
    }

    onReportIdEmitted(reportId: string) {
        this.nonVisibleBenchmarks.delete(reportId);
        let listNonVisibleBenchmarks = [];
        this.nonVisibleBenchmarks.forEach((it)=>{
            listNonVisibleBenchmarks.push(it)
        })
        this.nonAccessibleReportWarningList = [...listNonVisibleBenchmarks ];
        this.isNonAccessibleReportWarning = (listNonVisibleBenchmarks && listNonVisibleBenchmarks.length > 0) ? true : false;
    }

    createNonVisibleBenchmarkMap(){
        this.benchmarks.forEach(benchmark=>{
            this.nonVisibleBenchmarks.set(benchmark.benchmarkReportId,benchmark.benchmarkReportName);
        });
        let listNonVisibleBenchmarks = [];
        this.nonVisibleBenchmarks.forEach((it)=>{
            listNonVisibleBenchmarks.push(it)
        })
        this.nonAccessibleReportWarningList = [...listNonVisibleBenchmarks ];
        this.isNonAccessibleReportWarning = (listNonVisibleBenchmarks && listNonVisibleBenchmarks.length > 0) ? true : false;
    }

    redirectToRequestAccess(){
        window.open(`${this.appConfigService.config.projectui.url}/#/request`, '_blank');
    }
}
