import {Subgroup} from '@platform/models/subgroup.model';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {AttributesFilter, FilterItem} from '../models/filter.model';
import {FilterService} from '@platform/services/filter.service';
import {AttributesDeliverableView} from '../models/attributes.model';
import {Injectable} from '@angular/core';
import {Observable, of, zip} from 'rxjs';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {take, switchMap} from 'rxjs/operators';
import {defaultAttributesFilter} from '../models/default-attributes-filter';
import {ReportService} from '@platform/services/report.service';
import {SubgroupService} from '@platform/services/subgroup.service';
import {ConceptService} from '@platform/services/concept.service';
import {Concept} from '@platform/models/concept.model';
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';
import {DeliverableViewType} from '@app/deliverables/attributes/models/deliverable-view-type.enum';

/**
 * `AttributesService` has all data operations for fetching attributes
 * and attributes filter data.
 *
 * @export
 * @class AttributesService
 */
@Injectable({
    providedIn: 'root'
})
export class AttributesService {

    /**
     * Creates an instance of AttributesService.
     *
     * @constructor
     * @param {DeliverableViewService} deliverableViewService
     * @param productDeliverableViewService
     * @param {ReportService} reportService
     * @param {ConceptService} conceptService
     * @param {SubgroupService} subgroupService
     * @param {FilterService} filterService
     * @memberof AttributesService
     */
    constructor(
        private deliverableViewService: DeliverableViewService,
        private productDeliverableViewService: ProductDeliverableViewService,
        private reportService: ReportService,
        private conceptService: ConceptService,
        private subgroupService: SubgroupService,
        private filterService: FilterService) {
    }

    /**
     * Returns an observable of `AttributesDeliverableView` data.
     *
     * @returns {Observable<AttributesDeliverableView>}
     * @memberof AttributesService
     */
    public getAttributes(): Observable<AttributesDeliverableView> {
        const report$ = this.reportService.get();
        const filter$ = this.getAttributesFilter();
        const deliverableViews$ = this.deliverableViewService.getDeliverableViews(DeliverableType.ATTRIBUTES.type);
        return report$.pipe(switchMap(report => {
            return filter$.pipe(
                switchMap(filter => {
                    return deliverableViews$.pipe(switchMap((deliverableViews) => {
                        const deliverableView = deliverableViews.find(it => it.viewName === DeliverableViewType.CONCEPT);
                        const views$: Observable<AttributesDeliverableView> = this.productDeliverableViewService.get<AttributesDeliverableView>(report.id, deliverableView.productViewId);
                        return views$.pipe(switchMap(view => {
                            return of(this.filter(filter, view));
                        }))
                    }))
                })
            )
        }))
    }

    /**
     * Filter projection function for filtering the attributes data and returns
     * filtered `AttributesDeliverableView` data.
     *
     * @private
     * @param {AttributesFilter} filter
     * @param {AttributesDeliverableView} data
     * @returns {AttributesDeliverableView}
     * @memberof AttributesService
     */
    private filter(filter: AttributesFilter, data: AttributesDeliverableView): AttributesDeliverableView {
        const selectedConcepts = filter.concepts.filter(c => c.isSelected).map(c => c.id);
        const selectedSubgroups = filter.subgroups.filter(s => s.isSelected).map(s => s.id);
        const filteredStatements = [];
        let filteredStatement;
        let filteredDataItems;
        let filteredView;
        for (const statement of data.statements) {
            filteredDataItems = statement.statementData.filter(
                d => selectedConcepts.includes(d.conceptId) && selectedSubgroups.includes(d.segmentId)
            );
            filteredStatement = {
                label: statement.label,
                statementData: filteredDataItems
            };
            filteredStatements.push(filteredStatement);
        }
        filteredView = Object.assign({}, data, {statements: filteredStatements});
        return filteredView;
    }

    /**
     * Returns observable of attributes filter data.
     *
     * @returns {Observable<AttributesFilter>}
     * @memberof AttributesService
     */
    public getAttributesFilter(): Observable<AttributesFilter> {
        const deliverableType = DeliverableType.ATTRIBUTES.type,
            filter$ = this.filterService.get<AttributesFilter>(deliverableType);
        return filter$;
    }

    /**
     * Loads default attributes filter data.
     *
     * @memberof AttributesService
     */
    public loadDefaultFilter(concept ?: Concept): Observable<AttributesFilter> {
        const defaultFilter: AttributesFilter = Object.assign({}, defaultAttributesFilter);
        const report$ = this.reportService.get();
        const concepts$ = this.conceptService.getReportDeliverableConcepts(DeliverableType.ATTRIBUTES.type);
        const subgroups$ = this.subgroupService.getReportSubgroups(DeliverableType.ATTRIBUTES.type);
        return zip(report$, concepts$, subgroups$).pipe(switchMap(result => {
            defaultFilter.countries = [result[0].country];
            const concepts = concept ? [concept] : result[1];
            defaultFilter.concepts = this.getConceptFilters(concepts);
            defaultFilter.subgroups = this.getSubgroupFilters(result[2]);
            this.filterService.update(defaultFilter);
            return of(defaultFilter);
        }));
    }

    /**
     * Returns concept filter items.
     *
     * @private
     * @param {Array<Concept>} concepts
     * @param deliverables
     * @returns {Array<FilterItem>}
     * @memberof AttributesService
     */
    private getConceptFilters(concepts: Array<Concept>): Array<FilterItem> {
        const conceptFilterItems: Array<FilterItem> = [];
        for (const item of concepts) {
            conceptFilterItems.push({
                name: item.name,
                id: item.exerciseConceptId,
                isSelected: true,
                position: item.position
            });
        }
        return conceptFilterItems;
    }

    /**
     * Returns subgroup filter items.
     *
     * @private
     * @param {Array<Subgroup>} subgroups
     * @returns {Array<FilterItem>}
     * @memberof AttributesService
     */
    private getSubgroupFilters(subgroups: Array<Subgroup>): Array<FilterItem> {
        const subgroupFilterItems: Array<FilterItem> = [];
        for (const item of subgroups) {
            subgroupFilterItems.push({
                name: item.name,
                id: item.segmentId,
                isSelected: false,
                position: item.position
            });
        }
        subgroupFilterItems[0].isSelected = true;
        return subgroupFilterItems;
    }

    /**
     * Returns updated map of Selected filter items for insights.
     *
     * @private
     * @param filter
     * @returns {Array<FilterItem>}
     * @memberof AttributesService
     */
    public getSelectedFilterItem(filterItem) {
        const selectedFilterItems = [];
        for (const item of filterItem) {
            selectedFilterItems.push({
                name: item.name,
                id: item.id,
            });
        }
        return selectedFilterItems;
    }

    /**
     * Returns Selected show filter items for insights.
     *
     * @private
     * @param filter
     * @returns {Array<FilterItem>}
     * @memberof AttributesService
     */
    public getSelectedShowFilter(filterItem) {
        const insight = {};
        for (const [k, v] of Object.entries(filterItem)) {
            if (k === 'percents' && v['isSelected'] === true) {
                for (const [key, val] of Object.entries(v)) {
                    if (val === true) {
                        insight[key] = val;
                    }
                }
            }
            if (v === true) {
                insight[k] = v;
            }
        }
        return insight;
    }

}
