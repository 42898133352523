import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Benchmark} from '@platform/models/benchmark.model';
import {ReportService} from '@platform/services/report.service';
import {ConceptService} from '@platform/services/concept.service';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {Report} from '@platform/models/report.model';
import {Concept} from '@platform/models/concept.model';
import {Organization} from '@platform/models/organization.model';
import {OrganizationService} from '@platform/services/organization.service';
import {concatMap, take} from 'rxjs/operators';
import {SubgroupService} from '@platform/services/subgroup.service';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {Subgroup} from '@platform/models/subgroup.model';
import {FactorsDeliverableView} from '@app/deliverables/factors/models/factors.model';
import {FinancialPotentialDeliverableView} from '@app/deliverables/financial-potential/models/financial-potential.model';
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';
import {ActivationProfileDeliverableView} from '@app/deliverables/activation-profile/models/activationProfile.model';

@Component({
    selector: 'ns-imported-benchmark',
    templateUrl: './imported-benchmark.component.html',
    styleUrls: ['./imported-benchmark.component.scss']
})
export class ImportedBenchmarkComponent implements OnInit, OnDestroy {

    @Input() benchmarks: Benchmark[];
    @Input() benchmarkReportId: any;
    @Output() reportIdEmitted = new EventEmitter<string>();

    report: Report;

    reportOrg: Organization;

    concepts: Concept[];

    dbSubgroup: Subgroup;

    factorsDeliverableView: FactorsDeliverableView = null;
    financialPotentialDeliverableView: FinancialPotentialDeliverableView = null;
    activationProfileDeliverableView: ActivationProfileDeliverableView = null;


    /**
     * RxJS subscriptions holder.
     */
    private subscriptions: Subscription;

    constructor(private reportService: ReportService,
                private conceptService: ConceptService,
                private organizationService: OrganizationService,
                private subgroupService: SubgroupService,
                private deliverableViewService: DeliverableViewService,
                private productDeliverableViewService: ProductDeliverableViewService) {
    }

    ngOnInit(): void {
        this.loacdData();
    }

    ngOnChanges(): void {
        this.loacdData();
    }

    loacdData() {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
        this.subscriptions = new Subscription();
        const subscription = combineLatest([
            this.reportService.fetchReportFromAPI(this.benchmarkReportId).pipe(take(1)),
            this.conceptService.fetchFromAPI(this.benchmarkReportId),
            this.subgroupService.fetchFromAPI(this.benchmarkReportId)
        ]).pipe(concatMap(result => {
            this.report = result[0];
            this.reportVisibleId();
            this.concepts = result[1].filter(concept => this.benchmarks.some(it => it.benchmarkConceptId === concept.id));
            this.concepts = this.concepts.filter(it => it.show == null || it.show);
            this.dbSubgroup = result[2].find(it => it.isDatabaseable);
            return this.organizationService.getById(this.report.orgId);
        })).subscribe(org => {
            this.reportOrg = org;
            this.addDeliverableData(this.benchmarkReportId);
        });

        this.subscriptions.add(subscription);
    }

    addDeliverableData(reportId) {
        const deliverableViews$ = this.deliverableViewService.fetchAllWithReportId(reportId);
        deliverableViews$.subscribe(result => {
            // Activation Profile Pull
            const activationProfileDeliverableViews = result.filter(it => it.type === 'activation.profiles');
            const activationProfileProductId = activationProfileDeliverableViews?.find(it => it.viewName === 'concept')?.productViewId;
            // Factors Pull
            const factorsDeliverableViews = result.filter(it => it.type === 'factors');
            const factorsProductId = factorsDeliverableViews?.find(it => it.viewName === 'concept')?.productViewId;
            // Financial Potential Pull
            const financialPotentialDeliverableViews = result.filter(it => it.type === 'financial.potential');
            const financialPotentialProductId = financialPotentialDeliverableViews[0]?.productViewId;
            // zip the 3 deliverables and capture data
            const activationProfileViews$: Observable<ActivationProfileDeliverableView> = this.productDeliverableViewService.get<ActivationProfileDeliverableView>(reportId, activationProfileProductId);
            const financialPotentialViews$: Observable<FinancialPotentialDeliverableView> = this.productDeliverableViewService.get<FinancialPotentialDeliverableView>(reportId, financialPotentialProductId);
            const factorsViews$: Observable<FactorsDeliverableView> = this.productDeliverableViewService.get<FactorsDeliverableView>(reportId, factorsProductId);
            const viewsArr = [];
            if (activationProfileProductId != null || activationProfileProductId !== undefined) {
                viewsArr.push(activationProfileViews$);
            }

            if (factorsProductId != null || factorsProductId !== undefined) {
                viewsArr.push(factorsViews$);
            }

            if (financialPotentialProductId != null || financialPotentialProductId !== undefined) {
                viewsArr.push(financialPotentialViews$);
            }
            this.subscriptions = combineLatest(viewsArr).subscribe(data => {
                const activationData = data.filter(it => it.type === 'activation.profiles');
                const fpData = data.filter(it => it.type === 'financial.potential');
                const factorsData = data.filter(it => it.type === 'factors');
                this.activationProfileDeliverableView = activationData.length > 0 ? activationData[0] : null;
                this.financialPotentialDeliverableView = fpData.length > 0 ? fpData[0] : null;
                this.factorsDeliverableView = factorsData.length > 0 ? factorsData[0] : null;
            });
        });
    }

    reportVisibleId(): void {
        this.reportIdEmitted.emit(this.benchmarkReportId);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
