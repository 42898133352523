import {Concept} from '@platform/models/concept.model';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ReachAnalysisService} from '@app/deliverables/reach-analysis/services/reach-analysis.service';
import {FilterService} from '@platform/services/filter.service';
import {ReportService} from '@platform/services/report.service';
import { TranslateService } from '@ngx-translate/core';
import {FilterItem, ReachAnalysisFilter} from '@app/deliverables/reach-analysis/models/filter.model';
import {combineLatest, Subscription} from 'rxjs';
import {DataType, DropdownData, DropdownItem} from '@products/shared/dropdown/dropdown.data.model';
import {take} from 'rxjs/operators';
import {Report} from '@platform/models/report.model';
import {VarietyDropdownData, VarietyDropdownItem} from '@app/deliverables/reach-analysis/models/variety-filter.model';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelLabel, MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';

@Component({
  selector: 'ns-reach-anaylsis-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {

  /**
   * survey-question filter object.
   *
   * @property
   * @type {ReachAnalysisFilter}
   * @memberof FilterComponent
   */
  public filter: ReachAnalysisFilter;
  public report: Report;
  public defaultFilter: ReachAnalysisFilter;

  /**
   * Array of subscriptions for cleanup.
   *
   * @property
   * @private
   * @type {Array<Subscription>}
   * @memberof FilterComponent
   */
  private subscriptions: Array<Subscription>;
  /**
   * List of concept dropdown items.
   *
   * @property
   * @type {Array<DropdownData>}
   * @memberof FilterComponent
   */
  public conceptsListData: DropdownData<number>;

  /**
   * List of subgroup dropdown items.
   *
   * @property
   * @type {Array<DropdownData>}
   * @memberof FilterComponent
   */
  public subgroupListData: DropdownData<number>;

  /**
   * List of deliverable view dropdown items.
   *
   * @property
   * @type {Array<DropdownData>}
   * @memberof FilterComponent
   */
  public compareListData: DropdownData<string>;

  /**
   * List of countries on the report.
   *
   * @property
   * @type {Array<DropdownData>}
   * @memberof FilterComponent
   */
  public countryListData: DropdownData<string>;
  /**
   * Mixpanel label for the deliverable
   *
   * @property
   * @private
   * @type {string}
   * @memberof FilterComponent
   */
  private deliverableLabel: string;
  public varietiesListData: VarietyDropdownData<number>;
  public compareView: string;
  public basesLabel: string;

  @Input() public concept: Concept;
  public deliverableViews: Array<DeliverableView>;

  constructor(
    private reachAnalysisService: ReachAnalysisService,
    private filterService: FilterService,
    private reportService: ReportService,
    private translate: TranslateService,
    private mixpanelService: MixpanelService,
    private deliverableViewService: DeliverableViewService,
    private productDeliverableViewService: ProductDeliverableViewService
  ) {
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.deliverableLabel = this.concept ? MixpanelLabel.concepts : MixpanelLabel.reach;
    const deliverableViews$ = this.deliverableViewService.getDeliverableViews(DeliverableType.REACH_ANALYSIS.type);
    const deliverableViewSubscription = deliverableViews$.pipe(take(1)).subscribe(
          (deliverableViews: Array<DeliverableView>) => {
              this.deliverableViews = deliverableViews;
              const filter$ = this.reachAnalysisService.getReachAnalysisFilter();
              const report$ = this.reportService.get();
              const subscription = combineLatest([filter$, report$ ])
                  .subscribe(([filter, report]) => {
                      this.filter = filter;
                      this.report = report;
                      this.deliverableViews = deliverableViews;
                      const metaInfo = this.reachAnalysisService.getMetaInfo(report, filter, deliverableViews);
                      this.basesLabel = metaInfo.basesLabel;
                      this.compareView = filter ? filter.compare.find(it => it.isSelected === true).id : 'summary';
                      this.setFilters(filter, report);
                  });
              const filterSubscription = filter$.pipe(take(1)).subscribe(
                  (filter: ReachAnalysisFilter) => {
                      this.defaultFilter = filter;
                  }
              );
              this.subscriptions.push(filterSubscription);
              this.subscriptions.push(subscription);
          });
      this.subscriptions.push(deliverableViewSubscription);
  }

  /**
   * Sets the filter options for concepts, subgroups, compareList, countries.
   *
   * @private
   * @param {AttributesFilter} filter
   * @param {Report} report
   * @memberof FilterComponent
   */
  private setFilters(filter: ReachAnalysisFilter, report: Report): void {
    this.conceptsListData = this.getConceptFilterOptions(filter);
    this.subgroupListData = this.getSubGroupFilterOptions(filter);
    this.varietiesListData = this.getVarietiesFilterOptions(filter);
    this.compareListData = this.getCompareFilterOptions(filter.compare);
    this.countryListData = this.getCountryOptions(filter.countries[0]);
  }

  /**
   * Returns concepts filter options.
   *
   * @private
   * @returns {Array<DropdownData>}
   * @memberof FilterComponent
   * @param filter
   */
  private getVarietiesFilterOptions(filter: ReachAnalysisFilter): VarietyDropdownData<number> {
    const dataType = DataType.CHECKBOX;
    const items = filter.concepts.find(it => it.isSelected === true)?.varieties?.filter(it => it.position).sort(function (a, b) {
      return a.position - b.position;
    });
    const varietyDropdownData: VarietyDropdownItem<number>[] = items?.map(item => {
      return {
        value: item.id,
        label: item.name,
        selected: item.isSelected,
        isLocked: item.isLocked
      };
    });

    return {
      dropdownLabel: this.basesLabel === 'OBC' ? this.translate.instant('shared.deliverables.reach.filter.benefit.label') : this.translate.instant('shared.deliverables.reach.filter.varieties.label'),
      dataType: dataType,
      contentTitle: this.basesLabel === 'OBC' ? this.translate.instant('shared.deliverables.reach.filter.benefit.itemsTitle') : this.translate.instant('shared.deliverables.reach.filter.varieties.itemsTitle'),
      groupSelect: this.basesLabel === 'OBC' ? this.translate.instant('shared.deliverables.reach.filter.benefit.allSelectedLabel') : this.translate.instant('shared.deliverables.reach.filter.varieties.allSelectedLabel'),
      data: varietyDropdownData
    };
  }

  /**
   * Returns concepts filter options.
   *
   * @private
   * @returns {Array<DropdownData>}
   * @memberof FilterComponent
   * @param filter
   */
  private getConceptFilterOptions(filter: ReachAnalysisFilter): DropdownData<number> {
    const dataType = DataType.RADIO;
    const items = filter.concepts.filter(it => it.id).sort(function (a, b) {
      return a.position - b.position; });
    const dropdownData: DropdownItem<number>[] = items.map(item => {
      return {
        value: item.id,
        label: item.name,
        selected: item.isSelected
      };
    });

    return {
      dropdownLabel: this.translate.instant('shared.deliverables.attributes.filter.concepts.label'),
      dataType: dataType,
      contentTitle: this.translate.instant('shared.deliverables.attributes.filter.concepts.itemsTitle'),
      groupSelect: '',
      data: dropdownData
    };
  }

  /**
   * Returns sub groups filter options.
   *
   * @private
   * @returns {Array<DropdownData>}
   * @memberof FilterComponent
   * @param filter
   */
  private getSubGroupFilterOptions(filter: ReachAnalysisFilter): DropdownData<number> {
    const dataType = DataType.RADIO;
    const groupSelect = DataType.RADIO;
    const items = filter.subgroups.filter(it => it.id).sort(function (a, b) {
      return a.position - b.position; });
    const dropdownData: DropdownItem<number>[] = items.map(item => {
      return {
        value: item.id,
        label: item.name,
        selected: item.isSelected
      };
    });

    return {
      dropdownLabel: this.translate.instant('shared.deliverables.survey-question.filter.subgroups.label'),
      dataType: dataType,
      groupSelect: groupSelect,
      contentTitle: this.translate.instant('shared.deliverables.survey-question.filter.subgroups.itemsTitle'),
      data: dropdownData
    };
  }

  private getCompareFilterOptions(items: Array<FilterItem>): DropdownData<string> {
    const dropdownData: DropdownItem<string>[] = items.map(item => {
      return {
        value: item.id,
        label: item.name,
        selected: item.isSelected
      };
    });

    return {
      dropdownLabel: this.translate.instant('shared.deliverables.attributes.filter.compare.label'),
      dataType: DataType.RADIO,
      contentTitle: this.translate.instant('shared.deliverables.attributes.filter.compare.itemsTitle'),
      data: dropdownData
    };
  }

  private getCountryOptions(country: string): DropdownData<string> {
    return {
      dropdownLabel: this.translate.instant('shared.deliverables.attributes.filter.country.label'),
      dataType: DataType.RADIO,
      contentTitle: this.translate.instant('shared.deliverables.attributes.filter.country.itemsTitle'),
      data: [
        {
          value: country,
          label: country,
          selected: true
        }
      ]
    };
  }


  /**
   * Event listener for concept selection change event.
   *
   * @param {Array<DropdownData>} concepts
   * @memberof FilterComponent
   */
  selectConcept(concepts: Array<DropdownItem<number>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      concepts: this.filter.concepts.map((concept) => {
        return {
          ...concept,
          isSelected: concepts.find(selectedConcept => selectedConcept.value === concept.id).selected
        };
      }),
      show: {
        ...this.filter.show,
        reload: true
      }
    });
  }

  /**
   * Event listener for concept selection change event.
   *
   * @memberof FilterComponent
   * @param varieties Array<VarietyDropdownItem<number>>
   */
  selectVarieties(varieties: Array<VarietyDropdownItem<number>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      show: {
        ...this.filter.show,
        reload: true
      },
      concepts: this.filter.concepts.map((concept) => {
        return {
          ...concept,
          varieties: concept.varieties.map((variety) => {
            if (concept.isSelected === true) {
              return {
                ...variety,
                isSelected: varieties.find(selectedVariety => selectedVariety.value === variety.id).selected,
                isLocked: varieties.find(selectedVariety => selectedVariety.value === variety.id).isLocked
              };
            } else {
              return {...variety};
            }
          })
        };
      })
    });
  }


  /**
   * Event listener for subgroup selection change event.
   *
   * @param {Array<DropdownData>} subgroups
   * @memberof FilterComponent
   */
  selectSubgroup(subgroups: Array<DropdownItem<number>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      subgroups: this.filter.subgroups.map((subgroup) => {
        return {
          ...subgroup,
          isSelected: subgroups.find(selectedSubgroup => selectedSubgroup.value === subgroup.id).selected
        };
      }),
      show: {
        ...this.filter.show,
        reload: true
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Emits event of the selected filter menu item.
   */
  selectedEvent(event): void {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    console.log(event);
  }

  /**
   * Sets the new filter entries when changes are made to compare drop down.
   *
   * @param {Array<DropdownData>}compare The list of compare options.
   */
  selectCompare(compare: any) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      compare: this.filter.compare.map((item: FilterItem) => {
        return {
          ...item,
          isSelected: compare.find(selected => selected.value === item.id).selected
        };
      }),
      show: {
        ...this.filter.show
      },
      concepts: this.filter.concepts.map((concept, index) => {
        return {
          ...concept,
          isSelected:  concept.isSelected,
          varieties: concept.varieties.map((variety) => {
            if (concept.isSelected === true) {
              return {
                ...variety,
                isSelected: true,
                isLocked: false
              };
            } else {
              return {...variety};
            }
          })
        };
      }),
    });
  }
}
