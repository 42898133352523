import { Concept } from '@platform/models/concept.model';
import { Dictionary } from '@ngrx/entity';
import { State } from '@platform/store/state/app.state';
import { ConceptState } from '@platform/store/state/concept.state';
import { conceptAdapter } from '@platform/store/adapters/concept.adapter';
import { createFeatureSelector, createSelector } from '@ngrx/store';

/**
 * Default selectors from adapter.
 *
 * @property
*/
const {
  selectEntities,
  selectAll
} = conceptAdapter.getSelectors();

// Concept Selectors
/**
 * Concept feature selector.
 *
 * @export
 * @property
*/
export const selectConceptsFeature = createFeatureSelector< ConceptState>('concepts');
/**
 * Concept selector.
 *
 * @export
 * @property
*/
export const selectConceptsState = createSelector(
  selectConceptsFeature,
  (state: ConceptState) => state
);
/**
 * Concept entity selector.
 *
 * @export
 * @property
*/
export const selectConceptEntities = createSelector(
  selectConceptsState,
  selectEntities
);
/**
 * Selector for selecting concepts by id.
 *
 * @export
 * @property
*/
export const selectConceptById = createSelector(
  selectConceptEntities,
  (entities: Dictionary<Concept>, props: { id: string }) => {
    return entities[props.id];
  }
);
/**
 * Selector for selecting all concepts.
 *
 * @export
 * @property
*/
export const selectAllConcepts = createSelector(
  selectConceptsState,
  selectAll
);
/**
 * Selector for selecting concepts for a report object.
 *
 * @export
 * @property
*/
export const selectReportConcepts = (reportId: string) => {
  return createSelector(selectAllConcepts, (concepts: Concept[]) => {
      return concepts.filter(c => c.reportId === reportId).sort((a, b) => a.position - b.position);
    }
  )
};
