import { Concept } from '@platform/models/concept.model';
import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { FilterItem } from '@app/deliverables/attributes/models/filter.model';
import {DataType, DropdownData, DropdownItem} from '@products/shared/dropdown/dropdown.data.model';
import {combineLatest, Subscription} from 'rxjs';
import {FilterService} from '@platform/services/filter.service';
import {ReportService} from '@platform/services/report.service';
import { TranslateService } from '@ngx-translate/core';
import {TgaService} from '@app/deliverables/target-group-analysis/services/tga.service';
import {TGAFilter} from '@app/deliverables/target-group-analysis/models/filter.model';
import {take} from 'rxjs/operators';
import {Report} from '@platform/models/report.model';

@Component({
  selector: 'ns-tga-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit , OnDestroy {
  /**
   * Attributes filter object.
   *
   * @property
   * @type {AttributesFilter}
   * @memberof FilterComponent
   */
  public filter: TGAFilter;
  public defaultFilter: TGAFilter;

  /**
   * List of concept dropdown items.
   *
   * @property
   * @type {Array<DropdownData>}
   * @memberof FilterComponent
   */
  public conceptsListData: DropdownData<string>;

  /**
   * List of subgroup dropdown items.
   *
   * @property
   * @type {DropdownData}
   * @memberof FilterComponent
   */
  public subgroupListData: DropdownData<string>;

  /**
   * List of deliverable view dropdown items.
   *
   * @property
   * @type {DropdownData}
   * @memberof FilterComponent
   */
  public compareListData: DropdownData<string>;

  /**
   * List of countries on the report.
   *
   * @property
   * @type {DropdownData}
   * @memberof FilterComponent
   */
  public countryListData: DropdownData<string>;

  /**
   * Array of subscriptions for cleanup.
   *
   * @property
   * @private
   * @type {Array<Subscription>}
   * @memberof FilterComponent
   */
  private subscriptions: Array<Subscription>;

  @Input() public concept: Concept;
  report: Report;

  constructor(
    private tgaService: TgaService,
    private filterService: FilterService,
    private reportService: ReportService,
    private translate: TranslateService
  ) {
    this.subscriptions = [];
  }

  ngOnInit(): void {
    const filter$ = this.tgaService.getTGAFilter();
    const report$ = this.reportService.get();
    const subscription = combineLatest([report$, filter$]).subscribe(([report, filter]) => {
      this.report = report;
                this.filter = filter;
                this.setFilters(filter, report);
        });
    this.subscriptions.push(subscription);
  }

  /**
   * Cleanup the component on removing from the UI.
   *
   * @memberof FilterComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Sets the filter options for concepts, subgroups, compareList, countries.
   *
   * @private
   * @param {AttributesFilter} filter
   * @param {Report} report
   * @memberof FilterComponent
   */
  private setFilters(filter: TGAFilter, report: Report): void {
    this.conceptsListData = this.getConceptFilterOptions(filter);
    this.subgroupListData = this.getSubGroupFilterOptions(filter);
    this.compareListData = this.getCompareOptions(filter.compare);
    this.countryListData = this.getCountryOptions(filter.countries[0]);
  }

  /**
   * Returns concepts filter options.
   *
   * @private
   * @param {Array<FilterItem>} items
   * @returns {DropdownData}
   * @memberof FilterComponent
   */
  private getConceptFilterOptions(filter: TGAFilter): DropdownData<string> {
    const dataType = DataType.CHECKBOX;
    const dropdownData: DropdownItem<string>[] = filter.concepts.map(item => {
      return {
        value: item.name,
        label: item.name,
        selected: item.isSelected
      };
    });

    const dropdownItems: DropdownData<string> = {
      dropdownLabel: this.translate.instant('shared.deliverables.attributes.filter.concepts.label'),
      dataType: dataType,
      contentTitle: this.translate.instant('shared.deliverables.attributes.filter.concepts.itemsTitle'),
      groupSelect: this.translate.instant('shared.deliverables.attributes.filter.concepts.allSelectedLabel'),
      data: dropdownData
    };

    return dropdownItems;
  }

  /**
   * Returns sub groups filter options.
   *
   * @private
   * @param {Array<FilterItem>} items
   * @returns {DropdownData}
   * @memberof FilterComponent
   */
  private getSubGroupFilterOptions(filter: TGAFilter): DropdownData<string> {
    const dataType = DataType.CHECKBOX;
    const groupSelect = this.translate.instant('shared.deliverables.attributes.filter.subgroups.allSubgroups');
    const dropdownData: DropdownItem<string>[] = filter.subgroups.map(item => {
      return {
        value: item.name,
        label: item.name,
        selected: item.isSelected
      };
    });

    const dropdownItems: DropdownData<string> = {
      dropdownLabel: this.translate.instant('shared.deliverables.attributes.filter.subgroups.label'),
      dataType: dataType,
      groupSelect: groupSelect,
      contentTitle: this.translate.instant('shared.deliverables.attributes.filter.subgroups.itemsTitle'),
      data: dropdownData
    };

    return dropdownItems;
  }

  /**
   * Returns the compare filter options.
   *
   * @private
   * @param {Array<Deliverable>} deliverables
   * @param {string} viewType
   * @returns {DropdownData}
   * @memberof FilterComponent
   */
  private getCompareOptions(items: Array<FilterItem>): DropdownData<string> {
    const dropdownData: DropdownItem<string>[] = items.map(item => {
      return {
        value: item.id,
        label: item.name,
        selected: item.isSelected
      };
    });

    const dropdownItems: DropdownData<string> = {
      dropdownLabel: this.translate.instant('shared.deliverables.attributes.filter.compare.label'),
      dataType: DataType.RADIO,
      contentTitle: this.translate.instant('shared.deliverables.attributes.filter.compare.itemsTitle'),
      data: dropdownData
    };
    return dropdownItems;
  }

  /**
   * Returns the country filter options.
   *
   * @private
   * @param {string} country
   * @returns {DropdownData}
   * @memberof FilterComponent
   */
  private getCountryOptions(country: string): DropdownData<string> {
    return {
      dropdownLabel: this.translate.instant('shared.deliverables.attributes.filter.country.label'),
      dataType: DataType.RADIO,
      contentTitle: this.translate.instant('shared.deliverables.attributes.filter.country.itemsTitle'),
      data: [
        {
          value: country,
          label: country,
          selected: true
        }
      ]
    };
  }

  /**
   * Event listener for concept selection change event.
   *
   * @listens event:selectionChange
   * @param {Array<DropdownItem>} concepts
   * @memberof FilterComponent
   */
  selectConcept(allConcepts: Array<DropdownItem<string>>) {
    this.filterService.update({
      ...this.filter,
      concepts: this.filter.concepts.map((concept) => {
        return {
          ...concept,
          isSelected: allConcepts.find(selectedConcept => selectedConcept.value === concept.name).selected
        };
      })
    });
  }

  /**
   * Event listener for subgroup selection change event.
   *
   * @listens event:selectionChange
   * @param {Array<DropdownItem>} subgroups
   * @memberof FilterComponent
   */
  selectSubgroup(subgroups: Array<DropdownItem<string>>) {
    this.filterService.update({
      ...this.filter,
      subgroups: this.filter.subgroups.map((subgroup) => {
        return {
          ...subgroup,
          isSelected: subgroups.find(selectedSubgroup => selectedSubgroup.value === subgroup.name).selected
        };
      })
    });
  }

  /**
   * Sets the new filter entries when changes are made to compare drop down.
   *
   * @listens event:selectionChange
   * @param {Array<DropDownItem>}compare The list of compare options.
   */
  selectCompare(compare: any) {
    this.filterService.update({
      ...this.filter,
      compare: this.filter.compare.map((item: FilterItem) => {
        return {
          ...item,
          isSelected: compare.find(selected => selected.value === item.id).selected
        };
      })
    });
  }

  /**
   * Emits event of the selected filter menu item.
   */
  selectedEvent(event): void {
    console.log(event);
  }

}
