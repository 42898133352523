import {Concept} from '@platform/models/concept.model';
import {ScoreCardView} from '@platform/score-cards/score-card-view';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ExportPngService} from '@platform/services/export-png.service';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {SpinnerService} from '@platform/services/spinner.service';
import {DeliverableInsightService} from '@platform/services/deliverable-insight.service';
import {UserService} from '@platform/services/user.service';
import {skipWhile, take, debounceTime} from 'rxjs/operators';
import {TGAFilter} from '@app/deliverables/target-group-analysis/models/filter.model';
import {TgaService} from '@app/deliverables/target-group-analysis/services/tga.service';
import {combineLatest, forkJoin, Subscription} from 'rxjs';
import {TGAMetaInfo} from '@app/deliverables/target-group-analysis/models/view-meta-info.model';
import {TGADeliverableView} from '@app/deliverables/target-group-analysis/models/tga.model';
import {DeliverableInsight} from '@platform/deliverable-insight/deliverable-insight.model';
import {ReportService} from '@platform/services/report.service';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {DeliverableViewType} from "@app/deliverables/target-group-analysis/models/deliverable-view-type-enum";
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';
import {Report} from '@platform/models/report.model';
import {Subgroup} from '@platform/models/subgroup.model';
import {SubgroupService} from '@platform/services/subgroup.service';
import {FilterService} from '@platform/services/filter.service';
import {RouterService} from '@platform/services/router.service';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import {UserView} from '@platform/models/user-view.model';
import {InsightService} from '@platform/insights/insights.service';
import {UserViewService} from '@platform/services/user-view.service';
import {SurveyQuestionFilter} from '@app/deliverables/survey-question/models/filter.model';

@Component({
    selector: 'ns-target-group-analysis',
    templateUrl: './target-group-analysis.component.html',
    styleUrls: ['./target-group-analysis.component.scss']
})
export class TargetGroupAnalysisComponent implements OnInit, OnDestroy, ScoreCardView {

    /**
     * TGA deliverable view
     * @type {TGADeliverableView} tgaDeliverableView
     * @memberOf TargetGroupAnalysisComponent
     */
    public tgaDeliverableView: TGADeliverableView;

    /**
     * toggle selected View
     * @type {Boolean} selectedView
     * @memberOf TargetGroupAnalysisComponent
     */
    public previousAnalysis: boolean = false;
    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @member TargetGroupAnalysisComponent
     */
    public subscriptions: Array<Subscription>;
    /**
     * Subscription objects for cleanup.
     *
     * @type {TGAFilter} filter
     * @member TargetGroupAnalysisComponent
     */
    public filter: TGAFilter;

    public defaultViewFilters: TGAFilter;
    public isInternalUser: Boolean;
    /**
     * toggle insight btn
     * @type {Boolean} isInsightEnable
     * @memberOf TargetGroupAnalysisComponent
     */
    public isInsightEnable = false;

    public disableBtn: boolean;

    /**
     * The deliverable insight data when creating insight.
     * @type {DeliverableInsight} deliverableData
     * @memberOf TargetGroupAnalysisComponent
     */
    public deliverableData: DeliverableInsight;

    /**
     * Meta info for TGA
     *
     * @type {TGAMetaInfo}
     * @memberOf TargetGroupAnalysisComponent
     */
    public viewTGAMetaInfo: TGAMetaInfo;


    /**
     * Spinner.
     *
     * @type {Array<any>}
     * @member AttributesComponent
     */
    public displayProgressSpinner = false;

    /**
     * ScoreCard Concept object for target group analysis.
     *
     * @type {Concept}
     */
    public scoreCardConcept: Concept;

    /**
     * Feature FLAG for Automatic Headlines.
     *
     * @type {Boolean}
     */
    public isAutomatedHeadlinesEnabled: boolean;

    /**
     * Take the insight in the HTML format for this Deliverable
     *
     * @type {Boolean}
     */
    public addHTMLToInsight = false;

    public deliverableViews: Array<DeliverableView>;

    public report: Report;

    public subgroups: Subgroup[];

    public deliverableInfos: Array<DeliverableInfo>;

    public userViews: Array<UserView>;

    public deliverableType =  DeliverableType.TARGET_GROUP_ANALYSIS.type;

    private insightId: string;

    constructor(
        private reportService: ReportService,
        private tgaService: TgaService,
        private exportPNGService: ExportPngService,
        private viewMetaInfoService: ViewMetaInfoService,
        private spinnerService: SpinnerService,
        private deliverableInsightService: DeliverableInsightService,
        private deliverableViewService: DeliverableViewService,
        private userService: UserService,
        private productDeliverableViewService: ProductDeliverableViewService,
        private subgroupService: SubgroupService,
        private filterService: FilterService,
        private routeService: RouterService,
        private deliverableInfoService: DeliverableInfoService,
        private insightService: InsightService,
        private userViewService: UserViewService
    ) {
        this.subscriptions = [];
    }

    ngOnInit(): void {
       this.init();
      this.reportService.reloadDeliverable.subscribe(() => {
          this.productDeliverableViewService.clearCache();
          this.deliverableViewService.clearCache();
            this.init(true);
        });
    }

    init(reload = false): void {
        this.clearSubscriptions();
        this.spinnerService.showSpinner();
        const insightId = this.routeService.getQueryParam('insightId');
        const user$ = this.userService.getUser();
        const report$ = this.reportService.get();
        const tga$ = this.tgaService.getTGA();
        const filter$ = this.tgaService.getTGAFilter();
        const defaultFilter$ =  this.tgaService.loadDefaultFilter(null);
        const viewMetaInfo$ = this.viewMetaInfoService.get<TGAMetaInfo>(this.deliverableType);
        const deliverableViews$ = this.deliverableViewService.getDeliverableViews(this.deliverableType, true);
        const subgroups$ = this.subgroupService.getReportSubgroups(DeliverableType.TARGET_GROUP_ANALYSIS.type);
        const subscription = combineLatest([
            report$,
            user$,
            deliverableViews$
        ]).subscribe(([report, user, deliverableViews]) => {
            this.report = report;
            this.deliverableInfos = this.deliverableInfoService.getNonForecastDeliverables(report);
            this.deliverableViews = deliverableViews;
            this.isInternalUser = user.isInternalUser;
            this.isAutomatedHeadlinesEnabled = user.featureFlags.includes('REPORTING_AUTOMATED_HEADLINES');
            forkJoin([
                this.userViewService.fetchReportUserViewsFromAPI(this.report.id),
                this.insightService.getInsightFilterData<TGAFilter>(report.id, insightId),
                defaultFilter$
            ]).pipe(debounceTime(1000)).subscribe(([userViews, insightFilter, defaultViewFilters]) => {
                this.userViews = this.userViewService.setupUserViews(this.report.id, this.deliverableType, userViews, defaultViewFilters, insightFilter);
               this.defaultViewFilters = defaultViewFilters;
                const insightView = this.userViews.find(it => it.id === this.userViewService.insightViewId);
                if (!reload) {
                    this.selectUserView(insightView ? insightView : this.userViews.find(it => it.isSelected));
                } else {
                    const conceptsAdded = this.userViewService.updateUserViewConceptsWithDefaultViewConcepts(this.filter , this.defaultViewFilters);
                    this.filterService.update({
                        ...this.filter,
                        concepts: conceptsAdded
                    });
                }
                /**
                 * Add subscription to watch filter changes here so that s&w deliverable data can also be updated as per filter change.
                 * */
                this.subscriptions.push(combineLatest([
                    filter$,
                    tga$,
                    subgroups$,
                    viewMetaInfo$
                ]).subscribe(([filter, tga, subgroups, viewMetaInfo]) => {
                    this.filter = filter;
                    this.disableBtn = this.isConceptSubgroupExists(filter);
                    this.tgaDeliverableView = tga;
                    this.subgroups = subgroups;
                    this.report = report;
                    this.deliverableViews = deliverableViews;
                    this.previousAnalysis = tga.previousAnalysis;
                    this.tgaDeliverableView = tga;
                    this.viewTGAMetaInfo = viewMetaInfo;
                    this.deliverableData = {
                        title: this.isAutomatedHeadlinesEnabled ? this.deliverableInsightService.generateInsightTitle('Target Group Performance Analysis') : '',
                        deliverable: {
                            deliverableViewId: this.deliverableViews.find(it => it.viewName === DeliverableViewType.TARGETGROUP).id,
                            filter: this.filter,
                            metaInfo: this.viewTGAMetaInfo,
                            insightHTML: ''
                        }
                    };
                    if (this.isInsightEnable && this.isAutomatedHeadlinesEnabled) {
                        this.openInsightCreationForm();
                    }
                }));
                this.spinnerService.hideSpinner();
            });
        });
        this.subscriptions.push(subscription);
    }

    /**
     * Method that is triggered when user view is changed. This will in turn update the filter model in the store.
     * */
    selectUserView(userView: UserView): void {
        this.insightId = userView.id;
        this.filter = userView.filter as TGAFilter;
        const conceptsAdded = this.userViewService.updateUserViewConceptsWithDefaultViewConcepts(this.filter , this.defaultViewFilters);
        if (userView.id !== 'Default View') {
            this.filterService.update({
                ...this.filter,
                concepts: conceptsAdded
            });
        } else {
            this.filterService.update(userView.filter);
        }
        this.disableBtn = this.isConceptSubgroupExists(this.filter);
    }

     /**
     * capture screen layout and export as png.
     *
     */
    exportAsPNG() {
        this.displayProgressSpinner = true;
        this.exportPNGService.exportPNG();
        this.spinnerService.getSpinnerObs().subscribe(loading => this.displayProgressSpinner = loading);
    }

    /**
     * check concept count
     * */
    isConceptSubgroupExists(filter: TGAFilter): boolean {
        const conceptCount = filter.concepts.filter(it => it.isSelected === true).length;
        const subgroupCount = filter.subgroups.filter(it => it.isSelected === true).length;
        return (conceptCount === 0 || subgroupCount === 0);
    }

    /**
     * toggle between headers and insight creation form.
     *
     */
    openInsightCreationForm() {
        this.setPreviousAnalysisInMetaInfo();
        this.isInsightEnable = true;
        this.addHTMLToInsight = true;
    }

    /**
     * set previous analysis in meta info
     *
     */
    setPreviousAnalysisInMetaInfo() {
        const deliverableType = DeliverableType.TARGET_GROUP_ANALYSIS.type;
        const viewInfo = {deliverableType, previousAnalysis: false};
        viewInfo.previousAnalysis = this.previousAnalysis;
        this.viewMetaInfoService.update(viewInfo, deliverableType);
    }

    /**
     * Close insight form
     */
    closeInsight() {
        this.isInsightEnable = false;
    }

    /**
     * Cleanup hook.
     *
     * @memberOf SurveyQuestionComponent
     */
    ngOnDestroy(): void {
        this.clearSubscriptions();
        this.productDeliverableViewService.clearCache();
        this.deliverableViewService.clearCache();
    }

    clearSubscriptions(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    /**
     * Action that is triggered when the deliverable info is changed.
     *
     * @param deliverableInfo
     */
    onDeliverableChange(deliverableInfo: DeliverableInfo): void {
        this.deliverableInfoService.routeToDeliverable(deliverableInfo);
    }
}
