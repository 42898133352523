import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {FilterService} from '@src/app/platform/services/filter.service';
import {SurveyQuestionFilter} from '../../models/filter.model';
import {SurveyQuestionService} from '../../services/survey-question.service';
import {filterNames as filterNameSource} from './filter-names';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelLabel, MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {DeliverableType} from "@app/deliverables/deliverable-type.enum";
import {ConceptService} from "@platform/services/concept.service";

@Component({
    selector: 'ns-survey-question-filter-show',
    templateUrl: './show.component.html',
    styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit, OnDestroy {

    @Input() deliverableLabel: string;

    /**
     * Filter label.
     *
     * @property
     * @type {string}
     * @memberof ShowComponent
     */
    public filterLabel: string;

    public filter: SurveyQuestionFilter;

    private subscription: Subscription;

    /**
     * Filter name object.
     *
     * @property
     * @type {*}
     * @memberof ShowComponent
     */
    public filterNames: any;

    public isConceptView: boolean;

    public showAggregateFilter: boolean;

    constructor(
        private surveyQuestionService: SurveyQuestionService,
        private filterService: FilterService,
        private mixpanelService: MixpanelService,
        private conceptService: ConceptService
    ) {
        this.filterNames = filterNameSource;
    }

    ngOnInit(): void {
        this.subscription = combineLatest([
            this.surveyQuestionService.getSurveyQuestionFilter(),
            this.conceptService.getHiddenConcepts(DeliverableType.SURVEY_QUESTION)
        ]).subscribe(([filter, hiddenConcepts]) => {
            this.filter = filter;
            let isConceptView = filter.compare.find(selected => selected.isSelected === true).name === 'Concepts';
            let showAggregateFilter = hiddenConcepts.length === 0;
            if (isConceptView) {
                if (!showAggregateFilter && filter.show.aggregate !== showAggregateFilter) {
                    this.toggleSelection('aggregate');
                }
            } else {
                showAggregateFilter = false;
            }

            this.isConceptView = isConceptView;
            this.showAggregateFilter = showAggregateFilter;
            this.setFilterLabel();
        });
    }

    /**
     * Sets filter value property.
     *
     * @private
     * @memberof ShowComponent
     */
    private setFilterLabel(): void {
        const showFilter = this.filter.show;
        let label = '';
        const options: string[] = [];
        if (showFilter.statTesting) {
            options.push('Stat Testing');
        }
        if (showFilter.aggregate && this.isConceptView) {
            options.push('Aggregate');
        }
        if (showFilter.mention) {
            options.push('Mention < 5%');
        }

        if (options.length === 0) {
            options.push('Show');
        }
        label = `${label} ${options.join(', ')}`;
        this.filterLabel = label;
    }

    /**
     * Event listener for decimal place filter change.
     *
     * @listens event:change
     * @param {*} event
     * @memberof ShowComponent
     */
    public changeDecimalPlace(event): void {
        const filterNames = this.filterNames;
        const decimalPlace = event.value;
        const filter = this.cloneFilter();
        filter.show.noDecimal = decimalPlace === filterNames.noDecimals;
        filter.show.oneDecimal = decimalPlace === filterNames.oneDecimalPlace;
        filter.show.twoDecimal = decimalPlace === filterNames.twoDecimalPlace;
        this.filterService.update(filter);
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    }

    /**
     * Returns selected decimal place value.
     *
     * @returns {string}
     * @memberof ShowComponent
     */
    public getSelectedDecimalPlace(): string {
        const filterNames = this.filterNames;
        const showFilter = this.filter?.show;
        let decimalPlace = '';
        decimalPlace = showFilter && showFilter.noDecimal ? filterNames.noDecimals : decimalPlace;
        decimalPlace = showFilter && showFilter.oneDecimal ? filterNames.oneDecimalPlace : decimalPlace;
        decimalPlace = showFilter && showFilter.twoDecimal ? filterNames.twoDecimalPlace : decimalPlace;
        return decimalPlace;
    }

    /**
     * Toggles the selection for checkboxes.
     *
     * @param {string} filterName
     * @memberof ShowComponent
     */
    public toggleSelection(filterName: string): void {
        const filterNames = this.filterNames;
        const filter = this.cloneFilter();
        const show = filter.show;
        switch (filterName) {
            case filterNames.statTesting:
                show.statTesting = !show.statTesting;
                break;
            case filterNames.aggregate:
                show.aggregate = !show.aggregate;
                break;
            case filterNames.mention:
                show.mention = !show.mention;
                break;
        }
        this.filterService.update(filter);
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    }

    /**
     * Clones filter component.
     */
    private cloneFilter(): SurveyQuestionFilter {
        const filter = this.filter;
        const newShowFilter = {...filter.show};
        const newFilter = {...filter, show: newShowFilter};
        return newFilter;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
