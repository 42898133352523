<ns-deliverable-configuration-header
        [headerText]="'Items And Ordering'"
        (backBtnClickEvent)="returnBack()"
        (closeBtnClickEvent)="onCloseButtonClick()">
</ns-deliverable-configuration-header>
<ns-deliverable-configuration-body>
    <div class="warning-prompt">Hiding concepts also hides Aggregate results in Survey Questions.</div>
    <div cdkDropList (cdkDropListDropped)="dropConcept($event)">
        <div class="item-row-dragndrop" *ngFor="let concept of clonedConcepts" cdkDrag>
            <div class="cdk-custom-placeholder" *cdkDragPlaceholder></div>
            <div class="item-row">
                <button mat-icon-button cdkDragHandle class="drag-n-drop-button">
                    <span class="sif sif-drag-handler sif-12 sif-gray"></span>
                </button>
                <input matInput class="name-input" (change)="displayNameChange($event,concept)" [value]="concept.name" matTooltipPosition="above" [matTooltip]="concept.name" placeholder="Enter concept name"/>
                <button mat-icon-button class="visibility-button" (click)="toggleVisibility(concept)">
                    <span class="sif sif-gray cursor-pointer" [ngClass]="concept.show? 'sif-eye-enabled sif-12' : 'sif-eye-disabled sif-15'"></span>
                </button>
            </div>
        </div>
    </div>
</ns-deliverable-configuration-body>
<ns-deliverable-configuration-footer
        [enableReset]="enableReset"
        [enableSave]="enableSave"
        [showResetToDefault]="false"
        (saveBtnClickEvent)="onSave()"
        (saveAndCloseBtnClickEvent)="onSaveAndClose()"></ns-deliverable-configuration-footer>
