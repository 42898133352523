import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FactorsService} from '@app/deliverables/factors/services/factors.service';
import {UserService} from '@platform/services/user.service';
import {combineLatest, forkJoin, of, Subscription} from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators';
import {ConceptDataItem, FactorsDeliverableView} from '@app/deliverables/factors/models/factors.model';
import {FactorsFilter} from '@app/deliverables/factors/models/filter.model';
import {DeliverableInsight} from '@platform/deliverable-insight/deliverable-insight.model';
import {Concept} from '@platform/models/concept.model';
import {ScoreCardView} from '@platform/score-cards/score-card-view';
import {DeliverableViewType} from './models/deliverable-view-type.enum';
import {SubgroupMeansService} from './services/subgroup-means.service';
import {ExportPngService} from '@platform/services/export-png.service';
import {SpinnerService} from '@platform/services/spinner.service';
import {DeliverableInsightService} from '@platform/services/deliverable-insight.service';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {FactorsMetaInfo} from './models/view-meta-info.model';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelEvent, MixpanelLabel} from '@src/assets/utils/mixpanel-enum';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {ReportService} from '@platform/services/report.service';
import {ProductDeliverableViewService} from "@platform/services/product-deliverable-view.service";
import {RouterService} from '@platform/services/router.service';
import {InsightService} from '@platform/insights/insights.service';
import {UserViewService} from '@platform/services/user-view.service';
import {UserView} from '@platform/models/user-view.model';
import {FilterService} from '@platform/services/filter.service';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';

@Component({
    selector: 'ns-factors',
    templateUrl: './factors.component.html',
    styleUrls: ['./factors.component.scss']
})

export class FactorsComponent implements OnInit, OnDestroy, ScoreCardView {

    /**
     * Array of static column indexes passed to `<ns-swipe-table>`
     * component.
     *
     * @type {number[]}
     * @member FactorsComponent
     */
    public staticColumns: number[];

    /**
     * FFS deliverable view filter object.
     *
     * @type {FactorsFilter}
     * @member FactorsComponent
     */
    public filter: FactorsFilter;

    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @member FactorsComponent
     */
    public subscriptions: Array<Subscription>;

    /**
     * FFS has 2 views: (1) Concepts view (2) Subgroup Means view
     * Is the view Concepts View
     *
     * @type {boolean}
     * @member FactorsComponent
     */
    public isConceptView = true;

    /**
     * @param isImport Concept params
     */
    public isImport = false;

    /**
     * The Internal user
     */
    public isInternalUser: Boolean;

    /**
     * Disable Insights Button.
     *
     * @type {Boolean}
     * @member FactorsComponent
     */
    public disableBtn: boolean;

    /**
     * toggle insight btn
     * @type {Boolean} isInsightEnable
     * @member FactorsComponent
     */
    public isInsightEnable = false;

    /**
     * Spinner.
     *
     * @type {Boolean}
     * @member FactorsComponent
     */
    public displayProgressSpinner = false;

    /**
     * The deliverable insight data when creating insight.
     * @type {DeliverableInsight} deliverableData
     * @member FactorsComponent
     */
    public deliverableData: DeliverableInsight;

    /**
     * Meta info for Factors
     *
     * @type {FactorsMetaInfo}
     * @member FactorsComponent
     */
    public viewFactorsMetaInfo: FactorsMetaInfo;

    /**
     * Creates an instance of FactorsComponent and initialize
     * ScoreCard Concept object for factors.
     *
     * @type {Concept}
     */
    public scoreCardConcept: Concept;

    /**
     * Array of all column widths passed to `<ns-swipe-table>`
     * component.
     *
     * @type {number[]}
     * @member FactorsComponent
     */
    public columnWidths: number[];

    /**
     * Spinner.
     *
     * @type {Boolean}
     * @member FactorsComponent
     */
    public displayDeliverableHeader = false;

    public deliverableViews: Array<DeliverableView>;

     /**
     * Check from Default Concept View child component
     * if Data is present
     */
     public hasData: Boolean = true;

    /**
     * Feature FLAG for Automatic Headlines.
     *
     * @type {Boolean}
     */
    public isAutomatedHeadlinesEnabled: boolean;

    public isAlcoholStudy = false;

    public isCannabisStudy = false;

    public factors: FactorsDeliverableView;

    /**
     * Take the insight in the HTML format for this Deliverable
     *
     * @type {Boolean}
     */
    public addHTMLToInsight = false;
    public reportId: string;
    public subgroupViews: FactorsDeliverableView;
    public userViews: Array<UserView>;
    public deliverableInfos: Array<DeliverableInfo>;
    public deliverableType = DeliverableType.FACTORS.type;
    public defaultViewFilters: FactorsFilter;

    /**
     * Creates an instance of FactorsComponent and initialize
     * the component data.
     *
     * @constructor
     * @param deliverableInsightService
     * @param viewMetaInfoService
     * @param {FactorsService} factorsService
     * @param filterService
     * @param subgroupMeansService
     * @param cdr
     * @param userService
     * @param mixpanelService
     * @member FactorsComponent
     */
    constructor(
        private deliverableInsightService: DeliverableInsightService,
        private viewMetaInfoService: ViewMetaInfoService,
        private factorsService: FactorsService,
        private subgroupMeansService: SubgroupMeansService,
        private cdr: ChangeDetectorRef,
        private exportPNGService: ExportPngService,
        private spinnerService: SpinnerService,
        private userService: UserService,
        private mixpanelService: MixpanelService,
        private deliverableViewService: DeliverableViewService,
        private productDeliverableViewService: ProductDeliverableViewService,
        private reportService: ReportService,
        private routeService: RouterService,
        private userViewService: UserViewService,
        private insightService: InsightService,
        private filterService: FilterService,
        private deliverableInfoService: DeliverableInfoService,) {
        this.subscriptions = [];
        this.userViews = [];
        this.deliverableInfos = [];
    }

    /**
     * Initialize the component for FFS
     *
     * @member FactorsComponent
     */
    ngOnInit() {
        this.init();
        this.reportService.reloadDeliverable.subscribe(() => {
            this.productDeliverableViewService.clearCache();
            this.deliverableViewService.clearCache();
            this.init();
        });
    }

    init() {
        const deliverableType = DeliverableType.FACTORS.type;
        const user$ = this.userService.getUser();
        const filter$ = this.factorsService.getFactorsFilter();
        const viewMetaInfo$ = this.viewMetaInfoService.get<FactorsMetaInfo>(deliverableType);
        const deliverableView$ = this.deliverableViewService.getDeliverableViews(deliverableType);
        const insightId = this.routeService.getQueryParam('insightId');
        const subscription =
            combineLatest([this.reportService.get(), deliverableView$]).pipe(switchMap(([report, deliverableViews]) => {
                this.deliverableInfos = this.deliverableInfoService.getNonForecastDeliverables(report);
                this.reportId = report.id;
                report.projectType && report.projectType.toLowerCase() === 'alcohol' ? this.isAlcoholStudy = true : this.isAlcoholStudy = false;
                report.projectType && report.projectType.toLowerCase() === 'cannabis' ? this.isCannabisStudy = true : this.isCannabisStudy = false;
                this.factorsService.setInitFlyoutConfigItems(this.isAlcoholStudy, this.isCannabisStudy);
                this.deliverableViews = deliverableViews;
                const conceptProductViewId = this.deliverableViews.find(view => view.viewName === 'concept').productViewId;
                const subgroupProductViewId = this.deliverableViews.find(view => view.viewName === 'subgroup').productViewId;
                this.factorsService.setFactorsConfiguration(report.id);
                return combineLatest([this.productDeliverableViewService.get<FactorsDeliverableView>(report.id, conceptProductViewId), this.productDeliverableViewService.get<FactorsDeliverableView>(report.id, subgroupProductViewId)]);
            })).subscribe(([conceptView, subgroupView]) => {
                this.factorsService.setExcludedKmaFactors(conceptView.concepts);
                this.factors = conceptView;
                this.subgroupViews = subgroupView;
                forkJoin([
                    this.userViewService.fetchReportUserViewsFromAPI(this.reportId),
                    this.insightService.getInsightFilterData<FactorsFilter>(this.reportId, insightId),
                    this.factorsService.loadDefaultFilter(null)
                ]).subscribe(([userViews, insightFilter, defaultViewFilters]) => {
                    this.defaultViewFilters = defaultViewFilters;
                    this.userViews = this.userViewService.setupUserViews(this.reportId, DeliverableType.FACTORS.type, userViews, defaultViewFilters, insightFilter);
                    const insightView = this.userViews.find(it => it.id === this.userViewService.insightViewId);
                    this.selectUserView(insightView ? insightView : this.userViews.find(it => it.isSelected));
                    combineLatest([filter$, viewMetaInfo$, user$]).pipe(debounceTime(10))
                        .subscribe(([filters, viewMetaInfo, user]) => {
                            this.isAutomatedHeadlinesEnabled = user.featureFlags.includes('REPORTING_AUTOMATED_HEADLINES');
                            this.isInternalUser = user.isInternalUser;
                            this.filter = filters;
                            this.viewFactorsMetaInfo = viewMetaInfo;
                            this.filter.deliverableViewType === DeliverableViewType.CONCEPT ? this.isConceptView = true : this.isConceptView = false;
                            this.staticColumns = [0];
                            const selectedConcepts = filters.concepts.filter(c => c.isSelected && !c.isImported).map(c => c.id);
                            const selectedSubgroups = filters.subgroups.filter(s => s.isSelected).map(s => s.id);
                            const selectedFactors = filters.show.factorsOptions.filter(factor => factor.isSelected).map(factor => factor.name);
                            if (this.isConceptView) {
                                (selectedConcepts.length === 0 || filters.concepts.length === 0) || selectedSubgroups.length === 0 ? this.disableBtn = true : this.disableBtn = false;
                            }
                            if (!this.isConceptView) {
                                selectedFactors.length === 0 || selectedSubgroups.length === 0 || filters.concepts.length === 0 ? this.disableBtn = true : this.disableBtn = false;
                            }
                            if (this.isInsightEnable && this.isAutomatedHeadlinesEnabled) {
                                this.openInsightCreationForm();
                            }
                            if (!this.factorsService.filtersAreConfigured(filters)) {
                                this.filterService.update(this.factorsService.changeFilterAsPerConfig(filters));
                            }
                        });
                });

            });
        this.reportService.factorsConfigChanged$.subscribe((changedConfigs) => {
            if (changedConfigs) {
                const changedViewName = this.filter.compare.find(option => option.isSelected).id;
                const changedViewProdViewId = this.deliverableViews.find(view => view.viewName === changedViewName).productViewId;
                this.productDeliverableViewService.clearCache();
                this.productDeliverableViewService.get<FactorsDeliverableView>(this.reportId, changedViewProdViewId).subscribe(view => {
                    if(changedViewName === 'concept') {
                        this.factors = view;
                    } else if(changedViewName === 'subgroup') {
                        this.subgroupViews = view;
                    }
                    this.cdr.detectChanges();
                    this.factorsService.setFactorsConfiguration(null, changedConfigs);
                    this.factorsService.setExcludedKmaFactors(this.factors.concepts);
                    this.filter = this.factorsService.changeFilterAsPerConfig(this.filter);
                    this.filterService.update(this.filter);
                });
            }
        });
        const columnWidthSubscription = this.subgroupMeansService.columnWidthsSubject.subscribe(columnWidth => {
            this.columnWidths = columnWidth;
            this.cdr.detectChanges();
        });
        const deliverableHeaderSubscription = this.factorsService.deliverableHeaderSubject.subscribe(showHeader => {
            this.displayDeliverableHeader = showHeader;
            this.cdr.detectChanges();
        });
        this.subscriptions.push(subscription, columnWidthSubscription, deliverableHeaderSubscription);
    }

     /**
     * Checks if Deliverable Data is present from the view
     * @param dataPresence
     */
     checkDeliverableDataPresence(dataPresence) {
        this.hasData = dataPresence;
    }

    /**
     * Method that is triggered when user view is changed. This will in turn update the filter model in the store.
     * */
    selectUserView(userView: UserView): void {
        this.filter = userView.filter as FactorsFilter;
        this.filter = this.factorsService.changeFilterAsPerConfig(this.filter);
        const conceptsAdded = this.userViewService.updateUserViewConceptsWithDefaultViewConcepts(this.filter , this.defaultViewFilters);
        if (userView.id !== 'Default View') {
            this.filterService.update({
                ...this.filter,
                concepts: conceptsAdded
            });
        } else {
            this.filterService.update(userView.filter);
        }
    }

    /**
     * Updates displayed columns.
     *
     * @param {number[]} visibleColumns array of visible column indexes.
     */
    public update(visibleColumns: number[]): void {
        this.factorsService.visibleColumnsSubject.next(visibleColumns);
    }

    /**
     * Triggers mixpanel event on clicking 'Next' button in table
     *
     * @param none
     */
    nextPage(): void {
        this.mixpanelService.track(MixpanelLabel.factors, MixpanelEvent.nextPage);
    }

    /**
     * Triggers mixpanel event on clicking 'Previous' button in table
     *
     * @param none
     */
    previousPage(): void {
        this.mixpanelService.track(MixpanelLabel.factors, MixpanelEvent.previousPage);
    }

    /**
     * capture screen layout and export as png.
     *
     */
    exportAsPNG() {
        this.mixpanelService.track(MixpanelLabel.factors, MixpanelEvent.exportAsPNG);
        this.displayProgressSpinner = true;
        this.exportPNGService.exportPNG();
        this.spinnerService.getSpinnerObs().subscribe((loading) => this.displayProgressSpinner = loading);
    }

    /**
     * toggle between headers and insight creation form.
     *
     */
    openInsightCreationForm() {
        setTimeout(() => {
            this.isInsightEnable = true;
            const selectedCompareFilter = this.filter.compare.find(it => it.isSelected);
            const deliverableView = this.deliverableViews.find(it => it.viewName === selectedCompareFilter.id);
            this.addHTMLToInsight = true;
            this.deliverableData = {
                title: this.isAutomatedHeadlinesEnabled ? this.deliverableInsightService.generateInsightTitle('Factors for Success') : '',
                deliverable: {
                    deliverableViewId: deliverableView.id,
                    filter: this.filter,
                    metaInfo: this.viewFactorsMetaInfo,
                    insightHTML: '',
                },
            };
        },100);
    }

    /**
     * Close insight form
     */
    closeInsight() {
        this.isInsightEnable = false;
    }

    /**
     * open Import
     */
    openImport() {
        this.isImport = true;
    }

    /**
     * Cancel Import
     */
    cancelImport() {
        this.isImport = false;
    }

    /**
     * Apply Import
     */
    applyImport() {
        this.isImport = false;
    }

    /**
     * Cleanup hook.
     *
     * @member FactorsComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * Action that is triggered when the deliverable info is changed.
     *
     * @param deliverableInfo
     */
    onDeliverableChange(deliverableInfo: DeliverableInfo): void {
        this.deliverableInfoService.routeToDeliverable(deliverableInfo);
    }

}
