import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'ns-deliverable-configuration-footer',
    templateUrl: './deliverable-configuration-footer.component.html',
    styleUrls: ['./deliverable-configuration-footer.component.scss']
})
export class DeliverableConfigurationFooterComponent {

    @Input() enableSave;
    @Input() enableReset;
    @Input() showResetToDefault = true;

    @Output() resetBtnClickEvent = new EventEmitter();
    @Output() saveBtnClickEvent = new EventEmitter();
    @Output() saveAndCloseBtnClickEvent = new EventEmitter();

    onReset(): void {
        this.resetBtnClickEvent.emit();
    }

    onSave(): void {
        this.saveBtnClickEvent.emit();
    }

    onSaveAndClose(): void {
        this.saveAndCloseBtnClickEvent.emit();
    }
}
