import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {SubgroupService} from '@platform/services/subgroup.service';
import {DeliverableViewType} from '../models/deliverable-view-type.enum';
import {Subgroup} from '@platform/models/subgroup.model';
import {WordCloudFilter} from '../models/filter.model';
import {QuestionBySubgroup, WordCloudDeliverableView} from '../models/word-cloud.model';
import {WordCloudService} from '../services/word-cloud.service';

@Component({
    selector: 'ns-word-cloud-by-subgroups',
    templateUrl: './compare-by-subgroups.component.html',
    styleUrls: ['./compare-by-subgroups.component.scss']
})
export class CompareBySubgroupsComponent implements OnInit, OnDestroy {

    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @memberof CompareBySubgroupsComponent
     */
    private subscriptions: Array<Subscription>;

    /**
     * Word Cloud filter object.
     *
     * @type {WordCloudFilter}
     * @memberof CompareBySubgroupsComponent
     */
    public filter: WordCloudFilter;

    /**
     * The deliverable view object.
     *
     * @type {WordCloudDeliverableView}
     * @memberOf CompareBySubgroupsComponent
     */
    public wordCloud: WordCloudDeliverableView;


    /***
     *  Subgroup Concepts Verbatims object
     *
     * @memberOf CompareBySubgroupsComponent
     */
    public questionSubgroups: Array<QuestionBySubgroup>;

    public showWordCloud: Boolean = false;

    /**
     * Creates an instance of AttributesComponent and initialize
     * the component data.
     *
     * @constructor
     * @param {WordCloudService} wordCloudService
     * @param {SubgroupService} subgroupService
     * @param {ChangeDetectorRef} cdr
     * @memberof CompareBySubgroupsComponent
     */
    constructor(
        private wordCloudService: WordCloudService,
        private subgroupService: SubgroupService,
        private cdr: ChangeDetectorRef
    ) {
        this.subscriptions = [];
        this.questionSubgroups = [];
    }

    /**
     * Initialize component and load data
     *
     * @member CompareBySubgroupsComponent
     */
    ngOnInit(): void {
        const wordCloud$ = this.wordCloudService.getWordCloud();
        const subgroups$ = this.subgroupService.getReportSubgroups();
        const subscription = combineLatest([subgroups$, wordCloud$])
            .subscribe(([subgroups, wordCloud]) => {
                if (wordCloud.viewName === DeliverableViewType.WORD_CLOUD_SUBGROUPS) {
                    if (this.wordCloud && this.wordCloud.questionsBySubgroup.length !== wordCloud.questionsBySubgroup.length) {
                        this.showWordCloud = false;
                    }
                    this.wordCloud = wordCloud;
                    this.questionSubgroups = this.addSubgroupName(subgroups, wordCloud.questionsBySubgroup);
                    this.cdr.detectChanges();
                    this.showWordCloud = true;
                }
            });
        this.subscriptions.push(subscription);
    }

    /**
     * Adds subgroup name and position to the questions by subgroup array.
     * @param {Array<Subgroup>} subgroups
     * @param {Array<QuestionBySubgroup>} questionsBySubgroup
     * @member CompareBySubgroupsComponent
     */
    addSubgroupName(subgroups: Array<Subgroup>, questionsBySubgroup: Array<QuestionBySubgroup>) {
        const data = questionsBySubgroup.reduce((acc, question: QuestionBySubgroup) => {
            acc.push(Object.assign({}, question, {
                subgroupName: subgroups.find(it => it.segmentId === question.subgroupId).name,
                position: subgroups.find(it => it.segmentId === question.subgroupId).position
            }))
            return acc;
        }, []);

        data.sort(function (a, b) {
            return a.position - b.position;
        });
        return data;
    }

    /**
     * Returns unique id for the loop to be refreshed.
     *
     * @param {number} index the performance table column for loop index
     * @param {any} item the colHeader object
     */
    public trackItem(index: number, item: any): string {
        return `${index}-${item.subgroupId ? item.subgroupId : 0}`;
    }


    /**
     * Cleanup the component on removing from the UI.
     *
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
