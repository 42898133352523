import {Subgroup} from '@platform/models/subgroup.model';
import {FilterService} from '@platform/services/filter.service';
import {Injectable} from '@angular/core';
import {Observable, of, zip} from 'rxjs';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {switchMap} from 'rxjs/operators';
import {ReportService} from '@platform/services/report.service';
import {SubgroupService} from '@platform/services/subgroup.service';
import {ConceptService} from '@platform/services/concept.service';
import {Concept} from '@platform/models/concept.model';
import {AppConfigService} from '@app/core/config/app-config.service';
import {Report} from '@platform/models/report.model';
import {LocaleService} from '@platform/services/locale.service';
import {WordCloudDeliverableView} from '../models/word-cloud.model';
import {FilterItem, WordCloudFilter} from '../models/filter.model';
import {DeliverableViewType} from "@app/deliverables/word-cloud/models/deliverable-view-type.enum";
import {defaultWordCloudFilter} from "@app/deliverables/word-cloud/models/default-word-cloud-filter";
import {DeliverableType} from "@app/deliverables/deliverable-type.enum";
import {DeliverableView} from "@platform/models/deliverable-view.model";
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';

/**
 * `WordCloudService` has all data operations for fetching word cloud
 * and word cloud filter data.
 *
 * @export
 * @class WordCloudService
 */
@Injectable()
export class WordCloudService {
    private baseURI: string;

    /**
     * Creates an instance of WordCloudService.
     *
     * @constructor
     * @param {DeliverableViewService} deliverableViewService
     * @param {ReportService} reportService
     * @param {ConceptService} conceptService
     * @param {SubgroupService} subgroupService
     * @param {FilterService} filterService
     * @param cs
     * @memberof WordCloudService
     */
    constructor(
        private deliverableViewService: DeliverableViewService,
        private productDeliverableViewService: ProductDeliverableViewService,
        private reportService: ReportService,
        private conceptService: ConceptService,
        private subgroupService: SubgroupService,
        private filterService: FilterService,
        private localeService: LocaleService,
        private cs: AppConfigService) {
        this.baseURI = `${this.cs.config.reporting.url}`;
    }

    /**
     * Returns an observable of `WordCloudDeliverableView` data.
     *
     * @returns {Observable<WordCloudDeliverableView>}
     * @memberof WordCloudService
     */
    public getWordCloud(): Observable<WordCloudDeliverableView> {
        const report$ = this.reportService.get();
        const filter$ = this.getWordCloudFilter();
        const deliverableViews$ = this.deliverableViewService.getDeliverableViews(DeliverableType.WORD_CLOUD.type);
        return report$.pipe(switchMap(report => {
            return filter$.pipe(
                switchMap(filter => {
                    return deliverableViews$.pipe(switchMap((deliverableViews) => {
                        const selectedCompare = filter.compare.find(o => o.isSelected);
                        const selectedView = deliverableViews.find(it => it.viewName === selectedCompare.id);
                        const views$: Observable<WordCloudDeliverableView> = this.productDeliverableViewService.get<WordCloudDeliverableView>(report.id, selectedView.productViewId);
                        return views$.pipe(switchMap(view => {
                            return of(this.filter(filter, view));
                        }))
                    }));
                })
            )
        }));
    }

    /**
     * Filter projection function for filtering the word cloud data and returns
     * filtered `WordCloudDeliverableView` data.
     *
     * @private
     * @param {WordCloudFilter} filter
     * @param {WordCloudDeliverableView} data
     * @returns {WordCloudDeliverableView}
     * @memberof WordCloudService
     */
    private filter(filter: WordCloudFilter, data: WordCloudDeliverableView): WordCloudDeliverableView {
        const selectedConcepts = filter.concepts.filter(c => c.isSelected).map(c => c.id);
        const selectedSubgroups = filter.subgroups.filter(s => s.isSelected).map(s => s.id);
        const selectedCompare = filter.compare.find(o => o.isSelected).id;
        const selectedQuestion = filter.questions.find(q => q.isSelected).id;
        const selectedLocale = filter.locales.find(l => l.isSelected).id;
        const questions = [];

        // When "Compare By" filter is "WordCloudConcepts" then we should filter the questionsByConcept
        // and keep all relevant subgroupVervatims (according to selectedSubgroups)
        // When "Compare By" filter is "WordCloudSubgroups" then we should filter the questionsBySubgroup
        // and keep all relevant conceptVervatims (according to selectedConcepts)
        if (selectedCompare === DeliverableViewType.WORD_CLOUD_CONCEPTS) {
            const filteredQuestionList = data.questionsByConcept.filter(it => {
                return (it.questionId === selectedQuestion && selectedConcepts.includes(it.conceptId));
            });
            const groupedConceptsList: any = this.groupFilteredQuestionList(filteredQuestionList);
            for (const groupedConcept of groupedConceptsList) {
                const subgroupsVerbatims = [];
                for (const question of groupedConcept) {
                    subgroupsVerbatims.push(question.subgroupsVerbatims.filter(sg => selectedSubgroups.includes(sg.entityId)));
                }
                questions.push(Object.assign({}, groupedConcept[0], {subgroupsVerbatims: subgroupsVerbatims}));
            }
        } else {
            const filteredQuestionList = data.questionsBySubgroup.filter(it => {
                return (it.questionId === selectedQuestion && selectedSubgroups.includes(it.subgroupId));
            });
            const groupedSubgroupsList: any = this.groupFilteredQuestionList(filteredQuestionList, false);
            for (const groupedSubgroup of groupedSubgroupsList) {
                const conceptsVerbatims = [];
                for (const question of groupedSubgroup) {
                    conceptsVerbatims.push(question.conceptsVerbatims.filter(c => selectedConcepts.includes(c.entityId)));
                }
                questions.push(Object.assign({}, groupedSubgroup[0], {conceptsVerbatims: conceptsVerbatims}));
            }
        }
        return Object.assign({}, data, selectedCompare === DeliverableViewType.WORD_CLOUD_CONCEPTS ? {questionsByConcept: questions} : {questionsBySubgroup: questions});
    }

    /**
     * Returns Grouped Filtered Question.
     *
     * @param filteredQuestionList
     * @param useConceptId
     */
    groupFilteredQuestionList(filteredQuestionList: any, useConceptId = true) {
        const grouped = {};
        filteredQuestionList.forEach(item => {
            const key = useConceptId ? item.conceptId : item.subgroupId;
            if (!grouped[key]) {
                grouped[key] = [];
            }
            grouped[key].push(item);
        });
        return Object.values(grouped);
    }

    /**
     * Returns observable of word cloud filter data.
     *
     * @returns {Observable<WordCloudFilter>}
     * @memberof WordCloudService
     */
    public getWordCloudFilter(): Observable<WordCloudFilter> {
        const deliverableType = DeliverableType.WORD_CLOUD.type;
        return this.filterService.get<WordCloudFilter>(deliverableType);
    }

    /**
     * Loads default word cloud filter data.
     *
     * @memberof WordCloudService
     */
    public loadDefaultFilter(concept ?: Concept): Observable<WordCloudFilter> {
        const defaultFilter: WordCloudFilter = Object.assign({}, defaultWordCloudFilter);
        const report$ = this.reportService.get();
        const concepts$ = this.conceptService.getReportDeliverableConcepts(DeliverableType.WORD_CLOUD.type);
        const subgroups$ = this.subgroupService.getReportSubgroups(DeliverableType.WORD_CLOUD.type);
        const deliverableViews$ = this.deliverableViewService.getDeliverableViews(DeliverableType.WORD_CLOUD.type);
        return zip(report$, concepts$, subgroups$, deliverableViews$).pipe(switchMap(result => {
            defaultFilter.countries = [result[0].country];
            const concepts = concept ? [concept] : result[1];
            const deliverableViews: Array<DeliverableView> = result[3];
            defaultFilter.locales = this.getDefaultLocales(result[0] as Report);
            defaultFilter.concepts = this.getDefaultConceptFilters(concepts as Array<Concept>, deliverableViews);
            defaultFilter.subgroups = this.getDefaultSubgroupFilters(result[2] as Array<Subgroup>, deliverableViews);
            defaultFilter.questions = this.getDefaultQuestions(deliverableViews);
            this.filterService.update(defaultFilter);
            return of(defaultFilter);
        }));
    }

    /**
     * Returns concept filter items.
     *
     * @private
     * @param {Array<Concept>} concepts
     * @param deliverableViews
     * @returns {Array<FilterItem>}
     * @memberof WordCloudService
     */
    private getDefaultConceptFilters(concepts: Array<Concept>, deliverableViews: Array<DeliverableView>): Array<FilterItem> {
        const conceptFilterItems: Array<FilterItem> = [];
        const localeFilterItems: Array<FilterItem> = [];
        const conceptDeliverableViewType: DeliverableViewType = DeliverableViewType.WORD_CLOUD_CONCEPTS;
        const view = deliverableViews.find(v => v.viewName === conceptDeliverableViewType);
        const distinctTranslations = view.metaInfo.locale;

        distinctTranslations.forEach(locale =>
            localeFilterItems.push({
                name: this.localeService.getLocaleLabel(locale),
                id: locale,
                isSelected: false
            })
        );

        // // select by default first locale
        localeFilterItems[0].isSelected = true;

        for (const item of concepts) {
            conceptFilterItems.push({
                name: item.name,
                id: item.exerciseConceptId,
                isSelected: true,
                position: item.position,
                translations: localeFilterItems
            });
        }
        return conceptFilterItems;
    }

    /**
     * Returns subgroup filter items.
     *
     * @private
     * @param {Array<Subgroup>} subgroups
     * @param deliverableViews
     * @returns {Array<FilterItem>}
     * @memberof WordCloudService
     */
    private getDefaultSubgroupFilters(subgroups: Array<Subgroup>, deliverableViews: Array<DeliverableView>): Array<FilterItem> {
        const subgroupFilterItems: Array<FilterItem> = [];
        const localeFilterItems: Array<FilterItem> = [];
        const subgroupDeliverableViewType: DeliverableViewType = DeliverableViewType.WORD_CLOUD_SUBGROUPS;
        const view = deliverableViews.find(v => v.viewName === subgroupDeliverableViewType);
        const distinctTranslations = view.metaInfo.locale;
        distinctTranslations.forEach(locale =>
            localeFilterItems.push({
                name: this.localeService.getLocaleLabel(locale),
                id: locale,
                isSelected: false
            })
        );

        // select by default first translation
        localeFilterItems[0].isSelected = true;

        for (const item of subgroups) {
            subgroupFilterItems.push({
                name: item.name,
                id: item.segmentId,
                isSelected: false,
                position: item.position,
                translations: localeFilterItems
            });
        }
        // select by default first subgroup
        subgroupFilterItems[0].isSelected = true;
        return subgroupFilterItems;
    }


    /**
     * Returns questions filter items.
     *
     * @private
     * @returns {Array<FilterItem>}
     * @memberof WordCloudService
     * @param deliverablesViews
     */
    public getDefaultQuestions(deliverablesViews: Array<DeliverableView>): Array<FilterItem> {
        const questionFilterItems: Array<FilterItem> = [];
        const distinctQuestionIds = deliverablesViews[0].metaInfo.questions;

        distinctQuestionIds.forEach(question =>
            questionFilterItems.push({
                name: question.questionText.toString(),
                id: question.questionId,
                isSelected: false,
                position: question.questionPosition
            })
        );
        questionFilterItems.sort(function (a, b) {
            return a.position - b.position;
        });
        // select by default first question
        questionFilterItems[0].isSelected = true;
        return questionFilterItems;
    }

    /**
     * Returns locale filter items.
     *
     * @private
     * @returns {Array<FilterItem>}
     * @memberof WordCloudService
     * @param report
     */
    public getDefaultLocales(report: Report): Array<FilterItem> {
        const localeFilterItems: Array<FilterItem> = [];
        const distinctsLocales = report.locales;

        distinctsLocales.forEach(locale =>
            localeFilterItems.push({
                name: locale,
                id: locale,
                isSelected: false
            })
        );

        // select by default first locale
        localeFilterItems[0].isSelected = true;
        return localeFilterItems;
    }

    /**
     * Gets the selections for insights
     * @param filter The current filter for this deliverable
     * @param nativeElement The native element
     */
    public getSelectors(filter: WordCloudFilter, nativeElement: any): any {
        const selectors = [];
        const compareFilter = filter.compare.find(it => it.isSelected === true).id;
        let count = filter.concepts.filter(it => it.isSelected === true).length;
        if (compareFilter === 'WordCloudSubgroups') {
            count = filter.subgroups.filter(it => it.isSelected === true).length;
        }
        for (let i = 0, len = count; i !== len; ++i) {
            const elementId = `#word-cloud-container-${i}`;
            const element = nativeElement.querySelector(elementId);
            if (element) {
                selectors.push(elementId);
            }
        }
        return selectors && selectors.length > 0 ? selectors.slice(0, 10) : [];
    }
}
