<ng-container *ngIf="clickDataLoadComplete && hasShownConcepts && hasShownSubgroups; else noData">
  <div *ngFor="let concept of strenghtWatchouts.concepts let i = index; trackBy: identify" id="{{'sw-concept-' + concept.exerciseConceptId}}" class="strength-and-watchouts-export-png">
    <ng-container *ngIf="i === 0">
      <sw-click-data-concept-row [concept]="concept" [concepts]="strenghtWatchouts.concepts" [index]="i" [filter]="filter"
                                 [aiZoneSummaries]="aiZoneSummaries" [aiSubgroupSummaries]="aiSubgroupSummaries">

      </sw-click-data-concept-row>
    </ng-container>
  </div>
</ng-container>
<ng-template #noData>
    <ns-deliverables-empty-state *ngIf="clickDataLoadComplete" sifIcon="sif-no-results"
                                 message="There are no Results" message2="Try refining the filters to populate data.">
    </ns-deliverables-empty-state>
</ng-template>
