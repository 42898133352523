import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {TgaService} from '@app/deliverables/target-group-analysis/services/tga.service';
import {TGADeliverableView} from '@app/deliverables/target-group-analysis/models/tga.model';
import {TGAFilter} from '@app/deliverables/target-group-analysis/models/filter.model';
import {MatSort} from '@angular/material/sort';
import {SubgroupService} from '@platform/services/subgroup.service';
import {ReportService} from '@platform/services/report.service';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelEvent, MixpanelLabel} from '@src/assets/utils/mixpanel-enum';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {debounceTime, skipWhile} from 'rxjs/operators';
import {Concept} from '@platform/models/concept.model';
import {Report} from '@platform/models/report.model';
import {Subgroup} from '@platform/models/subgroup.model';


@Component({
  selector: 'ns-target-group-new',
  templateUrl: './target-group-new.component.html',
  styleUrls: ['./target-group-new.component.scss']
})
export class TargetGroupNewComponent implements OnInit, OnDestroy  , OnChanges{

    @Input() public report: Report;

    @Input() public filter: TGAFilter;

    @Input() public tgaDeliverableView: TGADeliverableView;

    @Input() public subgroups: Subgroup[];


  /**
   * Subscription objects for cleanup.
   *
   * @type {Array<Subscription>}
   * @member AttributesComponent
   */
  public subscriptions: Array<Subscription>;
  /**
   * Datasource object for the angular material table.
   *
   * @member AttributesComponent
   */

  public dataSource: MatTableDataSource<any>;
  /**
   * Array of displayed columns keys.
   *
   * @type {Array<string>}
   * @member AttributesComponent
   */
  public displayedColumns: Array<string>;

  /**
   * Array of static column indexes passed to `<ns-swipe-table>`
   * component.
   *
   * @type {number[]}
   * @member AttributesComponent
   */
  public staticColumns: number[];
  /**
   * Array of all column widths passed to `<ns-swipe-table>`
   * component.
   *
   * @type {number[]}
   * @member AttributesComponent
   */
  public columnWidths: number[];

  /**
   * All column headers for the attributes table.
   *
   * @type {Array<any>}
   * @member AttributesComponent
   */
  public colHeaders: Array<any>;
  public oldView: boolean;
  /**
   * View child for angular material table sorting.
   *
   * @type {MatSort}
   * @memberOf AttributesComponent
   */
  @ViewChild(MatSort) sort: MatSort;
  constructor(private tgaService: TgaService,
              private reportService: ReportService,
              private subgroupService: SubgroupService,
              private mixpanelService: MixpanelService) {
    this.displayedColumns = [];
    this.subscriptions = [];
    this.staticColumns = [];
  }

  ngOnInit(): void {
      this.initializeData();
  }

  initializeData() {
      const tga$ = this.tgaService.getTGA();
      const filter$ = this.tgaService.getTGAFilter();
      const subgroups$ = this.subgroupService.getReportSubgroups();
      const report$ = this.reportService.get();
      const subscription = combineLatest([tga$, filter$, subgroups$, report$])
          .subscribe(([tga, filters, subgroups, report]) => {
                  this.tgaDeliverableView = tga;
                  this.filter = filters;
                  const [colHeaders, chartData] = this.tgaService.getTableData(filters, tga, subgroups, report);
                  this.dataSource = new MatTableDataSource(chartData);
                  this.colHeaders = colHeaders;
                  this.staticColumns = [0];
                  this.columnWidths = this.getColumnWidths(colHeaders);
                  this.oldView = tga.previousAnalysis;
              }
          );
      this.subscriptions.push(subscription);
  }

  /**
   * Return an array of column widths for all columns in the table.
   *
   * @param {any[]} colHeaders The array of headers
   */
  private getColumnWidths(colHeaders: any[]): number[] {
    const minWidth =  300;
    const columnWidths = Array(colHeaders.length).fill(minWidth);
    columnWidths[0] = 345;
    return columnWidths;
  }

  /**
   * Angular material sorting data accessor function for attributes.
   *
   * @param {*} data
   * @param {string} sortHeaderId
   * @returns {(string | number)}
   * @memberOf ChartDataService
   */
  public matSortingDataAccessor(data: any, sortHeaderId: string): string | number {
    const cellData = data[sortHeaderId];
    if (!cellData) {
      return 0;
    }
    return cellData.actualValue;
  }

  /**
   * Updates displayed columns.
   *
   * @param {number[]} visibleColumns array of visible column indexes.
   */
  public update(visibleColumns: number[]): void {
    const columns = [];
    this.colHeaders.forEach((item, index) => {
      if (visibleColumns.indexOf(index) >= 0) {
        columns.push(item.name);
      }
    });
    this.displayedColumns = columns;
  }

  /**
   * Triggers mixpanel event on clicking 'Next' button in table
   *
   * @param none
   */
  nextPage(): void {
    this.mixpanelService.track(MixpanelLabel.targetGroup, MixpanelEvent.nextPage);
  }

  /**
   * Triggers mixpanel event on clicking 'Previous' button in table
   *
   * @param none
   */
  previousPage(): void {
    this.mixpanelService.track(MixpanelLabel.targetGroup, MixpanelEvent.previousPage);
  }

  /**
   * Returns unique id for the loop to be refreshed.
   *
   * @param {number} index the questions table column for loop index
   * @param {any} item the colHeader object
   */
  public trackItem(index: number, item: any): string {
    return `${index}-${item.id ? item.id : 0}`;
  }
  /**
   * return class as per value
   * @param value
   */
  public getTargetGroupClass(value: number): string{
    let className = "";
    switch (value) {
      case 1:
        className =  "sif-circle-outline";
        break;
      case 2:
        className =  "sif-circle-half";
        break;
      case 3:
        className =  "sif-circle-fill";
        break;
      default:
        className =  "";
        break;
    }
    return className;
  }

  /**
   * Cleanup hook.
   *
   * @memberOf SurveyQuestionComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

    ngOnChanges(changes: SimpleChanges): void {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
      this.initializeData();
    }
}
