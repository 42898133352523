<div class="deliverable-container tga-content">
  <ng-container *ngIf="!scoreCardConcept">
    <div class="sticky-header-container header-filter-container" [ngClass]="{'hide': isInsightEnable}">
      <div class="sticky-header-container">
        <!--Swipe -->
          <ns-deliverable-nav-header [deliverableInfos]="deliverableInfos" (deliverableChanged)="onDeliverableChange($event)">
          <ns-kebab-menu>
            <ul class="kebab-menu-list">
              <li><span (click)="exportAsPNG()">{{ 'export.as.png' | translate }}</span></li>
            </ul>
          </ns-kebab-menu>
          <span rel="tooltip" matTooltipPosition="above" matTooltip=" {{'create.insights' | translate }}" *ngIf="isInternalUser" [ngClass]="{'disable-insight-btn': disableBtn}" class="insight-icon sif sif-insight" (click)="openInsightCreationForm()"></span>
            <span saveUserView>
                <ns-save-user-view class="save-views"
                                   [(views)]="userViews"
                                   [filter]="filter"
                                   [reportId]="report?.id"
                                   [deliverableType]="deliverableType"
                                   (viewsChange)="userViews=$event"
                                   (selectUserView)="selectUserView($event)"></ns-save-user-view>
            </span>
          </ns-deliverable-nav-header>
      </div>
    </div>
    <ns-deliverable-insight [enabled]="isInsightEnable" [deliverableData]="deliverableData" (close)="closeInsight()" [isHTMLInsight]="addHTMLToInsight"></ns-deliverable-insight>
  </ng-container>


  <div id="deliverable-container">
    <div class="filters-sticky-container" [ngClass]="{'hide': isInsightEnable}">
      <ns-tga-filter [concept]="scoreCardConcept"></ns-tga-filter>
    </div>
    <div *ngIf="!disableBtn && previousAnalysis" class="insight-html-root">
      <ns-target-group-old></ns-target-group-old>
    </div>
    <div *ngIf="!disableBtn && !previousAnalysis && filter && filter.concepts && filter.subgroups" class="insight-html-root">
      <ns-target-group-new [filter] ="filter" [report] ="report" [subgroups]="subgroups" [tgaDeliverableView]="tgaDeliverableView"></ns-target-group-new>
    </div>
      <ng-container *ngIf="disableBtn">
          <ns-deliverables-empty-state  sifIcon="sif-no-results"
                                        message="There are no Results" message2="Try refining the filters to populate data.">
          </ns-deliverables-empty-state>
      </ng-container>
  </div>
</div>
<ns-progress-spinner class="example-margin" [display]="displayProgressSpinner">
</ns-progress-spinner>
