import {Injectable} from '@angular/core';
import {Concept} from '@platform/models/concept.model';
import {Report} from '@platform/models/report.model';
import {Subgroup} from '@platform/models/subgroup.model';
import {ConceptService} from '@platform/services/concept.service';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {FilterService} from '@platform/services/filter.service';
import {ReportService} from '@platform/services/report.service';
import {StringHelper} from '@platform/services/string-helper.service';
import {SubgroupService} from '@platform/services/subgroup.service';
import {DeliverableType} from "@app/deliverables/deliverable-type.enum";
import * as numeral from 'numeral';
import {combineLatest, Observable, of, Subject, zip} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {CompareView} from '../models/compare-view-enum';
import {defaultSurveyQuestionFilter} from '../models/default-survey-question-filter';
import {FilterItem, SurveyQuestionFilter} from '../models/filter.model';
import {QuestionDataItem, SurveyQuestionDeliverableView} from '../models/survey-question.model';
import {SurveyQuestionsMetaInfo} from '../models/view-meta-info.model';
import {ProductDeliverableViewService} from "@platform/services/product-deliverable-view.service";
import {DeliverableView} from "@platform/models/deliverable-view.model";
import {MathService} from '@src/assets/utils/math.service';

/**
 * `SurveyQuestionService` has all data operations for fetching Question
 * and Survey Question filter data.
 *
 * @export
 * @class SurveyQuestionService
 */
@Injectable()
export class SurveyQuestionService {

    public newDefaultQuestions: FilterItem[];

    /**
     * Creates an instance of SurveyQuestionService.
     *
     * @constructor
     * @param {StringHelper} stringHelper
     * @param {DeliverableViewService} deliverableViewService
     * @param {ReportService} reportService
     * @param {ConceptService} conceptService
     * @param {SubgroupService} subgroupService
     * @param {FilterService} filterService
     * @memberOf SurveyQuestionService
     */
    constructor(
        private stringHelper: StringHelper,
        private deliverableViewService: DeliverableViewService,
        private reportService: ReportService,
        private conceptService: ConceptService,
        private subgroupService: SubgroupService,
        private filterService: FilterService,
        private productDeliverableViewService: ProductDeliverableViewService,
        private utilsService: MathService
    ) {
    }

    /**
     * Returns a list of questions that filter out hidden questions.
     *
     * @returns {Observable<SurveyQuestionDeliverableView>}
     * @memberOf SurveyQuestionService
     */
    public getFilteredQuestionList(filterQues, deliverableViews): void {
        const includedQuesNameList = deliverableViews.filter(ques => ques.metaInfo.excluded === undefined || ques.metaInfo.excluded === 'false').map(ques => ques.viewName);
        this.newDefaultQuestions = filterQues.filter(ques => includedQuesNameList.includes(ques.id));
    }

    /**
     * Returns an observable of `DeliverableView` data.
     *
     * @returns {Observable<SurveyQuestionDeliverableView>}
     * @memberOf SurveyQuestionService
     */
    public getSurveyQuestion(): Observable<SurveyQuestionDeliverableView> {
        const deliverableType = DeliverableType.SURVEY_QUESTION.type;
        const deliverableViews$ = this.deliverableViewService.getDeliverableViews(deliverableType);
        const concepts$ = this.conceptService.getReportDeliverableConcepts(deliverableType);
        const filter$ = this.getSurveyQuestionFilter();
        const defaultData$ = this.getDefaultFilterData();
        return combineLatest([filter$, deliverableViews$, defaultData$]).pipe(
            switchMap(([filter, deliverableViews, defaultData]) => {
                this.getFilteredQuestionList(defaultData.questions.map(defQues => Object.assign({}, defQues, {isShow: true}, {isSelected: (filter.questions.find(ques => ques.id === defQues.id)?.isSelected)??false})), deliverableViews);
                const questionId = (this.newDefaultQuestions.map(dQues => dQues.id).includes(filter.questions.find(fQues => fQues.isSelected).id))?filter.questions.find(fQues => fQues.isSelected).id:this.newDefaultQuestions[0].id;
                const selectedDeliverableView = deliverableViews.find(it=>it.viewName==questionId);
                return this.reportService.get().pipe(switchMap(report => {
                    return this.productDeliverableViewService.get<SurveyQuestionDeliverableView>(report.id, selectedDeliverableView.productViewId);
                }));
            })
        );
    }

    /**
     * Returns observable of survey question filter data.
     *
     * @returns {Observable<SurveyQuestionFilter>}
     * @memberOf SurveyQuestionService
     */
    public getSurveyQuestionFilter(): Observable<SurveyQuestionFilter> {
        const deliverableType = DeliverableType.SURVEY_QUESTION.type;
        return this.filterService.get<SurveyQuestionFilter>(deliverableType);
    }

    /**
     * Filter projection function for filtering the Survey question data and returns
     * filtered `SurveyQuestionDeliverableView` data.
     *
     * @private
     * @param {SurveyQuestionFilter} filter
     * @param questionDetails
     * @returns {SurveyQuestionDeliverableView}
     * @memberOf SurveyQuestionService
     */
    public filter(filter: SurveyQuestionFilter, questionDetails: SurveyQuestionDeliverableView): SurveyQuestionDeliverableView {
        const selectedConcepts = filter.concepts.filter(c => c.isSelected).map(c => c.id);
        const selectedSubgroups = filter.subgroups.filter(s => s.isSelected).map(s => s.id);
        const filteredQuestions = [];
        let filteredQuestion;
        let filteredDataItems;
        let filteredView;
        const question = questionDetails;
        filteredDataItems = question.data.filter(
            d => ([-1].includes(d.conceptId) || selectedConcepts.includes(d.conceptId)) && selectedSubgroups.includes(d.segmentId)
        );
        filteredQuestion = {
            questionId: question.questionId,
            questionName: question.questionName,
            questionText: question.questionText,
            questionPosition: question.questionPosition,
            data: filteredDataItems
        };
        filteredQuestions.push(filteredQuestion);
        filteredQuestions.sort(function (a, b) {
            return a.position - b.position;
        });
        filteredView = Object.assign({}, questionDetails, {questions: filteredQuestions});
        return filteredView;
    }

    public getDefaultFilterData(concept ?: Concept): Observable<SurveyQuestionFilter>{
        const defaultFilter: SurveyQuestionFilter = Object.assign({}, defaultSurveyQuestionFilter);
        const report$ = this.reportService.get();
        const concepts$ = this.conceptService.getReportDeliverableConcepts(DeliverableType.SURVEY_QUESTION.type)
        const subgroups$ = this.subgroupService.getReportSubgroups(DeliverableType.SURVEY_QUESTION.type);
        const deliverableViews$ = this.deliverableViewService.getDeliverableViews(DeliverableType.SURVEY_QUESTION.type);
        return zip(report$, concepts$, subgroups$, deliverableViews$).pipe(switchMap(result => {
            const report: Report = result[0];
            const deliverableViews: Array<DeliverableView> = result[3];

            defaultFilter.countries = [report.country];
            const concepts = concept ? [concept] : result[1];
            defaultFilter.concepts = this.getConceptFilters(concepts, deliverableViews);
            defaultFilter.subgroups = this.getSubgroupFilters(result[2]);
            defaultFilter.questions = this.getQuestionFilters(deliverableViews);
            return of(defaultFilter);
        }));
    }

    /**
     * getQuestions from Deliverable View meta Info
     * @param questionsViews
     * @private
     */
    private getQuestionFilters(questionsViews: Array<DeliverableView>): Array<FilterItem> {
        const questions = questionsViews.filter(question => question.metaInfo.excluded === 'false');
        const questionFilterItems: Array<FilterItem> = [];
        questions.forEach(function (item, i) {
            questionFilterItems.push({
                name: item.metaInfo.label,
                id: item.viewName,
                isSelected: false,
                position: item.metaInfo.position
            });
        });
        questionFilterItems.sort(function (a, b) {
            return a.position - b.position;
        });
        questionFilterItems[0].isSelected = true;
        return questionFilterItems;
    }

    /**
     * Returns concept filter items.
     *
     * @private
     * @param {Array<Concept>} concepts
     * @param deliverables
     * @returns {Array<FilterItem>}
     * @memberOf SurveyQuestionService
     */
    private getConceptFilters(concepts: Array<Concept>, questionsViews: Array<DeliverableView>): Array<FilterItem> {
        const metaInfoForExcludedConcepts = questionsViews[0].metaInfo;
        let conceptFilterItems: Array<FilterItem> = [];
        for (const item of concepts) {
            conceptFilterItems.push({
                name: item.name,
                id: item.exerciseConceptId,
                isSelected: true,
                position: item.position
            });
        }
        // metaInfo.excludedConcepts will have competitor concepts information which needs to be excluded in RS projects
        if (metaInfoForExcludedConcepts['excludedConcepts']) {
            const excludedConcepts = metaInfoForExcludedConcepts['excludedConcepts'];
            conceptFilterItems = conceptFilterItems.filter(it => !excludedConcepts.includes(it.id));
        }
        return conceptFilterItems;
    }

    /**
     * Returns subgroup filter items.
     *
     * @private
     * @param {Array<Subgroup>} subgroups
     * @returns {Array<FilterItem>}
     * @memberOf SurveyQuestionService
     */
    private getSubgroupFilters(subgroups: Array<Subgroup>): Array<FilterItem> {
        const subgroupFilterItems: Array<FilterItem> = [];
        for (const item of subgroups) {
            subgroupFilterItems.push({
                name: item.name,
                id: item.segmentId,
                isSelected: false,
                position: item.position
            });
        }
        subgroupFilterItems[0].isSelected = true;
        return subgroupFilterItems;
    }

    /**
     * Returns a tuple of col headers and table data for angular material table.
     *
     * @param {SurveyQuestionFilter} filter
     * @param {viewMetaInfo} sQuestion
     * @param viewMetaInfo
     * @returns {[Array<any>, Array<any>]} - The tuple of column headers and Table data.
     * @memberOf SurveyQuestionService
     */
    public getTableData(filter: SurveyQuestionFilter, sQuestion: any, viewMetaInfo: SurveyQuestionsMetaInfo): [Array<any>, Array<any>] {
        let data = [];
        const isConcepts = this.selectedCompareFilterItems(filter.compare);
        const selectedConcepts = this.selectedFilterItems(filter.concepts, 'concept');
        const sortedSelectedConcepts = selectedConcepts.sort(function (a, b) {
            return a.position - b.position;
        });
        const selectedSubgroups = this.selectedFilterItems(filter.subgroups, 'subgroup');
        const conceptsMap = this.getFilterItemMap(sortedSelectedConcepts);
        const subgroupsMap = this.getFilterItemMap(selectedSubgroups);
        const aggregateFilter = filter.show.aggregate;
        let rowData, cellDataKey, cellData, aggregateNSize;
        const ncolHeaders: any[] = [];
        let colHeaders: Array<any> = isConcepts ? [...sortedSelectedConcepts] : [...selectedSubgroups];
        const filteredData = this.getFilteredData(sQuestion.data, sortedSelectedConcepts, selectedSubgroups, isConcepts);
        filteredData.forEach((dataItem) => {
            cellData = {};
            rowData = {};
            let isAnswerExist = {};
            if (!dataItem.excluded) {
                isAnswerExist = data.find(at => at.answerText === dataItem.answerText);
            }
            const filteredMetaInfo = Object.keys(viewMetaInfo).length > 1 ? Object.values(viewMetaInfo.rowHighlights).find(x => x.rowId === dataItem.answerId) : null;
            cellDataKey = isConcepts ? conceptsMap[dataItem.conceptId] : subgroupsMap[dataItem.segmentId];
            const pattern = this.getFormatPattern(filter, dataItem.valueType);
            const roundedValue = this.utilsService.round(dataItem.value, pattern);
            cellData.value = numeral(roundedValue).format(pattern);
            cellData.actualValue = dataItem.value;
            cellData.allOtherStat = dataItem.allOtherStat;
            cellData.statTesting = this.getStatTestingValue(filter, dataItem, isConcepts);
            if (!isAnswerExist) {
                rowData.answerId = dataItem.answerId;
                rowData.answerText = dataItem.answerText;
                rowData.position = dataItem.answerPosition;
                rowData.color = filteredMetaInfo ? 'text-highlight ' + filteredMetaInfo.color : '';
                if (isConcepts && !cellDataKey && dataItem.conceptId === -1) {
                    rowData['Aggregate'] = cellData;
                } else if (cellDataKey) {
                    rowData[cellDataKey.name] = cellData;
                }
                data.push(rowData);
            } else {
                rowData = isAnswerExist;
                if (cellDataKey) {
                    rowData[cellDataKey.name] = cellData;
                }
            }
            if (isConcepts && dataItem.conceptId === -1 && !aggregateNSize) {
                aggregateNSize = dataItem.baseSize;
            }
            const columnHeader = cellDataKey && ncolHeaders.find(col => col.id === cellDataKey.id);
            if (cellDataKey && dataItem.conceptId > -1 && !columnHeader) {
                const colHeader = colHeaders.find(ch => ch.id === cellDataKey.id);
                const newValue = Object.assign({}, colHeader, {size: dataItem.baseSize});
                ncolHeaders.push(newValue);
            }
        });
        ncolHeaders.sort(function (a, b) {
            return a.position - b.position;
        });
        colHeaders = ncolHeaders;
        if (!filter.show.mention && colHeaders.length > 0) {
            const columnNames = colHeaders.map(column => column.name);
            data = this.getMentionLessThenFive(filter, data, columnNames);
        }
        if (aggregateFilter) {
            colHeaders.unshift({name: 'Aggregate', id: -1, size: aggregateNSize});
        }
        colHeaders.unshift({name: 'statement'});
        data.sort(function (a, b) {
            return a.position - b.position;
        });
        return [colHeaders, data];
    }

    /**
     * Returns mention<5% filter dependent data
     *
     * @param {SurveyQuestionFilter} filter
     * @param {data} data
     * @param colHeader
     * @memberOf SurveyQuestionService
     */
    private getMentionLessThenFive(filter: SurveyQuestionFilter, data: any[], colHeader: string[]): any[] {
        const threshold = filter.show.noDecimal ? 0.045 : (filter.show.oneDecimal ? 0.0495 : 0.0499);

        return data.filter((value) => {
            const shouldExclude = colHeader.every(header => {
                const actualValue = value[header]?.actualValue;
                return actualValue !== undefined && actualValue < threshold;
            });

            return !shouldExclude;
        });
    }

    /**
     * filtered out data as per concept and subgroup
     *
     * @param {data} data
     * @param selectedConcept
     * @param selectedSubgroup
     * @param isConcept
     * @memberOf SurveyQuestionService
     */
    getFilteredData(data: any[], selectedConcept: Array<FilterItem>, selectedSubgroup: Array<FilterItem>, isConcept: boolean): any[] {
        const filteredData = [];
        data.filter((question) => {
            if (isConcept && question.conceptId === -1 && selectedSubgroup.find(subgroup => subgroup.id === question.segmentId)) {
                filteredData.push(question);
            } else {
                if (selectedConcept.find(concept => concept.id === question.conceptId) && selectedSubgroup.find(subgroup => subgroup.id === question.segmentId)) {
                    filteredData.push(question);
                }
            }
        });
        //BUG FIX FOR S2-3262: if question data are not in sorted order then the results get messed up when showing aggregate data
        return filteredData.sort((a, b) => {
            if (a.conceptId > b.conceptId) {
                return 1;
            }
            if (a.conceptId < b.conceptId) {
                return -1;
            }
            return 0;
        });
    }

    /**
     * Returns stat testing testing label for the data item.
     *
     * @param {SurveyQuestionFilter} filter
     * @param {QuestionDataItem} dataItem
     * @param isConcepts
     * @memberOf SurveyQuestionService
     */
    private getStatTestingValue(filter: SurveyQuestionFilter, dataItem: QuestionDataItem, isConcepts: boolean): string {
        const statTestingFilter = filter.show.statTesting;
        let ids = [];
        let filterIds;
        let alphabets: Array<string>;
        if (isConcepts) {
            filterIds = filter.concepts
                .filter(concept => concept.isSelected)
                .map(concept => concept.id);
            ids = statTestingFilter && dataItem.eachOtherStat ? dataItem.eachOtherStat : ids;
        } else {
            filterIds = filter.subgroups
                .filter(subgroup => subgroup.isSelected)
                .map(subgroup => subgroup.id);
            ids = statTestingFilter && dataItem.eachOtherStatSubgroup ? dataItem.eachOtherStatSubgroup : ids;
        }
        alphabets = ids
            .map(id => filterIds.indexOf(id))
            .filter(index => index >= 0)
            .map(index => this.stringHelper.alphabetSeries(index));
        return this.stringHelper.sortAlphabeticSeries(alphabets).join('');
    }

    /**
     * Returns compare filter if concept is select return true else false
     *
     * @memberOf SurveyQuestionService
     * @param items
     */
    private selectedCompareFilterItems(items: Array<FilterItem>): boolean {
        const selectedItems = items ? items.filter(item => item.isSelected) : [];
        return !!selectedItems.find(item => item.id === CompareView.CONCEPT);
    }

    /**
     * Returns selected filter items.
     *
     * @private
     * @param {Array<FilterItem>} items
     * @param type
     * @returns {Array<FilterItem>}
     * @memberOf SurveyQuestionService
     */
    private selectedFilterItems(items: Array<FilterItem>, type: string): Array<FilterItem> {
        return items ? items.filter(item => item.isSelected) : [];
    }

    /**
     * Returns map of filter items.
     *
     * @private
     * @param {Array<FilterItem>} items
     * @returns {{[key: number]: FilterItem}}
     * @memberOf SurveyQuestionService
     */
    private getFilterItemMap(items: Array<FilterItem>): { [key: number]: FilterItem } {
        const itemsMap: { [key: number]: FilterItem } = {};
        items.forEach(item => {
            itemsMap[item.id] = item;
        });
        return itemsMap;
    }

    /**
     * Returns numeral js display pattern for the numeric value.
     *
     * @private
     * @param {SurveyQuestionFilter} filter
     * @returns {string}
     * @memberOf SurveyQuestionService
     */
    private getFormatPattern(filter: SurveyQuestionFilter, type): string {
        let pattern = '0';
        pattern = filter.show.oneDecimal ? '0.0' : pattern;
        pattern = filter.show.twoDecimal ? '0.00' : pattern;
        pattern = type === 'Percent' ? pattern + '%' : pattern;
        return pattern;
    }

    /**
     * getSelectedFilterItem
     * @param filterItem
     */
    public getSelectedFilterItem(filterItem) {
        const selectedFilterItems = [];
        for (const item of filterItem) {
            selectedFilterItems.push({
                name: item.name,
                id: item.id,
            });
        }
        return selectedFilterItems;
    }

    /**
     * getselectedShowFilter
     * @param filterItem
     */
    public getselectedShowFilter(filterItem) {
        const insight = {};
        for (const [k, v] of Object.entries(filterItem)) {
            if (v === true) {
                insight[k] = v;
            }
        }
        return insight;
    }

}


