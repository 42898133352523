import {Subgroup} from '@platform/models/subgroup.model';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {DatasetFilteritem, FilterItem, FinancialPotentialFilter} from '../models/filter.model';
import {FilterService} from '@platform/services/filter.service';
import {Injectable} from '@angular/core';
import {combineLatest, Observable, of, zip} from 'rxjs';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {switchMap, take} from 'rxjs/operators';
import {ReportService} from '@platform/services/report.service';
import {SubgroupService} from '@platform/services/subgroup.service';
import {ConceptService} from '@platform/services/concept.service';
import {Concept} from '@platform/models/concept.model';
import {StringHelper} from '@platform/services/string-helper.service';
import {TranslateService} from '@ngx-translate/core';
import {Deliverable} from '@platform/models/deliverable.model';
import {FinancialPotentialDeliverableView} from '../models/financial-potential.model';
import {defaultFinancialPotentialFilter} from '@app/deliverables/financial-potential/models/default-financial-potential-filter';
import {CompareView} from '@app/deliverables/financial-potential/models/compare-view-enum';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {BenchmarkService} from '@platform/services/benchmark.service';
import {Benchmark} from '@platform/models/benchmark.model';
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';

/**
 * `FinancialPotential Service` has all data operations for fetching attributes
 * and attributes filter data.
 *
 * @export
 * @class FinancialPotentialService
 */
@Injectable()
export class FinancialPotentialService {

    /**
     * Creates an instance of FinancialPotentialService.
     *
     * @constructor
     * @param {DeliverableViewService} deliverableViewService
     * @param productDeliverableViewService
     * @param {ReportService} reportService
     * @param {ConceptService} conceptService
     * @param {SubgroupService} subgroupService
     * @param stringHelper
     * @param {FilterService} filterService
     * @param translate
     * @param benchmarkService
     * @memberof FinancialPotentialService
     */
    constructor(
        private deliverableViewService: DeliverableViewService,
        private productDeliverableViewService: ProductDeliverableViewService,
        private reportService: ReportService,
        private conceptService: ConceptService,
        private subgroupService: SubgroupService,
        private stringHelper: StringHelper,
        private filterService: FilterService,
        private translate: TranslateService,
        private benchmarkService: BenchmarkService) {
    }

    /**
     * Returns an observable of `FinancialPotentialDeliverableView` data.
     *
     * @returns {Observable<FinancialPotentialDeliverableView>}
     * @memberof FinancialPotentialService
     */
    public getFinancialPotential(): Observable<FinancialPotentialDeliverableView> {
        const report$ = this.reportService.get();
        const filter$ = this.getFinancialPotentialFilter();
        const deliverableViews$ = this.deliverableViewService.getDeliverableViews(DeliverableType.FINANCIAL_POTENTIAL.type);
        return report$.pipe(switchMap(report => {
            return filter$.pipe(
            switchMap(filter => {
                return deliverableViews$.pipe(switchMap((deliverableViews) => {
                   const deliverableView = deliverableViews.find(it => it.viewName === filter.deliverableViewType);
                   const views$: Observable<FinancialPotentialDeliverableView> = this.productDeliverableViewService.get<FinancialPotentialDeliverableView>(report.id, deliverableView.productViewId);
                   return views$.pipe(switchMap(view => {
                   return of(this.filter(filter, view));
                }));
            }));
        })
        );
        }));
    }

    /**
     * Filter projection function for filtering the FinancialPotential data and returns
     * filtered `FinancialPotentialDeliverableView` data.
     *
     * @private
     * @param {FinancialPotentialFilter} filter
     * @param {FinancialPotentialDeliverableView} data
     * @returns {FinancialPotentialDeliverableView}
     * @memberof FinancialPotentialService
     */
    public filter(filter: FinancialPotentialFilter, data: FinancialPotentialDeliverableView): FinancialPotentialDeliverableView {
        const selectedConcepts = filter.concepts.filter(c => c.isSelected).map(c => c.id);
        const selectedSubgroups = filter.subgroups.filter(s => s.isSelected).map(s => s.id);
        const importedConcepts = filter.concepts.filter(it => it.isImported).map(s => s.id);
        let filteredDataItems;
        let filteredView;
        filteredDataItems = data.measures.filter(
            item => importedConcepts.includes(item.conceptId) ? selectedConcepts.includes(item.conceptId) :
                selectedConcepts.includes(item.conceptId) && selectedSubgroups.includes(item.segmentId)
        );
        filteredView = Object.assign({}, data, {measures: filteredDataItems});
        return filteredView;
    }

    /**
     * Returns observable of FinancialPotential filter data.
     *
     * @returns {Observable<FinancialPotentialFilter>}
     * @memberof FinancialPotentialService
     */
    public getFinancialPotentialFilter(): Observable<FinancialPotentialFilter> {
        const deliverableType = DeliverableType.FINANCIAL_POTENTIAL.type,
            filter$ = this.filterService.get<FinancialPotentialFilter>(deliverableType);
        return filter$;
    }

    /**
     * Loads default financialPotential filter data.
     *
     * @memberof FinancialPotentialService
     */
    public loadDefaultFilter(concept ?: Concept, compare?: FilterItem[]): Observable<FinancialPotentialFilter> {
        const defaultFilter: FinancialPotentialFilter = Object.assign({}, defaultFinancialPotentialFilter);
        const report$ = this.reportService.get();
        const concepts$ = this.conceptService.getReportDeliverableConceptsWithBenchmarks(DeliverableType.FINANCIAL_POTENTIAL.type);
        const subgroups$ = this.subgroupService.getReportSubgroups(DeliverableType.FINANCIAL_POTENTIAL.type);
        const benchmarks$ = this.benchmarkService.getBenchMark();
        const deliverableView$ = this.deliverableViewService.getDeliverableViews(DeliverableType.FINANCIAL_POTENTIAL.type);
        return zip(report$, concepts$, subgroups$, benchmarks$, deliverableView$).pipe(switchMap(result => {
            defaultFilter.countries = [result[0].country];
            const concepts = concept ? [concept] : result[1];
            const compareArray = compare ? compare : defaultFilter.compare;
            const hasBenchmarkConcepts = result[3].length > 0;
            const deliverableViews = result[4];
            if (hasBenchmarkConcepts) {
                defaultFilter.concepts = this.getConceptFiltersWithBenchmarks(concepts, result[3]);
            } else {
                defaultFilter.concepts = this.getConceptFilters(concepts);
            }
            defaultFilter.compare = compareArray;
            defaultFilter.subgroups = this.getSubgroupFilters(result[2]);
            defaultFilter.datasets = this.getDataSetsFilters(result[0].deliverables, deliverableViews);
            this.filterService.update(defaultFilter);
            return of(defaultFilter);
        }));
    }

    /**
     * Returns concept filter items.
     *
     * @private
     * @param {Array<Concept>} concepts
     * @returns {Array<FilterItem>}
     * @memberof AttributesService
     */
    private getConceptFilters(concepts: Array<Concept>): Array<FilterItem> {
        const conceptFilterItems: Array<FilterItem> = [];
        for (const item of concepts) {
            conceptFilterItems.push({
                name: item.name,
                id: item.exerciseConceptId,
                isSelected: true,
                position: item.position,
                isImported: false
            });
        }
        return conceptFilterItems;
    }

    private getConceptFiltersWithBenchmarks(concepts: Array<Concept>, benchmarks: Array<Benchmark>): Array<FilterItem> {
        const conceptFilterItems: Array<FilterItem> = [];
        const benchmarkConceptIds = [];
        const benchmarkReports = new Map();
        // navigate through benchmarks array to get report and concept details so that we can use it in Concept filter mapping
        benchmarks.forEach(benchmark => {
            if (!benchmarkReports.get(benchmark.benchmarkReportId)) {
                // here capture the reportId and report name in a Map -- require an API from backend for reportNames
                benchmarkReports.set(benchmark.benchmarkReportId, benchmark.benchmarkReportName);
            }
            // here capture all the benchmark conceptId's
            benchmarkConceptIds.push(benchmark.benchmarkConceptId);
        });
        for (const item of concepts) {
            if (benchmarkConceptIds.includes(item.id)) {
                // for Benchmark Concept call them imported true and add the report name
                const benchmarkConcept = benchmarks.find(it => it.benchmarkConceptId === item.id);
                conceptFilterItems.push({
                    name: benchmarkConcept.displayName ? benchmarkConcept.displayName : item.name,
                    id: item.exerciseConceptId,
                    isSelected: true,
                    position: item.position,
                    isImported: true,
                    reportName: benchmarkReports.get(item.reportId)
                });
            } else {
                // For all original concepts call them imported false
                conceptFilterItems.push({
                    name: item.name,
                    id: item.exerciseConceptId,
                    isSelected: true,
                    position: item.position,
                    isImported: false
                });
            }
        }
        return conceptFilterItems;
    }

    /**
     * Returns subgroup filter items.
     *
     * @private
     * @param {Array<Subgroup>} subgroups
     * @returns {Array<FilterItem>}
     * @memberof AttributesService
     */
    private getSubgroupFilters(subgroups: Array<Subgroup>): Array<FilterItem> {
        const subgroupFilterItems: Array<FilterItem> = [];
        for (const item of subgroups) {
            subgroupFilterItems.push({
                name: item.name,
                id: item.segmentId,
                isSelected: false,
                position: item.position,
                isDatabasable: item.isDatabaseable,
                isImported: false
            });
        }
        const dbSubgroupIndex = subgroupFilterItems.findIndex(it => it.isDatabasable);
        if (dbSubgroupIndex >= 0) {
            subgroupFilterItems[dbSubgroupIndex].isSelected = true;
        } else {
            subgroupFilterItems[0].isSelected = true;
        }
        return subgroupFilterItems;
    }

    /**
     * This will fetch the DataSets items
     * @param deliverables
     * @private
     */
    private getDataSetsFilters(deliverables: Array<Deliverable>, deliverableViews: Array<DeliverableView>): Array<DatasetFilteritem> {
        const datasetsFilterItems: Array<DatasetFilteritem> = [];
        const metaInfos = deliverableViews ? deliverableViews[0].metaInfo['alcoholDetails'] : null;
        // if there is metaInfo from backend then there will always be Retail and Public Data for FP deliverable
        if (metaInfos) {
            if (metaInfos['Retail'] === true) {
                datasetsFilterItems.push({
                    name: this.translate.instant('quick.predict.deliverables.financial-potential.filter.datasets.label.retail'),
                    isSelected: true
                });
            }
            if (metaInfos['Public'] === true) {
                datasetsFilterItems.push({
                    name: this.translate.instant('quick.predict.deliverables.financial-potential.filter.datasets.label.public'),
                    isSelected: metaInfos['Retail'] === false
                });
            }
        }
        return datasetsFilterItems;
    }

    /**
     * Returns updated map of Selected filter items for insights.
     *
     * @private
     * @param filter
     * @returns {Array<FilterItem>}
     * @memberof AttributesService
     */
    public getSelectedFilterItem(filterItem) {
        const selectedFilterItems = [];
        for (const item of filterItem) {
            selectedFilterItems.push({
                name: item.name,
                id: item.id,
            });
        }
        return selectedFilterItems;
    }

    /**
     * Returns true if the selected compare view is Concepts
     * @param items
     * @public
     */
    public isConceptsCompareFilter(items: Array<FilterItem>): boolean {
        const selectedItems = items ? items.filter(item => item.isSelected) : [];
        return !!selectedItems.find(item => item.id === CompareView.CONCEPT);
    }

    /**
     * Returns selected filter items.
     *
     * @private
     * @param {Array<FilterItem>} items
     * @returns {Array<FilterItem>}
     * @memberof ChartDataService
     */
    public selectedFilterItems(items: Array<FilterItem>): Array<FilterItem> {
        const selectedItems = items ? items.filter(item => item.isSelected) : [];
        return selectedItems;
    }

    /**
     * Returns true if the selected compare view is Concepts
     * @param items
     * @public
     */
    public isSelectedSubgroupDatabasable(items: Array<FilterItem>): boolean {
        const selectedSubgroup: FilterItem = items.find(item => item.isSelected === true);
        return selectedSubgroup ? selectedSubgroup.isDatabasable : false;
    }

    /**
     * This will return the alphabets of respective concept or subgroups based on their Id's Array
     * @param items
     * @param filterIds
     */
    public getAlphabetsForStats(items: Array<number>, filterIds: Array<number>) {
        let alphabets: Array<string>;
        alphabets = items
            .map(id => filterIds.indexOf(id))
            .filter(index => index >= 0)
            .map(index => this.stringHelper.alphabetSeries(index));
        return this.stringHelper.sortAlphabeticSeries(alphabets).join('');
    }

    /**
     * This method will fetch the corresponding text for quantil score
     * @param quintileValue
     */
    public getQuintileRank(quintileValue: number) {
        if (quintileValue != null) {
            if (quintileValue <= 19) {
                return this.translate.instant('quick.predict.deliverables.financial-potential.legend.row1.bottom');
            } else if (quintileValue <= 39) {
                return this.translate.instant('quick.predict.deliverables.financial-potential.legend.row1.belowAverage');
            } else if (quintileValue <= 59) {
                return this.translate.instant('quick.predict.deliverables.financial-potential.legend.row1.average');
            } else if (quintileValue <= 79) {
                return this.translate.instant('quick.predict.deliverables.financial-potential.legend.row1.aboveAverage');
            } else {
                return this.translate.instant('quick.predict.deliverables.financial-potential.legend.row1.top');
            }
        } else {
            return null;
        }
    }
}
