<ng-container *ngIf="showWordCloud">
  <ng-container *ngFor="let subgroup of questionSubgroups;trackBy: trackItem; let i = index">
    <ns-word-cloud-card [subgroup]="subgroup" [index]="i">
    </ns-word-cloud-card>
  </ng-container>
</ng-container>
<ng-container *ngIf="!questionSubgroups?.length">
    <ns-deliverables-empty-state  sifIcon="sif-no-results"
                                  message="There are no Results" message2="Try refining the filters to populate data.">
    </ns-deliverables-empty-state>
</ng-container>
