import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Report} from "@platform/models/report.model";
import {Concept} from "@platform/models/concept.model";
import * as numeral from 'numeral';
import {Measures} from "@app/deliverables/financial-potential/models/financial-potential.model";
import {FinancialPotentialService} from "@app/deliverables/financial-potential/services/financial-potential.service";
import {DeliverableConfigurationService} from '@platform/services/deliverable-configuration.service';

@Component({
  selector: 'ns-benchmark-concept-financial-potential',
  templateUrl: './benchmark-concept-financial-potential.component.html',
  styleUrls: ['./benchmark-concept-financial-potential.component.scss']
})
export class BenchmarkConceptFinancialPotentialComponent implements OnInit, OnChanges {

  @Input() report: Report;
  @Input() measureDataForConcept: Measures;
  @Input() concept: Concept;
  @Input() showPurchaseIntent: boolean;
  @Input() showUnits: boolean;
  @Input() showFrequency: boolean;

  piTop2: string;
  piQuintileRank: string;
  trialUnitsAmongFavs: string;
  frequencyAmongFavs: string;
  trialUnitsRankScore: string;
  frequencyRankScore: string;
  hasFpData = true;

  constructor(private financialPotentialService: FinancialPotentialService,
              private deliverableConfigurationService: DeliverableConfigurationService) {
  }

  ngOnInit(): void {
    this.initialize();
  }

  ngOnChanges() {
    this.initialize();
  }

  initialize() {
      this.deliverableConfigurationService.fetchAll(this.report.id).subscribe(deliverableConfiguration => {
            const fpConfiguration = deliverableConfiguration.find(it => it.name === 'fp')?.config;
            if (this.measureDataForConcept != null) {
                this.showUnits = fpConfiguration.trialUnits;
                this.showPurchaseIntent = fpConfiguration.purchaseIntent;
                this.showFrequency = fpConfiguration.frequency;
                this.piQuintileRank = this.financialPotentialService.getQuintileRank(this.measureDataForConcept.purchaseIntentData.rankScore);
                this.piTop2 = numeral(this.measureDataForConcept.purchaseIntentData.definitelyWouldBuy + this.measureDataForConcept.purchaseIntentData.probablyWouldBuy).format('0.0');
                this.trialUnitsAmongFavs = numeral(this.measureDataForConcept.trialUnitsData.trialUnitsAmongFavs).format('0.0');
                this.frequencyAmongFavs = numeral(this.measureDataForConcept.frequencyData.frequencyAmongFavs).format('0.0');
                this.trialUnitsRankScore = this.financialPotentialService.getQuintileRank(this.measureDataForConcept.trialUnitsData.rankScore);
                this.frequencyRankScore = this.financialPotentialService.getQuintileRank(this.measureDataForConcept.frequencyData.rankScore);
            }
                this.hasFpData = this.showUnits || this.showPurchaseIntent || this.showFrequency;
      });
  }
}
