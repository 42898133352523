<div class="deliverable-container gap-analysis-wrapper-content performance-content">
  <div *ngIf="!scoreCardConcept" class="sticky-header-container header-filter-container" [ngClass]="{ 'hide': isInsightEnable }">
    <div class="sticky-header-container">
              <ns-deliverable-nav-header [deliverableInfos]="deliverableInfos" (deliverableChanged)="onDeliverableChange($event)">

          <ns-kebab-menu class="kabab-menu">
          <ul class="kebab-menu-list">
            <li><span (click)="exportAsPNG()">{{ 'export.as.png' | translate }}</span></li>
          </ul>
        </ns-kebab-menu>
        <span rel="tooltip" matTooltipPosition="above" matTooltip=" {{'create.insights' | translate }}" *ngIf="isInternalUser" [ngClass]="{ 'disable-insight-btn': disableBtn }"
              class="insight-icon sif sif-insight" (click)="openInsightCreationForm()">
        </span>
          <span saveUserView>
                <ns-save-user-view class="save-views"
                                   [(views)]="userViews"
                                   [reportId]="report.id"
                                   [filter]="filter"
                                   [deliverableType]="deliverableType"
                                   (viewsChange)="userViews=$event"
                                   (selectUserView)="selectUserView($event)">
                    </ns-save-user-view>
            </span>
      </ns-deliverable-nav-header>
    </div>
  </div>
  <ns-deliverable-insight [enabled]="isInsightEnable" [deliverableData]="deliverableData" [isHTMLInsight]="addHTMLToInsight" (close)="closeInsight()">
  </ns-deliverable-insight>

  <div id="deliverable-container">
    <div class="filters-sticky-container" [ngClass]="{ 'hide': isInsightEnable }">
      <ns-gap-analysis-filter></ns-gap-analysis-filter>
    </div>
    <div id="gap-analysis-content" class="gap-analysis-content carousel-container">
        <div class="sticky-swipe-table-container carousel-panel">
<!--          <div class="pagination-dots">-->
<!--            <ul>-->
<!--              <li *ngFor="let item of gapAnalysisPages; let i = index"><span [ngClass]="(i == currentPage) ? 'active': 'not'" class="dots"></span></li>-->
<!--            </ul>-->
<!--          </div>-->
          <mat-paginator #gapAnalysisPagination (page)="pageChanged($event)" class="gap-analysis-paginator pagination" [hidePageSize]="true" [pageSize]="1" [pageIndex]="pageIndexNum"></mat-paginator>
        </div>
        <div class="deliverable-insight-gap-analysis insight-html-root">
          <ns-gap-analysis-chart [hideCurrentConcept] = "hideCurrentConcept" [chartData]="currentPageChartData" [chartOptions]="chartOptions"></ns-gap-analysis-chart>
        </div>
    </div>
    <!-- Footer -->
    <div *ngIf = "filter && !disableBtn" class="footer-section footer-legend">
        <div *ngIf = "!hideCurrentConcept" class="current-legend">
          <span></span>Current
        </div>
        <div class="bumo-legend">
          <span></span>Brand Used Most Often
        </div>
        <div *ngIf = "!hideCurrentConcept" class="gap-legend">
          <span></span>Competitive gaps
        </div>
    </div>
    <div *ngIf = "filter && !disableBtn" class="col-12 legend-container">
      <div class="section padding-2 text-center footer-legend-text">
        <p><small>{{'restage.deliverable.gap.analysis.footer.text1' | translate}}</small> </p>
        <p><small>{{'restage.deliverable.gap.analysis.footer.text2' | translate}}</small> </p>
      </div>
    </div>
  </div>
    <div *ngIf = "filter && disableBtn">
        <ns-deliverables-empty-state  sifIcon="sif-no-results"
                                      message="There are no Results" message2="Try refining the filters to populate data.">
        </ns-deliverables-empty-state>
    </div>
</div>
<ns-progress-spinner class="example-margin" [display]="displayProgressSpinner">
</ns-progress-spinner>
