<ng-container *ngIf="verbatimsLoadComplete && hasShownConcepts && hasShownSubgroups; else noData">
  <div *ngFor="let concept of strengthWatchouts.concepts let i = index; trackBy: identify" id="{{'sw-concept-' + concept.exerciseConceptId}}" class="strength-and-watchouts-export-png">
    <ng-container *ngIf="i === 0">
      <sw-verbatims-concept-row [concepts]="strengthWatchouts.concepts" [concept]="concept" [selectedView]="selectedViewFromChartOptions" [filter]="filter"></sw-verbatims-concept-row>
    </ng-container>
  </div>
</ng-container>
<ng-template #noData>
    <ns-deliverables-empty-state *ngIf="verbatimsLoadComplete" sifIcon="sif-no-results"
                                 message="There are no Results" message2="Try refining the filters to populate data.">
    </ns-deliverables-empty-state>
</ng-template>
