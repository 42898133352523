import {Subgroup} from '@platform/models/subgroup.model';
import {FilterService} from '@platform/services/filter.service';
import {Injectable} from '@angular/core';
import {Observable, zip, of} from 'rxjs';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {switchMap, take} from 'rxjs/operators';
import {ReportService} from '@platform/services/report.service';
import {SubgroupService} from '@platform/services/subgroup.service';
import {ConceptService} from '@platform/services/concept.service';
import {Concept} from '@platform/models/concept.model';
import {FilterItem, GapAnalysisFilter} from '../models/filter.model';
import {defaultGapAnalysisFilter} from '@app/deliverables/gap-analysis/models/default-gap-analysis-filter.model';
import {GapAnalysisDeliverableView} from '@app/deliverables/gap-analysis/models/gap-analysis.model';
import {Report} from '@platform/models/report.model';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {DeliverableViewType} from '@app/deliverables/gap-analysis/models/deliverable-view-type-enum';

/**
 * `GapAnalysisService` has all data operations for fetching gap analysis deliverables
 * and gap analysis deliverables filter data.
 *
 * @export
 * @class GapAnalysisService
 */
@Injectable({
  providedIn: 'root'
})
export class GapAnalysisService {

  /**
   * Creates an instance of GapAnalysisService.
   *
   * @constructor
   * @param {DeliverableViewService} deliverableViewService
   * @param {ReportService} reportService
   * @param {ConceptService} conceptService
   * @param {SubgroupService} subgroupService
   * @param {FilterService} filterService
   * @memberOf GapAnalysisService
   */
  constructor(
    private deliverableViewService: DeliverableViewService,
    private reportService: ReportService,
    private conceptService: ConceptService,
    private subgroupService: SubgroupService,
    private filterService: FilterService) { }

  /**
   * Returns an observable of `GapAnalysisDeliverableView` data.
   *
   * @returns {Observable<GapAnalysisDeliverableView>}
   * @memberOf GapAnalysisService
   */
  public getGapAnalysis(): Observable<GapAnalysisDeliverableView> {
    const deliverableType = DeliverableType.GAP_ANALYSIS.type;
    const filter$ = this.getGapAnalysisFilter();
    return filter$.pipe(
      switchMap(filter => {
        return this.deliverableViewService.filter<GapAnalysisDeliverableView, GapAnalysisFilter>(
          filter.deliverableViewType,
          deliverableType,
          this.filter.bind(this),
            null,
            true
        );
      })
    );
  }

  /**
   * Filter projection function for filtering the gap analysis data and returns
   * filtered `GapAnalysisDeliverableView` data.
   *
   * @private
   * @param {GapAnalysisFilter} filter
   * @param {GapAnalysisDeliverableView} data
   * @returns {GapAnalysisDeliverableView}
   * @memberOf GapAnalysisService
   */
  private filter(filter: GapAnalysisFilter, data: GapAnalysisDeliverableView): GapAnalysisDeliverableView {
    const relaunchConcepts = filter.concepts.map(c => c.id);
    const selectedConcepts = filter.concepts.filter(c => c.isSelected).map(c => c.id);
    const selectedSubgroups = filter.subgroups.filter(s => s.isSelected).map(s => s.id);
    const selectedAttributes = filter.attributes.filter(a => a.isSelected).map(a => a.name);
    const filteredStatements = [];
    let filteredStatement;
    let filteredDataItems;
    let filteredView;
    for (const statement of data.statements) {
      if (selectedAttributes.includes(statement.label)) {
        filteredDataItems = statement.statementData.filter(
          d => selectedConcepts.includes(d.conceptId) && selectedSubgroups.includes(d.segmentId)
        );
        const currentConceptData = statement.statementData.find(
          d => selectedSubgroups.includes(d.segmentId) && !relaunchConcepts.includes(d.conceptId)
        );

        const statementValue = filter.show.meanIndex ? currentConceptData?.conceptMeanValue : filter.show.topBoxIndex ? currentConceptData?.conceptTopBoxValue : currentConceptData?.conceptTopTwoValue;

        filteredStatement = {
          label: statement.label,
          currentStatementValue: statementValue,
          statementData: filteredDataItems
        };
        filteredStatements.push(filteredStatement);
      }
    }
    filteredView = Object.assign({}, data, { statements: filteredStatements });
    return filteredView;
  }

  /**
   * Returns observable of performance filter data.
   *
   * @returns {Observable<GapAnalysisFilter>}
   * @memberOf GapAnalysisService
   */
  public getGapAnalysisFilter(): Observable<GapAnalysisFilter> {
    const deliverableType = DeliverableType.GAP_ANALYSIS.type;
    return this.filterService.get<GapAnalysisFilter>(deliverableType);
  }

  /**
   * Loads default gap analysis filter data.
   *
   * @memberOf GapAnalysisService
   */
  public loadDefaultFilter(deliverableViews = null): Observable<GapAnalysisFilter> {
    const deliverableType = DeliverableType.GAP_ANALYSIS.type;
    const defaultFilter: GapAnalysisFilter = Object.assign({}, defaultGapAnalysisFilter);
    const report$ = this.reportService.get();
    const concepts$ = this.conceptService.getReportDeliverableConcepts(deliverableType);
    const subgroups$ = this.subgroupService.getReportSubgroups(deliverableType);
    const deliverableViews$: Observable<GapAnalysisDeliverableView> = this.deliverableViewService.get(DeliverableViewType.CONCEPT, deliverableType);
    return zip(report$, concepts$, subgroups$, deliverableViews$).pipe(switchMap(result => {
      defaultFilter.countries = [result[0].country];
      defaultFilter.concepts = this.getConceptFilters(result[1] as Concept[],  deliverableViews);
      defaultFilter.subgroups = this.getSubgroupFilters(result[2] as Subgroup[]);
      defaultFilter.attributes = this.getAttributeFilters(result[3] as GapAnalysisDeliverableView);
      this.filterService.update(defaultFilter);
      return of(defaultFilter);
    }));
  }

  /**
   * Returns concept filter items.
   *
   * @private
   * @param {Array<Concept>} concepts
   * @param report
   * @returns {Array<FilterItem>}
   * @memberOf GapAnalysisService
   */
  private getConceptFilters(concepts: Array<Concept> , deliverableViews): Array<FilterItem> {
    const conceptFilterItems: Array<FilterItem> = [];
      const view = deliverableViews[0];
      concepts.forEach((concept) => {
          const relaunchConcepts = view.metaInfo.restage;
          const matchedRelaunchConcept = relaunchConcepts.find((relaunchConcept) => concept.exerciseConceptId === relaunchConcept.conceptId);
          if (matchedRelaunchConcept) {
              conceptFilterItems.push({
                  name: concept.name,
                  id: concept.exerciseConceptId,
                  isSelected: true,
                  position: matchedRelaunchConcept.position
              });
          }
    });

    return conceptFilterItems;
  }

  /**
   * Returns subgroup filter items.
   *
   * @private
   * @param {Array<Subgroup>} subgroups
   * @returns {Array<FilterItem>}
   * @memberOf GapAnalysisService
   */
  private getSubgroupFilters(subgroups: Array<Subgroup>): Array<FilterItem> {
    const subgroupFilterItems: Array<FilterItem> = [];
    for (const item of subgroups) {
      subgroupFilterItems.push({
        name: item.name,
        id: item.segmentId,
        isSelected: false,
        position: item.position
      });
    }
    subgroupFilterItems[0].isSelected = true;
    return subgroupFilterItems;
  }

  /**
   * Returns attribute filter items.
   *
   * @private
   * @param gapAnalysisDeliverableView
   * @returns {Array<FilterItem>}
   * @memberOf GapAnalysisService
   */
  private getAttributeFilters(gapAnalysisDeliverableView): Array<FilterItem> {
    const attributeFilterItems: Array<FilterItem> = [];
    if(gapAnalysisDeliverableView.statements) {
      gapAnalysisDeliverableView.statements.forEach((statement, index) => {
        attributeFilterItems.push({
          name: statement.label,
          id: index,
          position: index,
          isSelected: true
        });
      })
    }
    return attributeFilterItems;
  }

  /**
   * Returns updated map of Selected filter items for insights.
   *
   * @private
   * @param filterItem
   * @returns {Array<FilterItem>}
   * @memberOf GapAnalysisService
   */
  public getSelectedFilterItem(filterItem) {
    const selectedFilterItems = [];
    for (const item of filterItem) {
      selectedFilterItems.push({
        name: item.name,
        id: item.id,
      });
    }
    return selectedFilterItems;
  }

  /**
   * Returns Selected show filter items for insights.
   *
   * @private
   * @param filterItem
   * @returns {Array<FilterItem>}
   * @memberOf GapAnalysisService
   */
  public getSelectedShowFilter(filterItem) {
    const insight = {};
    for (const [k, v] of Object.entries(filterItem)) {
      if (k === 'percents' && v['isSelected'] === true) {
        for (const [key, val] of Object.entries(v)) {
          if (val === true) {
            insight[key] = val;
          }
        }
      }
      if (v === true) {
        insight[k] = v;
      }
    }
    return insight;
  }

}
