import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {
    Click,
    ClickZone,
    StrengthWatchoutsConcept,
    StrengthWatchoutsDeliverableView
} from '@app/deliverables/strengths-watchouts/models/strength-watchouts.model';
import {combineLatest, Observable, of, zip} from 'rxjs';
import {FilterService} from '@platform/services/filter.service';
import {ConceptService} from '@platform/services/concept.service';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {Injectable} from '@angular/core';
import {ReportService} from '@platform/services/report.service';
import {SubgroupService} from '@platform/services/subgroup.service';
import {switchMap} from 'rxjs/operators';
import {StrengthWatchoutsFilter} from '@app/deliverables/strengths-watchouts/models/strength-watchouts-filter.model';
import {FilterItem} from '@app/deliverables/strengths-watchouts/models/strength-watchouts-filter.model';
import {
    defaultStrengthWatchoutsFilter
} from '@app/deliverables/strengths-watchouts/models/default-strength-watchouts-filter';
import {Concept} from '@platform/models/concept.model';
import {Subgroup} from '@platform/models/subgroup.model';
import {LocaleService} from '@platform/services/locale.service';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {Report} from '@platform/models/report.model';
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';

/**
 * `StrengthWatchoutsService` has all data operations for fetching strength and watch outs.
 *
 * @export
 * @class StrengthWatchoutsService
 */
@Injectable({
    providedIn: 'root'
})
export class StrengthWatchoutsService {
    private defaultShownConceptsInFilter: Array<FilterItem>;
    private defaultShownSubgroupsInFilter: Array<FilterItem>;

    /**
     * Creates an instance of AttributesService.
     *
     * @constructor
     * @param {DeliverableViewService} deliverableViewService
     * @param {ReportService} reportService
     * @param {ConceptService} conceptService
     * @param {SubgroupService} subgroupService
     * @param {FilterService} filterService
     * @param {localeService} localeService
     * @memberof StrengthWatchoutsService
     */
    constructor(
        private deliverableViewService: DeliverableViewService,
        private productDeliverableViewService: ProductDeliverableViewService,
        private reportService: ReportService,
        private conceptService: ConceptService,
        private subgroupService: SubgroupService,
        private filterService: FilterService,
        private localeService: LocaleService) {
    }

    /**
     * Sorts concepts by position.
     * @param {StrengthWatchoutsDeliverableView} concepts
     * @memberOf StrengthWatchoutsService
     */
    public static sortConcepts(concepts: Array<StrengthWatchoutsConcept>): Array<StrengthWatchoutsConcept> {
        // sort the concepts by position
        return concepts.sort((currentConcept: StrengthWatchoutsConcept, nextConcept: StrengthWatchoutsConcept) => {
            return currentConcept.position - nextConcept.position;
        });
    }

    /**
     * Returns all the concept clicks that are likes based on filter.
     * @param filter The filter to apply to concept clicks.
     * @param {Array<Click>}clicks The concept clicks to filter.
     * @return Array of clicks.
     */
    public static getLikes(filter: StrengthWatchoutsFilter, clicks: Array<Click>): Array<Click> {
        let showLikes: boolean;
        let likes: Array<Click> = [];
        if (filter?.show?.options?.find((option) => option.id === '1') && clicks) {
            showLikes = filter.show.options.find((option) => option.id === '1').isSelected;
            likes = showLikes ? clicks.filter(click => click.like === 1) : [];
        }
        return likes;
    }

    /**
     * Returns all the concept clicks that are dislikes based on filter.
     * @param filter The filter to apply to concept clicks.
     * @param {Array<Click>}clicks The concept clicks to filter.
     * @return Array of clicks
     */
    public static getDislikes(filter: StrengthWatchoutsFilter, clicks: Array<Click>): Array<Click> {
        let showDislikes: boolean;
        let dislikes: Array<Click> = [];
        if (filter?.show?.options?.find((option) => option.id === '0') && clicks) {
            showDislikes = filter.show.options.find((option) => option.id === '0').isSelected;
            dislikes = showDislikes ? clicks.filter(click => click.like === 0) : [];
        }
        return dislikes;
    }

    /**
     * Returns all the concept clicks that are dislikes based on filter.
     * @param filter The filter to apply to concept clicks.
     * @param {Array<Click>}clicks The concept clicks to filter.
     * @return Array of clicks
     */
    public static getNeutral(filter: StrengthWatchoutsFilter, clicks: Array<Click>): Array<Click> {
        let showNeutral: boolean;
        let neutral: Array<Click> = [];
        if (filter?.show?.options?.find((option) => option.id === '2') && clicks) {
            showNeutral = filter.show.options.find((option) => option.id === '2').isSelected;
            neutral = showNeutral ? clicks.filter(click => click.like === 2) : [];
        }
        return neutral;
    }

    /**
     * Method to sort filter.show.options for use in components such as zone.component.
     * @param filter the original filter that needs to be sorted correctly.
     */
    public updateFilterOptions(filter: StrengthWatchoutsFilter): StrengthWatchoutsFilter {
        const optionsList = [];
        // Find the dislike, neutral, or like options to be added to the new Filter.
        let negativeOption = filter.show.options.find(it => it.id === '0' || it.id === 'dislikes');
        let positiveOption = filter.show.options.find(it => it.id === '1' || it.id === 'likes');
        let neutralOption = filter.show.options.find(it => it.id === '2' || it.id === 'neutral');
        optionsList.push(filter.show.options.find(it => it.id === 'zones'));
        if (positiveOption) {
            positiveOption = {
                ...positiveOption,
                id: '1',
                name: 'Likes'
            };
            optionsList.push(positiveOption);
        }
        if (neutralOption) {
            neutralOption = {
                ...neutralOption,
                id: '2',
                name: 'Neutral'
            };
            optionsList.push(neutralOption);
        }
        if (negativeOption) {
            negativeOption = {
                ...negativeOption,
                id: '0',
                name: 'Dislikes'
            };
            optionsList.push(negativeOption);
        }
        const newFilter: StrengthWatchoutsFilter = {
            ...filter,
            show: {
                ...filter.show,
                options: optionsList,
            }
        };
        return newFilter;
    }

    /**
     * Return the click zones for a given concept based on filter and
     * @param filter The filter to apply to the concept.
     * @param concept The concept to filter click zones for.
     * @returns Array of zones.
     */
    public static getClickZones(filter: StrengthWatchoutsFilter, concept: StrengthWatchoutsConcept): Array<ClickZone> {
        const type = filter.compare.find((option) => option.isSelected);
        return concept.zones.filter(zone => zone.name !== 'Unassigned');
    }

    /**
     * Returns an observable of `StrengthWatchoutsDeliverableView` data.
     *
     * @returns {Observable<StrengthWatchoutsDeliverableView>}
     * @memberof StrengthWatchoutsService
     */
    public getStrengthWatchouts(): Observable<StrengthWatchoutsDeliverableView> {
        return combineLatest([
            this.reportService.get(),
            this.getStrengthWatchoutsFilter(),
            this.deliverableViewService.getDeliverableViews(DeliverableType.STRENGTH_WATCHOUTS.type)
        ]).pipe(switchMap(([report, filter, deliverableViews]) => {
            const selectedViewId = `${filter.compare.find(it => it.isSelected)?.id}`;
            const clickDataProductViewId = deliverableViews.find(it => it.viewName === 'clickData')?.productViewId;
            const otherProductViewId = deliverableViews.find(it => it.viewName === selectedViewId)?.productViewId;
            const productViewId = selectedViewId === 'verbatims' ? clickDataProductViewId : otherProductViewId;
            return this.productDeliverableViewService.get<StrengthWatchoutsDeliverableView>(report.id, productViewId).pipe(switchMap(data => {
                return of(this.filter(filter, data));
            }));
        }));
    }

    /**
     * Filter projection function for filtering the attributes data and returns
     * filtered `StrengthWatchoutsDeliverableView` data.
     *
     * @private
     * @param {StrengthWatchoutsFilter} filter
     * @param {StrengthWatchoutsDeliverableView} data
     * @memberof StrengthWatchoutsService
     * @return StrengthWatchoutsDeliverableView
     */
    private filter(filter: StrengthWatchoutsFilter, data: StrengthWatchoutsDeliverableView): StrengthWatchoutsDeliverableView {
        const selectedConcepts: Array<any> = filter.concepts.filter(concept => concept.isSelected).map(concept => concept.id);
        const selectedSubgroups: Array<any> = filter.subgroups.filter(subgroup => subgroup.isSelected).map(subgroup => subgroup.id);
        const filteredConcepts = [];
        let filteredView;
        const locale = filter.show.languages.find(language => language.isSelected);

        for (const concept of data.concepts) {
            const matchingConcept = (locale.id === concept.locale) && selectedConcepts.includes(concept.exerciseConceptId);
            if (matchingConcept) {
                const filteredConcept = this.filterByClickSubgroup(selectedSubgroups, concept);
                filteredConcepts.push(filteredConcept);
            }
        }

        filteredView = Object.assign({}, data, {concepts: filteredConcepts});
        return filteredView;
    }

    /**
     * Filter data by subgroup selected
     *
     * @private
     * @param {Array<number>} selectedSubgroups
     * @param {StrengthWatchoutsConcept} concept
     * @memberOf StrengthWatchoutsService
     */
    private filterByClickSubgroup(selectedSubgroups: Array<number>, concept: StrengthWatchoutsConcept): StrengthWatchoutsConcept {
        const filteredClicks: Array<Click> = [];
        const clicks = (concept.clicks || []);
        for (const click of clicks) {
            const matchingSegment = click.segments.find((segment: number) => {
                return selectedSubgroups.includes(segment);
            });
            if (matchingSegment) {
                filteredClicks.push(click);
            }
        }

        const filteredZones = concept.zones ? concept.zones.filter((zone: ClickZone) => {
            return selectedSubgroups.includes(parseInt(zone.segmentId, 10));
        }) : [];

        return Object.assign({}, concept, {clicks: filteredClicks}, {zones: filteredZones});
    }

    /**
     * Returns observable of attributes filter data.
     *
     * @returns {Observable<StrengthWatchoutsFilter>}
     * @memberOf StrengthWatchoutsService
     */
    public getStrengthWatchoutsFilter(): Observable<StrengthWatchoutsFilter> {
        const deliverableType = DeliverableType.STRENGTH_WATCHOUTS.type;
        return this.filterService.get<StrengthWatchoutsFilter>(deliverableType);
    }

    /**
     * Load the default filter options and updates store.
     */
    public loadDefaultFilter(concept ?: Concept): void {
        this.getDefaultFilterData(concept).subscribe(defaultFilter => {
            this.filterService.update(defaultFilter);
        });
    }

    /**
     * Returns an observable that resolves to default filter for strength and watchout deliverable.
     */
    public getDefaultFilterData(concept ?: Concept): Observable<StrengthWatchoutsFilter> {
        const defaultFilter: StrengthWatchoutsFilter = Object.assign({}, defaultStrengthWatchoutsFilter);
        const report$ = this.reportService.get();
        const concepts$ = this.conceptService.getReportDeliverableConcepts(DeliverableType.STRENGTH_WATCHOUTS.type);
        const subgroups$ = this.subgroupService.getReportSubgroups(DeliverableType.STRENGTH_WATCHOUTS.type);
        const deliverableView$ = this.deliverableViewService.getDeliverableViews(DeliverableType.STRENGTH_WATCHOUTS.type);
        return zip(report$, concepts$, subgroups$, deliverableView$).pipe(switchMap(result => {
            // Remove the Element Engagement view when the flag is not found
            const elementEngagementDetails = result[3]?.find(it => it.viewName === 'elementEngagements');
            const defaultCompareHasEE = defaultFilter.compare.find(it => it.id === 'elementEngagements');
            const hideEE = elementEngagementDetails.metaInfo ? elementEngagementDetails?.metaInfo['hideElementEngagement'] : false;
            if (defaultCompareHasEE && hideEE) {
                   defaultFilter.compare.pop();
            }
            // Keep the default options
            const report = result[0];
            const deliverableViews = result[3];

            defaultFilter.countries = [result[0].country];
            const concepts = concept ? [concept] : result[1];
            this.defaultShownConceptsInFilter = defaultFilter.concepts = this.getConceptFilters(concepts, result[3]);
            this.defaultShownSubgroupsInFilter = defaultFilter.subgroups = this.getSubgroupFilters(result[2]);
            defaultFilter.show.options = this.getShowOptionsFilters(result[0], result[3]);

            const selectedViewId = `${defaultFilter.compare.find(it => it.isSelected)?.id}`;
            const clickDataProductViewId = deliverableViews.find(it => it.viewName === 'clickData')?.productViewId;
            const otherProductViewId = deliverableViews.find(it => it.viewName === selectedViewId)?.productViewId;
            const productViewId = selectedViewId === 'verbatims' ? clickDataProductViewId : otherProductViewId;

            return this.productDeliverableViewService.get<StrengthWatchoutsDeliverableView>(report.id, productViewId).pipe(switchMap(deliverableView => {
                const languages = this.getLanguageFilter(report.locales);
                const languagesMap = {
                    languages: this.getLanguageFilterByLocaleDataAvailability(languages, deliverableView)
                };
                defaultFilter.show = Object.assign({}, defaultFilter.show, languagesMap);
                return of(defaultFilter);
            }));
        }));
    }

    /**
     * Method to update the locale information if the default language has no data.
     * If the default locale does not have any data, it is removed from the filter dropdown selection,
     * as it would not be able to display correctly.
     * The first locale found in the strengthWatchoutsDeliverableView.concepts is set as the new default.
     *
     * @param languages the filter for language.
     * @param deliverableView the StrengthWatchoutsDeliverableView that is being used to update the locale information.
     */
    public getLanguageFilterByLocaleDataAvailability(languages: Array<FilterItem>, deliverableView: StrengthWatchoutsDeliverableView): Array<FilterItem> {
        // check for available locales that has concept data
        const availableLocales = deliverableView.concepts.reduce((acc, concept) => {
            acc.add(concept.locale);
            return acc;
        }, new Set([]));
        // if the available local with concept data size is just 2 then remove "all"
        if (availableLocales.size === 2 && availableLocales.has('all')) {
            availableLocales.delete('all');
        }
        const languageWithDataAvailable = languages.reduce((acc, language) => {
            if (availableLocales.has(language.id)) {
                acc.push({...language});
            }
            return acc;
        }, []);

        // If the selected language didn't have any data, set the first available locale as the default language
        if (languageWithDataAvailable.length && !languageWithDataAvailable.find(it => it.isSelected)) {
            languageWithDataAvailable[0].isSelected = true;
        }

        return languageWithDataAvailable;
    }

    /**
     * Get languages in the Filters
     * @param locales
     * @private
     */
    private getLanguageFilter(locales: Array<string>): Array<FilterItem> {
        const languages: Array<FilterItem> = [];
        if (locales.length > 1) {
            languages.push({
                name: 'All Languages*',
                id: 'all',
                isSelected: true
            });
        }
        locales.forEach((locale, index) => {
            const localeDisplay = this.localeService.getLocaleLabel(locale);
            languages.push({
                name: localeDisplay,
                id: locale,
                isSelected: index === 0 && locales.length === 1
            });
        });
        return languages;
    }

    /**
     * Returns concept filter items.
     *
     * @private
     * @param {Array<Concept>} concepts
     * @returns {Array<FilterItem>}
     * @memberof StrengthWatchoutsService
     */
    private getConceptFilters(concepts: Array<Concept>, deliverableViews: Array<DeliverableView>): Array<FilterItem> {
        const deliverableType = DeliverableType.STRENGTH_WATCHOUTS.type;
        const clickDataView = deliverableViews.find(item => item.viewName === 'clickData');
        const metaInfoForExcludedConcepts = clickDataView.metaInfo;

        let conceptFilterItems: Array<FilterItem> = [];
        for (const item of concepts) {
            conceptFilterItems.push({
                name: item.name,
                id: item.exerciseConceptId,
                isSelected: false
            });
        }

        // metaInfo.excludedConcepts will have competitor concepts information which needs to be excluded in RS projects
        if (metaInfoForExcludedConcepts['excludedConcepts']) {
            const excludedConcepts = metaInfoForExcludedConcepts['excludedConcepts'];
            conceptFilterItems = conceptFilterItems.filter(it => !excludedConcepts.includes(it.id));
        }
        if(conceptFilterItems.length > 0) {
            conceptFilterItems[0].isSelected = true;
        }
        return conceptFilterItems;
    }

    /**
     * Returns subgroup filter items.
     *
     * @private
     * @param {Array<Subgroup>} subgroups
     * @returns {Array<FilterItem>}
     * @memberOf StrengthWatchoutsService
     */
    private getSubgroupFilters(subgroups: Array<Subgroup>): Array<FilterItem> {
        const subgroupFilterItems: Array<FilterItem> = [];
        for (const item of subgroups) {
            subgroupFilterItems.push({
                name: item.name,
                id: item.segmentId,
                isSelected: false
            });
        }
        if(subgroupFilterItems.length > 0) {
            subgroupFilterItems[0].isSelected = true;
        }
        return subgroupFilterItems;
    }

/*    getSelectedFilterItem(filterItem: any): any {
        const selectedFilterItems = [];
        for (const item of filterItem) {
            selectedFilterItems.push({
                name: item.name,
                id: item.id,
            });
        }
        return selectedFilterItems;
    }

    getselectedShowFilter(show: any) {
        const insight = {};
        insight[show.languages.find(it => it.isSelected === true).id] = true;
        const options = show.options.filter(it => it.isSelected === true);
        for (const item of options) {
            insight[item.id] = true;
        }
        return insight;
    }*/

    /**
     * Gets the selections for insights
     * @param filter The current filter for this deliverable
     * @param nativeElement The native element
     */
    public getSelectors(filter: StrengthWatchoutsFilter, nativeElement: any): any {
        const selectors = [];
        const elements: Array<Element> = nativeElement.querySelectorAll('.strength-and-watchouts-export-png');
        elements.forEach((element: Element) => {
            const selector = `#${element.id}`;
            selectors.push(selector);
        });
        return selectors && selectors.length > 0 ? selectors.slice(0, 10) : ['#element-engagement'];
    }

    /**
     * Return s the show filter options array
     * @param report
     * @returns {Array<FilterItem>}
     */
    private getShowOptionsFilters(report: Report, deliverableViews: Array<DeliverableView>): Array<FilterItem> {
        const deliverableType = DeliverableType.STRENGTH_WATCHOUTS.type;
        const deliverable = report.deliverables.find(d => d.type === deliverableType);
        const clickDataView = deliverableViews.find(item => item.viewName === 'clickData');

        const clickData = clickDataView.metaInfo;
        const showOptionsFilterItems: Array<FilterItem> = [];
        const tempShowFilter = [];
        showOptionsFilterItems[0] = ({
            name: 'Zones',
            id: 'zones',
            isSelected: true,
            isAvailable: true
        });
        for (const item in clickData) {
            tempShowFilter.push({
                name: clickData[item],
                id: item,
                isSelected: true,
                isAvailable: true
            });
        }
        tempShowFilter.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
        return showOptionsFilterItems.concat(tempShowFilter);
    }

    /**
     * Method to reset all filter concepts to isSelected=true. This is used when switching to elementEngagements.
     * @param filter the filter to be updated.
     */
    resetFilterConcepts(filter: StrengthWatchoutsFilter): StrengthWatchoutsFilter {
        const conceptList = [];
        filter.concepts.forEach((concept) => {
            conceptList.push({
                ...concept,
                isSelected: true
            });
        });
        return {
            ...filter,
            concepts: conceptList
        };
    }

    /**
     * Update Concepts by Locale
     * @param filter
     * @param strengthWatchouts
     */
    public updateConceptsByLocale(filter: StrengthWatchoutsFilter, strengthWatchouts: StrengthWatchoutsDeliverableView): StrengthWatchoutsDeliverableView {
        return {
            ...strengthWatchouts,
            concepts: strengthWatchouts.concepts.filter((concept) => concept.locale === filter.show.languages.find((language) => language.isSelected).id)
        };
    }

    changeFilterAsPerConfig(filter: StrengthWatchoutsFilter): StrengthWatchoutsFilter {
        filter = JSON.parse(JSON.stringify(filter));
        const selectedConceptId = filter.concepts.find(concept => concept.isSelected)?.id;
        const selectedSubgroupId = filter.subgroups.find(subgroup => subgroup.isSelected)?.id;
        filter.concepts = JSON.parse(JSON.stringify(this.defaultShownConceptsInFilter));
        filter.subgroups = JSON.parse(JSON.stringify(this.defaultShownSubgroupsInFilter));
        filter.concepts.forEach(concept => concept.isSelected = false);
        filter.subgroups.forEach(subgroup => subgroup.isSelected = false);
        if(selectedConceptId) {
            filter.concepts.find(concept => concept.id === selectedConceptId).isSelected = true;
        }
        if(selectedSubgroupId) {
            filter.subgroups.find(subgroup => subgroup.id === selectedSubgroupId).isSelected = true;
        }
        return filter;
    }
}
