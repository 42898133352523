import {Dictionary} from '@ngrx/entity';
import {SubgroupState} from '@platform/store/state/subgroup.state';
import {State} from '@platform/store/state/app.state';
import {subgroupAdapter} from '@platform/store/adapters/subgroup.adapter';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {Subgroup} from '@platform/models/subgroup.model';
import {Concept} from "@platform/models/concept.model";
import {selectAllConcepts} from "@platform/store/selectors/concept.selectors";

/**
 * Default selectors from adapter.
 *
 * @property
 */
const {
    selectEntities,
    selectAll
} = subgroupAdapter.getSelectors();

/**
 * Subgroups feature selector
 *
 * @export
 * @property
 */
export const selectSubgroupsFeature = createFeatureSelector<SubgroupState>('subgroups');
/**
 * Sungroups selector.
 *
 * @export
 * @property
 */
export const selectSubgroupsState = createSelector(
    selectSubgroupsFeature,
    (state: SubgroupState) => state
);
/**
 * Subgroups entity selector.
 *
 * @export
 * @property
 */
export const selectSubgroupEntities = createSelector(
    selectSubgroupsState,
    selectEntities
);
/**
 * Selector for selecting subgroups by id.
 *
 * @exports
 * @property
 */
export const selectSubgroupById = createSelector(
    selectSubgroupEntities,
    (entities: Dictionary<Subgroup>, props: { id: string }) => {
        return entities[props.id];
    }
);
/**
 * Selector for selecting all subgroups.
 *
 * @export
 * @property
 */
export const selectAllSubgroups = createSelector(
    selectSubgroupsState,
    selectAll
);

/**
 * Selector for selecting concepts for a report object.
 *
 * @export
 * @property
 */
export const selectReportSubgroups = (reportId: string) => {
    return createSelector(selectAllSubgroups, (subgroups: Subgroup[]) => {
            return subgroups.filter(c => c.reportId === reportId).sort((a, b) => a.position - b.position);
        }
    )
};
