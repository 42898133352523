import {State} from '@platform/store/state/app.state';
import {AppConfigService} from '@app/core/config/app-config.service';
import {ReportService} from '@platform/services/report.service';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Subgroup} from '@platform/models/subgroup.model';
import {addSubgroups} from '@platform/store/actions/subgroup.actions';
import {selectReportSubgroups} from '@platform/store/selectors/subgroup.selectors';

/**
 * This service provides operations for fetching and loading report subgroups.
 *
 * @example
 * constructor(private subgroupService: SubgroupService) { }
 *
 * @export
 * @class SubgroupService
 */
@Injectable({
    providedIn: 'root'
})
export class SubgroupService {

    /**
     * Creates an instance of SubgroupService.
     *
     * @param {Store<State>} store The application state.
     * @param {HttpClient} httpClient
     * @param {ReportService} reportService
     * @memberof SubgroupService
     */
    constructor(
        private store: Store<State>,
        private httpClient: HttpClient,
        private reportService: ReportService,
        private cs: AppConfigService) {
    }

    /**
     * Returns observable of subgroups for the current report object
     * from the store if available else fetch it using API.
     *
     * @example
     * const subgroups$ = subgroupService.getReportSubgroups();
     *
     * @returns {Observable<Array<Subgroup>>} The subgroups observable.
     * @memberof SubgroupService
     */
    public getReportSubgroups(deliverableType?: string): Observable<Array<Subgroup>> {
        const report$ = this.reportService.get();
        return report$.pipe(
            switchMap(report => this.load(report.id, deliverableType))
        );
    }

    /**
     * Loads all subgroups associated with the current report into
     * the store.
     *
     * @example
     * subgroupService.load('1');
     *
     * @param {string} reportId The report id.
     * @memberof SubgroupService
     */
    public load(reportId: string, deliverableType?: string): Observable<Array<Subgroup>> {
        if (deliverableType === undefined) {
            return this.fetchReportSubgroupsFromStore(reportId).pipe(switchMap((subgroups) => {
                if (subgroups.length) {
                    return of(subgroups);
                } else {
                    return this.fetchFromAPI(reportId).pipe(switchMap((subgroups) => {
                        this.loadOnStore(subgroups);
                        return of(subgroups);
                    }));
                }
            }));
        } else {
            return this.fetchFromAPI(reportId, deliverableType);
        }
    }

    /**
     * Returns an observable of subgroups fetched using the API.
     *
     * @private
     * @param {string} reportId The report id.
     * @returns {Observable<Array<Subgroup>>} The subgroups observable.
     * @memberof SubgroupService
     */
    public fetchFromAPI(reportId: string, deliverableType?: string): Observable<Array<Subgroup>> {
        let url = `${this.cs.config.reporting.url}/reports/${reportId}/subgroups`;
        if (deliverableType) {
            url = `${url}?deliverableType=${deliverableType}`
        }
        return this.httpClient.get<Array<Subgroup>>(url);
    }

    /**
     * Loads a list of subgroups into the store.
     *
     * @private
     * @param {Array<Subgroup>} subgroups Array of subgroups
     * @memberof SubgroupService
     */
    public loadOnStore(subgroups: Array<Subgroup>): void {
        this.store.dispatch(addSubgroups({subgroups}));
    }

    /**
     * Fetch all subgroups associated with the current report
     * from the store.
     *
     * @private
     * @param {string} reportId The report id.
     * @returns {Observable<Array<Subgroup>>} The subgroups observable.
     * @memberof SubgroupService
     */
    private fetchReportSubgroupsFromStore(reportId: string): Observable<Array<Subgroup>> {
        return this.store.select(selectReportSubgroups(reportId));
    }

}
