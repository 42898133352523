import {State} from '@platform/store/state/app.state';
import {AppConfigService} from '@app/core/config/app-config.service';
import {ReportService} from '@platform/services/report.service';
import {combineLatest, Observable, of} from 'rxjs';
import {skipWhile, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Concept} from '@platform/models/concept.model';
import {addConcept, addConcepts, deleteConcept, updateConcept} from '@platform/store/actions/concept.actions';
import {selectAllConcepts, selectReportConcepts} from '@platform/store/selectors/concept.selectors';
import {DeliverableConfigurationService} from "@platform/services/deliverable-configuration.service";
import {ProductDeliverableType} from "@platform/models/product-deliverable-type";

/**
 * This service has operations for fetching and loading report concepts.
 *
 * @example
 * constructor(private conceptService: ConceptService) { }
 *
 * @export
 * @class ConceptService
 */
@Injectable({
    providedIn: 'root'
})
export class ConceptService {

    /**
     * Creates an instance of ConceptService.
     *
     * @constructor
     * @param {Store<State>} store
     * @param {HttpClient} httpClient
     * @param {ReportService} reportService
     * @param {AppConfigService} appConfigService
     * @memberof ConceptService
     */
    constructor(
        private store: Store<State>,
        private httpClient: HttpClient,
        private reportService: ReportService,
        private appConfigService: AppConfigService,
        private deliverableConfigurationService: DeliverableConfigurationService) {
    }

    /**
     * Returns observable of concepts for the current report object
     * from the store if available else fetch it using API.
     *
     * @example
     * const concepts$ = conceptService.getReportConcepts();
     *
     * @returns {Observable<Array<Concept>>}
     * @memberof ConceptService
     */
    public getReportConcepts(): Observable<Array<Concept>> {
        const report$ = this.reportService.get();
        return report$.pipe(
            switchMap(report => {
                return this.load(report.id);
            }));
    }

    public getInteractionConceptAndCompetitors(): Observable<Array<Concept>> {
        const report$ = this.reportService.get();
        return report$.pipe(
            switchMap(report => {
                return this.fetchFromAPI(report.id);
            }),
            skipWhile(concepts => concepts.length === 0)
        );
    }

    /**
     * Returns list of concepts for deliverable with excluded concepts removed.
     *
     * @returns {Observable<Array<Concept>>}
     * @memberof ConceptService
     */
    public getReportDeliverableConcepts(deliverableType: string): Observable<Array<Concept>> {
        const report$ = this.reportService.get();
        return report$.pipe(
            switchMap(report => {
                return this.load(report.id, deliverableType);
            })
        );
    }

    /**
     * Returns list of concepts for deliverable with Benchmarks.
     *
     * @returns {Observable<Array<Concept>>}
     * @memberof ConceptService
     */
    public getReportDeliverableConceptsWithBenchmarks(deliverableType: string): Observable<Array<Concept>> {
        const report$ = this.reportService.get();
        return report$.pipe(
            switchMap(report => {
                // this.load(report.id, deliverableType);
                // return this.fetchAllConceptsFromStore();
                return this.load(report.id, deliverableType);
            })
        );
    }

    /**
     * Return the concept image uri for the locale. If locale is not available
     * then en_US locale image is returned.
     *
     * @param {Concept} concept
     * @param {string} locale code
     * @returns {string} Concept image uri
     */
    getConceptImageURI(concept: Concept, locale?: string): string {
        const image = concept?.images?.find(ci => (locale && ci.locale === locale) || (!locale && ci.locale.startsWith('en_')));
        if (image) {
            const uri = `${this.appConfigService.config.reporting.url}/reports/${concept.reportId}/concepts/${concept.id}?imageFiles=true&locale=${image.locale}`;
            return uri;
        }
        return null;
    }

    /**
     * Loads all concepts associated with the current report into
     * the store.
     *
     * @example
     * conceptService.load('1');
     *
     * @param {string} reportId The report id
     * @param deliverableType
     * @memberof ConceptService
     */
    public load(reportId: string, deliverableType?: string): Observable<Array<Concept>> {
        if (deliverableType === undefined) {
            return this.fetchReportConceptsFromStore(reportId).pipe(switchMap((concepts) => {
                if (concepts.length) {
                    return of(concepts);
                } else {
                    return this.fetchFromAPI(reportId).pipe(switchMap((conceptsNew) => {
                        this.loadOnStore(conceptsNew);
                        return of(conceptsNew);
                    }));
                }
            }));
        } else {
            return this.fetchFromAPI(reportId, deliverableType);
        }
    }

    /**
     * Returns an observable of concepts fetched using the API.
     *
     * @private
     * @param {string} reportId The report id.
     * @param deliverableType
     * @returns {Observable<Array<Concept>>}
     * @memberof ConceptService
     */
    public fetchFromAPI(reportId: string, deliverableType?: string): Observable<Array<Concept>> {
        let url = `${this.appConfigService.config.reporting.url}/reports/${reportId}/concepts`;
        if (deliverableType) {
            url = `${url}?deliverableType=${deliverableType}`;
        }
        return this.httpClient.get<Array<Concept>>(url);
    }

    /**
     * Loads a list of concepts into the store.
     *
     * @private
     * @param {Array<Concept>} concepts Array of concepts.
     * @memberof ConceptService
     */
    public loadOnStore(concepts: Array<Concept>): void {
        this.store.dispatch(addConcepts({concepts}));
    }

    public updateConceptToStore(concept: Concept): void {
        this.store.dispatch(updateConcept({concept}));
    }

    public removeConceptFromStore(id: string): void {
        this.store.dispatch(deleteConcept({id}));
    }

    public loadConceptToStore(concept: Concept): void {
        this.store.dispatch(addConcept({concept}));
    }


    /**
     * Fetch all concepts associated with the current report
     * from the store.
     *
     * @private
     * @param {string} reportId The report id.
     * @returns {Observable<Array<Concept>>}
     * @memberof ConceptService
     */
    private fetchReportConceptsFromStore(reportId: string): Observable<Array<Concept>> {
        return this.store.select(selectReportConcepts(reportId));
    }

    /**
     * Fetch all concepts associated with the current report and any associated benchmarks
     * from the store.
     *
     * @private
     * @param {string} reportId The report id.
     * @returns {Observable<Array<Concept>>}
     * @memberof ConceptService
     */
    private fetchAllConceptsFromStore(): Observable<Array<Concept>> {
        return this.store.select(selectAllConcepts);
    }

    updateConceptsConfiguration(concepts: Concept[]): Observable<any> {
        const url = `${this.appConfigService.config.reporting.url}/reports/${concepts[0].reportId}/concepts/trigger/bulkUpdate`;
        const requestBody = {concepts};
        return this.httpClient.put(url, requestBody);
    }

    /**
     * Returns list of exercise concept ids that are hidden via configuration
     * NOTE: this doesn't return competitor concepts
     * */
    getHiddenConcepts(type: ProductDeliverableType): Observable<Array<number>> {
        return combineLatest([
            this.deliverableConfigurationService.getDeliverableConfiguration(`concepts-${type.type}`),
            this.getReportConcepts(),
        ]).pipe(switchMap(([deliverableConfiguration, concepts]) => {
            let deliverableConfigurationHiddenConcepts = [];
            let globalHiddenConcepts = concepts.filter(it => Object.keys(it).indexOf('show')!==-1 && !it.show).map(it => it.exerciseConceptId);
            if (deliverableConfiguration) {
                deliverableConfigurationHiddenConcepts = deliverableConfiguration.config.concepts.filter(it => !it.show).map(it => it.exerciseConceptId);
            }
            return of(Array.from(new Set([...globalHiddenConcepts, ...deliverableConfigurationHiddenConcepts])));
        }));
    }
}
