import {AppConfigService} from '@app/core/config/app-config.service';
import {DeliverableViewType} from '@app/deliverables/sorting-matrix/models/deliverable-view-type.enum';
import {take, map, skipWhile, switchMap, tap, catchError, delay} from 'rxjs/operators';
import {Concept, SortingMatrixDeliverable} from '@app/deliverables/sorting-matrix/models/sorting-matrix.model';
import {combineLatest, Observable, of, zip} from 'rxjs';
import {Injectable} from '@angular/core';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {FilterService} from '@platform/services/filter.service';
import {
    ConceptFilterItem,
    FilterItem,
    SortingMatrixFilter
} from '@app/deliverables/sorting-matrix/models/filter.model';
import {ConceptService} from '@platform/services/concept.service';
import {defaultSortingMatrixFilter} from './models/default-sorting-matrix-filter';
import {Concept as ReportConcept} from '@platform/models/concept.model';
import {SubgroupService} from '@platform/services/subgroup.service';
import {Subgroup as ReportSubgroup} from '@platform/models/subgroup.model';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {WordCloudFilter} from '@app/deliverables/word-cloud/models/filter.model';

@Injectable()
export class SortingMatrixService {

    constructor(
        private deliverableViewService: DeliverableViewService,
        private filterService: FilterService,
        private cs: AppConfigService,
        private conceptService: ConceptService,
        private subgroupService: SubgroupService
    ) {
    }

    public getSortingMatrixData(): Observable<SortingMatrixDeliverable> {
        return this.deliverableViewService.get<SortingMatrixDeliverable>(
            DeliverableViewType.SORTING_MATRIX,
            DeliverableType.SORTING_MATRIX.type
        );
    }

    /**
     * Returns observable of SortingMatrix filter data.
     */
    public getSortingMatrixFilter(): Observable<SortingMatrixFilter> {
        const deliverableType = DeliverableType.SORTING_MATRIX.type,
            filter$ = this.filterService.get<SortingMatrixFilter>(deliverableType);
        return filter$;
    }

    /**
     * Returns an observable of filtered `SortingMatrixDeliverable` combined with concept image data.
     */
    public getFilteredDataWithConceptImage(): Observable<SortingMatrixDeliverable> {
        const sortingMatrix$ = this.getFilteredData();
        const concepts$ = this.conceptService.getReportConcepts().pipe(take(1));
        const filteredData$ = combineLatest([sortingMatrix$, concepts$]).pipe(
            skipWhile(([sortingMatrix, concepts]) => !sortingMatrix || concepts.length === 0),
            map(([sortingMatrix, concepts]) => {
                sortingMatrix.concepts.forEach(concept => {
                    const reportConcept = concepts.find(_concept => _concept.exerciseConceptId === concept.exerciseConceptId);
                    concept.conceptName = reportConcept.name;
                    concept.position = reportConcept.position;
                    const reportConceptImage = reportConcept.images.find((image) => {
                        return image.locale.includes('en');
                    });
                    concept.image = {
                        imagePath: `${this.cs.config.reporting.url}/reports/${reportConcept.reportId}/concepts/${reportConcept.id}?imageFiles=true&locale=${reportConceptImage ? reportConceptImage.locale : reportConcept.images[0].locale
                        }`,
                        height: reportConcept.imageHeight,
                        width: reportConcept.imageWidth
                    };
                });
                return sortingMatrix;
            })
        );
        return filteredData$;
    }

    /**
     * Returns an observable of filtered `SortingMatrixDeliverable` data.
     */
    public getFilteredData(): Observable<SortingMatrixDeliverable> {
        return this.deliverableViewService
            .filter<SortingMatrixDeliverable, SortingMatrixFilter>(
                DeliverableViewType.SORTING_MATRIX,
                DeliverableType.SORTING_MATRIX.type,
                this.filter.bind(this)
            );
    }

    /**
     * Updates the store with default filter values.
     * This function should only be called once when filter component is initialized.
     */
    public loadDefaultFilter(): Observable<SortingMatrixFilter> {
        const defaultFilter: SortingMatrixFilter = Object.assign({}, defaultSortingMatrixFilter);
        const concepts$: Observable<ReportConcept[]> = this.conceptService.getReportDeliverableConcepts(DeliverableType.SORTING_MATRIX.type);
        const subgroups$: Observable<ReportSubgroup[]> = this.subgroupService.getReportSubgroups(DeliverableType.SORTING_MATRIX.type);
        const sortingMatrix$ = this.getSortingMatrixData();

        return zip(concepts$, subgroups$, sortingMatrix$).pipe(switchMap(result => {
            defaultFilter.concepts = this.getConceptFilters(result[0]);
            defaultFilter.subgroups = this.getSubgroupFilters(result[1]);
            defaultFilter.weight = this.getWeightFilters(result[2]);
            this.filterService.update(defaultFilter);
            return of(defaultFilter);
        }));
    }

    getWeightFilters(sortingMatrix: SortingMatrixDeliverable): any {
        let weight: number;
        if ((sortingMatrix.axisData.governingQuestionLabel1.toLowerCase() === 'need/desire'
                || sortingMatrix.axisData.governingQuestionLabel1.toLowerCase() === 'desire')
            && sortingMatrix.axisData.governingQuestionLabel2.toLowerCase() === 'uniqueness') {
            weight = 65;
        } else if ((sortingMatrix.axisData.governingQuestionLabel2.toLowerCase() === 'need/desire'
                || sortingMatrix.axisData.governingQuestionLabel2.toLowerCase() === 'desire')
            && sortingMatrix.axisData.governingQuestionLabel1.toLowerCase() === 'uniqueness') {
            weight = 35;
        } else {
            weight = 50;
        }
        return weight;
    }


    /**
     * Returns concept filter items.
     */
    private getConceptFilters(concepts: Array<ReportConcept>): Array<ConceptFilterItem> {
        return concepts.map(concept => {
            return {
                'name': concept.name,
                'id': concept.exerciseConceptId,
                'selected': true,
                'position': concept.position,
                'showId': true
            };
        });
    }

    /**
     * Returns subgroup filter items.
     */
    private getSubgroupFilters(subgroups: Array<ReportSubgroup>): Array<FilterItem> {
        const subgroupItem: Array<FilterItem> = [];
        subgroups.forEach(
            subgroup => {
                subgroupItem.push(
                    {
                        'name': subgroup.name,
                        'id': subgroup.segmentId,
                        'selected': false
                    }
                );
            }
        );
        subgroupItem[0].selected = true;
        return subgroupItem;

    }

    /**
     * Filter projection function for filtering the sorting matrix data and returns
     * filtered `SortingMatrixDeliverable` data.
     * This function is passed as the third argument to the filter method of `DeliverableViewService`
     */
    public filter(filter: SortingMatrixFilter, data: SortingMatrixDeliverable): SortingMatrixDeliverable {
        const selectedConceptIds = filter.concepts
            .filter(concept => concept.selected)
            .map(concept => concept.id);
        const selectedSubgroup = filter.subgroups.find(subgroup => subgroup.selected);
        const filteredConcepts = data.concepts.filter((concept) => {
            return selectedConceptIds.indexOf(concept.exerciseConceptId) >= 0 && concept.segmentId === selectedSubgroup.id;
        });

        return {
            ...data,
            concepts: this.updateRanks(filteredConcepts, filter.weight)
        };
    }

    /**
     * Updates the rank of all concepts based on the selected weight of need/desire to uniqueness
     */
    private updateRanks(concepts: Concept[], weight: number): Concept[] {
        const xWeight: number = weight / 100;
        const yWeight: number = 1 - xWeight;
        const sortedConcepts = concepts.sort((a, b) => {
            const bRank = b.needDesire * xWeight + b.uniqueness * yWeight;
            const aRank = a.needDesire * xWeight + a.uniqueness * yWeight;
            const rank = bRank - aRank;
            return rank;
        });
        const updatedConcepts = sortedConcepts.map((concept, index) => {
            return {
                ...concept,
                rank: index + 1
            };
        });
        return updatedConcepts;
    }

    /**
     * Returns the concepts data to be consumed by the chart component.
     */
    public getChartData(concepts) {
        return concepts.map((concept) => {
            return {
                conceptName: concept.conceptName,
                conceptNumber: concept.position,
                exerciseConceptId: concept.exerciseConceptId,
                x: concept.needDesire,
                y: concept.uniqueness,
                imagePath: concept.image.imagePath,
                rank: concept.rank,
                showId: concept.showId
            };
        });
    }

    /**
     * Returns updated map of Selected filter items for insights.
     *
     * @private
     * @param filter
     * @returns {Array<FilterItem>}
     * @memberof SortingMatrixService
     */
    public getSelectedFilterItem(filterItem) {
        const selectedFilterItems = [];
        for (const item of filterItem) {
            selectedFilterItems.push({
                name: item.name,
                id: item.id,
            });
        }
        return selectedFilterItems;
    }

    /**
     * Returns Selected show filter items for insights.
     *
     * @private
     * @param filter
     * @returns {Array<FilterItem>}
     * @memberof SortingMatrixService
     */
    public getSelectedShowFilter(filterItem) {
        const selectedFilterItems = [];
        for (const item of filterItem) {
            if (item.showId) {
                selectedFilterItems.push({
                    showId: item.showId,
                    position: item.position,
                    conceptId: item.id
                });
            }
        }
        return selectedFilterItems;
    }
}
