<div class="deliverable-container financial-potential-content">
  <ng-container *ngIf="!scoreCardConcept">
    <!--Deliverable headers -->
    <div class="sticky-header-container header-filter-container" [ngClass]="{'hide': isInsightEnable}">
        <ns-deliverable-nav-header [deliverableInfos]="deliverableInfos" (deliverableChanged)="onDeliverableChange($event)">
            <ns-kebab-menu class="kabab-menu">
                <ul class="kebab-menu-list">
                    <li><span (click)="exportAsPNG()">{{ 'export.as.png' | translate }}</span></li>
                </ul>
            </ns-kebab-menu>
            <span *ngIf="isInternalUser" [ngClass]="{'disable-insight-btn': disableBtn}" rel="tooltip" matTooltipPosition="above" matTooltip=" {{'create.insights' | translate }}" class="insight-icon sif sif-insight" (click)="openInsightCreationForm()"></span>
            <span saveUserView>
                <ns-save-user-view class="save-views"
                                   [(views)]="userViews"
                                   [filter]="filter"
                                   [reportId]="report.id"
                                   [deliverableType]="deliverableType"
                                   (viewsChange)="userViews=$event"
                                   (selectUserView)="selectUserView($event)"></ns-save-user-view>
            </span>
        </ns-deliverable-nav-header>
    </div>
    <ns-deliverable-insight [enabled]="isInsightEnable" [deliverableData]="deliverableData" [isHTMLInsight]="addHTMLToInsight" (close)="closeInsight()"></ns-deliverable-insight>
  </ng-container>

  <!--filters -->
  <div id="deliverable-container">
    <!--Financial Potential filter-->
    <div class="filters-sticky-container">
      <ns-fp-filter [alcoholStudy]="isAlcoholStudy" [concept]="scoreCardConcept" [deliverableViews]="deliverableViews"></ns-fp-filter>
    </div>

    <!--Financial Potential Table component -->
    <div class="table-wrapper financial-potential-table-container deliverable-insight-fp insight-html-root" *ngIf="(filter && dataSource.length > 0 && hasData)">
        <table id="financial-potential-table" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <!-- Concept Column -->
            <ng-container  matColumnDef="name">
              <th class="empty-cell" mat-header-cell *matHeaderCellDef ></th>
                <td class="concept" mat-cell *matCellDef="let rowData; let i = index">
                  <div   class="details">
                    <span class="badge" *ngIf="!rowData?.isImported">{{getRowBadgeLabel(i)}}</span>
                    <strong class="header-title">{{rowData.name}}</strong>
                    <sup *ngIf="isConceptView">{{matchCategoryWithConcept(rowData)}}</sup>
                    <div class="base-size">n={{rowData.nsize}}
                      <span class="imported-concept-report-name" *ngIf="rowData?.isImported">({{rowData?.reportName}})</span></div>
                  </div>
                </td>
            </ng-container>

            <!-- PURCHASE INTENT Column -->
            <ng-container matColumnDef="purchaseIntent">
              <th class="measure-header" mat-header-cell *matHeaderCellDef >
                <div class="block">
                  <span class="title">{{"quick.predict.deliverables.financial-potential.table.label.purchaseIntent" | translate }}</span>
                  <div class="table-head-range-indicator">
                    <svg class="chart" width="320" height="20">
                      <g class="chart-main" transform="translate(0 0)">
                        <line class="dashed-border-right" stroke-dasharray="2, 2" x1="280" y1="0" x2="280" y2="20" stroke-width="0.2"
                              stroke="black"></line>
                        <line class="dashed-border-left" stroke-dasharray="2, 2" x1="1" y1="0" x2="1" y2="20" stroke-width="0.2"
                              stroke="black"></line>
                        <text id="id" class="start-range-text" x="4" y="16" text-anchor="start" font-size="10" font-weight="700"
                              fill="#B1B2B4">0%</text>
                        <text id="upper-id" class="end-range-text" x="276" y="16" text-anchor="end" font-size="10" font-weight="700"
                              fill="#B1B2B4">100%</text>
                      </g>
                    </svg>
                  </div>
                </div>
              </th>
              <td class="measure-chart" mat-cell *matCellDef="let rowData">
                <div class="bar-chart">
                  <div class="quantile-score" [ngClass]="{'no-stat-testing': !filter.show.statTesting}">
                    <span >{{rowData.purchaseIntentRankScore}}</span>
                  </div>
                  <div *ngIf="filter.show.statTesting && !isConceptView" class="stat-testing">
                    <span class="small margin-top-0-point-8 margin-bottom-0-point-5 padding-bottom-1" *ngIf="!rowData?.isImported">{{rowData.purchaseIntentSubgroupStats}}</span>
                  </div>
                  <div *ngIf="filter.show.statTesting && isConceptView" class="stat-testing">
                    <span class="small margin-top-0-point-8 margin-bottom-0-point-5 " *ngIf="!rowData?.isImported">{{rowData.purchaseIntentConceptStats}}</span>
                  </div>
                  <ns-chart-horizontal-bar [barChart]="rowData.purchaseIntentBarChart"></ns-chart-horizontal-bar>
                </div>
              </td>
            </ng-container>

            <!-- TRIAL UNITS AMONG FAVS Column -->
            <ng-container matColumnDef="trialUnitsAmongFavs">
              <th class="measure-header" mat-header-cell *matHeaderCellDef >
                <div class="block">
                  <span class="title">{{"quick.predict.deliverables.financial-potential.table.label.trialUnits" | translate }}</span>
                  <div class="table-head-range-indicator">
                    <svg class="chart" width="320" height="20">
                      <g class="chart-main" transform="translate(0 0)">
                        <line class="dashed-border-right" stroke-dasharray="2, 2" x1="280" y1="0" x2="280" y2="20" stroke-width="0.2"
                              stroke="black"></line>
                        <line class="dashed-border-left" stroke-dasharray="2, 2" x1="1" y1="0" x2="1" y2="20" stroke-width="0.2"
                              stroke="black"></line>
                        <text id="id" class="start-range-text" x="4" y="16" text-anchor="start" font-size="10" font-weight="700"
                              fill="#B1B2B4">0</text>
                        <text id="upper-id" class="end-range-text" x="276" y="16" text-anchor="end" font-size="10" font-weight="700"
                              fill="#B1B2B4">{{getTrialUnitsMaxValue()}}</text>
                      </g>
                    </svg>
                  </div>
                </div>
              </th>
              <td class="measure-chart trialUnitsBarChart" mat-cell *matCellDef="let rowData">
                <div class="bar-chart">
                  <div class="quantile-score" [ngClass]="{'no-stat-testing': !filter.show.statTesting}">
                    <span>{{rowData.trialUnitsRankScore}}</span>
                  </div>
                  <div *ngIf="filter.show.statTesting && !isConceptView" class="stat-testing">
                    <span class="small margin-top-0-point-8 margin-bottom-0-point-5" *ngIf="!rowData?.isImported">{{rowData.trialUnitsSubgroupStats}}</span>
                  </div>
                  <div *ngIf="filter.show.statTesting && isConceptView" class="stat-testing">
                    <span class="small margin-top-0-point-8 margin-bottom-0-point-5" *ngIf="!rowData?.isImported">{{rowData.trialUnitsConceptStats}}</span>
                  </div>
                  <ns-chart-horizontal-bar [barChart]="rowData.trialUnitsBarChart"></ns-chart-horizontal-bar>
                </div>
              </td>
            </ng-container>

            <!-- FREQUENCY AMONG FAVS Column -->
            <ng-container matColumnDef="frequencyAmongFavs">
              <th class="measure-header" mat-header-cell *matHeaderCellDef >
                <div class="block">
                  <span class="title">{{"quick.predict.deliverables.financial-potential.table.label.frequency" | translate }}</span>
                  <div class="table-head-range-indicator">
                    <svg class="chart" width="320" height="20">
                      <g class="chart-main" transform="translate(0 0)">
                        <line class="dashed-border-right" stroke-dasharray="2, 2" x1="280" y1="0" x2="280" y2="20" stroke-width="0.2"
                              stroke="black"></line>
                        <line class="dashed-border-left" stroke-dasharray="2, 2" x1="1" y1="0" x2="1" y2="20" stroke-width="0.2"
                              stroke="black"></line>
                        <text id="id" class="start-range-text" x="4" y="16" text-anchor="start" font-size="10" font-weight="700"
                              fill="#B1B2B4">0</text>
                        <text id="upper-id" class="end-range-text" x="276" y="16" text-anchor="end" font-size="10" font-weight="700"
                              fill="#B1B2B4">{{getFrequencyUnitsMaxValue()}}</text>
                      </g>
                    </svg>
                  </div>
                </div>
              </th>
              <td class="measure-chart frequencyBarChart" mat-cell *matCellDef="let rowData">
                <div class="bar-chart">
                  <div class="quantile-score" [ngClass]="{'no-stat-testing': !filter.show.statTesting}">
                    <span>{{rowData.frequencyRankScore}}</span>
                  </div>
                  <div *ngIf="filter.show.statTesting && !isConceptView" class="stat-testing">
                    <span class="small margin-top-0-point-8 margin-bottom-0-point-5" *ngIf="!rowData?.isImported">{{rowData.frequencySubgroupStats}}</span>
                  </div>
                  <div *ngIf="filter.show.statTesting && isConceptView" class="stat-testing">
                    <span class="small margin-top-0-point-8 margin-bottom-0-point-5" *ngIf="!rowData?.isImported">{{rowData.frequencyConceptStats}}</span>
                  </div>
                  <ns-chart-horizontal-bar [barChart]="rowData.frequencyBarChart"></ns-chart-horizontal-bar>
                </div>
              </td>
            </ng-container>

            <!-- CONCEPT SCORE CARD Column -->
            <ng-container matColumnDef="conceptPotentialScore">
              <th class="measure-header last-column" mat-header-cell *matHeaderCellDef [hidden]="!isConceptView || !isSubgroupDatabasable">
                <span class="title">{{"quick.predict.deliverables.financial-potential.table.label.conceptPotentialScore" | translate }}</span>
              </th>
              <td class="value last-column" mat-cell *matCellDef="let rowData" [hidden]="!isConceptView || !isSubgroupDatabasable">
                <div class="quantile-score-concept-potential-score">
                  <span>{{rowData.conceptPotentialRankScore}}</span>
                </div>
                <div>
                  <span class="item">{{rowData.conceptPotentialScore}}</span>
                </div>
              </td>
            </ng-container>

          <!--overall Table iterator -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

      <!-- Import Concepts Section -->
<!--      <div class="import-section" *ngIf="isConceptView && isSubgroupDatabasable">
        <button *ngIf="!isImport" (click)="openImport()" mat-button class="import-button">+ Import Concepts</button>
        <div *ngIf="isImport" class="import-details">
          <div class="import-details-body">
            <div class="form">
              <input matInput placeholder="Please enter Project Name or Concept Name" class="import-search-bar">
              <button mat-button class="import-search-button"><span class="sif sif-search">search</span></button>
            </div>
            <div class="buttons">
              <button (click)="cancelImport()" mat-button class="cancel">Cancel</button>
              <button (click)="applyImport()" mat-button class="import">Import</button>
            </div>
          </div>
        </div>
      </div>-->

      <!--Footer legend Section-->
      <div class="row">
        <ns-fp-legend class="col-12 legend-container" [areRankScoresNull]="areRankScoresNull()" [categoriesData] = "getCategories()" [isConceptView]="isConceptView">  </ns-fp-legend>
      </div>
    </div>
      <div *ngIf = "filter && !hasData">
          <ns-deliverables-empty-state  sifIcon="sif-no-results"
                                        message="There are no Results" message2="Try refining the filters to populate data.">
          </ns-deliverables-empty-state>
      </div>
  </div>
</div>
<ns-progress-spinner class="example-margin" [display]="displayProgressSpinner">
</ns-progress-spinner>
